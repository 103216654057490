/* eslint-disable */
import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import CheckIcon from "@mui/icons-material/Check";

//Custom Components
import DialogActionButton from "@/ui/Dialog/DialogActionButton";

//Types
import { IDialogActionButtonDetail } from "@/@types/ui/Dialog";

const DialogActionButtonConfirm: FunctionComponent<IDialogActionButtonDetail> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<CheckIcon />} {...props} ariaLabel="confirm" ariaControls="confirm">
      {t("buttons.confirm")}
    </DialogActionButton>
  );
};

export default DialogActionButtonConfirm;
