import * as React from 'react';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// MUI
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// MUI Icons
import PersonIcon from '@mui/icons-material/Person';

// Custom Components
import HeaderMenuButton from '@/ui/Header/HeaderMenuButton';
import HeaderMenuIconButton from '@/ui/Header/HeaderMenuIconButton';
import HeaderMenuItem from '@/ui/Header/HeaderMenuItem';
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';
import { authService } from '@/services/authService';

// Types
import { Permission } from '@/@types/common';

export interface IProfileMenu {
  needPermission?: Permission[];
}

const ProfileMenu: FunctionComponent<IProfileMenu> = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext) as UserContextType;
  const tenantContext = useContext(TenantContext) as TenantContextType;
  const navigate = useNavigate();

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const handleUserDetails = () => {
    navigate('/profile');
  };

  const handleLogout = () => {
    authService.logout(tenantContext);
    window.location.reload();
    // navigate("/login");
  };

  const label =
    userContext && userContext.firstName ? userContext.firstName : 'USER';

  return mdDown ? (
    <HeaderMenuIconButton icon={<PersonIcon />}>
      <HeaderMenuItem
        label={t('menu.profile')}
        icon="person"
        onClick={handleUserDetails}
      />
      <HeaderMenuItem
        label={t('menu.logout')}
        icon="exit_to_app"
        onClick={handleLogout}
      />
    </HeaderMenuIconButton>
  ) : (
    <HeaderMenuButton
      text={label}
      startIcon={<PersonIcon />}
      showExpandIcon
      desc="profilemenu"
    >
      <HeaderMenuItem
        label={t('menu.profile')}
        icon="person"
        onClick={handleUserDetails}
      />
      <HeaderMenuItem
        label={t('menu.logout')}
        icon="exit_to_app"
        onClick={handleLogout}
      />
    </HeaderMenuButton>
  );
};

export default ProfileMenu;
