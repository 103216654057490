/* eslint-disable */
import * as React from "react";
import { useState } from "react";

//Material-UI Core Components
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";

//Material-UI Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { IPasswordControlProps } from "@/@types/controls/controls";

function PasswordControl(props: IPasswordControlProps) {

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setShowPassword((prevState) => {
      return !prevState;
    });
  };

  const handleMouseDownPassword = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
  };

  return (
    <BaseInputControl
      {...props}
      type={showPassword ? "text" : "password"}
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}

export default PasswordControl;
