import { createContext } from 'react';
import { Moment } from 'moment';

// Types
import {
  Permission,
  IUserSettings,
  IUserStatePreParsed,
  IMapSettings,
} from '@/@types/common';

export type UserContextType = {
  id: number;
  loaded: boolean;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  tenantId: number;
  roleId: number;
  permissionIds: number[];
  userSettings: IUserSettings;
  mapSettings?: IMapSettings;
  expireTime: Moment | null;
  setUserData: (data: IUserStatePreParsed) => void;
  setUserSettings: (settings: IUserSettings, otherSettings?: boolean) => void;
  setRecentTab: (page: string, tab: number | string) => void;
  getRecentTab: (page: string, defaultTab: number) => string | number;
  setRecent: (key: string, identifier: string, details: object) => void;
  getRecent: (key: string, identifier: string, defaultDetail: object) => object;
  setRecentFilters: <ValueType>(
    identifier: string,
    id: string,
    filter: ValueType,
    clearFilter?: boolean
  ) => void;
  hasAnyPermission: (needAnyPermission: Permission[]) => boolean;
  setLayerVisible: (id: string, isVisible: boolean) => void;
  setMultipleLayerVisible: (obj: object) => void;
};

const UserContext = createContext<UserContextType | null>(null);
UserContext.displayName = 'UserContext';

export default UserContext;
