import React, { useContext, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI Compoenents
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';

// Custom Components
import useFormController from '@/components/useFormController';
import FormContent from '@/components/FormContent';

// UI
import DynamicDialog from '@/ui/Dialog/DynamicDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import DialogActionButtonAdd from '@/ui/Dialog/ActionButtons/DialogActionButtonAdd';
import DialogActionButtonSave from '@/ui/Dialog/ActionButtons/DialogActionButtonSave';
import { GridContainer } from '@/ui/Grid';
import DialogActionButtonCancel from '@/ui/Dialog/ActionButtons/DialogActionButtonCancel';
import {
  CardToolbarMenuButton,
  CardToolbarMenuItemActivate,
  CardToolbarMenuItemDeactivate,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';

// Lib
import useApi from '@/lib/api/useApi';

// Contexts
import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';

// Types
import { ClosingDetails } from '@/@types/components/formController';

const SearchSettingsDialog: FunctionComponent<IDialogProps> = (props) => {
  const { hideDialog } = useContext(DialogContext);
  const { showNotification } = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { dc, mode, form, dataId, onClose, initialRecord } = props;

  const {
    record,
    validation,
    fields,
    pickerDataControllers,
    onFieldChange,
    doInsert,
    doUpdate,
    doClose,
    doDelete,
  } = useFormController({
    mode,
    recordId: dataId,
    form,
    dc,
    onClose,
    initialRecord,
  });

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.insert_toponim_tip_success';
            showNotification(msg, 'success');
            close({ dataChanged: true, action: 'insert', id: result.id });
          } else if (result.error) {
            const msg = 'error.insert_toponim_tip_success';
            showNotification(msg, 'error');
          }
        })
        .catch((resp) => {
          showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_toponim_tip_success';
            showNotification(msg, 'success');
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = 'error.update_toponim_tip_success';
            showNotification(msg, 'error');
          }
        })
        .catch((resp) => {
          showNotification(resp.error, 'error');
        });
    }
  };

  const handleDelete = () => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.delete_toponim_tip_success';
            showNotification(msg, 'success');
            close({ dataChanged: true, action: 'delete' });
          }
        })
        .catch((resp) => {
          if (resp.error) {
            const msg = t('error.delete_toponim_tip') + resp.error;
            showNotification(msg, 'error');
          }
        });
    }
  };

  const handleActivate = () => {
    const api = useApi();
    const url = `system/toponim-tipovi/${record.id}/activate`;
    api
      .patch(url)
      .then((resp) => {
        if (resp.success) {
          close({ dataChanged: true, action: 'update' });
        } else {
          const msg = 'error.activate_toponim_tip';
          showNotification(msg, 'error');
        }
      })
      .catch(() => {
        const msg = 'error.activate_toponim_tip';
        showNotification(msg, 'error');
      });
  };

  const handleDeactivate = () => {
    const api = useApi();
    const url = `system/toponim-tipovi/${record.id}/deactivate`;
    api
      .patch(url)
      .then((resp) => {
        if (resp.success) {
          close({ dataChanged: true, action: 'update' });
        } else {
          const msg = 'error.activate_toponim_tip';
          showNotification(msg, 'error');
        }
      })
      .catch(() => {
        const msg = 'error.activate_toponim_tip';
        showNotification(msg, 'error');
      });
  };

  const handleClose = () => {
    if (doClose) {
      doClose()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: false, action: 'cancel' });
          }
        })
        .catch(() => {});
    }
  };

  const close = (result: ClosingDetails) => {
    onClose(result);
    hideDialog();
  };

  return (
    <DynamicDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <DialogToolbarHeading>
            {t('titles.toponim_tipovi')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          {mode === 'update' ? (
            <CardToolbarMenuButton>
              {record.active ? (
                <CardToolbarMenuItemDeactivate onClick={handleDeactivate} />
              ) : (
                <CardToolbarMenuItemActivate onClick={handleActivate} />
              )}
              <CardToolbarMenuItemDelete onClick={handleDelete} />
            </CardToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <FormContent
              title={t('admin:titles.search_settings_information') as string}
              fieldNames={[
                'id',
                'toponim_tip',
                'ttoken',
                'app_permission_id',
                'mw_procedure_name',
                'active',
              ]}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={mode}
              columns={2}
              pickerDataControllers={pickerDataControllers}
              pickerStyleProps={{ ListboxMaxHeight: 200 }}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert' ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === 'update' ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
};

export default SearchSettingsDialog;
