export interface IFormats {
  date: string;
  time: string;
  datetime: string;
  timedate: string;
  supported_date_formats: string[];
}

const formats: IFormats = {
  date: 'DD.MM.YYYY',
  time: 'HH:mm',
  datetime: 'DD.MM.YYYY. HH:mm',
  timedate: 'HH:mm, DD.MM.YYYY.',
  supported_date_formats: [
    'DDMM HHmm',
    'DD.MM HH:mm',
    'DD.MM.YYYY',
    'DD.MM.YYYY HH:mm',
    'DD.MM.YY HH:mm',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DD',
    'YYYY-MM-DD HH:mm',
  ],
};

export { formats };
