/* eslint-disable */
import React, { Component, FC, useState } from "react";
import Chart from "react-apexcharts";

export type ChartType = {
    options: {
        chart: {
            id: string
        },
        xaxis: {
            categories: string[],
        }
        colors: any[],
    },
    series: {
        name: string,
        data: number[],
    }[]
}

type IChartProps = {
    chart: ChartType
}

const LoginLogsChart: FC<IChartProps> = (props) => {
    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={props.chart.options}
                        series={props.chart.series}
                        type="bar"
                        width={1600}
                        height={500}
                    />
                </div>
            </div>
        </div>
    );
}

export default LoginLogsChart;