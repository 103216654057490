import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const MapLegend: IModel = {
  title: 'Map Legend',
  apiPath: 'system/map_legends',
  forms: {
    insert: {
      fields: ['legend_ttoken', 'legend_width', 'legend_height', 'legend_rule', 'legend_style', 'legend_options'],
    },
    update: {
      fields: ['id', 'legend_ttoken', 'legend_width', 'legend_height', 'legend_rule', 'legend_style', 'legend_options'],
    },
    search: {
      fields: ['legend_ttoken', 'legend_width', 'legend_height', 'legend_rule', 'legend_style', 'legend_options'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'legend_ttoken', 'legend_width', 'legend_height', 'legend_rule', 'legend_style', 'legend_options'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'legend_ttoken',
      source: 'legend_ttoken',
      ttoken: 'admin:legend.ttoken',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 50,
      },
    },
    {
      title: 'legend_width',
      source: 'legend_width',
      ttoken: 'admin:legend.width',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'legend_height',
      source: 'legend_height',
      ttoken: 'admin:legend.height',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'legend_rule',
      source: 'legend_rule',
      ttoken: 'admin:legend.rule',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 50,
      },
    },
    {
      title: 'legend_style',
      source: 'legend_style',
      ttoken: 'admin:legend.style',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 50,
      },
    },
    {
      title: 'legend_options',
      source: 'legend_options',
      ttoken: 'admin:legend.options',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 2000,
      },
    },
    coreFields.created_on,
    coreFields.modified_on
  ],
};

export default MapLegend;
