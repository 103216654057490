/* eslint-disable */
import React from 'react'
import { useTranslation } from 'react-i18next';

// MUI
import LaunchIcon from '@mui/icons-material/Launch';
import { PhotoCamera } from '@mui/icons-material';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import Permissions from '@/lib/permissions';

// Types
import { TCustomGFIActionProps } from '@/@types/views/map'

const ShowPhotodocButton = (props: TCustomGFIActionProps) => {

    const { t } = useTranslation();

    const { featureData, layerIndex = 0 } = props;

    const handleShowPhotodoc = () => {
        const id = featureData.features[layerIndex].properties.objekt_id;
    
        if (id) {
          const url = `${
            location.origin
          }/ena/photodoc/?rec_id=${id.toString()}`;
          window.open(url, '_blank');
        }
    };

    return (
        <MapCardActionButton
            startIcon={<PhotoCamera />}
            onClick={handleShowPhotodoc}
            aria-label="photo"
            disabled={false}
            needPermission={[Permissions.Ena]}
            tooltip='tooltips.buttons.show_photos'
        >
            {t('buttons.show_photos')}
            <LaunchIcon
            style={{ marginLeft: '7px', width: '16px', height: '16px' }}
            />
        </MapCardActionButton>
    )
}

export default ShowPhotodocButton