// Types
export type DateHeadingValue = "recent" | "week" | "month" | "year";

export type DateHeadingOption = {
  title: string
  value: DateHeadingValue
}

export type DateSubOption = {
  label: string
  value: TDateSubOptionsValues
  icon?: "history" | "present" | "future"
}

export type DateHeadingOptions = Array<DateHeadingOption>;

export type DateSubOptions = {
  [key in DateHeadingValue]: Array<DateSubOption>
}


export const dateOptions: DateHeadingOptions = [{
    title: "datefilter.recent_heading",
    value: "recent"
  }, {
    title: "datefilter.week_heading",
    value: "week"
  }, {
    title: "datefilter.month_heading",
    value: "month"
  }, {
    title: "datefilter.year_heading",
    value: "year"
}];

export const dateSubOptions: DateSubOptions = {
  recent:[{
      label: "datefilter.tomorrow",
      value: "tomorrow",
      icon: "future"
    }, {
      label: "datefilter.today",
      value: "today",
      icon: "present"
    }, {
      label: "datefilter.yesterday",
      value: "yesterday",
      icon: "history"
  }],
  week: [{
      label: "datefilter.next_2_weeks",
      value: "next_2_weeks",
      icon: "future"
    }, {
      label: "datefilter.next_week",
      value: "next_week",
      icon: "future"
    }, {
      label: "datefilter.next_7_days",
      value: "next_7_days",
      icon: "future"
    }, {
      label: "datefilter.this_week",
      value: "this_week",
      icon: "present"
    }, {
      label: "datefilter.last_7_days",
      value: "last_7_days",
      icon: "history"
    }, {
      label: "datefilter.last_week",
      value: "last_week",
      icon: "history"
    }, {
      label: "datefilter.last_2_weeks",
      value: "last_2_weeks",
      icon: "history"
  }],
  month: [{
      label: "datefilter.next_month",
      value: "next_month",
      icon: "future"
    }, {
      label: "datefilter.next_30_days",
      value: "next_30_days",
      icon: "future"
    }, {
      label: "datefilter.this_month",
      value: "this_month",
      icon: "present"
    }, {
      label: "datefilter.last_30_days",
      value: "last_30_days",
      icon: "history"
    }, {
      label: "datefilter.last_month",
      value: "last_month",
      icon: "history"
  }],
  year: [{
      label: "datefilter.next_year",
      value: "next_year",
      icon: "future"
    }, {
      label: "datefilter.this_year",
      value: "this_year",
      icon: "present"
    }, {
      label: "datefilter.last_year",
      value: "last_year",
      icon: "history"
  }]
};

export type TDateSubOptionsValues = "next_year" | "this_year" | "last_year" 
| "last_month" | "last_30_days" | "this_month" | "next_30_days" | "next_month" 
| "last_2_weeks" | "last_week" | "last_7_days" | "this_week" | "next_7_days" | "next_week" | "next_2_weeks"
| "yesterday" | "today" | "tomorrow";