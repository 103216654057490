/* eslint-disable */ // TODO remove this and fix errors (no time now)
/* eslint-disable no-bitwise */ // i don't even want to try to refactor Pieros bitwise code
import React from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import numbro from 'numbro';

// MUI Components
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

// Custom Components
import { formats } from '@/lib/formats';
import { helpers } from '@/lib/helpers';
import {
  BoolFn,
  VoidFn,
  RenderFn,
  ISubModelsWithData,
  RowActionFn,
  AllowRowActionFn,
  IArg,
} from '@/@types/ui/Table';
import { FieldAny, IFieldMultiPicker } from '@/@types/models/model';
import useDocuments, { IDocuments } from '@/components/useDocuments';
import useApi, { IApi } from '@/lib/api/useApi';
import { DCFile, DCRecord } from '@/@types/lib/dataController';
import {
  FullscreenImageContextType,
  useFullscreenImages,
} from '@/context/FullscreenImageContext/FullscreenImageContext';

class TableGenerator {
  t: TFunction;

  onAction: RowActionFn;

  allowAction: AllowRowActionFn;

  apiInstance: IApi;

  docs: IDocuments;

  fullScreenImageContext: FullscreenImageContextType;

  constructor(
    t: TFunction,
    rowActionHandler: RowActionFn,
    allowRowAction: AllowRowActionFn
  ) {
    this.t = t;
    const emptyFnc: VoidFn = () => {};
    const alwaysAllow: BoolFn = () => true;

    this.onAction = rowActionHandler || emptyFnc;
    this.allowAction = allowRowAction || alwaysAllow;

    this.apiInstance = useApi();

    this.docs = useDocuments();

    this.fullScreenImageContext = useFullscreenImages();
  }

  generateRenderFunction(field: FieldAny, dc: ISubModelsWithData): RenderFn {
    const fieldDc = dc[field.source];
    const tg: TableGenerator = this;

    function renderPicker({ value }: IArg) {
      if (fieldDc) {
        if (value === null || value === undefined) {
          return '';
        }
        if (Array.isArray(value) && (field as IFieldMultiPicker).multi) {
          const labels = value.map((val) => {
            const rec = fieldDc.records.find((x: any) => x.value === val);
            return rec ? tg.t(rec.label) : '';
          });
          return labels.join(', ');
        }
        // const rec = fieldDc.records.find((x) => x.value === (val.value || value));
        const rec = fieldDc.records.find(
          (x: any) =>
            x.value === value ||
            (value.hasOwnProperty('value') && value.value === x.value)
        );
        return rec ? tg.t(rec.label) : '';
      }
      if (value && value.hasOwnProperty('value')) {
        return value.value;
      }
      if (field.items) {
        const ind = field.items.values.indexOf(value);
        const valueLabel = tg.t(field.items.labels[ind]);
        return field.items.icons ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon
              sx={{
                color:
                  field.items.iconColors && field.items.iconColors[ind]
                    ? field.items.iconColors[ind]
                    : '#111',
              }}
              fontSize="small"
            >
              {field.items.icons[ind]}
            </Icon>
            <div style={{ fontSize: '14px', marginLeft: '4px' }}>
              {valueLabel}
            </div>
          </div>
        ) : (
          valueLabel
        );
      }
      return value;
    }

    function renderRadio({ value }: IArg) {
      if (fieldDc) {
        if (value === null || value === undefined) {
          return '';
        }
        const rec = fieldDc.records.find((x: any) => x.value === value);
        return rec ? tg.t(rec.label) : '';
      }
      if (field.items) {
        const ind = field.items.values.indexOf(
          value && value.hasOwnProperty('value') ? value.value : value
        );
        return tg.t(field.items.labels[ind]);
      }
      return value;
    }

    function renderCheckbox({ value }: IArg) {
      if (Number.isInteger(value)) {
        if (fieldDc) {
          const rec = fieldDc.records.find((x: any) => x.value === value);
          return rec ? tg.t(rec.label) : '';
        }
        const { items } = field;
        if (!items) {
          return null;
        }
        // extract powers of two from value and add according labels to the codes array
        const codes = [];
        let i = 0;
        while (value) {
          // it will eventually decrease to 0
          if (value & 1) {
            // if smallest value bit is 1
            const p = 1 << i; // Math.pow(2, i);
            codes.push(tg.t(items.labels[items.values.indexOf(p)]));
          }
          i += 1;
          value >>= 1; // value = Math.floor(value / 2);
        }
        return codes.join(', ');
      }
      if (Array.isArray(value)) {
        if (fieldDc) {
          return value
            .map((x) => {
              const rec = fieldDc.records.find(
                (f: any) => f.value === x || f.value === x.value
              );
              return rec ? tg.t(rec.label) : '';
            })
            .filter((a) => a !== '')
            .join(', ');
        }
        const { items } = field;
        if (items) {
          const labels = value
            .map((x) => {
              if (items.labels.indexOf(x)) {
                return tg.t(items.labels[x - 1]);
              }
              return null;
            })
            .filter((x) => x !== null);
          return labels.join(', ');
        }
        return value;
      }
      return value;
    }

    // function renderButton({ value, row }: IArg) {
    //   const fieldButton: IFieldButton = field as IFieldButton;
    //   const idAttribute = fieldButton.idref ? fieldButton.idref : 'id';
    //   if (!(row && row[idAttribute])) return value;
    //   const allow = tg.allowAction(
    //     fieldButton.action,
    //     row[idAttribute] as number
    //   );
    //   const title = fieldButton.tooltip ? tg.t(fieldButton.tooltip) : '';
    //   return allow ? (
    //     <Tooltip title={title || ''}>
    //       <IconButton
    //         aria-label={fieldButton.action}
    //         size="small"
    //         disabled={!allow}
    //         onClick={(evt) => {
    //           evt.stopPropagation();
    //           tg.onAction(fieldButton.action, row[idAttribute] as number);
    //         }}
    //       >
    //         <Icon color="primary">{fieldButton.icon}</Icon>
    //       </IconButton>
    //     </Tooltip>
    //   ) : null;
    // }

    switch (field.type) {
      case 'numeric':
        return ({ value }) => { 
          if (value === 0) {
            return 0;
          } else {
            return value || '';
          }
        };
      case 'text':
      case 'wholenum':
      case 'multiline':
        return ({ value }) => {
          if (field.items) {
            const pos = field.items.values.indexOf(value);
            const lbl = field.items.labels[pos];
            return field.translate ? this.t(lbl) : lbl;
            // format not defined on these types for now
            // } else if (field.format) {
            //   return fieldFormat(value, field.format, field.translate ? this.t : (v) => v);
          }
          return field.translate ? this.t(value) : value;
        };
      case 'date':
        return ({ value }) =>
          value ? moment.utc(value).local().format(formats.date) : '-';
      case 'datetime':
        return ({ value }) =>
          value ? moment.utc(value).local().format(formats.datetime) : '';
      case 'currency':
        return ({ value }) => {
          if (value && typeof value === 'object') {
            if (value._value !== undefined && value._value !== null) {
              const num = numbro(value._value).format({
                thousandSeparated: true,
                mantissa: 2,
              });
              return (
                <div
                  style={{ textAlign: 'right' }}
                  // @ts-ignore
                  unformatedvalue={value._value}
                  type="currency"
                >
                  {num}
                </div>
              );
            }
            return value;
          }
          if (typeof value === 'number') {
            const num = numbro(value).format({
              thousandSeparated: true,
              mantissa: 2,
            });
            return (
              // @ts-ignore
              <div unformatedvalue={value} type="currency">
                {field.currency} {num}
              </div>
            );
          }
          return null;
        };
      case 'active':
        return ({ value }) => {
          if (field.items) {
            const pos = field.items.values.indexOf(value);
            return this.t(field.items.labels[pos]);
          }
          return value;
        };
      case 'boolean':
      case 'radio':
        return renderRadio;
      case 'checkbox':
        return renderCheckbox;
      case 'picker':
        return renderPicker;
      case 'array':
        return ({ value }) =>
          Array.isArray(value)
            ? value.map((x) => this.t(x)).join(', ')
            : (this.t(value) as string);
      case 'button':
        return ({ row }) => {
          const idAttribute = field.idref ? field.idref : 'id';
          const allow = this.allowAction(
            field.action,
            row as DCRecord,
            row[idAttribute] as number
          );
          const title = field.tooltip ? this.t(field.tooltip) : '';

          return allow ? (
            <Tooltip title={title || ''}>
              <IconButton
                aria-label={field.action}
                size="small"
                disabled={!allow}
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.onAction(field.action, row[idAttribute] as number, row);
                }}
              >
                <Icon color="primary">{field.icon}</Icon>
              </IconButton>
            </Tooltip>
          ) : null;
        };
      case 'link':
        return ({ value, row }) => {
          if (value) {
            const { recordIdField, recordType } = field.link;
            const recordId = row.hasOwnProperty(recordIdField)
              ? (row[recordIdField] as number)
              : 0;
            return (
              <>
                {value}
                <IconButton
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    this.onAction(recordType, recordId);
                  }}
                >
                  <Icon color="primary">link</Icon>
                </IconButton>
              </>
            );
          }
          return null;
        };
      case 'document':
        return ({ value }) => {
          if (value && Array.isArray(value)) {
            const privitciElements = value.map((file) => {
              if (Object.keys(file).length === 0) return null;
              return (
                <Tooltip
                  key={`dz-tooltip-${file.uuid}`}
                  title={file.name ? file.name : ''}
                >
                  <IconButton
                    aria-label="mail"
                    size="small"
                    disableTouchRipple
                    disableRipple
                    disableFocusRipple
                    onClick={(evt) => {
                      evt.stopPropagation();
                      if (file.content && file.name && file.size && file.type) {
                        this.docs.saveDocument(file);
                      } else if (file && file.uuid) {
                        this.docs.downloadDocument(file.uuid);
                      }
                    }}
                  >
                    <Icon fontSize="small" color="primary">
                      article
                    </Icon>
                  </IconButton>
                </Tooltip>
              );
            });
            return <Box display="flex">{privitciElements}</Box>;
          }
          return null;
        };
      case 'mail':
        return ({ value }) => {
          if (value) {
            return (
              <>
                <IconButton
                  aria-label="mail"
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    window.open(`mailto:${value}`, '_blank');
                  }}
                >
                  <Icon color="primary">mail_outline</Icon>
                </IconButton>
                {value}
              </>
            );
          }
          return null;
        };
      case 'iconStatus':
        return ({ value }) => {
          const { items } = field;
          if (!items) return null;

          const index = items.values.indexOf(value);
          const valLabel = this.t(items.labels[index]);

          const { icons } = items;
          if (!icons) return null;

          const icon = icons[index];
          const color = helpers.retrieveIconColor(icon);

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Icon style={{ color }}>{icon}</Icon>
              <div style={{ fontSize: '14px', marginLeft: '4px' }}>
                {valLabel}
              </div>
            </div>
          );
        };
      case 'images':
        return ({ value}) => {
          if (!value) return null;

          const arrayValue: DCFile[] = Array.isArray(value) ? value : [value];

          const handleShowImages = (index: number) => {
            this.fullScreenImageContext.showImages(
              arrayValue,
              index,
              field.subModel?.apiPath
            ); // Show all images
          };

          const privitciElements = arrayValue.map((file, i) => {
            if (Object.keys(file).length === 0) return null;
            return (
              <Tooltip
                key={`dz-tooltip-${file.uuid}`}
                title={file.name ? file.name : ''}
              >
                <IconButton
                  aria-label="mail"
                  size="small"
                  disableTouchRipple
                  disableRipple
                  disableFocusRipple
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleShowImages(i); // Pass the index of the clicked image
                  }}
                >
                  <Icon fontSize="small" color="primary">
                    image
                  </Icon>
                </IconButton>
              </Tooltip>
            );
          });
          return <Box display="flex">{privitciElements}</Box>;
        };
      case 'icon':
        return ({ value }) => {
          return <Icon color='primary'>{value}</Icon>
        }
      default:
        return ({ value }) => String(value);
    }
  }
}

export default TableGenerator;
