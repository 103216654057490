import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { DialogContent } from '@mui/material';

// MUI Icons
import CommentIcon from '@mui/icons-material/Comment';

// Custom Components
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';

// UI
import ModelTable from '@/ui/Table/ModelTable';

// Lib
import DataController from '@/lib/DataController';

// Context
import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';

// Types
import { DCRecord } from '@/@types/lib/dataController';

const AttachedCommentDialog = (props: IDialogProps) => {
  const {
    onClose,
    dc,
    initialRecord,
    gid,
    showNewCommentDialog,
    onAddNewComment,
    feature,
  } = props;

  const { t } = useTranslation();
  const { hideDialog } = useContext(DialogContext);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const refreshComments = () => {
    // WE NEED TO REFRESH COMMENTS HERE INSTEAD OF IN USECOMMENTS BECAUSE COMMENTLISTITEM IS UNMOUNTED AND CANT REFRESH COMMENTS. BUT WE STILL NEED INITIAL FETCH IN USECOMMENTS.
    (dc as DataController)
      .GetData(`comments/entity/${gid}`)
      .then((resp) => {
        if (resp.success) {
          if (resp.data && Array.isArray(resp.data)) {
            setRecords(resp.data);
          } else {
            setRecords([]);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(
            'Error while fetching comments for gid:',
            gid,
            'response:',
            resp
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error while fetching comments:', error);
      });
  };

  useEffect(() => {
    setRecords(initialRecord);
  }, []);

  const handleAdd = () => {
    showNewCommentDialog(gid, feature, () => {
      onAddNewComment();
      refreshComments();
    });
  };

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    hideDialog(1);
  };

  const handleClose = () => {
    close({ success: false });
  };

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <CommentIcon sx={{ transform: 'scale(1.3)' }} />
          <DialogToolbarHeading>
            {t('titles.attached_comments')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogContent sx={{ py: 0, px: 1, width: '100%' }}>
        <Box>
          <ModelTable
            key={`tblid-${records.length}`}
            dc={dc}
            records={records}
            allowSelection="none"
            title=""
            allowAdd
            handleAdd={handleAdd}
            allowRowAction={() => false}
            onRowAction={() => {}}
            viewName="info_dialog"
            allowColumnPicker={false}
          />
        </Box>
      </DialogContent>
    </DraggableDialog>
  );
};

export default AttachedCommentDialog;
