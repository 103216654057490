/* eslint-disable */
import React from 'react';

// MUI
import Typography from '@mui/material/Typography';

// Types
import { ITableHeadingProps } from '@/@types/ui/Table';

const TableHeading = (props: ITableHeadingProps) => {
  const { children } = props;
  return (
    <Typography
      align="left"
      variant="h5"
      component="h3"
      whiteSpace={"pre-line"}
      sx={{
        ml: "4px",
        mr: "8px",
        fontSize: {
          xs: '1.15rem',  // Smaller font size on extra-small screens
          sm: '1.25rem',  // Slightly larger on small screens
          md: '1.5rem',  // Default font size on medium and up
        },
      }}
    >
      {children}
    </Typography>
  );
};

export default TableHeading;
