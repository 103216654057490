/* eslint-disable */
import * as React from 'react';
import { FunctionComponent } from 'react';
import Draggable from 'react-draggable';

// MUI
import Dialog from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';

// Types
import { IDraggableDialog } from '@/@types/ui/Dialog';

const DragComponent = (props: PaperProps) => (
  <Draggable handle=".dialogTitle" cancel=".nodrag">
    <Paper {...props} />
  </Draggable>
)

const DraggableDialog: FunctionComponent<IDraggableDialog> = (props) => {
  const { fixedHeight, minHeight, ...rest } = props;

  return (
    <Dialog
      PaperComponent={DragComponent}
      PaperProps={{
        sx: {
          minHeight: minHeight ? minHeight : fixedHeight ? '90vh' : '30vh',
          maxHeight: '90vh'
        }
      }}
      fullWidth
      {...rest}
    >
      {props.children}
    </Dialog>
  );
};

export default DraggableDialog;
