import React, { FC } from 'react';

// Custom Components
import AppPage from '@/ui/AppPage/AppPage';
import CesiumPage from '@/views/ThreeD/CesiumPage';
import Permissions from '@/lib/permissions';

// Types
type ThreeDPageProps = {};

const ThreeDPage: FC<ThreeDPageProps> = () => (
  <AppPage titlett="page.threed" needPermission={[Permissions.ThreeDView]}>
    <CesiumPage />
  </AppPage>
);

export default ThreeDPage;
