import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import commonFields from './helpers/commonFields';

const File: IModel = {
  title: 'Files',
  apiPath: '', // jbg
  forms: {
    default: {
      fields: ['files'],
    },
  },
  listViews: {
    default: {
      fields: ['name', 'file', 'created_by', 'created_on', 'id'],
      hidden: ['id'],
    },
  },
  fields: [
    coreFields.id,
    commonFields.uuid,
    {
      title: 'name',
      source: 'name',
      ttoken: 'common.filename',
      type: 'text',
      readonly: true,
    },
    {
      title: 'file',
      source: 'file',
      ttoken: 'documents.file',
      type: 'document',
      subModel: {
        apiPath: 'core/documents',
        format: '',
      },
      maxFiles: 1,
    },
    coreFields.created_by,
    coreFields.created_on,
  ],
};

export default File;
