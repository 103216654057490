/* eslint-disable */
import React, { useContext, SyntheticEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

//MUI
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';

//Custom Components
import useFormController from '@/components/useFormController';
import DynamicDialog from '@/ui/Dialog/DynamicDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import DialogActionButtonClose from '@/ui/Dialog/ActionButtons/DialogActionButtonClose';
import DialogActionButtonAdd from '@/ui/Dialog/ActionButtons/DialogActionButtonAdd';
import DialogActionButtonSave from '@/ui/Dialog/ActionButtons/DialogActionButtonSave';
// import DialogToolbarMenuButton from '@/ui/Dialog/DialogToolbarMenuButton';
// import DialogToolbarMenuItem from '@/ui/Dialog/DialogToolbarMenuItem';
import DialogContext from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import { GridContainer, GridItem } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import { IDialogProps } from '@/context/DialogContext/DialogContext';
import DataController from '@/lib/DataController';
import LicenceModel from '@/models/licence';

//Types
import {
  ClosingDetails,
  FormControllerProps,
} from '@/@types/components/formController';
import DialogActionButtonCancel from '@/ui/Dialog/ActionButtons/DialogActionButtonCancel';

const TenantsDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { dc, mode, form, onClose } = props;

  const {
    record,
    validation,
    dataChanged,
    fields,
    pickerDataControllers,
    onFieldChange,
    doInsert,
    doUpdate,
    doClose,
    doDelete,
    doRefresh,
  } = useFormController(props as FormControllerProps);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt: SyntheticEvent) => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.insert_tenant_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'insert', id: result.id });
          } else if (result.error) {
            const msg = 'error.insert_tenant';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            //do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = (evt: SyntheticEvent) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_tenant_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = 'error.update_tenant';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            //do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose()
        .then((result) => {
          console.log("result resolve",result)
          if (result.success) {
            close({ dataChanged: false, action: 'cancel' });
          }
        })
    }
  };

  // const handleActivate = (evt: SyntheticEvent) => {
  //   if (record.id !== null) {
  //     try {
  //       userService.activate(Number(record.id)).then((result) => {
  //         if (result) {
  //           const msg = t('messages.activate_user_success');
  //           snackbarContext.showNotification(msg, 'success');
  //           doRefresh();
  //           onClose({ dataChanged: true, action: 'update' });
  //         } else {
  //           const msg = t('error.activate_user');
  //           snackbarContext.showNotification(msg, 'error');
  //         }
  //       });
  //     } catch (error) {
  //       const msg = t('error.activate_user');
  //       snackbarContext.showNotification(msg, 'error');
  //     }
  //   }
  // };

  //   const handleDeactivate = (evt: SyntheticEvent) => {
  //   if (record.id !== null) {
  //     try {
  //       userService.deactivate(Number(record.id)).then((result) => {
  //         if (result) {
  //           const msg = t('messages.deactivate_user_success');
  //           snackbarContext.showNotification(msg, 'success');
  //           close({ dataChanged: true, action: 'update' });
  //         } else {
  //           const msg = t('error.deactiavate_user');
  //           snackbarContext.showNotification(msg, 'error');
  //         }
  //       });
  //     } catch (error) {
  //       const msg = t('error.deactivate_user');
  //       snackbarContext.showNotification(msg, 'error');
  //     }
  //   }
  // };

  const close = (result: ClosingDetails) => {
    onClose(result);
    dialogContext.hideDialog();
  };

  // const handleDelete = (evt: SyntheticEvent) => {
  //   if (doDelete) {
  //     doDelete().then((result) => {
  //       if (result.success) {
  //         const msg = t('messages.user_deleted');
  //         snackbarContext.showNotification(msg, 'success');
  //         close({ dataChanged: true, action: 'update' });
  //       } else if (result.error) {
  //         const msg = t('error.delete') + result.error;
  //         snackbarContext.showNotification(msg, 'error');
  //       }
  //     });
  //   }
  // };

  let cmode = mode;
  if (record.status === 30) {
    cmode = 'view';
  }

  return (
    <DynamicDialog open={true} maxWidth={'sm'} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>
            {t('admin:titles.tenants')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          {/* {mode === 'update' || (mode === 'view' && record.status !== 40) ? (
            <DialogToolbarMenuButton>
              {record.status === 20 ? (
                <DialogToolbarMenuItem
                  onClick={handleDeactivate}
                  label={t('buttons.deactivate')}
                  icon={<CancelIcon color="action" />}
                />
              ) : null}
              {record.status === 30 ? (
                <DialogToolbarMenuItem
                  onClick={handleActivate}
                  label={t('buttons.activate')}
                  icon={<CheckCircleIcon color="action" />}
                />
              ) : null}

              <DialogToolbarMenuItem
                onClick={handleDelete}
                label={t('buttons.delete')}
                icon={<DeleteForeverIcon color="action" />}
              />
            </DialogToolbarMenuButton>
          ) : null} */}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <GridItem spacing={2} xs={12} sm={6}>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:titles.tenant_information') as string}
                  fieldNames={['name_ttoken', 'tag', 'host', 'tenant_state_id']}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={handleFieldChange}
                  mode={cmode}
                  columns={1}
                  pickerDataControllers={pickerDataControllers}
                />
              </GridContainer>
            </GridItem>
            <GridItem spacing={2} xs={12} sm={6}>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:titles.licence_information') as string}
                  fieldNames={[
                    'tenant_licence_started_on',
                    'tenant_licence_ends_on',
                    'tenant_licence_max_users',
                    'public_user_type',
                    'default_language'
                  ]}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={handleFieldChange}
                  mode={cmode}
                  columns={1}
                  pickerDataControllers={pickerDataControllers}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert' ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === 'update' ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
};

export default TenantsDialog;
