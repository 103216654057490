import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const Lokacije: IModel = {
  title: "Lokacije",
  apiPath: "data/lokacije",
  forms : {
    "default": {
      fields: [ "adresa", "ko_ime", "kc", "lokacija_status_id"]
    }
  },
  listViews: {
    "default": {
      fields: [ "id", "adresa", "ko_ime", "kc", "lokacija_status_id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "adresa",
      source: "adresa",
      ttoken: "common.adresa",
      type: "text",
      readonly: true
    },
    {
      title: "ko_ime",
      source: "ko_ime",
      ttoken: "dkp.ko_ime",
      type: "text",
      readonly: true
    },
    {
      title: "kc",
      source: "kc",
      ttoken: "dkp.kc_broj",
      type: "text",
      readonly: true
    },
    {
      title: "status",
      source: "lokacija_status_id",
      ttoken: "common.status",
      type: "picker",
      items: {
        labels: [
          '3D:lokacija_status.ok',
          '3D:lokacija_status.brisati',
          '3D:lokacija_status.pomaknuto',
          '3D:lokacija_status.novo'
        ],
        values: [1,2,3,5]
      }
    }
  ]
}

export default Lokacije;
