/* eslint-disable */
import React from 'react';

// Types
import { Theme } from '@mui/material';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

// Control Style
const makeMUIControlStyle = (theme: Theme) => ({
  control: {
    '& > button': {
      border: 0,
      backgroundColor: theme.palette.primary.main,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main)
    },
    '& > button:hover': {
      border: 0,
      backgroundColor: theme.palette.primary.dark,
      cursor: 'pointer',
      outline: 0,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main)
    },
    '& > button:focus': {
      border: 0,
      backgroundColor: theme.palette.primary.main,
      outline: 0,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main)
    },
    '& > .ol-full-screen-true:not(:active)': {
      backgroundColor: theme.palette.primary.main,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main)
    },
  },
  sidebarItem: {
    '& > button': {
      border: 0,
      borderRadius: "0",
      backgroundColor: theme.palette.primary.light,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light),
      // Same as MUI transition
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    '& > button:hover': {
      border: 0,
      backgroundColor: theme.palette.primary.dark,
      cursor: 'pointer',
      outline: 0,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light)
    },
    '& > button:focus': {
      border: 0,
      backgroundColor: theme.palette.primary.light,
      outline: 0,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light)
    },
    '& > .ol-full-screen-true:active': {
      backgroundColor: theme.palette.primary.dark,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light)
    },
    // Combined hover and focus
    '& > button:hover:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light),
    },
  },
  sidebarSticky: {
    marginLeft: 46,
    '@media (min-width: 768px)': {
      transition: 'margin-left 500ms',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      marginLeft: 317,
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
      marginLeft: 402,
    },
    '@media (min-width: 1200px)': {
      marginLeft: 472,
    },
    '.sidebar-map .sidebar-left.collapsed ~ .ol-viewport .ol-zoom': {
      marginLeft: 52,
    },
    '&:nth-of-type(1)': {
      marginLeft: 'unset',
    },
  },
  scaleline: {
    backgroundColor: theme.palette.primary.main,
    color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
    borderColor: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
    opacity: 0.75,
    fontWeight: "bolder",
    borderWidth: "2px"
  },
  zoom: {
    color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
  },
  fullscreenControl: {
    '& > .ol-full-screen-true': {
      backgroundColor: theme.palette.primary.main,
    },
    '& > .ol-full-screen-true:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '& > .ol-full-screen-true:hover, & > .ol-full-screen-true:focus:hover': {
      backgroundColor: theme.palette.primary.dark, // Darken on hover/focus
    },
    '& > .ol-full-screen-true:active': {
      backgroundColor: theme.palette.primary.main, // Lighten when active
    },
  }
});

export default makeMUIControlStyle;
