import React, { CSSProperties, useState } from 'react';
import { Box } from '@mui/material';
import { PanelResizeHandle } from 'react-resizable-panels';

type IPanelResizeHandle = {
    variant: 'vertical' | 'horizontal';
};

const StyledPanelResizeHandle = (props: IPanelResizeHandle) => {
    const { variant } = props;
    const [isHovered, setIsHovered] = useState(false);

    const dotStyle: CSSProperties = {
        width: '4px',
        height: '4px',
        borderRadius: '4px',
        backgroundColor: 'gray',
    };

    const handleStyle: CSSProperties = {
        width: variant === 'vertical' ? '8px' : '100%',
        height: variant === 'vertical' ? '100%' : '8px',
        display: 'flex',
        flexDirection: variant === 'vertical' ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        backgroundColor: isHovered ? '#f2f2f2' : 'transparent',
        transition: 'background-color 0.3s',
        position: 'relative', // required for the ::before pseudo-element to work
    };

    return (
        <PanelResizeHandle
            style={{ position: 'relative' }} // Ensures handle itself is relatively positioned
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Box style={handleStyle}>
                {[0, 1, 2].map((index) => (
                    <div key={index} style={dotStyle} />
                ))}
            </Box>
            {/* Add hover area */}
            <Box
                component="span"
                sx={{
                    position: 'absolute',
                    top: '-4px', // Adjust these values to control how much larger the hover area is
                    bottom: '-5px',
                    left: '-5px',
                    right: '-5px',
                    zIndex: 0, // Ensure the hover area is behind the dots
                    pointerEvents: 'auto', // Enable interaction in this expanded area
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        </PanelResizeHandle>
    );
};

export default StyledPanelResizeHandle;
