import { TFunction } from 'i18next';
import { PickerItem } from '../@types/controls/controls';

export default function fieldFormat(
  obj: PickerItem,
  format: string,
  t?: TFunction
) {
  const keys = Object.keys(obj);

  if (format) {
    let label = format;
    if (keys.length > 0) {
      keys.forEach((k) => {
        const key = k as keyof PickerItem;
        const re = new RegExp(`{${k}}`);
        label = label.replace(re, obj[key] != null ? (obj[key] as string) : '');
      });
      return t ? t(label) : label;
    }
    return null;
  }
  return keys
    .map((x) => {
      const xkey = x as keyof PickerItem;
      return t ? t(obj[xkey] as string) : (obj[xkey] as string);
    })
    .join(', ');
}
