import * as React from 'react';
import { FunctionComponent } from 'react';

// Custom components
import SidebarProvider from '@/context/SidebarContext/SidebarProvider';
import SidebarRoot from './SidebarRoot';

import { positionType } from '@/context/SidebarContext/SidebarContext';

// Types
export interface ISidebarControl {
  initialOpenId?: string;
  initialOpenHeading?: string;
  onTabChange?: (id: string) => void;
  id?: any;
  position?: positionType;
  children: JSX.Element | JSX.Element[];
}

const SidebarControl: FunctionComponent<ISidebarControl> = (props) => {
  const { id, initialOpenId, position, onTabChange, children, initialOpenHeading } = props;
  return (
    <SidebarProvider initialOpenId={initialOpenId} onTabChange={onTabChange} initialOpenHeading={initialOpenHeading}>
      <SidebarRoot id={id} initialOpenId={initialOpenId} position={position}>
        {children}
      </SidebarRoot>
    </SidebarProvider>
  );
};

export default SidebarControl;
