import * as React from 'react';
import { Fragment, FunctionComponent } from 'react';

// MUI
import Dialog from '@mui/material/Dialog';

// Custom Components
import DraggableDialog from '@/ui/Dialog/DraggableDialog';

// Types
import { IDraggableDialog } from '@/@types/ui/Dialog';

const DynamicDialog: FunctionComponent<IDraggableDialog> = (props) => {
  const { fixedHeight, children, maxWidth } = props;

  return (
    <>
      <DraggableDialog
        sx={{ display: { xs: 'none', md: 'block' } }}
        open
        fullScreen={false}
        fixedHeight={fixedHeight}
        maxWidth={maxWidth || 'lg'}
      >
        {children}
      </DraggableDialog>
      <Dialog sx={{ display: { xl: 'none', lg: 'block' } }} open fullScreen>
        {children}
      </Dialog>
    </>
  );
};

export default DynamicDialog;
