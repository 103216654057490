import { createContext } from 'react';

// Types
export type SnackbarContextType = {
  showNotification: (message: string, type: string) => void;
  hideNotification: () => void;
  status: INotificationStatus;
};

export interface INotificationStatus {
  show: boolean | undefined;
  message: string | undefined;
  variant: 'success' | 'info' | 'warning' | 'error' | '';
}

const SnackbarContext = createContext<SnackbarContextType>({
  status: { show: false, message: '', variant: '' },
  showNotification: () => {},
  hideNotification: () => {},
});

SnackbarContext.displayName = 'SnackbarContext';

export default SnackbarContext;
