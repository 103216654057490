/* eslint-disable */
import * as React from 'react';
import { FunctionComponent } from 'react';

// MUI
import DialogContent from '@mui/material/DialogContent';

// Types
import { IDialogBody } from '@/@types/ui/Dialog';

const DialogBody: FunctionComponent<IDialogBody> = (props) => (
  <DialogContent sx={{ pt: 0, width: "100%"}}>{props.children}</DialogContent>
);

export default DialogBody;
