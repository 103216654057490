/* eslint-disable */
import React, { useContext, MouseEvent, useEffect, useState, useRef } from 'react';

// MUI
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { ITableHeaderButtonProps } from '@/@types/ui/Table';

const TableHeaderButton: React.FC<ITableHeaderButtonProps> = (props) => {
  const userContext = useContext(UserContext);
  const { onClick, needPermission, smallButtons, disabled, ...otherProps } = props;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down(1050));

  const [toolbarWidth, setToolbarWidth] = useState<number>(Infinity);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    onClick(evt);
  };

  useEffect(() => {
    const buttonElement = buttonRef.current;

    // Very hacky, but passing parent ref all the way to every TableHeaderButton is too much work
    // Find the closest parent with the class `.MuiToolbar-root`
    const toolbarElement = buttonElement?.closest('.MuiToolbar-root');

    if (toolbarElement) {
      // Create a ResizeObserver to monitor changes to the element's size
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setToolbarWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(toolbarElement);

      // Cleanup
      return () => {
        resizeObserver.disconnect();
      };
    }
    return;
  }, []);

  const shouldRenderFab = smallButtons || mdDown || toolbarWidth < 850;

  if (needPermission && userContext && !userContext.hasAnyPermission(needPermission)) {
    return null;
  }

  return shouldRenderFab ? (
    <Fab
      ref={buttonRef}
      color="primary"
      size="small"
      onClick={handleClick}
      disabled={disabled ?? false}
      sx={{
        minHeight: { xs: 26, md: 32 },
        minWidth: 40,
        mx: { xs: '4px'},
      }}
    >
      {otherProps.startIcon}
    </Fab>
  ) : (
    <Button
      ref={buttonRef}
      color="primary"
      size="small"
      {...otherProps}
      onClick={handleClick}
      disabled={disabled ?? false}
      sx={{
        minHeight: 32,
        ml: 1,
        mr: 0,
      }}
    >
      {props.children}
    </Button>
  );
};

export default TableHeaderButton;
