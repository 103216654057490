/* eslint-disable */
import { createContext } from 'react';

export type positionType = "left" | "right";

export type SidebarContextType = {
  activePane: string | null;
  collapsed: boolean;
  setActivePane: (id: string) => void;
  setCollapsed: (collapsed: boolean) => void;
  position: positionType;
  setSidebarHeading: (heading: string) => void;
  sidebarHeading: string;
  setOnSidebarCollapse: (func: () => void) => void;
  onSidebarCollapse: () => void;
};

const SidebarContext = createContext<SidebarContextType | null>(null);

SidebarContext.displayName = 'SidebarContext';

export default SidebarContext;
