/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Types
export interface IHeaderIconButton {
  onClick: (evt: SyntheticEvent) => void;
  desc?: string;
  children?: ReactNode;
}

const HeaderIconButton: FunctionComponent<IHeaderIconButton> = (props) => {
  const { t } = useTranslation();
  const { desc, ...rest } = props;

  const title: string = desc ? t(`tooltips.header.${desc}`) : '';

  const style = {
    marginRight: 0,
    color: 'common.white',
    '&:hover': {
      bgcolor: 'primary.dark',
    },
  };

  return (
    <Tooltip disableFocusListener placement="bottom" arrow title={title}>
      <IconButton size="small" color="primary" {...rest} sx={style}>
        {props.children}
      </IconButton>
    </Tooltip>
  );
};

export default HeaderIconButton;
