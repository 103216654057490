import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';

// MUI
import { styled, useTheme } from '@mui/material/styles';
import { LocationOff, LocationOn } from '@mui/icons-material';

// OpenLayers
import OlGeolocation from 'ol/Geolocation';
import OlFeature from 'ol/Feature';
import OlGeomPoint from 'ol/geom/Point';
import OlVectorSource from 'ol/source/Vector';
import OlVectorLayer from 'ol/layer/Vector';
import { Geometry } from 'ol/geom';
import { IGeoLocateControl } from '@/@types/components/Map/Controls/Custom';
import { geoLocateStyle } from '@/components/Map/mapStyles';
import MapContext from '@/context/MapContext/MapContext';
import makeMUIControlStyle from '../makeMUIControlStyle';
import ButtonControl from '@/components/Map/Controls/Custom/Button/ButtonControl';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

// Types

const GeoLocateControl = (props: IGeoLocateControl) => {
  const mapContext = useContext(MapContext);
  const [active, setActive] = useState(false);
  const [locationFetched, setLocationFetched] = useState(false);

  const { tooltip, handleClick, target, className } = props;

  const theme = useTheme();

  const map = mapContext?.map;
  const geolocationRef = useRef<OlGeolocation | null>(null);
  const layerRef = useRef<OlVectorLayer<OlVectorSource<Geometry>> | null>(null);

  const handleGeoLocate = useCallback(() => {
    setActive((prev) => !prev);
  }, [handleClick]);

  useEffect(() => {
    const geolocation = new OlGeolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: map?.getView().getProjection(),
    });

    const accuracyFeature = new OlFeature();
    geolocation.on('change:accuracyGeometry', () => {
      const poly = geolocation.getAccuracyGeometry();
      if (poly) {
        accuracyFeature.setGeometry(poly);
      } else {
        accuracyFeature.setGeometry(undefined);
      }
    });

    const positionFeature = new OlFeature();
    positionFeature.setStyle(geoLocateStyle);

    geolocation.on('change:position', () => {
      const coordinates = geolocation.getPosition();
      positionFeature.setGeometry(
        coordinates ? new OlGeomPoint(coordinates) : undefined
      );
    });

    // on change check if location has been fetched
    geolocation.on('change', () => {
      if (geolocation.getPosition()) {
        setLocationFetched(true);
      }
    });

    geolocationRef.current = geolocation;

    const geolocateLayer = new OlVectorLayer({
      source: new OlVectorSource({
        features: [accuracyFeature, positionFeature],
      }),
      zIndex: 9999,
    });

    layerRef.current = geolocateLayer;
    map?.addLayer(geolocateLayer);
  }, []);

  useEffect(() => {
    geolocationRef.current?.setTracking(active);
    if (active && locationFetched) {
      const coordinates = geolocationRef.current?.getPosition();
      if (coordinates) {
        const view = map?.getView();
        view?.setCenter(coordinates);
      }
      if (layerRef.current) {
        map?.addLayer(layerRef.current);
      }
    } else if (layerRef.current) {
      map?.removeLayer(layerRef.current);
    }
  }, [active, locationFetched]);

  return (
    <ButtonControl
      id="geolocate-btn"
      className={className}
      title={tooltip}
      onClick={handleGeoLocate}
      target={target}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      disableElevation
      sx={{
        bgcolor: active ? 'primary.main' : 'primary.light',
        color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light),
        borderRadius: 0
      }}
    >
      {active ? (
        <LocationOn fontSize="small" />
      ) : (
        <LocationOff fontSize="small" />
      )}
    </ButtonControl>
  );
};

const StyledGeoLocateControl = styled(GeoLocateControl)(({ theme }) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.sidebarItem,
  };
});

export default StyledGeoLocateControl;
