import { useContext } from 'react';

// Openlayers
import { Feature as OlFeature } from 'ol';
import { Geometry as OlGeometry } from 'ol/geom';

// Context
import DialogContext from '@/context/DialogContext/DialogContext';

// Custom
import AttachedCommentDialog from '@/views/core/Comments/AttachedCommentDialog';
import { ClosingDetails } from '@/@types/components/authFormController';
import { DCRecord } from '@/@types/lib/dataController';
import DataController from '@/lib/DataController';

import modelComments from '@/models/comments';
import CommentDialog from '@/views/Comments/CommentDialog';

type HandleCloseFn = (result: ClosingDetails) => void;

export interface IComments {
  showCommentsDialog: (
    gid: string,
    feature: OlFeature<OlGeometry>,
    onClose?: HandleCloseFn
  ) => void;
}

// Hook
function useComments(): IComments {
  const { showDialog, hideDialog } = useContext(DialogContext);

  const dcComments = new DataController(modelComments);

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshComments = async (gid: string): Promise<DCRecord[]> => {
    // setIsLoading(true);
    try {
      const resp = await dcComments.GetData(`comments/entity/${gid}`);
      if (resp.success) {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data;
        }
        return [];
      }
      // eslint-disable-next-line no-console
      console.error(
        'Error while fetching comments for gid:',
        gid,
        'response:',
        resp
      );
      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while fetching comments:', error);
      return [];
    } finally {
      // setIsLoading(false);
    }
  };

  const showCommentsDialog = (
    gid: string,
    feature: OlFeature<OlGeometry>,
    onClose?: HandleCloseFn
  ) => {
    // Wait for the initial fetch to complete if it's still in progress
    refreshComments(gid).then((comments) => {
      if (comments.length) {
        showAttachedCommentsDialog(gid, feature, comments, onClose);
      } else {
        showNewCommentDialog(gid, feature, onClose);
      }
    });
  };

  const showNewCommentDialog = (
    gid: string,
    feature: OlFeature<OlGeometry>,
    onClose?: HandleCloseFn
  ) => {
    showDialog(
      CommentDialog,
      {
        dc: dcComments,
        mode: 'insert',
        form: 'insert',
        onClose,
        initialRecord: {
          entity_gid: gid,
          geom: feature,
          extent: feature.getGeometry()?.getExtent(),
        },
        level: 2,
      },
      2
    );
  };

  const onAddNewComment = async (result: ClosingDetails) => {
    if (!result) return;
    if (typeof result.success === 'boolean' && !result.success) return;

    if (result.dataChanged) {
      hideDialog(2);
    }
  };

  const showAttachedCommentsDialog = (
    gid: string,
    feature: OlFeature<OlGeometry>,
    comments: DCRecord[],
    onClose?: HandleCloseFn
  ) => {
    showDialog(
      AttachedCommentDialog,
      {
        dc: dcComments,
        initialRecord: comments,
        onClose,
        gid,
        showNewCommentDialog,
        onAddNewComment,
        feature,
      },
      1
    );
  };

  return {
    showCommentsDialog,
  };
}

export default useComments;
