import React from 'react';

import Box from '@mui/material/Box';

import AppPage from '@/ui/AppPage/AppPage';
import TestTable from './TestTable';
import Permissions from '@/lib/permissions';

const TestPage = () => (
  <AppPage titlett="menu.test" needPermission={[Permissions.Test]}>
    <Box m={2}>
      <TestTable />
    </Box>
  </AppPage>
);

export default TestPage;
