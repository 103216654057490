/* eslint-disable */
import React, { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Button, { ButtonProps } from '@mui/material/Button';
import { Tooltip } from '@mui/material';

// Custom Components
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

// Types
import { Permission } from '@/@types/common';

export interface IMapCardButtonProps extends ButtonProps {
  needPermission?: Permission[];
  tooltip?: string;
  disabled?: boolean;
  stickyRight?: boolean;
}
const MapCardActionButton = (props: IMapCardButtonProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext) as UserContextType;

  const { needPermission, tooltip, disabled, stickyRight,sx, ...rest } = props;

  const divStyle: CSSProperties = {};
  if (stickyRight) {
    divStyle.marginLeft = 'auto';
  }

  return needPermission &&
    !userContext.hasAnyPermission(needPermission) ? null : (
    <div style={divStyle}>
      <Tooltip title={t(tooltip || '')}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          {...rest}
          disabled={disabled || false}
          sx={{
            mr: { xs: 0, md: 1 },
            mt: { xs: 1, md: 0 },
            width: { xs: '100%', md: 'fit-content' },
            ...sx
          }}
        >
          {props.children}
        </Button>
      </Tooltip>
    </div>
  );
};

export default MapCardActionButton;
