/* eslint-disable */
import React from 'react'
import { useTranslation } from 'react-i18next';

// MUI
import LaunchIcon from '@mui/icons-material/Launch';
import { RequestPage } from '@mui/icons-material';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import Permissions from '@/lib/permissions';

// Types
import { TCustomGFIActionProps } from '@/@types/views/map'

const ShowKOButton = (props: TCustomGFIActionProps) => {

    const { t } = useTranslation();

    const { featureData, layerIndex = 0 } = props;

    const handleShowKO = () => {
        const id = featureData.features[layerIndex].properties.objekt_id;
        const locationId = featureData.features[layerIndex].properties.lokacija_id;

        if (id) {
          const locId = locationId === null ? '' : `&loc_id=${locationId}`;
          const url = `${
            location.origin
          }/ena/utility/?obj_id=${id.toString()}&open_first=true${locId}`;
          window.open(url, '_blank');
        }
    };

    return (
        <MapCardActionButton
            startIcon={<RequestPage />}
            onClick={handleShowKO}
            aria-label="ko"
            disabled={false}
            needPermission={[Permissions.Ena]}
            tooltip='tooltips.buttons.show_ko'
        >
            {t('buttons.show_ko')}
            <LaunchIcon
            style={{ marginLeft: '7px', width: '16px', height: '16px' }}
            />
        </MapCardActionButton>
    )
}

export default ShowKOButton