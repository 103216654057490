import React from 'react'
import { useTranslation } from 'react-i18next';

import proj4 from 'proj4';
import { transform } from 'ol/proj';

// MUI
import MapIcon from '@mui/icons-material/Map';
import { Launch as LaunchIcon } from '@mui/icons-material';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import Permissions from '@/lib/permissions';

// Types
import { TShowOnMapButton3D } from '@/@types/views/map'

const ShowOnMapButton3D = (props: TShowOnMapButton3D) => {

    const { t } = useTranslation();

    const { wkt } = props;

    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
    proj4.defs(
      'EPSG:3857',
      '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
    );

    const handleShowOnMap = () => {
        if (wkt) {
          // Parse WKT string to extract coordinates
          const match = wkt.match(/POINT\s*Z?\s*\(\s*([-\d.]+)\s+([-\d.]+)\s+[-\d.]+\s*\)/i);
          if (match) {
            const x = parseFloat(match[1]);
            const y = parseFloat(match[2]);
   
              // Transform coordinates from EPSG:3765 to EPSG:3857
              const [transformedX, transformedY] = transform([x, y], 'EPSG:3765', 'EPSG:3857');

              // Navigate to the desired URL with transformed coordinates
              const url = `/map?x=${transformedX}&y=${transformedY}`;
              window.open(url, '_blank');
          } else {
            // console.error('Invalid WKT format');
          }
        }
    }

    return (
        <MapCardActionButton
            startIcon={<MapIcon />}
            onClick={handleShowOnMap}
            aria-label="map"
            disabled={false}
            needPermission={[Permissions.Ena]}
            tooltip='tooltips.buttons.show_on_map'
        >
            {t('buttons.map')}
            <LaunchIcon
            style={{ marginLeft: '7px', width: '16px', height: '16px' }}
            />
        </MapCardActionButton>
    )
}

export default ShowOnMapButton3D