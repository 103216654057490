/* eslint-disable */
import React from "react";
import BusinessIcon from "@mui/icons-material/Business";

//Types
import { SvgIconProps } from "@mui/material";

function TenantIcon(props: SvgIconProps) {
  return <BusinessIcon {...props}/>;
}

export default TenantIcon;
