import * as React from 'react';
import { FunctionComponent } from 'react';

// Material-UI Core Components
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Types
// import { ILoader } from '@/@types/ui/Loader';

export interface ILoader {
  open: boolean;
}

const Loader: FunctionComponent<ILoader> = (props) => {
  const { open } = props;

  return (
    <Backdrop
      open={open}
      style={{
        zIndex: 99999,
        color: '#fff',
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loader;
