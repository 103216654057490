import OlControl, { Options as OlControlOptions } from 'ol/control/Control';

export default class BaseLayerSwitcherClass extends OlControl {
  constructor(opt_options: OlControlOptions) {
    super(opt_options);

    const options = { ...opt_options };

    const element = document.createElement('div');
    element.id = 'base-layer-switcher-container';
    element.className = 'ol-unselectable ol-control';
    // @ts-ignore TODO: Property 'element' does not exist on type 'never'
    element.appendChild(options.element);
  }
}

// @ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
if (window.ol && window.ol.control) {
  // @ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
  window.ol.control.BaseLayerSwitcher = BaseLayerSwitcherClass;
}
