/* eslint-disable */
import Control from "ol/control/Control";
import { Options } from "ol/control/Control";

export default class DraggableControl extends Control {

  constructor(opt_options: Options) {

    super(opt_options);

    var options = Object.assign({}, null, opt_options);

    var element = document.createElement('div');
    element.className = "draggable-control draggable";
    // @ts-ignore
    element.appendChild(options.element);

  }

}

// @ts-ignore
if (window.ol && window.ol.control) {
    // @ts-ignore
  window.ol.control.Status = DraggableControl;
}
