/* eslint-disable */
import * as React from "react";
import { Fragment, FunctionComponent } from "react";

const Interactions: FunctionComponent<any> = (props) => {

  return (<Fragment>{props.children}</Fragment>);
};

export default Interactions;

