/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useRef } from 'react';

//MUI Components
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from '@mui/material';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import CoordZoomStatus from './coordzoomstatus';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { ICordZoomStatusRoot } from '@/@types/components/Map/Controls/CordZoomStatus';
import Control from 'ol/control/Control';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

const CoordZoomStatusRoot: FunctionComponent<ICordZoomStatusRoot> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { ref, id } = props;

  const coordzoomstatusRef = useRef(ref);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (coordzoomstatusRef.current) {
      const options = {
        element: coordzoomstatusRef.current,
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new CoordZoomStatus(definedOptions);

      if (id) {
        control.set('id', id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, CoordZoomStatus);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as Control);
      } else {
        mapContext.initOptions.controls.push(control as Control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, CoordZoomStatus);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <Box
      id="coordzoomstatus-control"
      sx={{
        display: 'flex',
        position: 'absolute',
        bottom: 8,
        right: 8,

        maxWidth: 500,

        color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
        backgroundColor: 'primary.main',
        opacity: 0.75,

        pr: 0.5,
        borderRadius: "4px"
      }}
      ref={coordzoomstatusRef}
    >
      {props.children}
    </Box>
  );
};

export default CoordZoomStatusRoot;
