/* eslint-disable */
import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';
import { Grid } from '@mui/material';
import LoginLogsChart, { ChartType } from './LoginLogsChart';
import LoginLogsTable from './LoginLogsTable';
import { useState } from 'react';
import TenantContext from '@/context/TenantContext/TenantContext';
import { useTranslation } from 'react-i18next';

export type GraphData = {
    stat_current_month: {
        day: string,
        count: number
    }[],
    stat_previous_month: {
        day: string,
        count: number
    }[],
    stat_current_month_per_user: {
        username: string,
        count: number
    }[],
    stat_previous_month_per_user: {
        username: string,
        count: number
    }[],
    current_time: {
        current_month: number,
        current_year: number
    }
}




function LoginLogsPage() {
    const tenantContext = React.useContext(TenantContext);
    const [graphData, setGraphData] = React.useState<GraphData | undefined>();
    const [chart, setChart] = React.useState<ChartType>({
        options: {
            chart: {
                id: "not-loaded"
            },
            xaxis: {
                categories: []
            },
            colors: ["#b7c800"]
        },
        series: [
            {
                name: "n-a",
                data: [],
            }
        ]
    });
    const [chartPerUser, setChartPerUser] = React.useState<ChartType>({
        options: {
            chart: {
                id: "not-loaded"
            },
            xaxis: {
                categories: []
            },
            colors: ["#b7c800"]
        },
        series: [
            {
                name: "n-a",
                data: [],
            }
        ]
    });
    const [showGraphs, setShowGraphs] = useState(false);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (graphData?.stat_current_month && graphData.stat_current_month_per_user && graphData?.stat_previous_month && graphData.stat_previous_month_per_user) {
            const xAxis: string[] = []
            const yAxis: number[] = []
            const yAxisPar: number[] = []
            for (var i = 1; i <= 31; i++) {
                xAxis.push(i.toString());
                var current_ind = graphData.stat_current_month.find((value) => value.day === i.toString())
                var previous_ind = graphData.stat_previous_month.find((value) => value.day === i.toString())
                if (current_ind) yAxis.push(current_ind.count)
                else yAxis.push(0)
                if (previous_ind) yAxisPar.push(previous_ind.count)
                else yAxisPar.push(0)
            }
            setChart({
                options: {
                    chart: {
                        id: t("admin:login_logs.stats_month")
                    },
                    xaxis: {
                        categories: xAxis
                    },
                    colors: [tenantContext?.tenantTheme?.primary_main || '#b7c800', tenantContext?.tenantTheme?.secondary_main || '#2a80b8'],
                },
                series: [
                    {
                        name: t(`admin:login_logs.month_${graphData.current_time.current_month % 12}`) + " " + graphData.current_time.current_year,
                        data: yAxis,
                    },
                    {
                        name: t(`admin:login_logs.month_${(graphData.current_time.current_month - 1) % 12}`) + " " + graphData.current_time.current_year,
                        data: yAxisPar,
                    },
                ],
            })
            const xAxis2: string[] = []
            const yAxis2: number[] = []
            const yAxisPar2: number[] = []
            graphData.stat_current_month_per_user.forEach((value) => { xAxis2.push(value.username) })
            graphData.stat_previous_month_per_user.forEach((value) => { if (!xAxis2.includes(value.username)) xAxis2.push(value.username) })
            graphData.stat_current_month_per_user.forEach((value) => { yAxis2.push(value.count) })
            graphData.stat_previous_month_per_user.forEach((value) => { yAxisPar2.push(value.count) })
            setChartPerUser({
                options: {
                    chart: {
                        id: t("admin:login_logs.stats_month_per_user")
                    },
                    xaxis: {
                        categories: xAxis2
                    },
                    colors: [tenantContext?.tenantTheme?.primary_main || '#b7c800', tenantContext?.tenantTheme?.secondary_main || '#2a80b8'],
                },
                series: [
                    {
                        name: t(`admin:login_logs.month_${graphData.current_time.current_month % 12}`) + " " + graphData.current_time.current_year,
                        data: yAxis2,
                    },
                    {
                        name: t(`admin:login_logs.month_${(graphData.current_time.current_month - 1) % 12}`) + " " + graphData.current_time.current_year,
                        data: yAxisPar2,
                    }
                ]
            })
            setShowGraphs(true);

        }
        else { setShowGraphs(false); }
    }, [graphData])

    return (
        <AppPage
            titlett="admin:page.login_logs"
            needPermission={[Permissions.Administration]}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box m={0} style={{ height: '100%' }}>
                        <LoginLogsTable setGraphData={setGraphData} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={'6rem'}>
                        {showGraphs ? (<LoginLogsChart chart={chart} />
                        ) : (<p>{graphData && t('admin:login_logs.unavailable')}</p>)}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={'6rem'}>
                        {showGraphs ? (
                            <LoginLogsChart chart={chartPerUser} />
                        ) : (<></>)}
                    </Box>
                </Grid>

            </Grid>
        </AppPage>
    );
}

export default LoginLogsPage;

