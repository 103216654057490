import { AuthorizationHeader } from '../../@types/lib/api';

export default function authHeader(): AuthorizationHeader {
  // return authorization header with basic auth credentials
  const authToken: string | null = localStorage.getItem('auth_token');

  if (authToken !== null) {
    return { Authorization: `Bearer ${authToken}` };
  }

  return {};
}
