/* eslint-disable */
import React from 'react';

// Custom Components
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelNerazvrstaneCeste from '@/models/nerazvrstane_ceste';

// Types
import MapCentricPage from '@/ui/MapCentric/MapCentricPage';
import { highlightedVectorStyle } from '@/components/Map/mapStyles';

type NerazvrstaneCesteMapCentricPageParams = {};

const NerazvrstaneCesteMapCentricPage = (
  props: NerazvrstaneCesteMapCentricPageParams
) => (
    <AppPage
      titlett="nerazvrstane_ceste:page.nerazvrstane_ceste"
      needPermission={[Permissions.NerazvrstaneCeste]}
    >
      <MapCentricPage
        model={modelNerazvrstaneCeste}
        titleToken="titles.nerazvrstane_ceste"
        view='default'
        baseRecordPath={modelNerazvrstaneCeste.apiPath}
        mapId={35}
        mapModel={modelNerazvrstaneCeste}
        selectedLayerStyle={highlightedVectorStyle}
      />
    </AppPage>
  );

export default NerazvrstaneCesteMapCentricPage;
