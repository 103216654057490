/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, Fragment } from 'react';

// MUI
import DialogTitle from '@mui/material/DialogTitle';

// Custom components
import FormHeaderStatus from '@/ui/Form/FormHeaderStatus';

// Types
import { ICardHeading } from '@/@types/ui/Card';

const CardHeading: FunctionComponent<React.PropsWithChildren<ICardHeading>> = (
  props
) => {
  const { children, recordStatus, statuses, header, color, subheader } = props;

  return (
    <>
      <DialogTitle
        sx={{
          color: 'common.white',
          backgroundColor: 'primary.main',
        }}
      >
        {header || children}
      </DialogTitle>
      {statuses !== undefined && recordStatus !== undefined ? (
        <FormHeaderStatus statuses={statuses} recordStatus={recordStatus} />
      ) : null}
    </>
  );
};

export default CardHeading;
