/* eslint-disable */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Box, useMediaQuery, useTheme } from '@mui/material';

// Ui
import { GridContainer, GridItem } from '@/ui/Grid';
import TableHeading from '@/ui/Table/TableHeading';

// Views
import ImageDialog from '@/views/Comments/ImageDialog';

// Context
import DialogContext, {
  DialogContextType,
} from '@/context/DialogContext/DialogContext';

// Types
import { DCRecord } from '@/@types/lib/dataController';

interface IDataCentricImagesOverlayProps {
  closeOverlay: () => void;
  images: DCRecord[];
  selectedObjektId: number;
}

const DataCentricImagesOverlay = (props: IDataCentricImagesOverlayProps) => {
  const { t } = useTranslation();

  const dialogContext = useContext<DialogContextType>(DialogContext);

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const { closeOverlay, images, selectedObjektId } = props;

  const handleOpenImageDialog = (image: any) => {
    // @ts-ignore
    dialogContext.showDialog(ImageDialog, {
      image,
      onClose: handleDialogClose,
    });
  };

  const handleDialogClose = () => {
    dialogContext.hideDialog();
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        backgroundColor: '#fafafa',
        overflowY: 'auto',
        overflowX: "hidden",
        zIndex: 1200,
      }}
    >
      <Box display="flex" justifyContent="space-between" position={"relative"}>
        <TableHeading>
          {t('titles.photos_on_object') + " " + selectedObjektId}
        </TableHeading>
        <IconButton
          style={{ transform: 'scale(1.7)' }}
          color="primary"
          onClick={() => closeOverlay()}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <GridContainer p={0}>
        {images.map((photo, index) => (
          <GridItem xs={12} md={6} lg={4} height="100%" width="100%" display={"flex"} justifyContent={"center"}>
            <img
              key={`image-${index}`}
              src={`data:image/jpeg;base64, ${photo.image}`}
              onClick={() => {
                handleOpenImageDialog(photo.image);
              }}
              style={{
                objectFit: 'cover',
                width: "calc( 100% - 32px )", // -16px cus of grid doing wierd things with 16px padding and margin
                height: "calc( 100% - 32x )",
                aspectRatio: lgUp ? '4/3' : '1/1',
                cursor: 'pointer',
              }}
            />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default DataCentricImagesOverlay;
