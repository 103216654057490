import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { OpenInNew } from '@mui/icons-material';
import KucaZaOdmorForm from '@/views/Ena/KuceZaOdmor/KucaZaOdmorForm';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import DataController from '@/lib/DataController';
import modelKuceZaOdmor from '@/models/kuce_za_odmor';

// Types
import { TCustomGFIDKPProps } from '@/@types/views/map';

const KuceZaOdmorButton = (props: TCustomGFIDKPProps) => {
  const { record, onToggle, handleCloseMenu } = props;
  const { t } = useTranslation();
  const identifier = 'kuce_za_odmor';
  const dcKuceZaOdmor = new DataController(modelKuceZaOdmor);

  const recordId = useMemo(
    () =>
      record
        ? record.id
          ? (record.id as number)
          : record.fid
          ? (record.fid as number)
          : undefined
        : undefined,
    [record]
  );

  const memoizedModelForm = useMemo(
    () => (
      <KucaZaOdmorForm
        dc={dcKuceZaOdmor}
        mode="update"
        form="update"
        recordId={recordId}
        onClose={() => {
          if (handleCloseMenu) handleCloseMenu();
        }}
      />
    ),
    [record]
  );

  const handleToggleKuceZaOdmor = () => {
    onToggle(memoizedModelForm, identifier);
  };

  return (
    <MapCardActionButton
      startIcon={<AssignmentIndIcon />}
      endIcon={<OpenInNew />}
      onClick={handleToggleKuceZaOdmor}
      aria-label="expand"
    >
      {t('buttons.edit')}
    </MapCardActionButton>
  );
};

export default KuceZaOdmorButton;
