import { IModel } from "@/@types/models/model";
import coreFields from "./helpers/coreFields";
import buttonFields from "./helpers/buttonFields";

const ToponimTipovi: IModel = {
  title: "Toponim Tipovi",
  apiPath: "core/toponim-tipovi",
  forms: {
    "default": {
      fields: ["id", "toponim_tip", "ttoken", "app_permission_id", "mw_procedure_name"]
    },
    "insert": {
      fields: ["id", "toponim_tip", "ttoken", "app_permission_id", "mw_procedure_name"]
    },
    "assign_to_tenant": {
      fields: ["toponim_tipovi_picker", "sortorder"]
    },
    "edit_assign_to_tenant": {
      fields: ["sortorder"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "toponim_tip", "ttoken", "app_permission_id", "active", "created_on", "created_by", "modified_on", "modified_by"],
      hidden: ["id"]
    },
    "admin": {
      fields: ["id", "toponim_tip", "ttoken", "app_permission_id", "mw_procedure_name", "active", "sortorder", "created_on", "created_by", "modified_on", "modified_by", "edit", "remove"]    
    }
  },
  fields: [
    coreFields.id,
    {
      title: 'Active',
      source: 'active',
      ttoken: 'common.active',
      type: 'active',
      filter: true,
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: true
      },
      readonly: true,
      translate: true,
      validation: {
        required: false
      } 
    },
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: "Tenant Toponim Tip Id",
      source: "toponim_tip_id",
      ttoken: "toponim_tip_id",
      type: "numeric"
    },
    {
      title: "Naziv",
      source: "toponim_tip",
      ttoken: "toponim_tip",
      type: "text",
      validation: {
        maxLength: 50,
        required: true
      }
    },
    {
      title: "Naziv Translation Token",
      source: "ttoken",
      ttoken: "translation.ttoken",
      type: "text",
      validation: {
        maxLength: 50,
        required: true
      }
    },
    {
      title: "App Permission Id",
      source: "app_permission_id", 
      ttoken: "admin:app_permission",
      type: "picker",
      subModel: {
        apiPath: "system/app_permissions?view=picker",
        format: "{label}",
        idattr: "id"
      },
      validation: {
        required: true
      }
    },
    {
      title: "MW Procedure Name",
      source: "mw_procedure_name",
      ttoken: "admin:data.mw_procedure_name",
      type: "text",
      validation: {
        required: true
      },
      width: "full"
    },
    {
      title: 'toponim_tipovi_picker',
      source: 'toponim_tipovi_picker',
      ttoken: 'admin:toponim_tip.picker',
      type: 'picker',
      subModel: {
        apiPath: "system/tenants/{tenant_id}/toponim-tipovi?view=picker",
        format: "{label}",
        idattr: "id"
      },
      readonly: false,
      validation: {
        required: false
      },
      translate: true
    },
    {
      title: 'sortorder',
      source: 'sortorder',
      ttoken: 'core:common.sortorder',
      type: 'numeric',
      validation: {
        required: false
      }
    },
    buttonFields.remove,
    buttonFields.edit,
    buttonFields.remove
  ]
}

export default ToponimTipovi;