/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, ReactElement, useContext } from 'react';

// MUI
import Tab from '@mui/material/Tab';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { UserContextType } from '@/context/UserContext/UserContext';
import { FieldValidation } from '@/@types/lib/appValidator';
import { Permission } from '@/@types/common';

export interface IFormTab {
  id?: string;
  value: number;
  label: string;
  icon: ReactElement;
  fields?: string[];
  validation?: { [k: string]: FieldValidation };
  needPermission?: Permission[];
}

function a11yProps(index: number): object {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const FormTab: FunctionComponent<IFormTab> = (
  props: React.PropsWithChildren<IFormTab>
) => {
  const userContext = useContext(UserContext) as UserContextType;
  const {
    value,
    label,
    icon,
    fields,
    validation,
    needPermission,
    children,
    ...other
  } = props;

  let hasError = false;
  if (
    fields &&
    validation &&
    fields.find(
      (x) => validation.hasOwnProperty(x) && validation[x].valid === false
    )
  ) {
    hasError = true;
  }

  const tabStyle = {
    minWidth: 100,
    px: 1,
    color: hasError ? 'error.dark' : 'primary.main',
  };

  return needPermission && !userContext.hasAnyPermission(needPermission) ? (
    <Tab style={{ minWidth: 0, padding: 0 }} {...other} />
  ) : (
    <Tab
      value={value}
      label={label}
      icon={icon}
      sx={tabStyle}
      {...a11yProps(value)}
      {...other}
    />
  );
};

export default FormTab;
