/* eslint-disable */
import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import DialogActionButton from "@/ui/Dialog/DialogActionButton";

//Types
import { IDialogActionButtonDetail } from "@/@types/ui/Dialog";

const DialogActionButtonClose: FunctionComponent<IDialogActionButtonDetail> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<CloseIcon />} {...props} ariaLabel="close" ariaControls="close">
      {t("buttons.close")}
    </DialogActionButton>
  );
};

export default DialogActionButtonClose;
