import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from 'react';
// Lightbox
import Lightbox, { Slide } from 'yet-another-react-lightbox';
import { Download, Zoom } from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/styles.css';

// Lib
import useApi from '@/lib/api/useApi';

// Types
import { DCFile } from '@/@types/lib/dataController';

export interface FullscreenImageContextType {
  showImages: (files: DCFile[], initialIndex: number, apiPath?: string) => void;
}

const FullscreenImageContext = createContext<
  FullscreenImageContextType | undefined
>(undefined);

export const FullscreenImageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { downloadWithMetadata } = useApi();
  const [isOpen, setIsOpen] = useState(false);
  const [fullscreenImages, setFullscreenImages] = useState<Slide[]>([]);
  const [initialIndex, setInitialIndex] = useState(0);

  const fetchImage = async (
    file: DCFile,
    apiPath: string
  ): Promise<Slide | null> => {
    if (file.content && file.name) {
      return {
        src: `data:image/jpeg;base64,${file.content}`,
        download: file.name,
      };
    }
    if (file.uuid) {
      let parsedApiPath: string;
      if (apiPath.includes('{uuid}')) {
        parsedApiPath = apiPath.replace('{uuid}', file.uuid);
      } else {
        parsedApiPath = `${apiPath}/${file.uuid}`;
      }

      const resp = await downloadWithMetadata(parsedApiPath);
      if (resp.success && resp.data && resp.data.content) {
        return {
          src: `data:image/jpeg;base64,${resp.data.content}`,
          download: resp.data.name,
        };
      }
    }
    // eslint-disable-next-line no-console
    console.error('Unable to fetch image or image content is incorrect');
    return null;
  };

  const showImages = (
    files: DCFile[],
    clickedIndex: number,
    apiPath?: string
  ) => {
    if (!apiPath) {
      // eslint-disable-next-line no-console
      console.error('No apiPath was provided for fetching images');
      return;
    }

    // Fetch all images and preload them for the lightbox
    Promise.all(files.map((file) => fetchImage(file, apiPath)))
      .then((slides) => {
        const validSlides = slides.filter(
          (slide): slide is Slide => slide !== null
        );
        if (validSlides.length > 0) {
          setFullscreenImages(validSlides);
          setInitialIndex(clickedIndex);
          setIsOpen(true);
        } else {
          // eslint-disable-next-line no-console
          console.warn('No valid images to display');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching images', error);
      });
  };

  const providerValue = useMemo(
    () => ({
      showImages,
    }),
    [showImages]
  );

  return (
    <FullscreenImageContext.Provider value={providerValue}>
      {children}
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={fullscreenImages}
        index={initialIndex}
        carousel={{ finite: true }}
        plugins={[Zoom, Download]}
      />
    </FullscreenImageContext.Provider>
  );
};

export const useFullscreenImages = (): FullscreenImageContextType => {
  const context = useContext(FullscreenImageContext);
  if (!context) {
    throw new Error(
      'useFullscreenImages must be used within a FullscreenImageProvider'
    );
  }
  return context;
};
