import { createContext } from 'react';

// Types
export type LoaderContextType = {
  loading: number;
  toggleLoading: (value: boolean) => void;
};

const LoaderContext = createContext<LoaderContextType>({
  loading: 0,
  toggleLoading: () => {},
});

LoaderContext.displayName = 'LoaderContext';
export default LoaderContext;
