import { createContext } from 'react';

// Types
export type ErrorContextType = {
  open: boolean;
  showError: (code: number, message: string, description?: string) => void;
  code: number | string;
  message: string;
  description?: string;
};

const ErrorContext = createContext<ErrorContextType>({
  open: false,
  showError: () => {},
  code: '',
  message: '',
  description: '',
});

ErrorContext.displayName = 'ErrorContext';
export default ErrorContext;
