/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AppValidator from '@/lib/AppValidator';
import DataControllerSubModel from '@/lib/DataControllerSubModel';

import radio from '@/models/submodels/radio';
import checkbox from '@/models/submodels/checkbox';
import picker from '@/models/submodels/picker';

import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DialogContext from '@/context/DialogContext/DialogContext';

import {
  DoCloseResponse,
  DoCloseResponseFail,
  DoCloseResponseSuccess,
  DoDeleteResponse,
  DoInsertResponse,
  DoInsertResponseFail,
  DoInsertResponseSuccess,
  DoUpdateResponse,
  DoUpdateResponseFail,
  DoUpdateResponseSuccess,
  FormControllerProps,
  PickerDataControllers,
  ServerErrorPromise,
} from '@/@types/components/formController';
import { FieldAny, IFieldPickerModel, IFieldsCollection } from '@/@types/models/model';
import {
  DataSingleResponse,
  DCFieldValue,
  DCInsertResponseSuccess,
  DCInsertResponse,
  DCRecord,
  DCResponseFail,
  DCFile,
} from '@/@types/lib/dataController';
import { RecordValidation, ValidatorFnName } from '@/@types/lib/appValidator';
import { ConfirmResult } from '@/ui/Dialog/ConfirmDialog/ConfirmDialog';
import { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';

export type SubmodelAPIPaths = {
  // field-name: path
  [fieldname: string]: string;
};

function useFormController(props: FormControllerProps) {
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const dialogContext = useContext(DialogContext);

  const [validation, setValidation] = useState<RecordValidation>({});
  const [validated, setValidated] = useState(false);
  const [pickerDataControllers, setPickerDataControllers] = useState<PickerDataControllers>({});
  const [dataChanged, setDataChanged] = useState(false);
  const [fields, setFields] = useState<IFieldsCollection>([]);
  const [record, setRecord] = useState<DCRecord>({});
  const [recordDocument, setRecordDocument] = useState<DCFile | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [lastChangedField, setLastChangedField] = useState<string>();
  const [lastChangeAt, setLastChangeAt] = useState<Date>();

  const [originalRecord, setOriginalRecord] = useState<DCRecord>({});

  const { t } = useTranslation();

  const {
    dc,
    form,
    mode,
    recordId,
    defaultValues,
    initialRecord,
    onDataUpdate,
    secondary,
    customPath,
    fieldNames,
    controllerForm,
    alternativeSubmodelAPIPath,
    pickerApiPathParams,
  } = props;

  const validator = new AppValidator(t);

  let mounted = false;

  useEffect(() => {
    mounted = true;
    const secondaryPath =
      secondary && recordId !== undefined
        ? secondary.replace('__split__', recordId.toString())
        : customPath
        ? customPath + `/${recordId}`
        : undefined;

    if (recordId !== undefined) {
      dc.GetDataSingle(recordId, secondaryPath)
        .then((resp: DataSingleResponse) => {
          if (resp.success) {
            const rec = resp.data as DCRecord;
            if (defaultValues && mode === 'insert') {
              Object.keys(defaultValues).forEach((key) => {
                // @ts-ignore
                if (!record.hasOwnProperty(key) || rec[key] === undefined || rec[key] === null) {
                  record[key] = defaultValues[key];
                }
              });
            }

            const recordCopy = { ...rec };
            const originalRecord = { ...rec };

            setRecord(recordCopy);
            setOriginalRecord(originalRecord);
          }
        })
        .catch((err: DCResponseFail) => {
          console.warn('Error fetching record from formController ', err);
        });
    } else if (initialRecord !== undefined) {
      const recordCopy = { ...initialRecord };
      const originalRecord = { ...initialRecord };

      setRecord(recordCopy);
      setOriginalRecord(originalRecord);
    } else if (mode === 'insert') {
      const rec = getDefaultValues();
      const recordCopy = { ...rec };
      const originalRecord = { ...rec };

      setRecord(recordCopy);
      setOriginalRecord(originalRecord);
    }

    const fs = getFields();
    setFields(fs);

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const fieldsWithModel = getFieldsWithModel(fields);

    const pdc: PickerDataControllers = {};

    fieldsWithModel.forEach((f) => {
      const modelType = f.type === 'picker' ? picker : f.type === 'radio' ? radio : f.type === 'checkbox' ? checkbox : null;

      if (modelType !== null) {
        if (alternativeSubmodelAPIPath) {
          if (Object.keys(alternativeSubmodelAPIPath).includes(f.title)) {
            f.subModel.apiPath = alternativeSubmodelAPIPath[f.title];
          }
        }
        Object.assign(pdc, {
          [f.source]: new DataControllerSubModel(modelType, f, pickerApiPathParams),
        });
      }
    });

    const getDataPromises = Object.keys(pdc).map((f) => pdc[f].GetData());

    Promise.all(getDataPromises)
      .then(() => {
        setPickerDataControllers(pdc);
      })
      .catch(() => {
        setPickerDataControllers(pdc);
      });
  }, [fields, alternativeSubmodelAPIPath]);

  useEffect(() => {
    if (validated && lastChangedField !== undefined) {
      validateField(lastChangedField);
    }
    setDataChanged(checkIfChanged(record));
  }, [lastChangeAt, lastChangedField]);

  const getDefaultValues = () => {
    const rec = {};

    // set default values from fields
    // @ts-ignore TODO fix types
    dc.fields.forEach((f: DCRecord) => {
      if (f.items && f.items.hasOwnProperty('default')) {
        if (f.type === 'boolean') {
          // @ts-ignore
          rec[f.source] = f.items.default;
        } else if (f.type === 'radio') {
          // @ts-ignore
          if (f.items.default !== undefined) {
            // @ts-ignore
            const pos = f.items.values.indexOf(f.items.default);
            // @ts-ignore
            rec[f.source] = {
              // @ts-ignore
              label: t(f.items.labels[pos]),
              // @ts-ignore
              value: f.items.default,
            };
          }
        } else if (f.type === 'checkbox') {
          // @ts-ignore
          const valueArray = Array.isArray(f.items.default)
            ? // @ts-ignore
              f.items.default
            : // @ts-ignore
              [f.items.default];
          // @ts-ignore
          rec[f.source] = valueArray;
        }
      }
    });

    // set default values from props
    if (defaultValues !== undefined && defaultValues !== null) {
      Object.keys(defaultValues).forEach((key) => {
        // @ts-ignore
        rec[key] = defaultValues[key];
      });
    }

    return rec;
  };

  const getFieldsWithModel = (flds: IFieldsCollection) => {
    const fieldModels = flds.filter((f) => f.hasOwnProperty('subModel')).map((f) => f as IFieldPickerModel);
    return fieldModels;
  };

  const getFields = () => {
    const formId = form || mode;

    let fs: IFieldsCollection = [];

    // get all fields for controller
    if (controllerForm) {
      fs = dc.getFormFields(controllerForm);
    }

    // override with custom definitions
    const formFields = dc.getFormFields(formId);
    if (formFields.length > 0) {
      return formFields;
    }
    formFields.forEach((f: FieldAny) => {
      const ind = fields.findIndex((x) => x.source == f.source);
      if (ind >= 0) {
        fs[ind] = f;
      } else {
        fs.push(f);
      }
    });

    // filter by fieldnames if necessary
    if (fieldNames) {
      return fs.filter((x) => fieldNames.indexOf(x.source) >= 0);
    }
    return fs;
  };

  const checkIfChanged = (record: DCRecord) => {
    const changed = validator.checkIfRecordChanged(record, originalRecord);
    return changed;
  };

  const validateField = (source: string) => {
    const field = fields.find((f) => f.source === source);

    if (field !== undefined) {
      const fieldValidation = validator.validateField(record, field);

      setValidation((prevState) => ({
        ...prevState,
        [source]: fieldValidation,
      }));
    }
  };

  const getValidator = () => {
    const formId = form || mode;
    const validateFunc = dc.getValidator(formId) as ValidatorFnName;
    if (validateFunc !== null && validateFunc !== 't') {
      if (validator[validateFunc] !== undefined && typeof validator[validateFunc] === 'function') {
        return validator[validateFunc];
      } /* else {
        // return (record: any, t: any) => ({});
      } */
    } /* else {
      // return (record: any, t: any) => ({});
    } */
    return null;
  };

  const validate = () => {
    // model validation
    const validation = validator.validateModel(record, fields);

    // custom validation
    const validatorFn = getValidator();
    const customValidation = validatorFn !== null ? validatorFn(record, t) : {};

    const finalValidation = validator.mergeValidation(validation, customValidation);
    const isValid = validator.checkIfValid(finalValidation);

    setValidation(finalValidation);
    setValidated(true);

    return isValid;
  };

  const serverErrorPromise = (serverValidation: RecordValidation): Promise<ServerErrorPromise> => {
    setValidation(serverValidation);
    return Promise.resolve({
      success: false,
      validationPass: false,
      validation: serverValidation,
    });
  };

  // *** Confirmations ***

  /* const confirmUpdate = () => { // Trash code. please refactor when needing this feature as its not used anywhere currently
    const checkFields = fields.filter(
      (f) => f.validation && f.validation.confirmChange
    );
    const changedFields = checkFields.filter((f) =>
      validator.checkIfFieldChanged(record[f.source], originalRecord[f.source])
    );

    const changePromises = changedFields.map(
      (f) =>
        new Promise((resolve) =>
          confirmUpdateField(f)
            .then((resp) => Promise.resolve({ sucess: true }))
            .catch((err) => Promise.resolve({ canceled: true }))
        )
    );

    return Promise.all(changePromises)
      .then((resp) => Promise.resolve({ confirmed: true }))
      .catch((err) => Promise.reject({ canceled: true }));
  }; */

  /* const confirmUpdateField = (field: FieldAny): Promise<ConfirmResult> => {
    const oldValue = originalRecord[field.source];
    const newValue = record[field.source];

    const title = `Želite li doista izmijeniti vrijednost polja '${field.title}'?`;
    const text = `Stara vrijednost: ${oldValue}, nova vrijednost: ${newValue}`;
    const confirmButtonText = 'Da, izmijeni';
    const cancelButtonText = 'Ne, odustani';

    return dialogContext.showConfirmDialog({
      title,
      text,
      confirmButtonText,
      cancelButtonText,
    });
  }; */

  const confirmDelete = (): Promise<ConfirmResult> => {
    const title = t('cdialogs.are_you_sure');
    const text = t('cdialogs.once_deleted');
    const confirmButtonText = t('cdialogs.yes_delete');
    const cancelButtonText = t('cdialogs.no_cancel');

    return dialogContext.showConfirmDialog({
      title,
      text,
      confirmButtonText,
      cancelButtonText,
    });
  };

  /* const confirmUpdateClose = (): Promise<ConfirmResult> => {
    const title = t('cdialogs.changes');
    const text = t('cdialogs.want_to_save');
    const confirmButtonText = t('cdialogs.yes_save_changes');
    const cancelButtonText = t('cdialogs.no_just_close');

    return dialogContext.showConfirmDialog({
      title,
      text,
      confirmButtonText,
      cancelButtonText,
    });
  }; */

  const confirmDataChangedClose = (): Promise<ConfirmResult> => {
    const title = t('cdialogs.changes');
    const text = t('cdialogs.close_text');
    const confirmButtonText = t('cdialogs.close');
    const cancelButtonText = t('cdialogs.cancel');

    return dialogContext.showConfirmDialog({
      title,
      text,
      confirmButtonText,
      cancelButtonText,
    });
  };

  // *** Export functions ***

  const onFieldChange = (value: DCFieldValue, source: string) => {
    if (source === 'document') {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          setRecordDocument(value[0] as DCFile);
        } else {
          setRecordDocument(null);
        }
      } else {
        setRecordDocument(value as DCFile);
      }
    } else {
      setRecord((prevState) => ({
        ...prevState,
        [source]: value,
      }));
    }

    setLastChangedField(source);
    setLastChangeAt(new Date());
  };

  const doInsert = (): Promise<DoInsertResponse> => {
    const isValid = validate();

    if (isValid) {
      setIsLoading(true);
      return dc
        .InsertRecord(record as DCRecord, customPath)
        .then((response: DCInsertResponse) => {
          const { id } = response as DCInsertResponseSuccess;
          if (onDataUpdate) {
            onDataUpdate();
          }
          return Promise.resolve({
            success: true,
            id,
          } as DoInsertResponseSuccess);
        })
        .catch((response: DCResponseFail) => {
          switch (response.errorCode) {
            case 401:
              return serverErrorPromise({
                email: {
                  valid: false,
                  msg: t('register.emailInUse'),
                },
              }) as Promise<DoInsertResponseFail>;
            default:
              break;
          }
          switch (response.errsubcode) {
            case 9922:
              snackbarContext.showNotification(t('insert.usernameInUse'), 'warning');
              return serverErrorPromise({
                username: {
                  valid: false,
                  msg: t('insert.usernameInUse'),
                },
              }) as Promise<DoInsertResponseFail>;
          }
          return Promise.resolve({
            success: false,
            validationPass: true,
            error: `Greška prilikom spremanja novih podataka! ${response.error ? response.error : ''}`,
          } as DoInsertResponseFail);
        })
        .finally(() => {
          if (mounted) {
            setIsLoading(false);
          }
        });
    }
    if (snackbarContext !== null) {
      snackbarContext.showNotification(t('messages.required_fields'), 'warning');
    }
    return Promise.resolve({ success: false, validationPass: false });
  };

  const doUpdate = (): Promise<DoUpdateResponse> => {
    const id = recordId || (record.id as number);
    if (id === undefined || id === null) {
      return Promise.reject({ success: false, error: 'Unknown id' });
    }

    const isValid = validate();

    if (isValid) {
      setIsLoading(true);
      /* return confirmUpdate()
        .then((result: ConfirmResult) => {
          if (result.canceled) {
            return Promise.reject({ success: false, canceled: true });
          }
          if (result.confirmed) { */
      return dc
        .UpdateRecord(id, record, customPath)
        .then(() => {
          if (fieldNames?.includes('document')) {
            // TODO update document
            console.log('Sad bi trebalo updateat dokument', recordDocument);
          }
        })
        .then(() => {
          setDataChanged(false);
          setOriginalRecord(record);
          setValidated(false);

          if (onDataUpdate) {
            onDataUpdate();
          }

          return Promise.resolve({
            success: true,
            updated: true,
          } as DoUpdateResponseSuccess);
        })
        .catch(() =>
          Promise.reject({
            success: false,
            validationPass: true,
            error: t('error.editRecord'),
          } as DoUpdateResponseFail)
        )
        .finally(() => {
          if (mounted) {
            setIsLoading(false);
          }
        });
      /* }
          return Promise.reject({
            success: false,
            error: 'Unknown',
          } as DoUpdateResponseFail);
        })
        .catch(() => Promise.reject({ success: false, canceled: true }));*/
    }
    if (snackbarContext !== null) {
      snackbarContext.showNotification(t('messages.required_fields'), 'warning');
    }
    return Promise.resolve({
      success: false,
      validationPass: false,
      validation,
    });
  };

  const doDelete = (): Promise<DoDeleteResponse> => {
    const id = recordId || (record.id as number);
    if (id === undefined || id === null) {
      return Promise.reject({ success: false, error: 'Unknonw id' });
    }

    return confirmDelete().then((result: ConfirmResult) => {
      if (!result.success) {
        return Promise.resolve({ success: false });
      }
      return dc
        .DeleteRecord(id, customPath)
        .then(() => {
          if (onDataUpdate) {
            onDataUpdate();
          }
          return Promise.resolve({ success: true });
        })
        .catch((response: DCResponseFail) =>
          Promise.reject({
            success: false,
            error: response.error ? response.error : 'Greška prilikom brisanja podataka',
          })
        );
    });
  };

  const doClose = (
    // return success = true on close, false on canceled close
    externalDataChanged: boolean = false
  ): Promise<DoCloseResponse> => {
    console.log("data changed",dataChanged)
    if (dataChanged || externalDataChanged) {
      // Only confirm close if data is changed, otherwise just close without confirmation
      return confirmDataChangedClose().then((result) => {
        return Promise.resolve({
          success: result.success,
        });
      });
    }
    return Promise.resolve({ success: true });
  };

  const doClear = () => {
    setRecord({});
    return Promise.resolve({ success: true });
  };

  const doPrepareSearch = () => {
    const isValid = validate();

    if (isValid) {
      return Promise.resolve({ success: true, filters: record });
    }
    if (snackbarContext !== null) {
      snackbarContext.showNotification(t('messages.required_fields'), 'warning');
    }
    return Promise.resolve({ success: false, validationPass: false });
  };

  const doRefresh = () => {
    if (recordId) {
      dc.GetDataSingle(recordId, customPath ? customPath + `/${recordId}` : undefined)
        .then((resp: DataSingleResponse) => {
          if (resp.success) {
            const rec = resp.data as DCRecord;
            const recordCopy = { ...rec };
            const originalRecord = { ...rec };

            setRecord(recordCopy);
            setOriginalRecord(originalRecord);
          }
        })
        .catch((err: DCResponseFail) => {
          console.warn('Error refreshing record from formController ', err);
        });
    }
  };

  const doValidate = (): boolean => validate();

  return {
    validation,
    validated,
    pickerDataControllers,
    dataChanged,
    fields,
    record,
    isLoading,

    onFieldChange,
    doInsert,
    doUpdate,
    doDelete,
    doClose,
    doClear,
    doPrepareSearch,
    doRefresh,
    doValidate,
  };
}

export default useFormController;
