/* eslint-disable */
import React, { BaseSyntheticEvent } from 'react';
import { TextField, Box, FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

// MUI
import SearchIcon from '@mui/icons-material/Search';

// Types
import { ThumbFilters } from '@/ui/PhotoCentric/PhotoCentricThumbs';

type PhotoCentricThumbsFilterProps = {
  filterObject: ThumbFilters;
  onFilterChange: any;
  recordsLength: number;
  filteredRecrodsLength: number;
};

const PhotoCentricThumbsFilter = (props: PhotoCentricThumbsFilterProps) => {
  const { filterObject, onFilterChange, recordsLength, filteredRecrodsLength } =
    props;
  const { t } = useTranslation();

  function handleSetFilter(e: BaseSyntheticEvent) {
    const { name } = e.target;

    const newFilter = { ...filterObject };
    if (name === 'text') {
      newFilter.textFilter = e.target.value;
    } else if (name === 'selected') {
      newFilter.selectedFilter = e.target.checked;
    }

    onFilterChange(newFilter);
  }

  const hasRecords = recordsLength > 0;

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
    >
      <TextField
        sx={{ ml: 3, mr: 3 }}
        variant="standard"
        margin="dense"
        name="text"
        value={filterObject.textFilter || ''}
        placeholder={`${t('common.search_count_placeholder_detailed', {
          filteredCount: filteredRecrodsLength,
          totalCount: recordsLength,
        })}`}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        onChange={handleSetFilter}
        disabled={!hasRecords}
      />
      <FormControlLabel
        control={<Switch checked={filterObject.selectedFilter} />}
        disabled={!hasRecords}
        name="selected"
        label={t('buttons.filter_selected') as string}
        onChange={handleSetFilter}
      />
    </Box>
  );
};

export default PhotoCentricThumbsFilter;
