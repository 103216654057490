import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTitle(title: string) {
  const { t } = useTranslation();
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${t('app_title_short')} - ${t(title)}`;
    return () => {
      document.title = prevTitle;
    };
  });
}
