/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';

//OpenLayers
import OlLayerVector from 'ol/layer/Vector';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import GroupLayerContext from './GroupLayer/GroupLayerContext';
import { getDefinedOptions, getEvents } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { GroupLayerContextType } from '@/@types/components/Map/Layers/GroupLayer';
import { IVectorLayer } from '@/@types/components/Map/Layers';

const VectorLayer: FunctionComponent<IVectorLayer> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const parentLayerContext = useContext(
    GroupLayerContext
  ) as GroupLayerContextType;

  const [isVisible, setIsVisible] = useState<boolean | undefined>(mapContext?.getLayerVisibility()[props.id] !== undefined ? mapContext?.getLayerVisibility()[props.id] : undefined);

  let layer = undefined;

  const options = {
    className: undefined,
    opacity: undefined,
    visible: isVisible,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    renderOrder: undefined,
    rednerBuffer: undefined,
    source: undefined,
    map: undefined,
    declutter: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined,
  };

  const idKey = 'id';
  const titleKey = 'title';

  const events = {
    change: undefined,
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  useEffect(() => {
    setIsVisible(mapContext?.getLayerVisibility()[props.id]);
  }, [mapContext?.getLayerVisibility()[props.id]]);

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    layer = new OlLayerVector(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.zIndex) {
      layer.setZIndex(props.zIndex);
    }

    if (
      parentLayerContext &&
      parentLayerContext.exists &&
      parentLayerContext.childLayers
    ) {
      // console.log('*** parent layer')
      parentLayerContext.childLayers.push(layer);
    } else if (mapContext.map) {
      const mapLayers = mapContext.map.getLayers();
      const mapLayer = mapLayers
        .getArray()
        .find((x) => x instanceof OlLayerVector && x.get(idKey) === props.id);

      if (mapLayer) {
        // console.log('--remove mapLayer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      // console.log('++add Layer', layer);
      mapContext.map.addLayer(layer);
    } else {
      // console.log('push init layer', layer);
      mapContext.initOptions.layers.push(layer);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO: TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      layer.on(eventName, olEvents[eventName]);
    }

    layer.changed();

    //console.log('layer Vector mounted', layer);
  }, [
    props.className,
    props.opacity,
    props.visible,
    props.extent,
    props.zIndex,
    props.minResolution,
    props.maxResolution,
    props.renderOrder,
    props.renderBuffer,
    props.source,
    props.map,
    props.declutter,
    props.style,
    props.updateWhileAnimating,
    props.updateWhileInteracting,
    isVisible
  ]);

  return null;
};

export default VectorLayer;
