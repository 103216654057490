/* eslint-disable */
import React, { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI Core Components
import Box from '@mui/material/Box';
import { SxProps, Typography } from '@mui/material';

// Custom Components
import AppPage from '@/ui/AppPage/AppPage';
import ModuleCard from '@/ui/ModuleCard/ModuleCard';
import TenantContext from '@/context/TenantContext/TenantContext';
import UserContext from '@/context/UserContext/UserContext';
import Permissions from '@/lib/permissions';
import modulesRoutes from '@/routes/modules';
import HomePageFooter from '@/views/core/HomePageFooter';
import HomePageModulesBox from './HomePage/HomePageModulesBox';

const styles = {
  coverContainer: {
    position: 'relative',
  } as CSSProperties,
  cover: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  } as CSSProperties,
  crest: {
    position: 'absolute',
    top: '50px',
    left: '50px',
    height: '200px',
  } as CSSProperties,
  welcomeText: {
    textAlign: 'left',
  } as CSSProperties,
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 15%',
  } as CSSProperties,
  staticPagesContainer: {
    marginTop: '50px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: '20px',
    borderTop: '1px solid lightgrey',
  } as CSSProperties,
  staticPageLink: {
    fontStyle: 'italic',
    color: 'grey',
  } as CSSProperties,
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 48px)', // viewport - header
  } as SxProps,
};

const HomePage = () => {
  const tenantContext = useContext(TenantContext);
  const userContext = useContext(UserContext);

  const { t } = useTranslation();

  return (
    <>
      {userContext?.hasAnyPermission([Permissions.Private]) ? (
        <AppPage titlett="menu.home" needPermission={[Permissions.Private]}>
          <Box sx={styles.pageContainer}>
            <Box sx={styles.coverContainer}>
              <img
                src={`data:${tenantContext?.tenantTheme?.background_image_type};base64, ${tenantContext?.tenantTheme?.background_image}`}
                style={styles.cover}
                alt=""
              />
              <img
                src={`data:${tenantContext?.tenantTheme?.crest_type};base64, ${tenantContext?.tenantTheme?.crest}`}
                style={styles.crest}
                alt=""
              />
            </Box>
            <Box sx={styles.contentContainer}>
              <Typography variant="h4">{t('home.welcome')}</Typography>
              <p>{t('home.description')}</p>
              <HomePageModulesBox modules={modulesRoutes} />
            </Box>
            <HomePageFooter />
          </Box>
        </AppPage>
      ) : (
        <AppPage titlett="menu.home" needPermission={[Permissions.Public]}>
          <Box sx={styles.pageContainer}>
            <Box sx={styles.coverContainer}>
              <img
                src={`data:image/png;base64, ${tenantContext?.tenantTheme?.background_image}`}
                style={styles.cover}
                alt=""
              />
              <img
                src={`data:image/png;base64, ${tenantContext?.tenantTheme?.crest}`}
                style={styles.crest}
                alt=""
              />
            </Box>
            <Box sx={styles.contentContainer}>
              <Typography variant="h4">{t('home.welcome')}</Typography>
              <p>{t('home.description')}</p>
              <HomePageModulesBox modules={modulesRoutes} />
            </Box>
            <HomePageFooter />
          </Box>
        </AppPage>
      )}
    </>
  );
};

export default HomePage;
