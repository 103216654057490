/* eslint-disable */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import SnackbarContext from "@/context/SnackbarContext/SnackbarContext";
import FormContent from "@/components/FormContent";
import FormButton from "@/ui/Form/FormButton";
import useAuthFormController from "@/components/useAuthFormController";
// import SnackbarContext from "../../../context/SnackbarContext/SnackbarContext"

//Types
import { PasswordResetRequestFormProps } from "@/@types/views/Auth";

function PasswordResetRequestForm(props: PasswordResetRequestFormProps) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);

  let navigate = useNavigate();

  const { onCompleted, form, dc } = props;
  const { record, validation, fields, onFieldChange, dataChanged, doSendPasswordResetRequest } =
    useAuthFormController(props);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
      switch (source) {
        case "username":
          onFieldChange(null, "email");
          break;
        case "email":
          onFieldChange(null, "username");
          break;
      }
    }
  };

  const handleSubmit = () => {
    if (doSendPasswordResetRequest) {
      doSendPasswordResetRequest()
        .then((result) => {
          if (result.success) {
            if (onCompleted) {
              onCompleted({ success: true });
            }
            if (snackbarContext) {
              snackbarContext.showNotification(t("notifications.success"), "success");
            }
          } else  {
            if (onCompleted) {
              onCompleted({ success: false });
            }
            if (snackbarContext) {
              snackbarContext.showNotification(t("notifications.fail"), "error");
            }
          }
        })
        .catch((result) => {
          if (result.validationPass === false) {
            if (snackbarContext) {
              snackbarContext.showNotification(result.validation as string, "error");
            }
          } else {
            if (onCompleted) {
              onCompleted({ success: false });
            }
            if (snackbarContext) {
              snackbarContext.showNotification("notifications.fail", "error");
            }
          }
        });
    }
  };

  const handleCancel = () => {
    navigate("/login");
  };

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h5" component="h3">
              {t("titles.password_reset_request")}
            </Typography>
          </GridItem>
          <FormContent
            fieldNames={dc.getFormFieldsNames(form)}
            record={record}
            validation={validation}
            fields={fields}
            onFieldChange={handleFieldChange}
            onSubmit={() => handleSubmit()}
            mode={"insert"}
            columns={1}
          />
        </GridContainer>
      </CardContent>
      <CardActions>
        <FormButton variant="contained" onClick={handleSubmit}>
          {t("buttons.send")}
        </FormButton>
        <FormButton variant="text" onClick={handleCancel}>
          {t("buttons.cancel")}
        </FormButton>
      </CardActions>
    </Card>
  );
}

export default PasswordResetRequestForm;