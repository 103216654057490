/* eslint-disable */
import React, { useContext, FunctionComponent, useState, useMemo } from 'react';
import { positionType } from "./SidebarContext";

// Custom components
import SidebarContext from '@/context/SidebarContext/SidebarContext';
import MapContext from '@/context/MapContext/MapContext';

const SidebarProvider: FunctionComponent<any> = (props) => {
  const mapContext = useContext(MapContext);
  const { onTabChange, positionProp, initialOpenId, children, initialOpenHeading} = props;

  // Configure here default settings for Sidebar: collapsed, default pane
  const [position, setPosition] = useState<positionType>(
    positionProp !== undefined ? positionProp : 'left'
  );
  const [activePane, setPane] = useState<string | null>(
    initialOpenId !== undefined ? initialOpenId : null
  );
  const [collapsed, setCollapse] = useState<boolean>(
    initialOpenId === undefined
  );

  const [onSidebarCollapse, setOnSidebarCollapse] = useState<() => void>(()=>{});

  const [sidebarHeading, handleSetSidebarHeading] = useState<string>(initialOpenHeading !== undefined ? initialOpenHeading : '');

  const setActivePane = (id: string): void => {
    if (onTabChange) {
      onTabChange(id);
    }
    setPane((prevState) => (prevState !== id ? id : null));
    const isCollapsed = activePane === id;
    setCollapse(isCollapsed);
    mapContext?.setSidebarCollapsed(isCollapsed);
  };

  const setCollapsed = (isCollapsed: boolean): void => {
    if (isCollapsed) {
      if (onTabChange) {
        onTabChange();
      }
      setTimeout(() => setPane(null), 380);
      setCollapse(true);
      mapContext?.setSidebarCollapsed(true);
    } else {
      setCollapse(true);
      mapContext?.setSidebarCollapsed(true);
    }
  };

  const setSidebarHeading = (heading: string) => {
    handleSetSidebarHeading(heading);
  }

  const providerValue = useMemo(
    () => ({
      activePane,
      collapsed,
      setActivePane,
      setCollapsed,
      position,
      setSidebarHeading,
      sidebarHeading,
      onSidebarCollapse,
      setOnSidebarCollapse
    }),
    [position, activePane, position, sidebarHeading]
  );

  return (
    <SidebarContext.Provider value={providerValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
