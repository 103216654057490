/* eslint-disable */
import React from "react";

//MUI
import Slider from "@mui/material/Slider";

interface ITimeSeriesSlider {
  ticks: number;
  checked: string | boolean | undefined;
  handleValueLabel: (x: number) => string;
  handleChangeCommited: (event: React.SyntheticEvent | Event, value: number | Array<number>) => void;
}

function TimeSeriesSlider(props: ITimeSeriesSlider) {
  const {ticks, checked, handleValueLabel, handleChangeCommited} = props

    return (
        <Slider
          size="medium"
          aria-label="Timestamp"
          defaultValue={20}
          valueLabelDisplay="auto"
          valueLabelFormat={handleValueLabel}
          step={1}
          marks
          min={0}
          max={ticks}
          onChangeCommitted={handleChangeCommited}
          sx={{
            width: 100,
            mr: 3
          }}
          disabled={ checked ? false : true }
        />
    )
}

export default TimeSeriesSlider