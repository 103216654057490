/* eslint-disable */
import OlControl from "ol/control/Control";
import { Options as OlControlOptions } from "ol/control/Control";

export default class ButtonGroup extends OlControl {

  constructor(id: string, opt_options: OlControlOptions) {

    super(opt_options);

    // var options = Object.assign({}, null, opt_options);

    var element = document.createElement("div");
    element.id = id;
    element.className = "button-group";

    if (opt_options.element) {
      element.appendChild(opt_options.element);
    }
  }
}

//@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
if (window.ol && window.ol.control) {
  //@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
  window.ol.control.MUIButtonGroup = ButtonGroup;
}
