import * as React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import InputAdornment from '@mui/material/InputAdornment';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

// Custom Controls
import BaseInputControl from './BaseInputControl';

// Types
import { IIconControlProps } from '@/@types/controls/controls';

const IconControl = (props: IIconControlProps) => {
  const { value, onChange } = props;

  const [focused, setFocused] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const handleInputChange = (newValue: string, source: string) => {
    onChange(newValue.toLowerCase().replace(' ', '_'), source);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleClickInfo = () => {
    const url = 'https://fonts.google.com/icons';
    window.open(url, '_blank');
  };

  return (
    <BaseInputControl
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleInputChange}
      InputLabelProps={{
        // bcus label goes under inputadorment
        shrink: focused || !!value,
        style: { marginLeft: !(focused || !!value) ? 30 : 0, transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)' }, // hacky shit cause
      }}
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon color="primary">{value}</Icon>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={t('admin:models.icon_info')}>
              <IconButton disableFocusRipple disableRipple disableTouchRipple size="small" onClick={handleClickInfo}>
                <Info fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default IconControl;
