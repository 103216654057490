import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';

// MUI
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ImageList, ImageListItem, ImageListItemBar, Skeleton } from '@mui/material';

// MUI Icons
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

// UI
import PhotoCentricThumbsFilter from '@/ui/PhotoCentric/PhotoCentricThumbsFilter';
import ImageDialog from '@/ui/PhotoCentric/PhotoCentricImageDialog';

// Custom Components
import DataController from '@/lib/DataController';

// Context
import LoaderContext, { LoaderContextType } from '@/context/LoaderContext/LoaderContext';
import DialogContext, { DialogContextType } from '@/context/DialogContext/DialogContext';

// Services
import imgService from '@/services/imgService';

// Model
import modelImage from '@/models/images';

// Types
import { DCRecord } from '@/@types/lib/dataController';

export type ThumbFilters = {
  textFilter: string;
  selectedFilter: boolean;
};

type PhotoCentricThumbsProps = {
  baseRecordPath: string;
  recordId: number | null;
};

type IThumbsDictionary = {
  [key: number]: string;
};

const REDDOT_RADIUS = 5;

const PERCENTAGE = 5;

const initialFilterObject: ThumbFilters = {
  textFilter: '',
  selectedFilter: false,
};

const PhotoCentricThumbs = (props: PhotoCentricThumbsProps) => {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { baseRecordPath, recordId } = props;

  const [filterObject, setFilterObject] = useState<ThumbFilters>(initialFilterObject);
  const [records, setRecords] = useState<DCRecord[]>([]);
  const [thumbs, setThumbs] = useState<IThumbsDictionary>({});
  const [dontRefresh, setDontRefresh] = useState(false); // Flag for thumbs to not refetch when we change favoritization of one record
  const [listHeight, setListHeight] = useState(0);

  const imageListRef = useRef<HTMLUListElement>(null);

  const dc = new DataController(modelImage);

  // #region useMemo
  const filteredRecords: DCRecord[] = useMemo(() => {
    if (records.length) {
      const newFilteredRecords = records.filter((record: any) => {
        let textOk = true;
        let selectedOk = true;

        if (filterObject.textFilter.length > 0) {
          if (
            !String(record.path)
              .substring(String(record.path).lastIndexOf('/') + 1)
              .toString()
              .toLowerCase()
              .includes(filterObject.textFilter)
          ) {
            textOk = false;
          }
        }

        if (filterObject.selectedFilter === true && record.selected === false) {
          selectedOk = false;
        }

        return textOk && selectedOk;
      });
      return newFilteredRecords;
    }
    return [];
  }, [filterObject, records]);

  const thumbHeight = useMemo(
    () => (listHeight ? listHeight - 8 : 0), // Needs -8 cuz there is a vertical scrollbar in imglist otherwise
    [listHeight]
  );

  const thumbWidth = useMemo(() => thumbHeight * (12 / 9), [thumbHeight]);
  // #endregion

  // #region functions
  const loadThumbnails = () => {
    loaderContext.toggleLoading(true);
    const endsWithSlash = baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1;
    dc.GetData(`${baseRecordPath}${endsWithSlash ? '' : '/'}${recordId}/images`)
      .then((resp) => {
        if (resp.success) {
          const responseRecords = resp.data as DCRecord[];

          if (!Array.isArray(responseRecords)) return;

          const filteredData = responseRecords.filter((r) => {
            const { width, height, objpx, objpy } = r as {
              width: number;
              height: number;
              objpx: number;
              objpy: number;
            };
            if (!objpx || !objpy || !width || !height) {
              return false;
            }

            if (r.selected) {
              return true;
            }

            const lower = PERCENTAGE / 100;
            const upper = (100 - PERCENTAGE) / 100;
            if (objpx < width * lower || objpx > width * upper) {
              return false;
            }
            if (objpy < height * lower || objpy > height * upper) {
              return false;
            }

            return true;
          });

          const hasAtLeastOneRedPoint = responseRecords.find((x) => x.objpx !== null && x.objpy !== null) !== undefined;
          if (hasAtLeastOneRedPoint) {
            setRecords(filteredData as DCRecord[]);
          } else {
            setRecords(responseRecords);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleThumbnailClick = (rec: DCRecord) => {
    dialogContext.showDialog(ImageDialog, {
      record: rec,
      baseRecordPath,
      baseRecordId: recordId,
      onClose: () => {},
    });
  };

  const handleMarkFavorite = (evt: React.SyntheticEvent, imageId: number) => {
    evt.stopPropagation();

    const rec = records.find((x) => x.id === imageId);
    if (rec) {
      const isFav = !rec.selected;

      imgService.setObjImageFav(baseRecordPath, recordId as number, imageId, isFav).then((resp) => {
        if (resp.success) {
          if (records.length > 0) {
            // If records already exists, stop thumbnail refetch
            setDontRefresh(true);
          }
          setRecords((prev) => prev.map((img: DCRecord) => (img.id === imageId ? { ...img, selected: isFav } : img)));
        } else {
          // eslint-disable-next-line no-console
          console.error('Error while marking an image as favorite', resp);
        }
      });
    }
  };

  const buildDataUrl = (imageId: number) => {
    if (thumbs[imageId] !== undefined) {
      return `data:image/jpg;base64,${thumbs[imageId]}`;
    }
    return '';
  };
  // #endregion

  // #region useEffect
  useEffect(() => {
    if (recordId) {
      loadThumbnails();
    } else {
      setThumbs({});
      setRecords([]);
    }

    setDontRefresh(false);
  }, [recordId]);

  useEffect(() => {
    if (!dontRefresh && Array.isArray(records)) {
      records.forEach((x) => {
        if (recordId && x.id && typeof x.id === 'number') {
          imgService
            .getRecordImageThumb(baseRecordPath, recordId, x.id)
            .then((base64) => {
              setThumbs((prevState) => ({
                ...prevState,
                [x.id as number]: base64,
              }));
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error('Error while fetching thumbs:', err);
            });
        }
      });
    }
  }, [dontRefresh, records]);

  // kinda hacky but i don't care at this moment
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === imageListRef.current) {
          setListHeight(entry.contentRect.height);
        }
      });
    });

    if (imageListRef.current) {
      resizeObserver.observe(imageListRef.current);
    }

    return () => {
      if (imageListRef.current) {
        resizeObserver.unobserve(imageListRef.current);
      }
    };
  }, []);
  // #endregion

  return (
    <Paper elevation={0} sx={{ ml: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        m={0}
        p={0}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <PhotoCentricThumbsFilter
          filterObject={filterObject}
          onFilterChange={setFilterObject}
          recordsLength={records.length}
          filteredRecrodsLength={filteredRecords.length}
        />
      </Box>
      <ImageList
        ref={imageListRef}
        sx={{
          overflowX: 'auto',
          overflowY: 'auto',
          display: 'block',
          whiteSpace: 'nowrap',
          width: '100%',
          height: '100%',
          marginBlock: 0,
        }}
      >
        {filteredRecords.map((x) => {
          if (x.thumb_path !== undefined) {
            const px = x.objpx && x.width ? Math.round((x.objpx as number) * (thumbWidth / (x.width as number)) - REDDOT_RADIUS / 2) : null;
            const py =
              x.objpy && x.height ? Math.round((x.objpy as number) * (thumbHeight / (x.height as number)) - REDDOT_RADIUS / 2) : null;

            return (
              <ImageListItem
                key={x.id as string}
                onClick={() => handleThumbnailClick(x)}
                sx={{
                  width: thumbWidth,
                  height: thumbHeight,
                  display: 'inline-block',
                  mr: 1,
                }}
              >
                {thumbs[x.id as number] === undefined ? (
                  <Skeleton animation="wave" variant="rectangular" width={thumbWidth} height={thumbHeight} />
                ) : (
                  <img
                    id={x.id as string}
                    src={buildDataUrl(x.id as number)}
                    alt={x.serial as string}
                    width={thumbWidth}
                    height={thumbHeight}
                    style={{ objectFit: 'cover' }}
                  />
                )}
                {thumbs[x.id as number] !== undefined && px && py ? (
                  <Box
                    m={0}
                    sx={{
                      position: 'absolute',
                      zIndex: 10,
                      width: 0,
                      height: 0,
                      border: `${REDDOT_RADIUS}px solid red`,
                      borderRadius: `${REDDOT_RADIUS}px`,
                      left: `${px}px`,
                      top: `${py}px`,
                    }}
                  />
                ) : null}

                <ImageListItemBar
                  title={
                    x.path && String(x.path) && String(x.path).substring(String(x.path).lastIndexOf('/') + 1)
                      ? String(x.path).substring(String(x.path).lastIndexOf('/') + 1)
                      : null
                  }
                  sx={{
                    '.MuiImageListItemBar-title': {
                      fontSize: '9px',
                    },
                  }}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label={`star ${x.path}`}
                      onClick={(evt) => handleMarkFavorite(evt, x.id as number)}
                    >
                      {x.selected ? <StarIcon id={x.id as string} /> : <StarBorderIcon id={x.id as string} />}
                    </IconButton>
                  }
                  actionPosition="right"
                />
              </ImageListItem>
            );
          }
          return null;
        })}
      </ImageList>
    </Paper>
  );
};

export default PhotoCentricThumbs;
