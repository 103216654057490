/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI Icons
import CloudDownloadSharpIcon from '@mui/icons-material/CloudDownloadSharp';

// Custom Components
import TableHeaderButton from './TableHeaderButton';
import { exportTableToExcel } from '@/lib/exportExcel';
import { ITableHeaderButtonExportProps } from '@/@types/ui/Table';

const TableHeaderButtonExport = (props: ITableHeaderButtonExportProps) => {
  const { t } = useTranslation();
  const { dc, rows, records, tableName, ...rest } = props;

  const handleExport = () => {
    const filteredRecords = [];
    for (let i = 0; i < rows.length; i += 1) {
      const recordIndex = parseInt(rows[i].id, 10);
      filteredRecords.push(records[recordIndex]);
    }

    const fields = dc.getViewFields('exportExcel');
    const obj = {
      records: filteredRecords,
      fields,
      filename: tableName || (dc.filename ? dc.filename : ''),
      t,
    };
    exportTableToExcel(obj);
  };

  return (
    <TableHeaderButton
      startIcon={<CloudDownloadSharpIcon />}
      variant="outlined"
      {...rest}
      onClick={handleExport}
      sx={{ display: { xs: 'none', sm: 'flex' } }}
    >
      {t('buttons.export')}
    </TableHeaderButton>
  );
};

export default TableHeaderButtonExport;
