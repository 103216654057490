/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, ReactNode, useState } from 'react';
import ErrorContext from './ErrorContext';

export interface IErrorProvider {
  children: ReactNode;
}

const ErrorProvider: FunctionComponent<IErrorProvider> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [code, setCode] = useState<number | string>('');
  const [message, setMessage] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const showError = (
    code: number,
    message: string,
    description: string = ''
  ) => {
    setOpen(true);
    setCode(code);
    setMessage(message);
    setDescription(description);
  };
  const providerValue = {
    open,
    showError,
    code,
    message,
    description,
  };

  return (
    <ErrorContext.Provider value={providerValue}>
      {props.children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
