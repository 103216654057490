/* eslint-disable */
import React, { useContext, useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';
import { IDataController } from '@/lib/DataController';

// Material UI Core

// Material UI Icons

// Custom Components

import ModelTable, { IModelTableProps } from '@/ui/Table/ModelTable';
import DialogContext from '@/context/DialogContext/DialogContext';
import LoaderContext from '@/context/LoaderContext/LoaderContext';

import model from '@/models/tenant';

import dataController from '@/lib/DataController';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import { LoaderContextType } from '@/context/LoaderContext/LoaderContext';
import { IView, IViewCollection } from '@/@types/models/model';
import useApi from '@/lib/api/useApi';
import { GraphData } from './LoginLogsPage';



type ILoginLogsTableProps = {
  setGraphData: React.Dispatch<React.SetStateAction<GraphData | undefined>>
}

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them
let asd = 0;
const LoginLogsTable: FC<ILoginLogsTableProps> = (props) => {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new dataController(model);

  const expandedlistViews: IViewCollection = {
    default: {
      fields: ['id', 'name_ttoken', 'tag', 'host', 'tenant_state_id', 'last_login_user_id', 'last_login_user_name', 'last_login_on'],
    }
  }
  dc.listViews = expandedlistViews;


  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            //@ts-ignore
            // const recs = new Array(100).fill({});
            // //@ts-ignore
            // resp.data.forEach((r, i) => { recs[i] = {...r} });
            // asd = resp.data.length;
            //@ts-ignore
            // setRecords(recs);
            setRecords(resp.data);
          } else {
            setRecords([]);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const fetchData = (limit: number, offset: number) => {
    for (let i = 0; i < limit; ++i) {
      records[offset * limit + i] = records[i % asd];
    }
    console.log('here', limit, offset);
    // setRecords(Array.from(records));
    //@ts-ignore
    return new Promise((r) => r());
  };

  const showGraph = (evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId: number = parseInt(Object.keys(sel)[0]);
    const tenantId = records[selId].id
    //const path = 'system/tenants/' + String(tenantId) + '/login-stats'
    const path = 'system/tenants/' + String(tenantId) + '/login-stats'
    const response = useApi().get(path).then((resp: any) => {
      const data: GraphData = resp.data;
      const { success } = resp;
      return { success, data };
    });
    response.then((resp) => {
      if (resp.success) {
        if (resp.data == null) {
          alert("data is null")
        }
        else {
          //alert(resp.data.stat_current_month)
          props.setGraphData(resp.data)
        }
      }
    })
    // .finally(() => {
    //   loaderContext.toggleLoading(false);
    // });


    
  };

  return (
    <ModelTable
      title={t('admin:titles.login_logs')}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={true}
      allowColumnPicker={true}
      onRowAction={() => { }}
      allowRowAction={() => true}
      unselectRow={false}
      onRowClick={showGraph}
    >
    </ModelTable>
  );
}

export default LoginLogsTable;
