/* eslint-disable */
import { FunctionComponent, useContext, useEffect } from 'react';

import { PinchZoom } from 'ol/interaction';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlInteraction } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IPinchZoomInteraction } from '@/@types/components/Map/Interactions/OpenLayers';

const PinchZoomInteraction: FunctionComponent<IPinchZoomInteraction> = (
  props
) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    duration: undefined,
  };

  const events = {
    change: undefined,
    'change:active': undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(
    () => registerOlInteraction(context, PinchZoom, props, options, events),
    []
  );

  return null;
};

export default PinchZoomInteraction;
