/* eslint-disable */
import * as React from 'react';
import { useContext } from 'react';

// MUI Icons
import LightModeIcon from '@mui/icons-material/Brightness4';
import DarkModeIcon from '@mui/icons-material/BrightnessHigh';

// Custom Components
import ThemeContext from '@/context/ThemeContext/ThemeContext';
import HeaderIconButton from '@/ui/Header/HeaderIconButton';

// Types
import { ThemeContextType, Theme } from '@/context/ThemeContext/ThemeContext';

const ThemeSwitch = () => {
  const themeContext = useContext(ThemeContext) as ThemeContextType;

  const handleThemeChange = (): void => {
    const newTheme: Theme = themeContext.theme === 'light' ? 'dark' : 'light';

    themeContext.setTheme(newTheme);
  };

  return (
    <HeaderIconButton onClick={handleThemeChange} desc="theme">
      {themeContext.theme === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
    </HeaderIconButton>
  );
};

export default ThemeSwitch;
