/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { UserContextType } from '@/context/UserContext/UserContext';
import { Permission } from '@/@types/common';

export interface IFormTabPanel {
  index: number | string;
  value: number;
  needPermission?: Permission[];
  padding?: number | string;
  noBox?: boolean;
}

const FormTabPanel: FunctionComponent<
  React.PropsWithChildren<IFormTabPanel>
> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;

  const { children, value, index, needPermission, padding, noBox } = props;

  return needPermission &&
    !userContext.hasAnyPermission(needPermission) ? null : (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
    >
      {value === index ? (
        <Box p={noBox ? 0 : 3} style={{ padding }}>
          {children}
        </Box>
      ) : null}
    </Typography>
  );
};

export default FormTabPanel;
