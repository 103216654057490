import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Panel, PanelGroup } from 'react-resizable-panels';

// MUI
import { Grid, useMediaQuery, useTheme, Box } from '@mui/material';

// UI
import PhotoCentricTable from '@/ui/PhotoCentric/PhotoCentricTable';
import PhotoCentricThumbs from '@/ui/PhotoCentric/PhotoCentricThumbs';
import PhotoCentricMiniMap from '@/ui/PhotoCentric/PhotoCentricMiniMap';
import StyledPanelResizeHandle from '@/ui/PanelResize/StyledPanelResizeHandle';

// Context
import LoaderContext, { LoaderContextType } from '@/context/LoaderContext/LoaderContext';
import SnackbarContext, { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';

// Lib
import DataController, { IDataController } from '@/lib/DataController';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IModel } from '@/@types/models/model';
import { RowActionFn } from '@/@types/ui/Table';

type PhotoCentricPageParams = {
  titleToken: string;
  model: IModel;
  mapId: number;
  onCustomTableAction?: RowActionFn;
  viewName?: string;
  allowMinimapHideObjects?: boolean;
};

const PhotoCentricPage = (props: PhotoCentricPageParams) => {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const [selectedRecordId, setSelectedRecordId] = useState<number>(0);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const [alternateView, setAlternateView] = useState(false);

  const mapRef = useRef<HTMLDivElement>(null);

  const { titleToken, model, mapId, onCustomTableAction, viewName, allowMinimapHideObjects = true } = props;

  const [searchParams] = useSearchParams();

  const dc: IDataController = new DataController(model);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const rec_id = searchParams.get('rec_id');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const int_rec_id = rec_id ? parseInt(rec_id, 10) : undefined;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecords(resp.data);
          if (resp.data.length === 0) {
            snackbarContext.showNotification('messages.nodata', 'warning');
          }
          if (int_rec_id !== undefined) {
            const rec = resp.data.find((x) => x.id === int_rec_id);
            if (rec) {
              setSelectedRecordId((rec?.id as number) || 0);
            }
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRecordSelect = (id: number) => {
    if (id === selectedRecordId) {
      setSelectedRecordId(0);
    } else {
      setSelectedRecordId(id);
      scrollIntoMap();
    }
  };

  const scrollIntoMap = () => {
    if (!mdUp && mapRef.current) {
      mapRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (int_rec_id === undefined) {
      setSelectedRecordId(0);
    } else {
      const rec = records.find((x) => x.id === int_rec_id);
      if (rec) {
        setSelectedRecordId((rec?.id as number) || 0);
      }
    }
  }, [int_rec_id]);

  const handleMapResize = () => {
    setAlternateView((prev) => !prev);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.key === 'l' || event.key === 'L') && event.altKey) {
        handleMapResize();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return !(mdUp && alternateView) ? (
    <Grid columns={24} container height="100%">
      <Grid item xs={24} sx={{ maxHeight: '60%', minHeight: { xs: '0', md: '60%' } }}>
        <PhotoCentricTable
          records={records}
          onRowSelect={handleRecordSelect}
          onRowAction={onCustomTableAction}
          rowSelectedCustom={selectedRecordId}
          titleToken={titleToken}
          dc={dc}
          viewName={viewName}
        />
      </Grid>
      <Grid item sx={{ height: '40%' }} xs={24} md={9} lg={7} xl={5}>
        <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
          <PhotoCentricMiniMap
            onRecordSelect={handleRecordSelect}
            selectedRecordId={selectedRecordId}
            model={model}
            mapId={mapId}
            allowHideObjects={allowMinimapHideObjects}
            isResized={alternateView}
            onResize={handleMapResize}
          />
        </div>
      </Grid>
      <Grid item sx={{ height: { xs: '35%', md: '40%' } }} xs={24} md={15} lg={17} xl={19}>
        <PhotoCentricThumbs baseRecordPath={model.apiPath} recordId={selectedRecordId} />
      </Grid>
    </Grid>
  ) : (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={40} minSize={30}>
        <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
          <PhotoCentricMiniMap
            onRecordSelect={handleRecordSelect}
            selectedRecordId={selectedRecordId}
            model={model}
            mapId={mapId}
            allowHideObjects={allowMinimapHideObjects}
            isResized={alternateView}
            onResize={handleMapResize}
          />
        </div>
      </Panel>
      <StyledPanelResizeHandle variant="vertical" />
      <Panel defaultSize={60} minSize={40}>
        <PanelGroup direction="vertical">
          <Panel defaultSize={50} minSize={40}>
            <PhotoCentricTable
              records={records}
              onRowSelect={handleRecordSelect}
              onRowAction={onCustomTableAction}
              rowSelectedCustom={selectedRecordId}
              titleToken={titleToken}
              dc={dc}
              viewName={viewName}
            />
          </Panel>
          <StyledPanelResizeHandle variant="horizontal" />
          <Panel defaultSize={50} minSize={35}>
            <Box width="100%" height="100%">
              <PhotoCentricThumbs baseRecordPath={model.apiPath} recordId={selectedRecordId} />
            </Box>
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
};

export default PhotoCentricPage;
