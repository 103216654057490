// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: flex;
  align-items: stretch;
}

#root {
  display: flex;
  align-items: stretch;
  width: 100%;
}

#page {
  display: flex;
  width: 100%;
}

#content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
}

#content {
  width: 100%;
  height: calc(100% - 48px);
  padding: 0px;
  /*margin: 0 20px ;*/
  overflow-y: auto
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 100px; */
  /* Height of the footer */
}

.swal2-container {
  z-index: 1460 !important;
}

body.swal2-height-auto{
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/layout.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB;AACF;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["html,\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\nbody {\n  display: flex;\n  align-items: stretch;\n}\n\n#root {\n  display: flex;\n  align-items: stretch;\n  width: 100%;\n}\n\n#page {\n  display: flex;\n  width: 100%;\n}\n\n#content-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  width: 100%;\n  overflow: hidden;\n}\n\n#content {\n  width: 100%;\n  height: calc(100% - 48px);\n  padding: 0px;\n  /*margin: 0 20px ;*/\n  overflow-y: auto\n}\n\n#footer {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  /* height: 100px; */\n  /* Height of the footer */\n}\n\n.swal2-container {\n  z-index: 1460 !important;\n}\n\nbody.swal2-height-auto{\n  height: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
