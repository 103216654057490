import React, { FunctionComponent, useState, useMemo } from 'react';

// Custom components
import DataContext from '@/context/DataContext/DataContext';
import { DCRecord } from '@/@types/lib/dataController';

const DataProvider: FunctionComponent<any> = (props) => {
  const { children } = props;
  const [searchData, setSearchData] = useState<DCRecord[] | null>([]);

  const providerValue = useMemo(
    () => ({
      searchData,
      setSearchData
    }),
    [searchData, setSearchData]
  );

  return (
    <DataContext.Provider value={providerValue}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
