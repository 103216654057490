/* eslint-disable */
import { FunctionComponent, useContext, useEffect } from 'react';
import clsx from 'clsx';

//MUI
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

//OpenLayers
import { ZoomSlider } from 'ol/control';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlControl } from '@/lib/olHelpers';
import makeMUIControlStyle from '../makeMUIControlStyle';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IZoomSliderControl } from '@/@types/components/Map/Controls/OpenLayers';

const ZoomSliderControl: FunctionComponent<IZoomSliderControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const theme = useTheme();
  const classes = makeMUIControlStyle(theme);

  const options = {
    className: undefined,
    duration: undefined,
    render: undefined,
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(() => {
    const overridenProps = {
      ...props,
      className: clsx(props.className, 'ol-zoomslider'),
    };
    registerOlControl(context, ZoomSlider, overridenProps, options, events);
  }, [props.className, props.duration, props.render, classes.control]);

  return null;
};

const StyledZoomSliderControl = styled(ZoomSliderControl)(({ theme }) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control,
    ...styles.sidebarSticky,
  };
});

export default StyledZoomSliderControl;
