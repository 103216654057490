/* eslint-disable */
import React from 'react';
import LayersIcon from '@mui/icons-material/Layers';

//Types
import { SvgIconProps } from '@mui/material';

function MapLayerIcon(props: SvgIconProps) {
  return <LayersIcon {...props} />;
}

export default MapLayerIcon;
