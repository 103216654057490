import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import LaunchIcon from '@mui/icons-material/Launch';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

// MUI Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Custom
import useDocuments from '@/components/useDocuments';
import CustomBadge from './CustomBadge';

// Types
interface AttachDocumentGFIActionProps {
  gid: string;
  handleCloseMenu?: () => void;
  stats: { loading: boolean, count: number},
  onDialogClose: () => void;
}
const AttachDocumentButtonListItem = (props: AttachDocumentGFIActionProps) => {
  const { t } = useTranslation();

  const { gid, handleCloseMenu, stats, onDialogClose } = props;

  const { showDocumentsDialog } = useDocuments();

  const handleAttachDocument = () => {
    if (gid) {
      showDocumentsDialog(gid, onDialogClose);
    }
  };

  const handleClick = () => {
    handleAttachDocument();
    if (handleCloseMenu) {
      handleCloseMenu();
    }
  };

  return (
    <MenuItem onClick={handleClick} sx={{ minHeight: '36px' }}>
      <ListItemIcon>
        <AttachFileIcon color="primary" fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('buttons.show_attached_documents')}</ListItemText>
      <CustomBadge loading={stats.loading} badgeContent={stats.count}/>
      <LaunchIcon sx={{ ml: 1 }} color="disabled" fontSize="small" />
    </MenuItem>
  );
};

export default AttachDocumentButtonListItem;
