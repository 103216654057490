import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { OpenInNew } from '@mui/icons-material';

// Types
import { TCustomGFIDKPProps } from '@/@types/views/map';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import DataController from '@/lib/DataController';
import modelUkopani from '@/models/grobno_mjesto_ukopani';
import UserContext from '@/context/UserContext/UserContext';
import Permissions from '@/lib/permissions';
import DCTable from '@/ui/Table/DCTable';

const UkopaniButton = (props: TCustomGFIDKPProps) => {
  const identifier = 'ukopani';

  const userContext = useContext(UserContext);

  const canEdit = userContext?.hasAnyPermission([Permissions.GrobljaEdit]);

  const { t } = useTranslation();

  const { record, onToggle } = props;

  const recordId = useMemo(
    () =>
      record ? (record.id ? record.id : record.fid ? record.fid : null) : null,
    [record]
  );

  const dcUkopani = useMemo(
    () =>
      new DataController(
        modelUkopani,
        `groblja/grobna-mjesta/${recordId}/ukopani`
      ),
    [recordId]
  );

  const ukopaniTable = (
    <DCTable
      allowExport
      title="titles.ukopani"
      size="small"
      tableName="ukopani"
      viewName="default"
      dc={dcUkopani}
      allowSelection="one"
      allowAdd={canEdit}
      allowEdit={canEdit}
      allowDelete={canEdit}
      allowRowAction={() => false}
      onRowAction={() => {}}
      disableControls={false}
    />
  );

  const handleToggleUkopani = () => {
    onToggle(ukopaniTable, identifier);
  };

  return (
    <MapCardActionButton
      startIcon={<AssignmentIndIcon />}
      onClick={handleToggleUkopani}
      endIcon={<OpenInNew />}
    >
      {t('titles.ukopani')}
    </MapCardActionButton>
  );
};

export default UkopaniButton;
