/* eslint-disable */
import React, { useContext, useEffect, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI
import Toolbar from '@mui/material/Toolbar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';

// Material-UI Icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DataArrayIcon from '@mui/icons-material/DataArray';
import { userService } from '@/services/userService';

// Custom Icons
import GFIModelIcon from '@/icons/GFIModelIcon';

// Custom components
import FormContent from '@/components/FormContent';
import { GridContainer, GridItem } from '@/ui/Grid';
import {
  CardActionButtonSave,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';
import {
  FormAvatar,
  FormHeading,
  FormHeadingID,
  FormFillContent,
  FormTab,
  FormTabPanel,
  FormTabs,
} from '@/ui/Form';
import SnackbarContext, {
  SnackbarContextType,
} from '@/context/SnackbarContext/SnackbarContext';
import DialogContext, {
  DialogContextType,
} from '@/context/DialogContext/DialogContext';
import dataController from '@/lib/DataController';
import GFIModelFields from '@/models/gfi_model_fields';
import useFormController from '@/components/useFormController';
import { FormContentMode } from '@/components/FormContent';
import { DCRecord } from '@/@types/lib/dataController';
import ModelTable from '@/ui/Table/ModelTable';
import ModelAdminFieldsDialog from '@/ui/AdminDialogs/ModelAdminFieldsDialog';

interface IModelFormProps {
  dc: dataController;
  recordId: number;
  mode: FormContentMode;
  form: string;
}

const ModelForm = (props: IModelFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const dialogContext = useContext(DialogContext) as DialogContextType;

  const [currentTab, setCurrentTab] = useState(0);
  const [modelFieldsRecords, setModelFieldsRecords] =
    useState<Array<DCRecord>>();

  const { dc, recordId, mode, form } = props;
  const {
    record,
    validation,
    fields,
    onFieldChange,
    doUpdate,
    doDelete,
    doRefresh,
    doClose,
    pickerDataControllers,
    dataChanged,
  } = useFormController({
    mode,
    recordId,
    form,
    dc,
    onClose: close,
    pickerApiPathParams: {
      model_id: recordId,
    },
  });

  const dcModelFields = new dataController(
    GFIModelFields,
    `system/models/${String(recordId)}/fields`
  );

  const handleClickBack = () => {
    if (doClose) {
      doClose()
        .then((resp) => {
          if (resp.success) {
            navigate('/admin/models');
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, 'error');
        });
    }
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const handleUpdate = (evt: any) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_model_success';
            snackbarContext.showNotification(msg, 'success');
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            // do nothing
          } else {
            const msg = 'error.update_model';
            snackbarContext.showNotification(msg, 'error');
          }
        })
        .finally(() => {
          doRefresh();
        });
    }
  };

  const handleDelete = (evt: any) => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.delete_model_success';
            snackbarContext.showNotification(msg, 'success');
            navigate('/admin/models');
          }
        })
        .catch((result) => {
          const msg = 'error.delete_model';
          snackbarContext.showNotification(msg, 'error');
        });
    }
  };

  const handleRowActionField = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    if (action === 'edit') {
      dialogContext.showDialog(ModelAdminFieldsDialog, {
        mode: 'update',
        form: 'update',
        dataId: id,
        onClose: () => {
          refreshModelFieldsRecords();
        },
        refreshLayers: () => {},
        initialRecord: record || undefined,
        modelId: recordId,
      });
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.activate(Number(record.id)).then((result) => {
          if (result) {
            const msg = 'messages.activate_model_success';
            snackbarContext.showNotification(msg, 'success');
            doRefresh();
          } else {
            const msg = 'error.activate_model';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.activate_model';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const handleDeactivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.deactivate(Number(record.id)).then((result) => {
          if (result) {
            const msg = 'messages.deactivate_model_success';
            snackbarContext.showNotification(msg, 'success');
            // close({ dataChanged: true, action: 'update' });
            navigate(-1);
          } else {
            const msg = 'error.deactiavate_model';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.deactivate_model';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const openAddDialogField = () => {
    dialogContext.showDialog(ModelAdminFieldsDialog, {
      mode: 'insert',
      form: 'insert',
      dataId: undefined,
      onClose: () => {
        refreshModelFieldsRecords();
      },
      refreshLayers: () => {},
      initialRecord: undefined,
      modelId: recordId,
    });
  };

  const commonProps = {
    record,
    validation,
    onFieldChange,
    fields,
    mode,
    pickerDataControllers,
  };

  const title = record.code ? (record.code as string) : undefined;

  const showSaveButton = currentTab === 0;
  const disableSaveButton = !dataChanged;

  function refreshModelFieldsRecords() {
    dcModelFields
      .GetData()
      .then((resp) => {
        if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
          setModelFieldsRecords(resp.data);
        }
      })
      .catch((resp) => {
        console.log(resp);
      })
      .finally(() => {});
  }

  useEffect(() => {
    refreshModelFieldsRecords();
  }, []);

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters>
          <FormAvatar ariaLabel="model" icon={<GFIModelIcon />} />
          <FormHeading
            header={title}
            subheaderContent={<FormHeadingID id={recordId} />}
            dataChanged={dataChanged}
          />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs
          value={currentTab}
          onChange={handleTabChange}
          identifier={dc.getSource()}
        >
          <FormTab
            id="basic"
            label={t('admin:common.tabs.basic')}
            icon={<BusinessCenterIcon />}
            aria-label="basic"
            value={0}
          />
          <FormTab
            id="licences"
            label={t('admin:model.tabs.fields')}
            icon={<DataArrayIcon />}
            aria-label="fields"
            value={1}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.basic') as string}
                  fieldNames={[
                    'id',
                    'code',
                    'opis',
                    'title_token',
                    'title_field_id',
                    'icon',
                    'title_type',
                  ]}
                  {...commonProps}
                  columns={3}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <ModelTable
            title={t('admin:model.sections.fields') as string}
            key="tblid-model-admin-fields"
            dc={dcModelFields}
            records={modelFieldsRecords}
            allowSelection="one"
            allowRowAction={() => true}
            onRowAction={handleRowActionField}
            viewName="model_admin"
            allowColumnPicker
            allowAdd
            allowFilter
            handleAdd={openAddDialogField}
            addLabel={t('buttons.new_n') as string}
          />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <FormFillContent />
        {showSaveButton ? (
          <CardActionButtonSave
            onClick={handleUpdate}
            variant="contained"
            disabled={disableSaveButton}
          />
        ) : null}
      </CardActions>
    </Card>
  );
};

export default ModelForm;
