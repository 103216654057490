import React, {
  useContext,
  useState,
  useEffect,
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';

// Custom Components
import useDocuments from '@/components/useDocuments';

import ModelTable from '@/ui/Table/ModelTable';

import { downloadFile } from '@/lib/downloadFile';
import DataController, { IDataController } from '@/lib/DataController';
import LoaderContext, {
  LoaderContextType,
} from '@/context/LoaderContext/LoaderContext';
import SnackbarContext, {
  SnackbarContextType,
} from '@/context/SnackbarContext/SnackbarContext';

import TableButtonDocuments from "@/ui/Table/ActionButtons/TableButtonDocuments";


// Types
import { IModel } from '@/@types/models/model';
import { ClosingDetails } from '@/@types/components/formController';

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

interface IEnaDataCentricPrimaryTableProps {
  onRowSelect: (id: number, record: DCRecord) => void;
  onLoad?: (records: Array<DCRecord>) => void;
  lokacijaId?: number;
  filterByLocation?: boolean;

  model: IModel;
  titleToken: string;
  locationField: string;

  allowColumnPicker?: boolean;

  allowLegend?: boolean;
  onLegendClick?: () => void;
}

const EnaDataCentricPrimaryTable: FunctionComponent<
  React.PropsWithChildren<IEnaDataCentricPrimaryTableProps>
> = (props) => {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const { checkIfHasGid, showDocumentsDialog } = useDocuments();
  const { t } = useTranslation();
  const {
    onRowSelect,
    onLoad,
    lokacijaId,
    filterByLocation,
    allowLegend,
    allowColumnPicker = true,
    onLegendClick,
  } = props;
  const { model, titleToken, locationField } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<DCRecord[]>([]);

  const [zapisnikDl, setZapisnikDl] = useState<{ id: number }>({ id: 0 });
  const [rowSelected, setRowSelected] = useState<DCRecord | null>(null);

  const dc: IDataController = new DataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    const { id } = zapisnikDl;
    if (id === 0) {
      // do error logging
      return;
    }
    const record = records.find((r) => r.id === id);
    if (!record) {
      // do error logging
      return;
    }
    const recordName = (record.obveznik as string) || '';
    const encodedRecordName = record.obveznik
      ? encodeURIComponent(record.obveznik as string)
      : '';
      
    const path = `${model.apiPath}/${id}/zapisnik?ko_name=${encodedRecordName}`;

    const paddedId = String(id).slice(-6);
    downloadFile(path, undefined, `${recordName}-${paddedId}.pdf`);
  }, [zapisnikDl]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          const rs = resp.data.map((r) => ({
            ...r,
            ...{ zapisnik_dl: r.zap === '1' },
          }));
          setRecords(rs);
          setFilteredRecords(rs);
          if (resp.data.length === 0) {
            snackbarContext.showNotification(
              'messages.nodata',
              'warning'
              // true
            );
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowSelect = (
    evt: React.SyntheticEvent<Element, Event>,
    selection: { [key: string]: DCRecord }
  ) => {
    const record = selection[Object.keys(selection)[0]];

    if (record !== undefined) {
      const [rLok, rId] =
        rowSelected === null
          ? [-1, -1]
          : [rowSelected[locationField], rowSelected.id];
      if (record.id === rId && record[locationField] === rLok) {
        setRowSelected(null);
        onRowSelect(Number(record[locationField]), record);
      } else if (record.id !== rId && record[locationField] === rLok) {
        setRowSelected(record);
      } else {
        setRowSelected(record);
        onRowSelect(Number(record[locationField]), record);
      }
    } else {
      setRowSelected(null);
    }
  };

  const downloadZapisnik = (koId: number) => {
    setZapisnikDl({ id: koId });
  };

  useEffect(() => {
    if (filterByLocation) {
      if (lokacijaId) {
        const fRecords: DCRecord[] = records.filter(
          (r) => r[locationField] === lokacijaId
        );
        setFilteredRecords(fRecords);
        if (fRecords.length > 0) {
          setRowSelected(fRecords[0]);
          if (onLoad) {
            onLoad(fRecords);
          }
        } else {
          setRowSelected(null);
        }
      } else {
        setFilteredRecords(records);
        setRowSelected(null);
      }
    } else if (lokacijaId) {
      const fRecords: DCRecord[] = records.filter((r) => r[locationField]);
      if (fRecords.length > 0) {
        setRowSelected(fRecords[0]);
      }
    }
  }, [records, lokacijaId]);

  const handleRowAction = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    switch (action) {
      case 'show_photos':
        if (id) {
          // eslint-disable-next-line no-restricted-globals
          const url = `${location.origin}/ena/photodoc/?rec_id=${record?.glavniobj_id}`;
          window.open(url, '_blank');
        }
        break;
      case 'download_zapisnik':
        if (id) {
          downloadZapisnik(id);
        }
        break;
      default:
        break;
    }
  };

  const handleDocuments = ( evt: React.SyntheticEvent, sel: { [key: string]: DCRecord }) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];
    const gid = record.gid as string;

    if (gid) {
      showDocumentsDialog(gid, handleDialogClose);
    }
  }

  const handleDialogClose = (result: ClosingDetails) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const hasGid = checkIfHasGid(records);

  return (
    <ModelTable
      title={t(titleToken)}
      records={filteredRecords}
      dc={dc}
      allowSelection="one"
      allowFilter
      allowExport
      allowAdd={false}
      onRowAction={handleRowAction}
      onRowClick={handleRowSelect}
      allowRowAction={(action, record, id) => {
        if (action === 'download_zapisnik') {
          return id > 0 && !!record?.active; 
        }
        return true;
      }}
      rowSelectedCustom={
        rowSelected !== null && rowSelected.id ? (rowSelected.id as number) : -1
      }
      allowColumnPicker={allowColumnPicker}
      allowLegend={allowLegend !== undefined ? allowLegend : false}
      onLegendClick={onLegendClick || undefined}
    >
      { hasGid ?
        <TableButtonDocuments onClick={handleDocuments} />
      : null }
    </ModelTable>
  );
};

export default EnaDataCentricPrimaryTable;
