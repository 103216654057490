import { IModel } from '@/@types/models/model';
import buttonFields from './helpers/buttonFields';
import coreFields from './helpers/coreFields';
import commonFields from './helpers/commonFields';
import { addressFields } from './helpers/addressFields';
import { alarmFields } from './helpers/alarmFields';
import { userFields } from './helpers/userFields';

const Test: IModel = {
  title: 'Test Controls',
  exportFileName: 'test_page',
  apiPath: 'test/',
  forms: {
    default: {
      fields: [
        'desc',
        'date',
        'date_time',
        'wholenum',
        'float',
        'amount',
        'illegal',
        'features',
        'regions',
        'colors',
        'kingdom',
        'names',
        'email',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'desc',
        'date',
        'date_time',
        'wholenum',
        'float',
        'amount',
        'illegal',
        'kingdom',
        'features',
        'regions',
        'species',
        'colors',
        'names',
        'email',
        'related_animal',
        'edit',
      ],
    },
  },
  fields: [
    {
      title: 'id',
      source: 'id',
      ttoken: '',
      type: 'numeric',
      idattr: true,
      readonly: true,
    },
    {
      title: 'Related Animal Id',
      source: 'related_animal_id',
      ttoken: '',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Text',
      source: 'desc',
      ttoken: '',
      type: 'text',
      filter: true,
    },
    {
      title: 'Date',
      source: 'date',
      ttoken: 'test_page.date',
      type: 'date',
      filter: true,
      // readonly: true
      validation: {
        required: true,
      },
    },
    {
      title: 'Date and Time',
      source: 'date_time',
      ttoken: 'test_page.date_time',
      type: 'datetime',
      filter: true,
      // readonly: true
      validation: {
        required: true,
      },
    },
    {
      title: 'Whole number',
      source: 'wholenum',
      ttoken: '',
      type: 'wholenum',
      validation: {
        required: true,
        minValue: 10,
        maxValue: 99,
      },
      filter: true,
    },
    {
      title: 'float',
      source: 'float',
      ttoken: '',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Amount',
      source: 'amount',
      ttoken: '',
      type: 'currency',
      currency: 'Kn',
      filter: true,
    },
    {
      title: 'Bool',
      source: 'illegal',
      ttoken: '',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
      filter: true,
    },
    {
      title: 'Radio',
      source: 'features',
      ttoken: '',
      type: 'radio',
      items: {
        labels: [
          'test_page.features.1',
          'test_page.features.2',
          'test_page.features.3',
          'test_page.features.4',
          'test_page.features.5',
          'test_page.features.6',
          'test_page.features.7',
        ],
        values: [1, 2, 3, 4, 5, 6, 7],
      },
      row: true,
      filter: true,
    },
    {
      title: 'Checkbox',
      source: 'regions',
      ttoken: '',
      type: 'checkbox',
      items: {
        labels: [
          'test_page.regions.1',
          'test_page.regions.2',
          'test_page.regions.3',
          'test_page.regions.4',
          'test_page.regions.5',
          'test_page.regions.6',
          'test_page.regions.7',
        ],
        values: [1, 2, 3, 4, 5, 6, 7],
      },
      showToggleAll: true,
      row: true,
      filter: true,
    },
    {
      title: 'Icon Status',
      source: 'species',
      ttoken: '',
      type: 'iconStatus',
      items: {
        labels: [
          'test_page.species.1',
          'test_page.species.2',
          'test_page.species.3',
          'test_page.species.4',
          'test_page.species.5',
          'test_page.species.6',
          'test_page.species.7',
        ],
        values: [1, 2, 3, 4, 5, 6, 7],
        icons: [
          'drafts',
          'send',
          'thumb_up',
          'commute',
          'home',
          'remove_circle',
          'warning',
        ],
      },
      filter: true,
    },
    {
      title: 'M.Picker Model',
      source: 'colors',
      ttoken: '',
      type: 'picker',
      subModel: {
        apiPath: 'colors',
        format: '{label}',
      },
      multi: true,
      filter: true,
    },
    {
      title: 'Picker items',
      source: 'kingdom',
      ttoken: '',
      type: 'picker',
      items: {
        labels: [
          'test_page.kingdoms.animal',
          'test_page.kingdoms.plant',
          'test_page.kingdoms.fungi',
          'test_page.kingdoms.protista',
          'test_page.kingdoms.monera',
        ],
        values: [1, 2, 3, 4, 5],
      },
      validation: {
        required: true,
      },
      filter: true,
    },
    {
      title: 'Array',
      source: 'names',
      ttoken: '',
      type: 'array',
      filter: true,
    },
    {
      title: 'Link',
      source: 'related_animal',
      ttoken: '',
      type: 'link',
      link: {
        recordIdField: 'related_animal_id',
        recordType: 'related_animal',
      },
      filter: true,
    },
    {
      title: 'Mail',
      source: 'email',
      ttoken: '',
      type: 'mail',
      validation: {
        required: false,
        maxLength: 250,
        regex: 'email',
      },
      filter: true,
    },
    buttonFields.edit,
    buttonFields.print,
    buttonFields.remove,
    buttonFields.view,
    coreFields.id,
    coreFields.active,
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
    commonFields.email,
    commonFields.mobile,
    commonFields.oib,
    commonFields.tel,
    commonFields.vat,
    addressFields.adr_ulica,
    addressFields.adr_pbr,
    addressFields.adr_grad,
    addressFields.adr_drzava,
    userFields.first_name,
    userFields.last_login_on,
    userFields.last_name,
    userFields.password,
    userFields.password_confirm,
    userFields.username,
    alarmFields.alarm_active,
    alarmFields.alarm_remark,
    alarmFields.alarm_ts,
  ],
};

export default Test;
