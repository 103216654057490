/* eslint-disable */
import React from 'react';

//MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Close, Check } from '@mui/icons-material';

export interface IConfirmDialogOptions {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export type ConfirmResult = {
  success: boolean;
};
interface IConfirmDialogProps extends IConfirmDialogOptions {
  open: boolean;
  onConfim: () => void;
  onCancel: () => void;
}

const ConfirmDialog = (props: IConfirmDialogProps) => {
  const {
    open,
    title,
    text,
    cancelButtonText,
    confirmButtonText,
    onConfim,
    onCancel,
  } = props;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" startIcon={<Close />} onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button variant="contained" startIcon={<Check />} onClick={onConfim}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
