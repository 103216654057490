/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// MUI
import Button from '@mui/material/Button';

// Custom Components
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

// Types
import { ICardActionButton } from '@/@types/ui/Card';

const CardActionButton: FunctionComponent<ICardActionButton> = (
  props: React.PropsWithChildren<ICardActionButton>
) => {
  const userContext = useContext(UserContext) as UserContextType;
  const {
    needPermission,
    startIcon,
    onClick,
    variant,
    ariaLabel,
    ariaControls,
    disabled,
  } = props;

  if (
    needPermission === undefined ||
    (needPermission && userContext.hasAnyPermission(needPermission))
  ) {
    return (
      <Button
        color="primary"
        size="medium"
        startIcon={startIcon}
        onClick={onClick}
        variant={variant}
        aria-label={ariaLabel}
        aria-controls={ariaControls}
        disabled={disabled}
        sx={{
          //color: 'primary.dark',
          ml: 10,
          mr: 0,
        }}
      >
        {props.children}
      </Button>
    );
  }
  return null;
};

export default CardActionButton;
