/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

// Custom components
import App from '@/App';
import Splash from '@/Splash';
import useApi from '@/lib/api/useApi';
import TenantContext from '@/context/TenantContext/TenantContext';

// Styles
import '@/styles/layout.css';
import '@/styles/map.css';
import '@/styles/splash-screen.css';

// i18n
import i18n from '@/i18n';

// Types
import { ITenantStatePreParsed } from '@/@types/common';
import { DCRecord } from '@/@types/lib/dataController';

const AppLoader = () => {
  const [loaded, setLoaded] = useState(false);
  const api = useApi();
  const tenantContext = useContext(TenantContext);

  const isIdentified =
    tenantContext && tenantContext?.id && tenantContext?.id > 0;

  // call identify and set tenant context
  const identify = async () => {

    let detectedLanguage = localStorage.getItem('i18nextLng');
    api.post('core/auth/identify', {}).then((response) => {
      if (response.success && response.data) {  
        detectedLanguage = detectedLanguage || (response.data as DCRecord).default_language as string || 'hr';
        i18n.init({ lng: detectedLanguage });
        tenantContext?.setTenantData(response.data as ITenantStatePreParsed);
      } else {
        // try twice, should work without the redirect bug
        api.post('core/auth/identify', {}).then((response) => {
          if (response.success && response.data) {
            detectedLanguage = detectedLanguage || (response.data as DCRecord).default_language as string || 'hr';
            i18n.init({ lng: detectedLanguage });
            tenantContext?.setTenantData(response.data as ITenantStatePreParsed);
          } else {
            if (
              process.env.REACT_APP_NO_TENANT_REDIRECT_PATH !== undefined &&
              window.location.href !== process.env.REACT_APP_NO_TENANT_REDIRECT_PATH
            ) {
              window.location.href = process.env.REACT_APP_NO_TENANT_REDIRECT_PATH;
            }
          }
        });
      }
    });
  };

  if (!isIdentified) {
    identify();
  }

  return (tenantContext && tenantContext?.loaded) === true ? (
    <App />
  ) : (
    <Splash />
  );
};
export default AppLoader;
