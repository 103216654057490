import { ISubmodel } from "@/@types/models/submodel";

const checkbox: ISubmodel = {
  fields: [
    { source: "id",  type: "text", readonly: true, idattr: true },
    { source: "label", type: "text", readonly: true },
    { source: "value", type: "text", readonly: true }
  ]
}

export default checkbox;