/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next';

// Types
import { ITenantCommonProps, ITenantConfigMapProps } from '@/@types/views/Tenants/tenants';

// OL
import { Collection } from 'ol';
import BaseLayer from 'ol/layer/Base';
import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';

// MUI
import { Box, Button, ListItem, Typography } from '@mui/material';

// Custom components
import Map from '@/components/Map/Map';
import mapService from '@/services/mapService';
import { ViewOptionsType } from '@/@types/common';
import { Layers, VectorLayer } from '@/components/Map/Layers';
import { helpers } from '@/lib/helpers';
import { Controls, ZoomToExtentControl } from '@/components/Map/Controls';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import { blueStroke, greenStrokeDashed, purpleDot, redStrokeDashed, selectedStyle } from '@/components/Map/mapStyles';
import { relative } from 'path';
import proj4 from 'proj4';
import { register as OlRegister } from 'ol/proj/proj4';

const MAP_ID = 90; 

const DEFAULT_VIEW_CENTER = [1731757, 5581737] as [number,number]; // Circa center point of continental Croatia
const DEFAULT_DEFAULT_EXTENT = [1688674,5501166,1688774,5501266] // Covers whole of Croatia
const DEFAULT_MAX_EXTENT = undefined;
const DEFAULT_ZOOM = 13; 

 // define proj
 proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);



const TenantConfigMap = (props: ITenantConfigMapProps)=> {

    const { record, tenantGeom, maxExtentGeom, centerGeom, defaultExtentGeom} = props;

    const { t } = useTranslation();

    const zoomToDefaultExtentElementRef = useRef<HTMLButtonElement>(null);

    const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
        center: helpers.isArrayOfNumbersLength2(record.map_initial_view_center) 
          ? record.map_initial_view_center 
          : DEFAULT_VIEW_CENTER,
        zoom: typeof record.map_initial_view_zoom === "number"
          ? record.map_initial_view_zoom
          : DEFAULT_ZOOM,
        extent: helpers.isArrayOfNumbers(record.map_max_extent) 
          ? [record.map_max_extent[0]-5000,record.map_max_extent[1]-5000,record.map_max_extent[2]+5000,record.map_max_extent[3]+5000]
          : DEFAULT_MAX_EXTENT,
        // projection: wgs84PM,
        minZoom: 3,
        maxZoom: 20,
        constrainResolution: true,
      });

    const [defaultExtent, setDefaultExtent] = useState<number[]>(
        helpers.isArrayOfNumbers(record.map_max_extent) ? [record.map_max_extent[0]-5000,record.map_max_extent[1]-5000,record.map_max_extent[2]+5000,record.map_max_extent[3]+5000]
        : DEFAULT_DEFAULT_EXTENT
    );

    useEffect(()=>{
        if (!record) return;

        setViewOptions({
            center: helpers.isArrayOfNumbersLength2(record.map_initial_view_center) 
              ? record.map_initial_view_center 
              : DEFAULT_VIEW_CENTER,
            zoom: typeof record.map_initial_view_zoom === "number"
              ? record.map_initial_view_zoom
              : DEFAULT_ZOOM,
            extent: helpers.isArrayOfNumbers(record.map_max_extent) 
              ? [record.map_max_extent[0]-5000,record.map_max_extent[1]-5000,record.map_max_extent[2]+5000,record.map_max_extent[3]+5000]
              : DEFAULT_MAX_EXTENT,
            // projection: wgs84PM,
            minZoom: 3,
            maxZoom: 20,
            constrainResolution: true,
        });

        setDefaultExtent(
            helpers.isArrayOfNumbers(record.map_max_extent) ? [record.map_max_extent[0]-5000,record.map_max_extent[1]-5000,record.map_max_extent[2]+5000,record.map_max_extent[3]+5000]
            : DEFAULT_DEFAULT_EXTENT
        );


    },[record])

    const [tenantGeomSource, setTenantGeomSource] = useState<VectorSource<Geometry> | undefined>(new VectorSource({ features: tenantGeom ? [tenantGeom] : [] }));

    const [viewCenterGeomSource, setViewCenterGeomSource] = useState<VectorSource<Geometry> | undefined>(new VectorSource({ features: centerGeom ? [centerGeom] : [] }));
    const [maxExtentGeomSource, setMaxExtentGeomSource] = useState<VectorSource<Geometry> | undefined>(new VectorSource({ features: maxExtentGeom ? [maxExtentGeom] : [] }));
    const [defaultExtentGeomSource, setDefaulExtentGeomSource] = useState<VectorSource<Geometry> | undefined>(new VectorSource({ features: defaultExtentGeom ? [defaultExtentGeom] : [] }));

    useEffect(() => {
        setTenantGeomSource(new VectorSource({ features: tenantGeom ? [tenantGeom] : [] }));
    },[tenantGeom])

    useEffect(() => {
        setViewCenterGeomSource(new VectorSource({ features: centerGeom ? [centerGeom] : [] }));
    },[centerGeom])

    useEffect(() => {
        setMaxExtentGeomSource(new VectorSource({ features: maxExtentGeom? [maxExtentGeom] : [] }));
    },[maxExtentGeom])

    useEffect(() => {
        setDefaulExtentGeomSource(new VectorSource({ features: defaultExtentGeom ? [defaultExtentGeom] : [] }));
    },[defaultExtentGeom])

    return (
        <Box height={350} width={350} position={"relative"}>
            <Box position={"absolute"} top={5} right={5} zIndex={10}>
                <button ref={zoomToDefaultExtentElementRef} style={{
                    padding: 0,
                    border: "none",
                    background: "none",
                    transform: "scale(0.7)"
                }}/>
            </Box>
            <Map
                height='350px'
                view={viewOptions}
                id='tenant-config-map'
                initialized={true}
                moveTolerance={10}
                maxTilesLoading={10}
            >
                <Controls>
                    <GeoBaseLayerSwitcher invisible/>
                    {zoomToDefaultExtentElementRef?.current ? (
                        <ZoomToExtentControl
                        id="zoom-extent-default"
                        target={zoomToDefaultExtentElementRef.current}
                        extent={defaultExtent}
                        tipLabel={`${t('map:controls.zoom_to_extent')}`}
                        className="ol-sidebar-control"
                        />
                    ) : <div></div>}
                </Controls>
                <Layers>
                    <VectorLayer
                        id="tenantGeom"
                        source={tenantGeomSource}
                        style={blueStroke}
                        zIndex={100}
                    />
                    <VectorLayer
                        id="maxExtent"
                        source={defaultExtentGeomSource}
                        style={redStrokeDashed}
                        zIndex={101}
                    />
                    <VectorLayer
                        id="defaultExtent"
                        source={maxExtentGeomSource}
                        style={greenStrokeDashed}
                        zIndex={102}
                    />
                    <VectorLayer
                        id="defaultCenter"
                        source={viewCenterGeomSource}
                        style={purpleDot}
                        zIndex={103}
                    />
                </Layers>
            </Map>
        </Box>
    );
}

export default TenantConfigMap;