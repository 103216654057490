/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI Icons
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

// Custom Components
import TableButton from './TableButton';

// Types
import { ITableActionButtonDetail } from '@/@types/ui/Table';

function TableButtonView(props: ITableActionButtonDetail) {
  const { t } = useTranslation();

  return (
    <TableButton
      variant="contained"
      allow="one"
      startIcon={<OpenInBrowserIcon />}
      selection={{}}
      {...props}
    >
      {t('buttons.view')}
    </TableButton>
  );
}

export default TableButtonView;
