import React from 'react';
import { createRoot } from 'react-dom/client';
import AppLoader from '@/ui/AppLoader/AppLoader';
import TenantProvider from '@/context/TenantContext/TenantProvider';

import './styles/layout.css';
import './styles/map.css';
import './styles/splash-screen.css';
import './styles/cesium.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <TenantProvider>
    <AppLoader />
  </TenantProvider>
);
