/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';

// Custom Components
import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/Check';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import useFormController, {
  SubmodelAPIPaths,
} from '@/components/useFormController';
import GridContainer from '@/ui/Grid/GridContainer';
import FormContent from '@/components/FormContent';

import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DataController from '@/lib/DataController';
import modelLicences from '@/models/licence';
import DialogActionButtonCancel from '../Dialog/ActionButtons/DialogActionButtonCancel';
import DialogActionButtonAdd from '../Dialog/ActionButtons/DialogActionButtonAdd';
import DialogActionButtonSave from '../Dialog/ActionButtons/DialogActionButtonSave';
import DialogToolbarMenuButton from '../Dialog/DialogToolbarMenuButton';
import DialogToolbarMenuItemDelete from '../Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete';

const TenantAdminLicencesDialog = (props: IDialogProps) => {
  const {
    mode,
    dataId,
    onClose,
    form,
    initialRecord,
    tenantId,
    userId,
    refreshRecords,
    type
  } = props;

  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const dc =
    userId === undefined
      ? new DataController(
        modelLicences,
        type + '/tenants/' + String(tenantId) + '/licences'
      )
      : new DataController(
        modelLicences,
        type + '/tenants/' +
        String(tenantId) +
        '/users/' +
        userId +
        '/licences'
      );

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  const [submodelAPIPath, setSubmodelAPIPath] = useState<
    SubmodelAPIPaths | undefined
  >(
    userId
      ? initialRecord && initialRecord.tenant_licence_id && tenantId
        ? {
          tenant_licence_id:
            type + '/tenants/' + String(tenantId) + '/licences?view=picker',
          ul_app_permissions:
            type + '/tenants/' +
            String(tenantId) +
            '/licences/' +
            String(initialRecord.tenant_licence_id) +
            '/app_permissions?view=picker',
        }
        : tenantId
          ? {
            tenant_licence_id:
              type + '/tenants/' + String(tenantId) + '/users/' + String(userId) + '/licences?view=picker',
            ul_app_permissions: type + '/tenants/' +
              String(tenantId) +
              '/licences/' +
              0 +
              '/app_permissions?view=picker',
          }
          : undefined
      : initialRecord && initialRecord.app_module_id
        ? {
          app_module_id: type + '/app_modules?view=picker',
          tl_app_permissions:
            type + '/app_modules/' +
            String(initialRecord.app_module_id) +
            '/app_permissions?view=picker',
        }
        : {
          app_module_id: type + '/app_modules?view=picker',
          tl_app_permissions: type + '/app_modules/' +
            0 + '/app_permissions?view=picker',
        }
  );

  const {
    record,
    validation,
    fields,
    pickerDataControllers,
    onFieldChange,
    doInsert,
    doUpdate,
    doDelete,
    doClear,
    dataChanged,
  } = useFormController({
    mode,
    recordId: dataId,
    form,
    dc,
    onClose: close,
    initialRecord: initialRecord,
    alternativeSubmodelAPIPath: submodelAPIPath,
  });

  const disableSaveButton = !dataChanged;

  const handleFieldChange = (value: any, source: string) => {
    if (source === 'tenant_licence_id') {
      doClear();
      if (value && value !== null && value.value && value.value !== null) {
        setSubmodelAPIPath({
          tenant_licence_id:
            type + '/tenants/' + String(tenantId) + '/users/' + String(userId) + '/licences?view=picker',
          ul_app_permissions:
            type + '/tenants/' +
            String(tenantId) +
            '/licences/' +
            String(value.value) +
            '/app_permissions?view=picker',
        });
      }
      else {
        setSubmodelAPIPath({
          tenant_licence_id:
            type + '/tenants/' + String(tenantId) + '/users/' + String(userId) + '/licences?view=picker',
          ul_app_permissions:
            type + '/tenants/' +
            String(tenantId) +
            '/licences/' +
            0 +
            '/app_permissions?view=picker',
        });
      }
    } else if (source === 'app_module_id') {
      doClear();
      if (value && value !== null && value.value && value.value !== null) {
        setSubmodelAPIPath({
          app_module_id: type + '/app_modules?view=picker',
          tl_app_permissions:
            type + '/app_modules/' +
            String(value.value) +
            '/app_permissions?view=picker',
        });
      }
    }

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleClose = () => {
    close({ success: false });
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            refreshRecords();
            const msg = 'messages.insert_tenant_licence_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'insert' });
          } else if (result.error) {
            const msg = 'error.insert_tenant_licence';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            refreshRecords();
            const msg = 'messages.update_tenant_licence_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = 'error.update_tenant_licence';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleDelete = () => {
    doDelete()
      .then((result) => {
        if (result.success) {
          refreshRecords();
          const msg = 'messages.delete_tenant_licence_success';
          snackbarContext.showNotification(msg, 'success');
          close({ dataChanged: true, action: 'delete' });
        } 
      })
      .catch((resp) => {
        if (resp.error) {
          snackbarContext.showNotification(resp.error, 'error');
        }
      });
  };

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose} minHeight={'25rem'}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          {mode === 'update' ? (
            <EditIcon sx={{ transform: 'scale(1.3)' }} />
          ) : mode === 'insert' ? (
            <AddCommentIcon sx={{ transform: 'scale(1.3)' }} />
          ) : null}
          <DialogToolbarHeading>
            {mode === 'insert'
              ? t('admin:titles.add_licence')
              : mode === 'update'
                ? t('admin:titles.edit_licence')
                : null}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          {mode === 'update' ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              // @ts-ignore
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={mode}
              columns={1}
              pickerDataControllers={pickerDataControllers}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert' ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === 'update' ? (
          <DialogActionButtonSave
            variant="contained"
            onClick={handleUpdate}
            disabled={disableSaveButton}
          />
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
};

export default TenantAdminLicencesDialog;
