import * as React from 'react';
import { useContext } from 'react';

// Material UI
import { Box, SxProps, Typography, useTheme } from '@mui/material';

// Custom components
import ErrorContext from './ErrorContext';

// Types
import { ErrorContextType } from '@/context/ErrorContext/ErrorContext';

const ErrorContainer = () => {
  const errorContext = useContext(ErrorContext) as ErrorContextType;
  const theme = useTheme();

  const styles = {
    container: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 99,
    } as SxProps,
  };
  const { open, code, message, description } = errorContext;
  if (open) {
    return (
      <Box sx={styles.container}>
        <Typography variant="h1">{`${code} ${message}`}</Typography>
        {description ? (
          <Typography variant="subtitle1">{description}</Typography>
        ) : null}
      </Box>
    );
  }
  return null;
};

export default ErrorContainer;
