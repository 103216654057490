/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';

// Ui
import TableButtonView from '@/ui/Table/ActionButtons/TableButtonView';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import { DialogFormGenerateFunction } from '@/ui/DataCentric/DataCentricPage';
import TableButtonShowPhotos from '@/ui/Table/ActionButtons/TableButtonShowPhotos';
import GeneralDialog from '@/ui/Dialog/GeneralDialog';
import TableButtonDelete from '@/ui/Table/ActionButtons/TableButtonDelete';

// Lib
import DataController, { IDataController } from '@/lib/DataController';

// Context
import DialogContext, {
  DialogContextType,
  IDialogProps,
} from '@/context/DialogContext/DialogContext';

// Types
import { IModel } from '@/@types/models/model';
import { ClosingDetails } from '@/@types/components/formController';

interface IDataCentricSecondaryTable {
  selectedPrimaryRecordId: number;
  selectedPrimaryRecordQueryAttribute?: number | string | null;
  onRecordSelect: (id: number) => void;
  onLoad?: (records: Array<DCRecord>) => void;
  view: string | undefined;
  onRowAction: (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => void;
  setAllowImages: () => void;
  imagesEnabled: boolean;

  model: IModel;
  titleToken: string;

  baseRecordPath: string;
  childRecordRelativePath: string;

  allowView?: boolean;
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  dialogComponent?: React.FunctionComponent<IDialogProps>;
  dialogForm?: string | DialogFormGenerateFunction;

  triggerRefresh: boolean;
  setTriggerRefresh: (state: boolean) => void;
}

const DataCentricSecondaryTable = (props: IDataCentricSecondaryTable) => {
  const dialogContext = useContext<DialogContextType>(DialogContext);

  const { t } = useTranslation();
  const { onRecordSelect, onLoad, onRowAction, setAllowImages } = props;
  const {
    model,
    titleToken,
    baseRecordPath,
    childRecordRelativePath,
    imagesEnabled,
    view,
    selectedPrimaryRecordId,
    triggerRefresh,
    setTriggerRefresh,
    selectedPrimaryRecordQueryAttribute
  } = props;
  const {
    allowView,
    allowAdd,
    allowEdit,
    allowDelete,
    dialogComponent = GeneralDialog,
    dialogForm,
  } = props;

  const dc: IDataController = new DataController(model);

  const [records, setRecords] = useState<DCRecord[]>([]);

  useEffect(() => {
    if (selectedPrimaryRecordId !== 0) {
      refreshRecords();
    } else {
      setRecords([])
    }
  }, [selectedPrimaryRecordId,selectedPrimaryRecordQueryAttribute]);

  const refreshRecords = () => {
    if (selectedPrimaryRecordQueryAttribute) {
      fetchRecords(selectedPrimaryRecordQueryAttribute);
    } else if (selectedPrimaryRecordQueryAttribute === undefined && !selectedPrimaryRecordQueryAttribute && selectedPrimaryRecordId !== 0) {
      fetchRecords(selectedPrimaryRecordId);
    } else {
      setRecords([]);
    }
  }

  useEffect(() => {
    if (triggerRefresh) {
      setTriggerRefresh(false);
      refreshRecords();
    }
  }, [triggerRefresh]);

  const fetchRecords = (identifierField: number | string) => {
    const path = `${baseRecordPath}/${identifierField}/${childRecordRelativePath}`;
    dc.GetData(path)
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecords(resp.data);
          if (onLoad) {
            onLoad(resp.data);
          }
        } else {
          setRecords([]);
        }
      })
      .catch((resp) => {
        setRecords([]);
      });
  };

  const handleView = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const path = `${baseRecordPath}/${selectedPrimaryRecordId}/${childRecordRelativePath}`;
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    const form =
      typeof dialogForm === 'function' ? dialogForm(record) : dialogForm;

    if (dialogComponent && record?.id) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'view',
        form,
        recordId: record.id,
        customPath: path,
        onClose: handleDialogClose,
      });
    }
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const path = `${baseRecordPath}/${selectedPrimaryRecordId}/${childRecordRelativePath}`;
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    console.log("record",record);

    const form =
      typeof dialogForm === 'function' ? dialogForm(record) : dialogForm;

    if (dialogComponent && record?.id) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'update',
        form,
        initialRecord: record,
        onClose: handleDialogClose,
        customPath: path,
        title: `${t(titleToken)} - ${t('common.update')}`,
      });
    }
  };

  const handleAdd = () => {
    const path = `${baseRecordPath}/${selectedPrimaryRecordId}/${childRecordRelativePath}`;

    if (dialogComponent) {
      dialogContext.showDialog(dialogComponent, {
        dc,

        mode: 'insert',
        form: 'insert',
        onClose: handleDialogClose,
        customPath: path,
        title: `${t(titleToken)} - ${t('common.insert')}`,
      });
    }
  };

  const handleDelete = async (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const record = sel[Object.keys(sel)[0]];

    const confirmResult = await dialogContext.showConfirmDialog({
      title: t('title.translation') as string,
      text: `${t('translation.delete_confirm')}: ${record.ttoken}`,
      confirmButtonText: t('buttons.confirm') as string,
      cancelButtonText: t('buttons.cancel') as string,
    });

    if (confirmResult.success && record.id) {
      await dc
        .DeleteRecord(record.id as number)
        .then((result) => {
          // console.log(result)
        })
        .finally(() => {
          refreshRecords();
        });
    }
  };

  const handleDialogClose = (result: ClosingDetails) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  return (
    <ModelTable
      title={t(titleToken)}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={false}
      allowExport={false}
      allowAdd={allowAdd}
      handleAdd={handleAdd}
      onRowAction={onRowAction}
      allowRowAction={() => true}
      onRowClick={(
        evt: React.SyntheticEvent,
        sel: { [key: string]: DCRecord }
      ) => {
        const clickedRecord = sel[Object.keys(sel)[0]];
        return onRecordSelect(clickedRecord.id as number);
      }}
      viewName={view}
      rowSelectedCustom={selectedPrimaryRecordId}
    >
      <TableButtonShowPhotos
        disabled={!imagesEnabled}
        variant="contained"
        onClick={setAllowImages}
      />
      {allowDelete ? (
        <TableButtonDelete variant="outlined" onClick={handleDelete} />
      ) : (
        <></>
      )}
      {allowEdit ? (
        <TableButtonEdit variant="outlined" onClick={handleEdit} />
      ) : (
        <></>
      )}
      {allowView ? (
        <TableButtonView variant="contained" onClick={handleView} />
      ) : (
        <></>
      )}
    </ModelTable>
  );
};

export default DataCentricSecondaryTable;
