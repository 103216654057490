/* eslint-disable */
import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';
import ModelsTable from './ModelsTable';

function ModelsPage() {
  return (
    <AppPage
      titlett="admin:page.models"
      needPermission={[Permissions.Administration]}
    >
      <Box m={0} style={{ height: '100%' }}>
        <ModelsTable />
      </Box>
    </AppPage>
  );
}

export default ModelsPage;
