/* eslint-disable */
import React from 'react';

// Custom Components
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelRasvjetaMjesta from '@/models/rasvjeta_mjesta';
import modelRasvjetaTijela from '@/models/rasvjeta_tijela';

// Types
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';

type RasvjetaMjestaDataCentricPageParams = {};

const RasvjetaMjestaDataCentricPage = (
  props: RasvjetaMjestaDataCentricPageParams
) => (
    <AppPage
      titlett="rasvjeta:page.rasvjeta_mjesta"
      needPermission={[Permissions.Rasvjeta]}
    >
      <DataCentricPage
        primaryModel={modelRasvjetaMjesta}
        primaryTitleToken="titles.rasvjeta_mjesta"
        primaryView='default'
        secondaryModel={modelRasvjetaTijela}
        secondaryTitleToken="titles.rasvjeta_tijela"
        secondaryView='default'
        baseRecordPath={modelRasvjetaMjesta.apiPath}
        childRecordRelativePath="tijela"
        geomRelativePath=""
        mapId={31}
        mapModel={modelRasvjetaMjesta}
      />
    </AppPage>
  );

export default RasvjetaMjestaDataCentricPage;
