/* eslint-disable */
import React from 'react';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

//Types
import { SvgIconProps } from '@mui/material';

function GFIModelIcon(props: SvgIconProps) {
  return <AutoAwesomeMotionIcon {...props} />;
}

export default GFIModelIcon;