import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, IconButton, Tooltip } from '@mui/material';

// MUI Icons
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';

// CUSTOM
import MapCard from '@/ui/MapCard/MapCard';
import MapCardHeader from '@/ui/MapCard/MapCardHeader';
import MapCardAvatar from '@/ui/MapCard/MapCardAvatar';
import MapCardContent from '@/ui/MapCard/MapCardContent';
import MapCardActions from '@/ui/MapCard/MapCardActions';
import DataController from '@/lib/DataController';
import MapCardHeaderButtonClose from '@/ui/MapCard/MapCardHeaderButtonClose';
import { GridContainer, GridItem } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import { DCRecord } from '@/@types/lib/dataController';
import modelLokacije from '@/models/lokacije';
import UserContext, { UserContextType } from '@/context/UserContext/UserContext';
import Permissions from '@/lib/permissions';
import ShowKOButton3D from '@/components/Map/InfoCards/LayerFunctionalities/ShowKOButton3D';
import ShowOnMapButton3D from '@/components/Map/InfoCards/LayerFunctionalities/ShowOnMapButton3D';

type EntityInfoPaneProps = {
  record: DCRecord | undefined;
  onClose: () => void;
  focusOnObject: (id: number) => void;
  entityId: number;
  trackedEntityId: number;
};

const EntityInfoPane = (props: EntityInfoPaneProps) => {
  const { t } = useTranslation();

  const { record, entityId, trackedEntityId, onClose, focusOnObject } = props;

  const dc = new DataController(modelLokacije);
  const form = 'default';

  const userContext = useContext(UserContext) as UserContextType;

  const title = t('ena:komunalni_obveznici.lok');

  return record ? (
    <MapCard sx={{ width: 'min-content' }}>
      <MapCardHeader
        title={title}
        subheader={`${t('common.id')  }: ${  record.id}`}
        subheaderTypographyProps={{ color: 'initial' }}
        avatar={<MapCardAvatar ariaLabel="info" icon="info" />}
        action={
          <MapCardHeaderButtonClose
            onClick={() => {
              onClose();
            }}
          />
        }
      />
      <MapCardContent>
        <GridContainer spacing={0}>
          <GridItem xs={12} container>
            <GridContainer spacing={0}>
              {record && (
                <FormContent
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                />
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </MapCardContent>
      <MapCardActions style={{ justifyContent: 'space-between', paddingTop: 0 }} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box display="flex">
          {/* lokacija status id = 2 -> BRISATI */}
          {userContext.hasAnyPermission([Permissions.Ena]) ? <ShowKOButton3D disabled={record.lokacija_status_id === 2} locationId={record.id as number | undefined} /> : null}
          <ShowOnMapButton3D wkt={record.wkt as string}/> 
        </Box>
        <IconButton
          onClick={() => {
            focusOnObject(entityId as number);
          }}
          color={trackedEntityId === entityId ? 'primary' : 'default'}
        >
          <Tooltip sx={{ zIndex: 1201 }} placement="left" title={`${  t('buttons.focus_object')}`}>
            <VideoCameraBackIcon />
          </Tooltip>
        </IconButton>
      </MapCardActions>
    </MapCard>
  ) : (
    null
  );
};

export default EntityInfoPane;
