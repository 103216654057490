import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const ParkingMjesta: IModel = {
  title: 'Parking mjesta',
  apiPath: 'parking/mjesta',
  layer: 'parkirna_mjesta',
  forms: {
    default: {
      fields: [
        'id',
        'parkiraliste_id',
        'naplata',
        'vrsta_id',
        'povrsina',
        'opseg',
        'polozaj_id',
        'smjer_id',
        'podloga_id',
        'duzina',
        'sirina',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'parkiraliste_id',
        'naplata',
        'vrsta_id',
        'povrsina',
        'opseg',
        'polozaj_id',
        'smjer_id',
        'podloga_id',
        'duzina',
        'sirina',
      ],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'parkiraliste_id',
      source: 'parkiraliste_id',
      ttoken: 'parking:mjesta.naziv_parkiralista',
      type: 'picker',
      readonly: true,
      filter: true,
      subModel: {
        apiPath: 'parking/parkiralista?view=picker',
        format: '{label}',
        idattr: 'id',
      }
    },
    {
      title: 'naplata',
      source: 'naplata',
      ttoken: 'parking:mjesta.naplata',
      type: 'boolean',
      items: {
        labels: ['common.ne', 'common.da'],
        values: [false, true],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'povrsina',
      source: 'povrsina',
      ttoken: 'parking:mjesta.povrsina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'opseg',
      source: 'opseg',
      ttoken: 'parking:mjesta.opseg',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'duzina',
      source: 'duzina',
      ttoken: 'parking:mjesta.duzina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'sirina',
      source: 'sirina',
      ttoken: 'parking:mjesta.sirina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'vrsta',
      source: 'vrsta_id',
      ttoken: 'parking:mjesta.vrsta',
      type: 'picker',
      items: {
        values: [1, 2, 3, 4, 5, 6, 7],
        labels: [
          "EAP",
          "P-SLOBODNO",
          "TAXI",
          "P-NAPLATA",
          "BUS MINI",
          "INV",
          "BUS",
        ],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'polozaj',
      source: 'polozaj_id',
      ttoken: 'parking:mjesta.polozaj',
      type: 'picker',
      items: {
        values: [1, 2, 3],
        labels: [
          "parkiralište",
          "kolnik - nogostup",
          "uz kolnik",
        ],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'smjer',
      source: 'smjer_id',
      ttoken: 'parking:mjesta.smjer',
      type: 'picker',
      items: {
        values: [1, 2, 3, 4],
        labels: [
          "prema planu",
          "okomito",
          "paralelno",
          "koso",
        ],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'podloga',
      source: 'podloga_id',
      ttoken: 'parking:mjesta.podloga',
      type: 'picker',
      items: {
        values: [1, 2, 3, 4],
        labels: [
          "betonski opločnik",
          "betonska rešetka - trava",
          "asfalt - betonski opločnik",
          "asfalt",
        ],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
      readonly: true,
    },
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default ParkingMjesta;
