import React, { useEffect, useMemo, useState } from 'react';
import numbro from 'numbro';

// Material UI
import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';

import { useDebounce } from 'use-debounce';

// Types
import { INumberRangeFilter, NumberRangeFilterWrapperFn } from '@/@types/ui/Table';

/**
 * Filter wrapper function that returns the NumberRangeFilter component
 *
 * @returns The NumberRangeFilter component
 */
const NumberRangeFilter: NumberRangeFilterWrapperFn = (step) => {
  const NumberSlider = ({ column }: { column: INumberRangeFilter }) => {
    const { id, filterValue, setFilter, preFilteredRows } = column;

    const range = useMemo(() => {
      let min = Number.MAX_VALUE;
      let max = Number.MIN_VALUE;

      preFilteredRows?.forEach((row) => {
        const value = (row[id] as number | null) ?? 0; // Treat falsy values as 0
        if (value < min) min = value;
        if (value > max) max = value;
      });

      if (min === Number.MAX_VALUE) min = 0;
      if (max === Number.MIN_VALUE) max = 0;

      return [min, max];
    }, [preFilteredRows, id]);

    const [minValue, maxValue] = range;

    const calculatedStep = useMemo(() => {
      const diff = maxValue - minValue;
      return diff < 10 ? 0.1 : diff < 100 ? 1 : diff < 1000 ? 10 : 100;
    }, [minValue, maxValue]);

    useEffect(() => {
      if (!filterValue) {
        setFilter([minValue, maxValue]);
      }
    }, [filterValue, minValue, maxValue, setFilter]);

    // State for slider value
    const [sliderValue, setSliderValue] = useState<number | [number, number]>(filterValue || [minValue, maxValue]);

    // Debounced slider value
    const [debouncedValue] = useDebounce(sliderValue, 300);

    // Update filter value only when the debounced value changes
    useEffect(() => {
      setFilter(debouncedValue);
    }, [debouncedValue, setFilter]);

    // Set initial filter value
    useEffect(() => {
      if (!filterValue) {
        setFilter([minValue, maxValue]);
      }
    }, [filterValue, minValue, maxValue, setFilter]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
      setSliderValue(newValue as [number, number]);
    };

    /* const handleChangeTextField: ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const combinedValue: [number, number] = filterValue as [number, number];
      const index = e.target.id === 'outlined-number-1' ? 0 : 1;
      combinedValue[index] = parseFloat(e.target.value);
    }; */

    return (
      <Box>
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          min={minValue}
          max={maxValue}
          valueLabelDisplay="auto"
          color="primary"
          valueLabelFormat={(x) => numbro(x).format({ thousandSeparated: true, mantissa: 2 })}
          step={step || calculatedStep}
        />
      </Box>

      /* this is Vlatko's idea, we'll see if we will keep it */
      /* right now it messes with the positioning of other filters */
      /* <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <TextField
            id="outlined-number-1"
            label="min"
            type="number"
            key={0}
            value={(filterValue as [number, number])[0]}
            onChange={handleChangeTextField}
            size="small"
            margin="dense"
          />
          <Box sx={{ width: '5%' }}>
            <hr />
          </Box>
          <TextField
            id="outlined-number-2"
            label="max"
            type="number"
            key={1}
            value={(filterValue as [number, number])[1]}
            onChange={(e) => handleChangeTextField(e)}
            size="small"
            margin="dense"
          />
        </Box> */
    );
  };

  return ({ column }: { column: INumberRangeFilter }) => <NumberSlider column={column} />;
};

export default NumberRangeFilter;
