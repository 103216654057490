/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// Material UI
import { Box, useTheme } from '@mui/material';

// Custom components
import SidebarContext, {
  SidebarContextType,
} from '@/context/SidebarContext/SidebarContext';
import { useTranslation } from 'react-i18next';

// Types

const SidebarHeading: FunctionComponent = (props) => {
  const sidebarContext = useContext(SidebarContext) as SidebarContextType;

  const theme = useTheme();

  const { t } = useTranslation();

  const title= sidebarContext?.sidebarHeading as string;
  const onCollapse= sidebarContext?.onSidebarCollapse;

  const handleCollapse = (): void => {
    sidebarContext.setCollapsed(true);
    if(onCollapse) {
      onCollapse();
    }
  };

  return (
    <Box position={"absolute"} left={40} width={"calc( 100% - 40px )"} display={ sidebarContext.collapsed ? "none" : "block"}>
      <h1
        style={{
          backgroundColor: theme.palette.primary.main,
          margin: '0',
          height: 40,
          padding: '0 20px',
          paddingLeft: sidebarContext?.position === 'right' ? 40 : 20,
          lineHeight: '40px',
          fontSize: '14.4pt',
          color: '#fff',
          position: 'relative'
        }}
      >
        {t(title)}
        <span className="sidebar-close" onClick={handleCollapse}>
          <i
            className={`fas ${
              sidebarContext?.position === 'right'
                ? 'fa-caret-right'
                : 'fa-caret-left'
            }`}
          />
        </span>
      </h1>
    </Box>
  );
};

export default SidebarHeading;
