/* eslint-disable */
import React, { useEffect, useState, MouseEvent, SyntheticEvent } from 'react';
import { TFunction } from 'i18next';

// MUI
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

// MUI Icons
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import ListIcon from '@mui/icons-material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Custom Components
import {
  dateOptions,
  dateSubOptions,
  DateHeadingValue,
  TDateSubOptionsValues,
} from '@/lib/dateFilterOptions';

// Types
import { DateFilterWrapperFn, IDateColumnFilterProps } from '@/@types/ui/Table';

/**
 * Function that wraps around the DateColumnFilter component
 *
 * @param {TFunction} t - translation function
 *
 * @returns The DateColumnFilter component
 */
const dateColumnFilterWrapper: DateFilterWrapperFn =
  (t) => (filter) => {
    const { column } = filter;
    const { id, filterValue, setFilter } = column;
    return (
      <DateColumnFilter
        filterValue={filterValue}
        t={t as TFunction}
        onFilterChange={(value: string) =>
          setFilter(value as TDateSubOptionsValues)
        }
      />
    );
  };

/**
 * Function that stores the filter in local storage
 *
 * @param {string} filterValue - Filter value
 *
 * @returns The initial value of filters
 */
const getInitialFilterValue = (filterValue: string) => {
  if (filterValue === 'all') return filterValue;
  let result = '';
  Object.keys(dateSubOptions).forEach((key) => {
    if (result !== '') return;
    dateSubOptions[key as DateHeadingValue].forEach((subOption) => {
      if (result !== '') return;
      if (filterValue === subOption.value) {
        result = key;
      }
    });
  });
  return result === '' ? 'all' : result;
};

const DateColumnFilter = (props: IDateColumnFilterProps) => {
  const { filterValue, onFilterChange, t: t } = props;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [hoveredMenu, setHoveredMenu] = useState('');
  const [selectedMenuValue, setSelectedMenuValue] = useState('all');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleHover = (event: MouseEvent<HTMLLIElement>, menu: string) => {
    if (menu === 'all') {
      setAnchorElement(null);
    } else if (isOpen) {
      setAnchorElement(event.currentTarget);
      setHoveredMenu(menu);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (!value) return;

    switch (value) {
      case 'all':
        onFilterChange('');
        setIsOpen(false);
        setSelectedMenuValue(value);
        break;
      default:
        event.preventDefault();
        break;
    }
  };

  /**
   * Function handles clicks on popper MenuItems
   */
  const handleMenuItemClick = (
    event: SyntheticEvent,
    value: string,
    hoveredMenu: string
  ) => {
    onFilterChange(value);
    setSelectedMenuValue(hoveredMenu);
    setIsOpen(false);
  };

  /**
   * @returns filterValue to the Select component through renderValue
   */
  const getRenderedFilterValue = () => {
    if (filterValue) {
      return t(`datefilter.${filterValue}`);
    }
    return t('datefilter.all');
  };

  useEffect(() => {
    setSelectedMenuValue(getInitialFilterValue(filterValue));
  }, []);

  // Sets the popper anchor to null if select is not open
  useEffect(() => {
    if (!isOpen) {
      setAnchorElement(null);
    }
  }, [isOpen]);

  return (
    <>
      <Select
        open={isOpen}
        renderValue={getRenderedFilterValue}
        value={selectedMenuValue}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        input={<OutlinedInput size="small" margin="dense" />}
      >
        <MenuItem
          onMouseEnter={(event) => handleHover(event, 'all')}
          onClick={(event) => handleMenuItemClick(event, 'all', 'all')}
          value="all"
        >
          <ListItemIcon>
            <FilterListOffIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.all')}</ListItemText>
        </MenuItem>
        {dateOptions.map(({ title, value }) => (
          <MenuItem
            key={value}
            value={value as string}
            onMouseEnter={(event) => handleHover(event, value as string)}
          >
            <ListItemIcon>
              <ListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t(title)}</ListItemText>
            <ExpandMoreIcon
              fontSize="small"
              sx={{
                transform:
                  anchorElement !== null && value === hoveredMenu
                    ? 'rotate(90deg)'
                    : 'rotate(-90deg)',
              }}
            />
          </MenuItem>
        ))}
      </Select>
      <Popper
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        placement="right"
        style={{ zIndex: 1400 }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <Paper
          elevation={3}
          style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}
        >
          <MenuList>
            {(dateSubOptions[hoveredMenu as DateHeadingValue] || []).map(
              ({ value, label, icon }) => (
                <MenuItem
                  key={`date-menu-${value}`}
                  value={value}
                  onClick={(event) =>
                    handleMenuItemClick(event, value, hoveredMenu)
                  }
                >
                  {icon ? (
                    <ListItemIcon>
                      {icon === 'history' ? (
                        <HistoryIcon fontSize="small" />
                      ) : icon === 'future' ? (
                        <UpdateIcon fontSize="small" />
                      ) : (
                        <AccessTimeIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                  ) : null}
                  <ListItemText>{t(label)}</ListItemText>
                </MenuItem>
              )
            )}
          </MenuList>
        </Paper>
      </Popper>
    </>
  );
};

export default dateColumnFilterWrapper;
