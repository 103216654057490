import { createContext } from 'react';

// Types
export type Theme = 'light' | 'dark';
export type ThemeContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const ThemeContext = createContext<ThemeContextType | null>(null);

ThemeContext.displayName = 'ThemeContext';
export default ThemeContext;
