/* eslint-disable */
import * as React from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import DeleteIcon from "@mui/icons-material/Delete";

//Custom components
import CardToolbarMenuItem from "@/ui/Card/CardToolbarMenuItem";

//Types
import { ICardToolbarMenuItemDetail } from "@/@types/ui/Card";

const CardToolbarMenuItemDelete = React.forwardRef<HTMLLIElement, ICardToolbarMenuItemDetail>((props, ref) => {
  const { t } = useTranslation();
  const { onClick } = props;

  return <CardToolbarMenuItem label={t("buttons.delete")} icon={<DeleteIcon />} onClick={onClick} ref={ref} />;
});

export default CardToolbarMenuItemDelete;
