import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';

// MUI Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Custom Components
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import DialogActionButtonCancel from '@/ui/Dialog/ActionButtons/DialogActionButtonCancel';
import DialogActionButtonSave from '@/ui/Dialog/ActionButtons/DialogActionButtonSave';

import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import useFormController from '@/components/useFormController';
import GridContainer from '@/ui/Grid/GridContainer';
import FormContent from '@/components/FormContent';

import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DataController from '@/lib/DataController';
import modelDocuments from '@/models/documents';

const InsertDocumentDialog = (props: IDialogProps) => {
  const { mode, dataId, onClose, form, initialRecord } = props;

  const { t } = useTranslation();
  const { hideDialog } = useContext(DialogContext);
  const { showNotification } = useContext(SnackbarContext);

  const dc = new DataController(modelDocuments);

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    hideDialog(2);
  };

  const {
    record,
    validation,
    fields,
    onFieldChange,
    doInsert,
    // doUpdate,
  } = useFormController({
    mode,
    recordId: dataId,
    form,
    dc,
    onClose: close,
    initialRecord,
  });

  const handleClose = () => {
    close({ success: false });
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: 'insert' });
          } else if (result.error) {
            const msg = t('error.addRecord') + result.error;
            showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          showNotification(resp.error, 'error');
        });
    }
  };

  /* const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = t('error.editRecord') + result.error;
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  }; */

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <AttachFileIcon sx={{ transform: 'scale(1.3)' }} />
          <DialogToolbarHeading>
            {t('titles.add_document')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box mt={2}>
          <GridContainer>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              // @ts-ignore
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={onFieldChange}
              mode={mode}
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert' ? (
          <DialogActionButtonSave variant="contained" onClick={handleInsert} />
        ) : /* {: mode === 'update'
            ? <DialogActionButtonSave variant="contained" onClick={handleUpdate} /> } */
        null}
      </DialogActions>
    </DraggableDialog>
  );
};

export default InsertDocumentDialog;
