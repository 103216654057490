import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// OpenLayers
import proj4 from 'proj4';
import { register as OlRegister } from 'ol/proj/proj4';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import OlBaseLayer from 'ol/layer/Base';
import OlLayerGroup from 'ol/layer/Group';
import OlCollection from 'ol/Collection';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFeature from 'ol/Feature';
import { Style } from 'ol/style';

// MUI
import { Box, useMediaQuery, useTheme } from '@mui/material';

// Custom
import { Controls, RotateControl, ScaleLineControl, ZoomControl, ZoomToExtentControl } from '@/components/Map/Controls';
import { Layers, VectorLayer } from '@/components/Map/Layers';
import Map from '@/components/Map/Map';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import GeoAPILayers from '@/components/Map/Layers/GEO/GeoAPILayers';
import { highlightedVectorStyle } from '@/components/Map/mapStyles';
import MapButton from '@/components/Map/Controls/Custom/MapButton';

// Context
import TenantContext, { TenantContextType } from '@/context/TenantContext/TenantContext';

// Lib
import { padExtent, flattenLayers } from '@/lib/olHelpers';
import DataController from '@/lib/DataController';

// Services
import mapService from '@/services/mapService';
import gsService from '@/services/gsService';

// Types
import { IModel } from '@/@types/models/model';
import { DCRecord } from '@/@types/lib/dataController';
import EnumLayerFunctionality from '@/@types/services/gsServiceEnums';

type PhotoCentricMiniMapProps = {
  selectedRecordId: number;
  onRecordSelect: (id: number) => void;

  model: IModel;
  mapId: number;
  selectedLayerStyle?: (f: any) => Style[];

  allowHideObjects?: boolean;

  onResize?: () => void;
  isResized?: boolean;
};

const PhotoCentricMiniMap = (props: PhotoCentricMiniMapProps) => {
  const {
    selectedRecordId,
    onRecordSelect,
    model,
    mapId,
    selectedLayerStyle = highlightedVectorStyle,
    allowHideObjects,
    isResized,
    onResize,
  } = props;

  const tenantContext = useContext(TenantContext) as TenantContextType;

  const { t } = useTranslation();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [mapInitialized, setMapInitialized] = useState(false);

  const [recordExtent, setRecordExtent] = useState<number[]>(
    tenantContext?.mapSettings?.default_extent ? tenantContext?.mapSettings?.default_extent : [1437016, 5271097, 2206278, 5860579]
  );
  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);
  const [selectedSource, setSelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [showDOFLayer, setShowDOFLayer] = useState(true);
  const [showObjektiLayer, setShowObjektiLayer] = useState(true);

  const [hiddenLayerIDs, setHiddenLayerIDs] = useState<string[]>([]);

  const defaultExtent = useMemo(
    () =>
      padExtent(
        tenantContext?.mapSettings?.default_extent
          ? tenantContext?.mapSettings?.default_extent
          : [1437016, 5271097, 2206278, 5860579]
      ),
    []
  );

  const viewOptions = useMemo(
    () => ({
      center: tenantContext?.mapSettings?.initial_view_center
        ? tenantContext.mapSettings.initial_view_center
        : ([1731757, 5581737] as [number, number]),
      extent: tenantContext?.mapSettings?.max_extent ? tenantContext.mapSettings.max_extent : [1437016, 5271097, 2206278, 5860579],
      zoom: tenantContext?.mapSettings?.initial_view_zoom ? tenantContext.mapSettings.initial_view_zoom : 8,
      minZoom: tenantContext?.mapSettings?.map_min_view_zoom ? tenantContext.mapSettings.map_min_view_zoom : 8,
      maxZoom: tenantContext?.mapSettings?.map_max_view_zoom ? tenantContext.mapSettings.map_max_view_zoom : 21,
    }),
    [tenantContext]
  );

  // define proj
  proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  const dc = new DataController(model);

  const allowDOF = useMemo(() => {
    // if at least one layer has show_dof functionality allow the button
    if (layersCollection && layersCollection.getLength() > 0) {
      return layersCollection.getArray().some((layer) => layer.get('layer_functionality_id') === EnumLayerFunctionality.toggle_dof);
    }

    return false;
  }, [layersCollection]);

  useEffect(() => {
    getLayers();
  }, []);

  const getLayers = () => {
    mapService.getLayers(mapId).then((coll: OlCollection<OlBaseLayer>) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  useEffect(() => {
    if (selectedRecordId > 0) {
      dc.GetDataSingle(selectedRecordId).then((resp) => {
        if (resp.success) {
          const dcRec = resp.data as DCRecord;
          const feat = dcRec?.geom as OlFeature;
          if (feat) {
            const srcVector = new OlSourceVector({ features: [feat] });
            setSelectedSource(srcVector);
            const geom = feat.getGeometry();
            if (geom) {
              setRecordExtent(geom.getExtent());
            }
          }
        } else {
          setRecordExtent([]);
        }
      });
    } else {
      const srcVector = new OlSourceVector({ features: [] });
      setSelectedSource(srcVector);
      setRecordExtent([]);
      onRecordSelect(0);
    }
  }, [selectedRecordId]);

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    const feature = evt.map.forEachFeatureAtPixel(evt.pixel, (feat) => feat, {
      layerFilter: (layer) => {
        const layerId = layer.get('id');
        return layerId !== null && layerId !== undefined;
      },
    });

    if (feature) {
      const id = Number(feature.getId());
      onRecordSelect(id);
    } else {
      const allLayers = layersCollection ? flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup)) : [];

      const primaryRecordLayers = allLayers.filter((x) => {
        const layerName = x.get('layer');
        // finds the index of first underscore and slices everything after it
        const layerNameParsed = layerName.slice(layerName.indexOf('_') + 1);
        return x.get('query') === true && layerNameParsed === model.layer;
      });

      if (primaryRecordLayers.length > 0) {
        gsService.getFeatureInfo(evt.map, evt.pixel, primaryRecordLayers).then((resp) => {
          if (resp && Object.keys(resp).length !== 0) {
            const layerKeys = Object.keys(resp);
            const key = layerKeys.length > 0 ? layerKeys[0] : null;
            const features = key ? resp[key] : [];

            if (Array.isArray(features) && features.length > 0) {
              const feat = features[0];
              const { id } = feat.properties;
              onRecordSelect(Number(id));
            } else {
              onRecordSelect(0);
            }
          } else {
            onRecordSelect(0);
          }
        });
      } else {
        onRecordSelect(0);
      }
    }
  };

  const handleLayerSwitcherDOFClick = () => {
    setShowDOFLayer((prevState) => !prevState);
  };

  const handleLayerSwitcherObjektiClick = () => {
    setShowObjektiLayer((prevState) => !prevState);
  };

  useEffect(() => {
    const hiddenLayerIDsNew: string[] = [];
    if (!showDOFLayer) hiddenLayerIDsNew.push('dof');
    if (!showObjektiLayer) hiddenLayerIDsNew.push(model.layer || 'objekti');

    setHiddenLayerIDs(hiddenLayerIDsNew);
  }, [showDOFLayer, showObjektiLayer]);

  const handleClickResize = () => {
    if (onResize) {
      onResize();
    }
  };

  return layersCollection ? (
    <Map
      height="500px"
      view={viewOptions}
      onClick={handleClick}
      className="map"
      id="mini-map"
      zoomToExtent={
        recordExtent && recordExtent.length ? recordExtent : undefined
      }
      zoomToExtentPadding={[20, 20, -20, -20]}
      initialized={mapInitialized}
    >
      <Controls>
        <ZoomControl zoomInTipLabel={`${t('map:controls.zoom_in')}`} zoomOutTipLabel={`${t('map:controls.zoom_out')}`} />
        <RotateControl autoHide={false} />
        <ScaleLineControl type="minimap" />
        <GeoBaseLayerSwitcher type="minimap" />
        <ZoomToExtentControl
          id="zoom-extent-default"
          extent={defaultExtent}
          tipLabel={`${t('map:controls.zoom_to_extent')}`}
          className="ol-control ol-zoom-extent"
        />
        <ZoomToExtentControl
          id="zoom-extent-selected"
          extent={recordExtent}
          tipLabel={`${t('map:controls.zoom_to_selected')}`}
          className="ol-control ol-zoom-selected"
        />
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {allowDOF && (
            <MapButton
              id="layerswitcher-dof"
              className="ol-layerswitcher-dof"
              handleClick={handleLayerSwitcherDOFClick}
              title={t('map:layerswitcher.toggle_dof') as string}
              active={showDOFLayer}
            >
              <i className="fas fa-camera" />
            </MapButton>
          )}
          {allowHideObjects && (
            <MapButton
              id="layerswitcher-objekti"
              className="ol-layerswitcher-objekti"
              handleClick={handleLayerSwitcherObjektiClick}
              title={t('map:layerswitcher.toggle_objekti') as string}
              active={showObjektiLayer}
            >
              <i className="fas fa-vector-square" />
            </MapButton>
          )}
          {onResize && mdUp ? (
            <MapButton id="button-resize" className="ol-button-resize" handleClick={handleClickResize}>
              <i className={isResized ? 'fas fa-angle-double-down' : 'fas fa-angle-double-up'} />
            </MapButton>
          ) : null}
        </Box>
      </Controls>
      <Layers>
        <GeoAPILayers layersCollection={layersCollection} hiddenIDs={hiddenLayerIDs} />
        <VectorLayer id="selected" source={selectedSource} style={selectedLayerStyle} zIndex={950} />
      </Layers>
    </Map>
  ) : null;
};

export default PhotoCentricMiniMap;
