// Custom components
import useApi from '@/lib/api/useApi';

// Types
import {
  ActivateData,
  DeactivateData,
} from '@/@types/services/userService';

export interface IMapLayerService {
  activate: (id: number) => Promise<ActivateData>;
  deactivate: (id: number) => Promise<DeactivateData>;
}

const userApiUrl = 'system/map_layers/';

function activate(id: number): Promise<ActivateData> {
  const api = useApi();
  const url = `${userApiUrl + id.toString()}/activate`;

  return api.patch(url);
}

function deactivate(id: number): Promise<DeactivateData> {
  const api = useApi();
  const url = `${userApiUrl + id.toString()}/deactivate`;

  return api.patch(url);
}

export const mapLayerService: IMapLayerService = {
  activate,
  deactivate,
};
