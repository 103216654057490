/* eslint-disable */
import * as React from "react";
import { FunctionComponent } from "react";

const Overlays: FunctionComponent<any> = (props) => {

  if (React.Children.count(props.children) > 0) {
    return (
      <div id="overlays" style={{ display: "none" }}>{props.children}</div>
    );
  } else {
    return null;
  }
};

export default Overlays;

