/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

//Material UI Core Elements
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme, useMediaQuery } from '@mui/material';

//Material UI Icons
import AssignmentIcon from '@mui/icons-material/Assignment';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

//Custom Components
import CoordZoomStatusRoot from './CoordZoomStatusRoot';
import MapContext from '@/context/MapContext/MapContext';

//Openlayers
import { toStringHDMS as OlToStringHDMS } from 'ol/coordinate';
import { transform } from 'ol/proj';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import {
  ICordZoomStatusControl,
  CoordinateSystemType,
} from '@/@types/components/Map/Controls/CordZoomStatus';
import { MapEvent } from 'ol';

const CoordZoomStatusControl: FunctionComponent<ICordZoomStatusControl> = (
  props
) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { t } = useTranslation();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [center, setCenter] = useState<number[] | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);

  const { chosenCoordinateSystem } = props;

  // const [chosenCoordinateSystem, setChosenCoordinateSystem] = useState<CoordinateSystemType>("WGS84");

  useEffect(() => {
    if (mapContext.map) {
      mapContext.map.on('moveend', handleMoveEnd);
    }

    return () => {
      if (mapContext.map) {
        mapContext.map.un('moveend', handleMoveEnd);
      }
    };
  }, [mapContext.map]);

  const handleMoveEnd = (evt: MapEvent) => {
    const view = evt.map.getView();
    if (view) {
      setCenter(view.getCenter());
      setZoom(view.getZoom());
    }
  };

  const fontSize = 14;

  const displayCoords = (
    type: 'HTRS96/TM' | 'WGS84'
  ): string => {
    if (center) {
      switch (type) {
        case 'HTRS96/TM':
          let htrs_array = transform(center, 'EPSG:3857', 'EPSG:3765');
          return mdUp ? htrs_array[0].toFixed(2) + ', ' + htrs_array[1].toFixed(2) :  htrs_array[0].toFixed(2) + '\n' + htrs_array[1].toFixed(2)
        case 'WGS84':
          let wgs_string = OlToStringHDMS(transform(center, 'EPSG:3857', 'EPSG:4326'), 2);
          let wg_string_split = wgs_string.split(' ')
          return mdUp ? OlToStringHDMS(transform(center, 'EPSG:3857', 'EPSG:4326'), 2) : wg_string_split.slice(0,4).join(" ") + '\n' + wg_string_split.slice(4,8).join(" ");
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  const copyCoordinates = (): void => {
    navigator.clipboard.writeText(
      displayCoords(chosenCoordinateSystem) as string
    );
  };

  const textCenter = `${displayCoords(
    chosenCoordinateSystem
  )} `;
  const textZoom = `${
    zoom ? zoom.toFixed(1).toString() : ''
  }`;

  return (
    //@ts-ignore TODO: ref is missing in type ICordZoomStatusRoot, probably need to pass down ref
    <CoordZoomStatusRoot>
      <Box m={0} sx={{ gap: "4px", display: "flex", alignItmes: "center", height: "100%", py: "1px"}}>
        <Tooltip title={t('coordzoomstatus.copy') as string}>
          <Box onClick={copyCoordinates} display={"flex"} alignItems={"center"} gap={"2px"} sx={{cursor: "pointer"}}>
            <CenterFocusStrongIcon fontSize='small' sx={{transform: "scale(0.85)"}}/>
            <Typography variant="caption" lineHeight={1.1} whiteSpace={"pre-line"}>
              {textCenter}
            </Typography>
          </Box>
        </Tooltip>
        <Box display={"flex"} alignItems={"center"} gap={"1px"}>
          <ZoomInIcon fontSize='small'/>
          <Typography variant="caption" lineHeight={1.1} whiteSpace={"pre-line"}>
            {textZoom}
          </Typography>
        </Box>
      </Box>
    </CoordZoomStatusRoot>
  );
};

export default CoordZoomStatusControl;
