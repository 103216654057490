/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, ReactNode, useContext } from 'react';

// MUI
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom components
import SidebarContext, {
  SidebarContextType,
} from '@/context/SidebarContext/SidebarContext';
import UserContext from '@/context/UserContext/UserContext';

// Types
import { Permission } from '@/@types/common';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';
import { useTranslation } from 'react-i18next';

export interface ISidebarTabListItem {
  id: string;
  icon: ReactNode;
  disabled?: boolean;
  title: string;
  needPermission: Permission[];
  onChange?: (id: string) => void;
  onSidebarCollapse?: ( ) => void;
}

const StyledLi = styled('li')(({ theme }) => ({
  width: '100%',
  height: 40,
  color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light),
  fontSize: '12pt',
  overflow: 'hidden',
  transition: 'all 80ms',
  ':hover': {
    color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light),
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DisabledLi = styled(StyledLi)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.4)',
  ':hover': {
    backgroundColor: 'transparent',
  },
}));

const ActiveLi = styled(StyledLi)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledA = styled('a')(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  lineHeight: '40px',
  color: 'inherit',
  textDecoration: 'none',
  textAlign: 'center',
}));

const DisabledA = styled(StyledA)(() => ({
  cursor: 'default',
}));

const SidebarTabListItem: FunctionComponent<ISidebarTabListItem> = (props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const { id, icon, disabled, title, onChange, needPermission, onSidebarCollapse } = props;

  const sideBarContext = useContext(SidebarContext) as SidebarContextType;
  const userContext = useContext(UserContext);

  const handlePaneChange = (clickedId: string): void => {
    if (!disabled) {
      // userContext.setUserSetting({map_sidebar_pane: id});
      sideBarContext.setActivePane(clickedId);
      sideBarContext.setSidebarHeading(title);
      if (onSidebarCollapse) {
        sideBarContext.setOnSidebarCollapse(onSidebarCollapse);
      }
      

      if (onChange) {
        onChange(clickedId);
      }
    }
  };

  const isActive = sideBarContext.activePane === id;

  const classNames = [];
  if (disabled) {
    classNames.push('disabled');
  }
  if (isActive) {
    classNames.push('active');
  }
  const joinedClassNames = classNames.length > 0 ? classNames.join(' ') : '';

  const LI = disabled ? DisabledLi : isActive ? ActiveLi : StyledLi;
  const A = disabled ? DisabledA : StyledA;

  if (userContext?.hasAnyPermission(needPermission)) {
    return (
      <LI className={joinedClassNames} title={t(title) as string}>
        <A href={`#${id}`} role="tab" onClick={() => handlePaneChange(id)}>
          {icon}
        </A>
      </LI>
    );
  }
  return null;
};

export default SidebarTabListItem;
