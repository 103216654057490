/* eslint-disable */
import React from 'react';

// Material-UI Components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {
  SelectFilterWrapperFn,
  PickerValue,
  FilterFn,
  ISelectFilter,
} from '@/@types/ui/Table';

// Types
import { IDataControllerSub } from '@/@types/lib/dataControllerSub';
import { DCRecord } from '@/@types/lib/dataController';
import { PickerItem } from '@/@types/controls/controls';
import { PickerItemValue } from '@/@types/models/model';

/**
 * Filter wrapper function that returns the AutocompleteColumnFilter component
 *
 * @param {ISubModelsWithData} subModelsWithData - array of sub models filed with data
 * @param {IPickerItems} fieldItems - picker items
 * @param {TFunction} t - translation function
 *
 * @returns The AutocompleteColumnFilter component
 */
const PickerColumnFilter: SelectFilterWrapperFn = (
  subModelsWithData,
  fieldItems,
  t
) => {
  const AutocompleteFn: FilterFn<ISelectFilter> = (filter) => {
    const { column } = filter;
    const { id, filterValue, setFilter, preFilteredRows } = column;

    const handleChange = (event: any, newValue: PickerItem | null) => {
      setFilter(newValue ? newValue.value : undefined);
    };

    const options = (() => {
      const optionSet = new Set<PickerItem>();

      if (fieldItems) {
        fieldItems.labels.forEach((label, index) => {
          const foundValue = preFilteredRows?.find(
            (row: DCRecord) =>
              row[id] === fieldItems.values[index] ||
              (row.hasOwnProperty('odstupanje') &&
                row['odstupanje'] === fieldItems.values[index])
          );

          if (foundValue !== undefined) {
            if (fieldItems.icons) {
              optionSet.add({
                color: 'primary',
                icon: fieldItems.icons[index],
                value: fieldItems.values[index],
                label,
              });
            } else {
              optionSet.add({
                value: fieldItems.values[index],
                label,
              });
            }
          }
        });
      } else if (subModelsWithData && subModelsWithData[id]) {
        const { records } = subModelsWithData[id];
        records.forEach((record) => {
          const foundValue = preFilteredRows?.find((row: DCRecord) => {
            const rowValue = row[id] as PickerItemValue;
            return Array.isArray(rowValue)
              ? rowValue.includes(record.value)
              : rowValue === record.value;
          });

          if (foundValue !== undefined) {
            optionSet.add(record);
          }
        });
      }

      return Array.from(optionSet);
    })();

    return (
      <Autocomplete
        noOptionsText={t("core:common.no_options")}
        options={options}
        getOptionLabel={(option) => t(option.label)}
        value={options.find((opt) => opt.value === filterValue) || null}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    );
  };

  return AutocompleteFn;
};

export default PickerColumnFilter;
