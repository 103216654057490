import * as React from 'react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from '@mui/material';

import OlCollection from 'ol/Collection';
import OlBaseLayer from 'ol/layer/Base';
import gsService from '@/services/gsService';
import { flattenLayers } from '@/lib/olHelpers';

// Types
export interface ILegendPane {
  layersCollection: OlCollection<OlBaseLayer>;
}

const LegendPane: FunctionComponent<ILegendPane> = (props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme() as any;

  const { layersCollection } = props;

  const bgColor = theme.palette.background.default.substring(1);
  const fontColor = theme.palette.mode === 'light' ? '000000' : 'FFFFFF';

  return (
    <div>
      {layersCollection
        ? flattenLayers(layersCollection.getArray(), 5).map((layer: any) =>
            layer.getProperties().legend ? (
              layer.getProperties().legend_ttoken ? (
              <Box key={`legend-${layer.getProperties().id}`}>
                <Typography variant="h6">
                  {t(layer.getProperties().legend_ttoken)}
                </Typography>
                <img
                  src={gsService.getLegend(
                    layer.getProperties(),
                    i18n.language,
                    bgColor,
                    fontColor
                  )}
                  alt=""
                />
              </Box>
            ) : (
              <Box key={`legend-${layer.getProperties().id}`}>
                <Typography variant="h6">
                  {t(layer.getProperties().title)}
                </Typography>
                <img
                  src={gsService.getLegend(
                    layer.getProperties(),
                    i18n.language,
                    bgColor,
                    fontColor
                  )}
                  alt=""
                />
              </Box>
              )
            ) : null
          )
        : null}
    </div>
  );
};

export default LegendPane;
