/* eslint-disable */
import React, {
  useState,
  useContext,
  FunctionComponent,
  SyntheticEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI Core Components
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Material-UI Icons
import PublicIcon from '@mui/icons-material/Public';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Custom Components
import Icon from '@mui/material/Icon';
import useFormController from '@/components/useFormController';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import FormAvatar from '@/ui/Form/FormAvatar';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import DialogToolbarMenuButton from '@/ui/Dialog/DialogToolbarMenuButton';
import DialogContext from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import { GridContainer, GridItem } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import FormTabs from '@/ui/Form/FormTabs';
import { FormTab, FormTabPanel } from '@/ui/Form';
import DialogActionButtonClose from '@/ui/Dialog/ActionButtons/DialogActionButtonClose';
import DialogActionButtonAdd from '@/ui/Dialog/ActionButtons/DialogActionButtonAdd';
import DialogActionButtonSave from '@/ui/Dialog/ActionButtons/DialogActionButtonSave';
import DialogActionButtonCancel from '@/ui/Dialog/ActionButtons/DialogActionButtonCancel';
import DialogActionButtonConfirm from '@/ui/Dialog/ActionButtons/DialogActionButtonUpload';
import DialogSubHeading from '@/ui/Dialog/DialogSubHeading';
import DialogToolbarMenuItemActivate from '@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemActivate';
import DialogToolbarMenuItemCopy from '@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy';
import DialogToolbarMenuItemDraft from '@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDraft';
import DialogToolbarCreationDetails from '@/ui/Dialog/DialogToolbarCreationDetails';
import DynamicDialog from '@/ui/Dialog/DynamicDialog';

// Types
import {
  FormControllerProps,
  ClosingDetails,
} from '@/@types/components/formController';
import { IDialogProps } from '@/context/DialogContext/DialogContext';
import { FormContentColumns } from '@/components/FormContent';

const TestDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(1);

  const { dc, form, mode, onClose } = props;
  const {
    record,
    validation,
    fields,
    pickerDataControllers,
    doInsert,
    doUpdate,
    doClose,
    onFieldChange,
    dataChanged,
  } = useFormController(props as FormControllerProps);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const handleInsert = (evt: SyntheticEvent) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: 'insert' });
        } else if (result.error) {
          const msg = `Greška prilikom dodavanja novog zapisa! ${result.error}`;
          snackbarContext.showNotification(msg, 'error');
        } else if (result.validationPass === false) {
          // do nothing
        }
      });
    }
  };

  const handleUpdate = (evt: SyntheticEvent) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: 'update' });
        } else if (result.error) {
          const msg = `Greška prilikom ažuriranja podataka! ${result.error}`;
          snackbarContext.showNotification(msg, 'error');
        } else if (result.validationPass === false) {
          // do nothing
        }
      });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: false, action: 'cancel' });
          }
        })
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {};

  const handleDeactivate = (evt: SyntheticEvent) => {};

  const handleAnonymize = (evt: SyntheticEvent) => {};

  const commonProps = {
    fieldNames: dc.getFormFieldsNames(form),
    record,
    validation,
    fields,
    onFieldChange,
    mode,
    columns: 1 as FormContentColumns,
    pickerDataControllers,
  };

  const close = (result: ClosingDetails) => {
    onClose(result);
    dialogContext.hideDialog();
  };

  return (
    <DynamicDialog open maxWidth="lg" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <FormAvatar iconWord="adb" ariaLabel="adb" />
          <DialogToolbarHeading
            header={`${t('titles.test_dialog')}`}
            subheader={`${t('titles.test_dialog')}`}
          />
          <ToolbarFillContent />
          <DialogToolbarCreationDetails record={record} />
          <DialogToolbarMenuButton>
            <DialogToolbarMenuItemActivate onClick={handleClose} />
            <DialogToolbarMenuItemCopy onClick={handleClose} />
            <DialogToolbarMenuItemDraft onClick={handleClose} />
          </DialogToolbarMenuButton>
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={0}
            label={t('test_page.forms.sample_form_1.tabs.basic')}
            icon={<PublicIcon />}
            validation={validation}
            fields={[]}
          />
          <FormTab
            value={1}
            label={t('test_page.forms.sample_form_1.tabs.extra')}
            icon={<PublicIcon />}
            validation={validation}
            fields={[
              'desc',
              'date',
              'date_time',
              'amount',
              'illegal',
              'features',
              'regions',
              'species',
              'names',
              'email',
              'wholenum',
            ]}
          />
          <FormTab
            value={2}
            label={t('test_page.forms.sample_form_1.tabs.epic')}
            icon={<PublicIcon />}
            validation={validation}
            fields={[]}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer>
            <GridItem>
              <Typography>1. tab - polja su na 2. tabu</Typography>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <GridContainer>
            <FormContent
              title={`${t('test_page.forms.sample_form_1.sections.extra')}`}
              {...commonProps}
            />
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <GridContainer>
            <GridItem>
              <Typography>3. tab - polja su na 2. tabu</Typography>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        <DialogActionButtonCancel variant="contained" onClick={handleClose} />
        <DialogActionButtonConfirm variant="contained" onClick={handleClose} />
        <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
      </DialogActions>
    </DynamicDialog>
  );
};

export default TestDialog;
