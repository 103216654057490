import * as React from 'react';
import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { useTranslation } from 'react-i18next';

// Openlayers
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFeature from 'ol/Feature';
import OlFormatWKT from 'ol/format/WKT';
import { ProjectionLike } from 'ol/proj';

// MUI
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

// MUI Icons
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from '@mui/icons-material/Search';
import ExploreIcon from '@mui/icons-material/Explore';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

// Context
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';
import DataContext, {
  DataContextType,
} from '@/context/DataContext/DataContext';
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';

// Lib
import DataController from '@/lib/DataController';

// Models
import modelToponimi from '@/models/toponimi';
import modelToponimTipovi from '@/models/toponim_tipovi';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IMenuState } from '@/@types/ui/Table';

export interface SearchPaneProps {
  setSelectedSource: Dispatch<React.SetStateAction<OlSourceVector<Geometry>>>;
  searchUsingMW: boolean;
  searchItemsListDisplayLimit?: number;
}

const SearchPane: FunctionComponent<any> = (props: SearchPaneProps) => {
  const { setSelectedSource, searchUsingMW, searchItemsListDisplayLimit } =
    props;

  const { t } = useTranslation();
  const mapContext = useContext(MapContext);
  const { map } = mapContext as MapContextType;
  const dataContext = useContext(DataContext);
  const { searchData, setSearchData } = dataContext as DataContextType;
  const tenantContext = useContext(TenantContext);
  const { mapSettings } = tenantContext as TenantContextType;

  const theme = useTheme();
  const dc = new DataController(modelToponimi);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>('');
  const [records, setRecords] = useState<DCRecord[]>([]);
  const [results, setResults] = useState<DCRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [toponimTipovi, setToponimTipovi] = useState<Array<DCRecord>>([]);
  const [menuState, setMenuState] = useState<IMenuState>({
    open: false,
    anchorEl: null,
  });
  const [hiddenTypes, setHiddenTypes] = useState<Array<Number> | null>(null);
  const [toponimTipoviDisplayLimits, setToponimTipoviDisplayLimits] = useState<{
    [key: number]: number;
  } | null>(null);

  // #region useEffect

  // Load toponim tipovi
  useEffect(() => {
    loadToponimTipovi();
  }, []);

  // Load hidden types from storage
  useEffect(() => {
    if (toponimTipovi.length) {
      loadHiddenTypesFromStorage();
    }
  }, [toponimTipovi]);

  // Initialize toponimTipoviDisplayLimits array for each type to searchItemsListDisplayLimit
  useEffect(() => {
    refreshToponimTipoviDisplayLimits();
  }, [toponimTipovi]);

  // Load toponimi from dataContext
  useEffect(() => {
    if (
      searchData &&
      searchData !== null &&
      searchData.length !== 0 &&
      searchUsingMW === false
    ) {
      setRecords(searchData);
    }
  }, [searchData]);

  // Load toponimi if FE loading; first check dataContext for caching
  useEffect(() => {
    // dont search if data exists in context
    if (
      searchData &&
      searchData !== null &&
      searchData.length !== 0 &&
      searchUsingMW === false
    ) {
      return;
    }
    if (
      toponimTipovi &&
      toponimTipovi !== null &&
      toponimTipovi.length > 0 &&
      searchUsingMW === false
    ) {
      reloadToponimi();
    }
  }, [toponimTipovi]);

  // Focus search upon entry into component
  useEffect(() => {
    if (
      !(
        (records.length === 0 && searchUsingMW === false) ||
        toponimTipovi.length === 0
      ) &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [records, searchUsingMW, toponimTipovi]);

  // Filter results
  useEffect(() => {
    if (value.length === 0) {
      refreshToponimTipoviDisplayLimits();
    }
    if (value.length >= 3) {
      if (searchUsingMW === true) {
        const timer = setTimeout(() => {
          reloadToponimi(value);
        }, 500);

        return () => {
          clearTimeout(timer);
        };
      }
      const filtered = filterResults();
      setResults(filtered);
    } else {
      setResults([]);
    }

    return () => {};
  }, [value, searchUsingMW]);

  // Save hiddenTypes to localstorage upon user settings change
  useEffect(() => {
    if (Array.isArray(hiddenTypes)) {
      saveHiddenTypesToStorage();
    }
  }, [hiddenTypes]);

  // #endregion

  // #region Functions

  const refreshToponimTipoviDisplayLimits = () => {
    if (toponimTipovi && toponimTipovi !== null) {
      const res: { [key: number]: number } = {};
      toponimTipovi.forEach((toponimTip) => {
        res[toponimTip.id as number] = searchItemsListDisplayLimit || 20;
      });
      setToponimTipoviDisplayLimits(res);
    }
  };

  const loadToponimTipovi = () => {
    const dcTipovi = new DataController(modelToponimTipovi);

    dcTipovi.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setToponimTipovi(resp.data || []);
        } else {
          setToponimTipovi([]);
        }
      }
    });
  };

  const loadHiddenTypesFromStorage = () => {
    try {
      const hiddenTypesStringified = localStorage.getItem(
        'hidden_search_toponyms'
      );
      if (hiddenTypesStringified) {
        const parsedHiddenTypes = JSON.parse(hiddenTypesStringified);
        // Validate that parsedHiddenTypes is an array of numbers
        if (
          Array.isArray(parsedHiddenTypes) &&
          parsedHiddenTypes.every((x) => typeof x === 'number')
        ) {
          const resultingHiddenTypes = toponimTipovi
            .slice()
            .filter((x) => parsedHiddenTypes.indexOf(x.id as number) !== -1)
            .map((x) => x.id as number);
          setHiddenTypes(resultingHiddenTypes);
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'Invalid hiddenTypes data in localStorage, resetting to default.'
          );
          setHiddenTypes([]);
        }
      } else {
        setHiddenTypes([]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing hiddenTypes from localStorage', error);
      setHiddenTypes([]); // Fallback to empty array in case of error
    }
  };

  const saveHiddenTypesToStorage = () => {
    try {
      localStorage.setItem(
        'hidden_search_toponyms',
        JSON.stringify(hiddenTypes)
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving hiddenTypes to localStorage', error);
    }
  };

  const filterResults = () => {
    const valueDeacc = deaccent(value as string);
    const res = records.filter((x) => {
      const naziv = x.naziv as string;
      const opis = x.opis as string;
      if (naziv) {
        let found = false;
        if (opis && opis !== null && opis !== '') {
          found =
            deaccent(naziv).indexOf(valueDeacc) >= 0 ||
            deaccent(opis).indexOf(valueDeacc) >= 0;
        } else {
          found = deaccent(naziv).indexOf(valueDeacc) >= 0;
        }
        if (found) {
          return true;
        }
        return false;
      }
      return false;
    });
    return res;
  };

  const reloadToponimi = (searchString?: string) => {
    // Load only searched
    if (searchString) {
      setLoading(true);
      dc.GetData(`core/toponimi-search/${value}`)
        .then((resp) => {
          if (resp.success) {
            if (Array.isArray(resp.data)) {
              setRecords(resp.data || []);
              setResults(resp.data);
            } else {
              setRecords([]);
              setResults([]);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
      // Load all
    } else {
      setLoading(true);
      dc.GetData()
        .then((resp) => {
          if (resp.success) {
            if (Array.isArray(resp.data)) {
              setRecords(resp.data || []);
              if (setSearchData) {
                setSearchData(resp.data);
              }
            } else {
              setRecords([]);
              if (setSearchData) {
                setSearchData([]);
              }
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deaccent = (val: string) => {
    let r = val.toLowerCase();
    r = r.replace(/[čć]/g, 'c');
    r = r.replace(/[ž]/g, 'z');
    r = r.replace(/[š]/g, 's');
    r = r.replace(/[đ]/g, 'd');

    return r;
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    setValue(val);
  };

  const handleExplore = (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: DCRecord
  ) => {
    const { wkt } = item;
    if (wkt) {
      const proj = item.proj || 'EPSG:3765';
      const wktFormatter = new OlFormatWKT();
      const feat = wktFormatter.readFeature(wkt, {
        // dataProjection: "EPSG:3765" // piece of shit code luka // sorry luka comment was made in the heat of the moment
        dataProjection: proj as ProjectionLike,
        featureProjection: 'EPSG:3857',
      });

      const searchSource = new OlSourceVector({
        features: [feat] as OlFeature<Geometry>[],
      });
      setSelectedSource(searchSource);

      setTimeout(() => {
        const extent = feat.getGeometry()?.getExtent();
        if (map) {
          const view = map.getView();
          if (extent)
            view.fit(extent, {
              padding: [10, 10, 10, 220],
              duration: 500,
              maxZoom:
                mapSettings && mapSettings?.map_max_view_zoom
                  ? mapSettings.map_max_view_zoom
                  : 20,
            });
        }
      }, 10);
    }
  };

  const handleOpenSearchSettings = (
    evt: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  };

  const handleCloseSearchSettings = () => {
    setMenuState({ open: false, anchorEl: null });
  };

  const handleToggleToponimTip = (evt: any, tipId: number) => {
    if (hiddenTypes) {
      if (hiddenTypes.includes(tipId)) {
        setHiddenTypes(hiddenTypes.filter((x) => x !== tipId));
      } else {
        const newHiddenTypes = hiddenTypes.concat([tipId]);
        setHiddenTypes(newHiddenTypes);
      }
    }
  };

  const loadMoreItems = (toponimTipId: number) => {
    if (
      toponimTipoviDisplayLimits !== null &&
      toponimTipoviDisplayLimits[toponimTipId] &&
      toponimTipoviDisplayLimits[toponimTipId] !== null
    ) {
      setToponimTipoviDisplayLimits((prevToponimTipoviDisplayLimits) => ({
        ...prevToponimTipoviDisplayLimits,
        // If toponimTipId doesnt exist, set it to searchItemsListDisplayLimit; then add searchItemsListDisplayLimit amount; if searchItemsListDisplayLimit is undefined, use 20
        [toponimTipId]:
          toponimTipoviDisplayLimits[toponimTipId] +
          (searchItemsListDisplayLimit || 20),
      }));
    }
  };

  const icons: { [key: string]: React.ReactNode } = {
    '1': <HomeIcon color="primary" />,
    '2': <AccountBoxIcon color="primary" />,
    '3': <LanguageIcon color="primary" />,
    '4': <RequestPageIcon color="primary" />,
    default: <TravelExploreIcon color="primary" />,
  };

  // #endregion

  return (
    <>
      <TextField
        inputRef={inputRef}
        sx={{ padding: '10px 20px' }}
        value={value}
        onChange={handleChange}
        disabled={
          (records.length === 0 && searchUsingMW === false) ||
          toponimTipovi.length === 0
        }
        fullWidth
        placeholder={t('map:sidebar.search_placeholder') as string}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? <CircularProgress size={20} /> : <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {loading || toponimTipovi.length === 0 ? null : (
                <>
                  <IconButton onClick={handleOpenSearchSettings}>
                    <SettingsIcon />
                  </IconButton>
                  <Menu
                    open={menuState.open}
                    onClose={handleCloseSearchSettings}
                    anchorEl={menuState.anchorEl}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                  >
                    {toponimTipovi.map((tip, i) => (
                      <MenuItem
                        value={i}
                        onClick={(evt) =>
                          handleToggleToponimTip(evt, tip.id as number)
                        }
                        key={`search-menu-item-${tip.id}`}
                      >
                        <Switch
                          color="primary"
                          checked={
                            hiddenTypes
                              ? !hiddenTypes.includes(tip.id as number)
                              : true
                          }
                          value={i}
                        />
                        <Typography
                          variant="caption"
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: 600,
                          }}
                        >
                          {t(`map:toponimi.${tip.toponim_tip}` as string)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </InputAdornment>
          ),
        }}
      />
      {results ? (
        <List
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {toponimTipovi
            .filter(
              (toponimTip) => !hiddenTypes?.includes(toponimTip.id as number)
            )
            .map((toponimTip) => (
              <li key={`section-${toponimTip.id}`}>
                <ul>
                  {results.filter((x) => x.toponim_tip_id === toponimTip.id)
                    .length > 0 ? (
                    <ListSubheader>
                      {`${t(
                        `map:toponimi.${toponimTip.toponim_tip}` as string
                      )} (${
                        results.filter(
                          (x) => x.toponim_tip_id === toponimTip.id
                        ).length
                      })`}
                    </ListSubheader>
                  ) : null}

                  {toponimTipoviDisplayLimits &&
                  toponimTipoviDisplayLimits !== null &&
                  toponimTipoviDisplayLimits[toponimTip.id as number] ? (
                    <>
                      {results
                        .filter((x) => x.toponim_tip_id === toponimTip.id)
                        .slice(
                          0,
                          toponimTipoviDisplayLimits[toponimTip.id as number]
                        )
                        .map((item) => (
                          <ListItem
                            key={`item-${toponimTip.id}-${item.id}`}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="explore"
                                onClick={(evt) => handleExplore(evt, item)}
                              >
                                <ExploreIcon color="primary" />
                              </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  backgroundColor:
                                    theme.palette.background.default,
                                }}
                              >
                                {icons[toponimTip.id as string] !== undefined &&
                                icons[toponimTip.id as string] !== null
                                  ? icons[toponimTip.id as string]
                                  : icons.default}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${item.naziv}`}
                              secondary={`${item.opis}`}
                            />
                          </ListItem>
                        ))}

                      {results.filter((x) => x.toponim_tip_id === toponimTip.id)
                        .length >
                      (toponimTipoviDisplayLimits[
                        toponimTip.id as number
                      ] as number) ? (
                        <Button
                          onClick={() => loadMoreItems(toponimTip.id as number)}
                          variant="contained"
                          size="small"
                          sx={{
                            width: '80%',
                            textAlign: 'center',
                            transform: 'translate(10%,0)',
                          }}
                        >
                          {t('buttons.show_more')}
                        </Button>
                      ) : null}
                    </>
                  ) : null}
                </ul>
              </li>
            ))}
        </List>
      ) : null}
    </>
  );
};

export default SearchPane;
