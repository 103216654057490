import { ModuleCardType } from '@/@types/common';
import Permissions from '@/lib/permissions';

import parkingImg from '@/images/parking.jpg';
import mapImg from '@/images/map.jpg'
import enaImg from '@/images/ena.jpg'
import threeDImg from '@/images/3d.jpg'
import grobljaImg from '@/images/groblja.jpg'
import ncImg from '@/images/nc.jpg'
import rasvjetaImg from '@/images/rasvjeta.jpg'


const modulesRoutes: ModuleCardType[] = [
  {
    path: '/map',
    name: 'map',
    titleTtoken: 'modules.title.map',
    icon: 'map',
    needPermission: [Permissions.Core],
    imageSrc: mapImg,
    descriptionTtoken: "modules.description.map"
  },
  {
    path: '/ena/utility',
    name: 'ENA',
    titleTtoken: 'modules.title.ena',
    icon: 'house',
    needPermission: [Permissions.Ena],
    imageSrc: enaImg,
    descriptionTtoken: "modules.description.ena"
  },
  {
    path: '/3d',
    name: 'threed',
    titleTtoken: 'modules.title.threed',
    icon: 'public',
    needPermission: [Permissions.ThreeDView],
    imageSrc: threeDImg,
    descriptionTtoken: "modules.description.threed"
  },
  {
    path: '/rasvjeta/mjesta',
    name: 'Rasvjeta',
    titleTtoken: 'menu.rasvjeta',
    icon: 'light',
    needPermission: [Permissions.Rasvjeta],
    imageSrc: rasvjetaImg,
    descriptionTtoken: "modules.description.rasvjeta"
  },
  {
    path: '/groblja/grobna-mjesta',
    name: 'Groblja',
    titleTtoken: 'modules.title.groblja',
    icon: 'church',
    needPermission: [Permissions.Groblja],
    imageSrc: grobljaImg,
    descriptionTtoken: "modules.description.groblja"
  },
  {
    path: '/nerazvrstane-ceste',
    name: 'Nerazvrstane ceste',
    titleTtoken: 'menu.nerazvrstane_ceste',
    icon: 'remove_road',
    needPermission: [Permissions.NerazvrstaneCeste],
    imageSrc: ncImg,
    descriptionTtoken: "modules.description.nerazvrstane-ceste"
  },
  {
    path: '/parking',
    name: 'Parking',
    titleTtoken: 'menu.parking',
    icon: 'remove_road',
    needPermission: [Permissions.Parking],
    imageSrc: parkingImg,
    descriptionTtoken: "modules.description.parking"
  },
];

export default modulesRoutes;
