import { IModel } from '@/@types/models/model';
import coreFields from '@/models/helpers/coreFields';

const MapView: IModel = {
  title: 'Map Views',
  apiPath: 'core/maps/1/views',
  forms: {
    default: {
      fields: ['title', 'view_type'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'title', 'view_type', 'show_on_map', 'delete'],
      hidden: ['id', 'view_type'],
    },
  },
  fields: [
    {
      ...coreFields.id,
      filter: true,
    },
    {
      title: 'Map view name',
      source: 'title',
      ttoken: 'map:map_view.title',
      type: 'text',
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'Zoom level',
      source: 'zoom',
      type: 'numeric',
      ttoken: 'map:map_view.zoom',
    },
    {
      title: 'Center',
      source: 'center',
      type: 'text',
      ttoken: 'map:map_view.center',
    },
    {
      title: 'Show on map',
      source: 'show_on_map',
      type: 'button',
      ttoken: 'buttons.show_on_map',
      icon: 'fmd_good',
      action: 'show_on_map',
      padding: 'none',
      sort: false,
    },
    {
      title: 'Delete',
      source: 'delete',
      type: 'button',
      ttoken: 'buttons.delete',
      icon: 'delete_forever',
      action: 'delete',
      padding: 'none',
      sort: false,
    },
    {
      title: 'View type',
      source: 'view_type',
      ttoken: 'map:map_view.view_type',
      type: 'picker',
      items: {
        labels: ['map:map_view.personal', 'map:map_view.shared'],
        values: ['U', 'T']
      },
      validation: {
        required: true,
      },
      translate: true
    },
  ],
};

export default MapView;
