/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';
import { IDataController } from '@/lib/DataController';

// Material UI Core

// Material UI Icons

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import DialogContext from '@/context/DialogContext/DialogContext';
import LoaderContext from '@/context/LoaderContext/LoaderContext';
import MapLegendsDialog from './MapLegendsDialog';
import MapLegendModel from '@/models/map_legend';
import dataController from '@/lib/DataController';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import { LoaderContextType } from '@/context/LoaderContext/LoaderContext';
import { useNavigate } from 'react-router-dom';

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them
let asd = 0;
function MapLegendsTable() {
  const navigate = useNavigate();
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new dataController(MapLegendModel);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    dialogContext.showDialog(MapLegendsDialog, {
      dc: dc,
      mode: 'insert',
      form: 'insert',
      onClose: handleDialogClose,
    });
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = Object.keys(sel)[0]; // neven check if works
    navigate('/admin/map_legends/' + sel[selId].id);
  };

  const handleDialogClose = (result: {
    dataChanged: boolean;
    action: string;
    id: number;
  }) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    if (result.action === 'insert') {
      navigate(`/admin/map_legends/${result.id}`);
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            //@ts-ignore
            // const recs = new Array(100).fill({});
            // //@ts-ignore
            // resp.data.forEach((r, i) => { recs[i] = {...r} });
            // asd = resp.data.length;
            //@ts-ignore
            // setRecords(recs);
            setRecords(resp.data);
          } else {
            setRecords([]);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const fetchData = (limit: number, offset: number) => {
    for (let i = 0; i < limit; ++i) {
      records[offset * limit + i] = records[i % asd];
    }
    // setRecords(Array.from(records));
    //@ts-ignore
    return new Promise((r) => r());
  };

  return (
    <ModelTable
      title={t('admin:titles.map_legends')}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={true}
      allowAdd={true}
      allowColumnPicker={true}
      addLabel={t('buttons.new_m') as string}
      handleAdd={handleAdd}
      onRowAction={() => {}}
      allowRowAction={() => true}
      unselectRow={false}
      onDoubleClick={handleEdit}
    >
      <TableButtonEdit variant="contained" onClick={handleEdit} />
    </ModelTable>
  );
}

export default MapLegendsTable;
