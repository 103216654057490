/* eslint-disable */
import React, { useContext } from 'react';
import AppPage from '@/ui/AppPage/AppPage';
import Permissions from '@/lib/permissions';
import MainMap from '@/ui/Map/MainMap';

import UserContext, { UserContextType } from '@/context/UserContext/UserContext';

const MapPage = () => {

  const userContext = useContext(UserContext) as UserContextType;

  const map_id = userContext.hasAnyPermission([Permissions.Private]) ? 1 : 2;

  return (
    <AppPage titlett="menu.map" needPermission={[Permissions.Public, Permissions.Private]}>
      <MainMap map_id={map_id} />
    </AppPage>
  );
};

export default MapPage;
