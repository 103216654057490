/* eslint-disable */
import * as React from 'react';
import {
  FunctionComponent,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from 'react';

// MUI
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import { PopoverProps } from '@mui/material/Popover';

// MUI Icons
import MenuIcon from '@mui/icons-material/Menu';

// Custom Components
import HeaderIconButton from '@/ui/Header/HeaderIconButton';
import HeaderMenuItem from '@/ui/Header/HeaderMenuItem';

// Types
export interface IHeaderMenuIconButton {
  icon?: ReactNode;
  desc?: string;
  keepOpen?: boolean;
  toggleSidebar?: () => void;
  badgeColor?: 'primary' | 'secondary' | 'default' | 'error';
  badge?: ReactNode;
  children: ReactElement | ReactElement[];
  sx?: { [k: string]: any };
}

const HeaderMenuIconButton: FunctionComponent<IHeaderMenuIconButton> = (
  props
) => {
  const [anchorEl, setAnchorEl] =
    React.useState<PopoverProps['anchorEl']>(null);

  const { icon, desc, keepOpen, toggleSidebar, badge, badgeColor, ...rest } =
    props;

  const handleClick = (evt: SyntheticEvent): void => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const hookAction = (action: () => void): void => {
    if (!keepOpen) {
      setAnchorEl(null);
    }
    action();
  };

  const buildMenuItem = (node: ReactElement, index: number): ReactNode => {
    const { onClick, label, icon, needPermission } = node.props;
    return (
      <HeaderMenuItem
        onClick={() => hookAction(onClick)}
        label={label}
        icon={icon}
        key={index}
        needPermission={needPermission}
      />
    );
  };

  const menuItems = [];
  if (Array.isArray(props.children)) {
    props.children.forEach((child, i) => {
      if (child !== null && child !== undefined) {
        if ((child as ReactElement).type === HeaderMenuItem) {
          menuItems.push(buildMenuItem(child as ReactElement, i));
        } else {
          menuItems.push(child);
        }
      }
    });
  } else if (props.children !== null && props.children !== undefined) {
    menuItems.push(buildMenuItem(props.children, 1));
  }

  return (
    <>
      <HeaderIconButton
        // variant="outlined"
        onClick={
          toggleSidebar ||
          (menuItems && menuItems.length ? handleClick : () => {})
        }
        desc={desc}
        {...rest}
      >
        <Badge color={badgeColor} badgeContent={badge}>
          {icon || <MenuIcon />}
        </Badge>
      </HeaderIconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper': {
            backgroundColor: 'primary.main',
            color: 'common.white',
          },
          '.MuiMenu-list': {
            color: 'common.white',
            padding: '1px 0',
          },
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default HeaderMenuIconButton;
