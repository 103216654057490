/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';

// MUI
import { Input, SxProps, TextField, Tooltip, useTheme } from '@mui/material';

// OpenLayers
import { getDistance } from 'ol/sphere';
import { transform } from 'ol/proj';
import Map from 'ol/Map';
import View from 'ol/View';

// Custom components
import { MapEvent } from 'ol';
import MapContext from '@/context/MapContext/MapContext';
import ScaleRatioRoot from '@/components/Map/Controls/ScaleRatio/ScaleRatioRoot';

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IScaleRatioControl } from '@/@types/components/Map/Controls/ScaleRatio';
import { useTranslation } from 'react-i18next';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';


const ScaleRatioControl: FunctionComponent<IScaleRatioControl> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const [fieldValue, setFieldValue] = useState<string>('');

  const { t } = useTranslation();

  const theme = useTheme();

  const bgColor = theme.palette.primary.main;
  const textColor = getOptimalTextColorForBackgroundColor(bgColor);

  const { ppi } = props;

  useEffect(() => {
    if (mapContext && mapContext.map) {
      showScale();
    }
  }, [mapContext]);

  useEffect(() => {
    if (mapContext.map) {
      mapContext.map.on('moveend', handleMoveEnd);
    }

    return () => {
      if (mapContext.map) {
        mapContext.map.un('moveend', handleMoveEnd);
      }
    };
  }, [mapContext.map]);

  const handleFieldValue = (value: string): void => {
    const regex = /^1:\d*$/;
    if (regex.test(value) || value === "1:") {
      setFieldValue(value);
    }
    if (value === "") {
      setFieldValue("1:")
    }
  }

  const handleMoveEnd = (evt: MapEvent) => {
    const view = evt.map.getView();
    if (view) {
      const newZoom = evt.map.getView().getZoom();
      showScale();
    }
  };

  const getD = (map: Map): number | null => {
    const view = map.getView();
    const proj = view.getProjection();
    const center = view.getCenter();
    // @ts-ignore TODO: Type 'undefined' is not assignable to type 'number[]', function does not account for undefined
    const px = map.getPixelFromCoordinate(center);
    if (px !== null) {
      px[1] += 1;
      const coord = map.getCoordinateFromPixel(px);
      let d = getDistance(
        // @ts-ignore TODO: Type 'undefined' is not assignable to type 'number[]', function does not account for undefined
        transform(center, proj, 'EPSG:4326'),
        transform(coord, proj, 'EPSG:4326')
      );
      d *= ppi / 0.254;
      return d;
    }
    return null;
  };

  const showScale = (): void => {
    if (mapContext.map) {
      const d = getD(mapContext.map);
      if (d !== null) {
        handleFieldValue(formatScale(d * 10));
      } else {
        handleFieldValue(formatScale(null));
      }
    }
  };

  const formatScale = (d: number | null): string => {
    if (d === null) {
      return '1:???';
    }
    if (d > 100) {
      d = Math.round(d / 100) * 100;
    } else {
      d = Math.round(d);
    }
    return `1:${d.toString()}`;
  };

  const setScale = (value: any): void => {
    if (mapContext.map && value) {
      const view = mapContext.map.getView();
      let fac;
      if (value[1] === ':') {
        fac = value.split(':')[1];
        fac = parseInt(fac) / 10;
      } else {
        return setScale(formatScale(value));
      }
      const d = getD(mapContext.map);
      // @ts-ignore TODO: object is possibly undefined
      view.setResolution(view.getResolution() * (fac / d));
    }
    showScale();
  };

  const handleEnterKey = (evt: React.KeyboardEvent<HTMLDivElement>): void => {
    if (evt.key === 'Enter') {
      const inputValue = (evt.target as HTMLInputElement).value;
      if (inputValue === "1:") {
        setScale("1:400000");
      } else {
        setScale(inputValue);
      }
    }
  };


  return (
    <ScaleRatioRoot>
     <Tooltip 
      placement='top'
      title={t("map:controls.ratio")} 
      arrow
    >
      <TextField 
        sx={{
          width: "6em",
          '& .MuiInput-underline:hover': { borderBottomColor: textColor },
          '& .MuiInput-underline:before': { borderBottomColor: textColor }, // Default border color
          '& .MuiInput-underline:after': { borderBottomColor: textColor }, // Focused border color
          '& .MuiInput-underline:hover:before': { borderBottomColor: textColor }, // Hover border color
          '& .MuiInputBase-root': { color:  textColor}, // Text color
        }}
        InputProps={{sx:{fontWeight: "700", fontSize: "13px"}}}
        inputProps={{sx:{textAlign: "center"}}}
        variant='standard'
        size='small'
        value={fieldValue}
        onChange={(evt) => handleFieldValue(evt.target.value)}
        onKeyDown={(evt) => handleEnterKey(evt)}
      />
    </Tooltip>
    </ScaleRatioRoot>
  );
};

export default ScaleRatioControl;
