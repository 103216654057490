/* eslint-disable */
// JS imports
import Control from 'ol/control/Control';

class TabItemHtmlElement extends HTMLElement {
  constructor() { super(); }
  _sidebar!: Sidebar;
}

//TODO: Spent much time on this one, left as js
export default class Sidebar extends Control {

   _container!: HTMLDivElement;
   _tabitems!: Array<TabItemHtmlElement>;
   _panes!: Array<HTMLElement>;
   _closeButtons!: Array<HTMLButtonElement>;
   classList!: DOMTokenList;
   _sidebar!: Sidebar;

    constructor(opt_options: any) {

        let defaults = {
            element: null,
            position: 'left'
        }
        let i: number
        let child: HTMLElement;

        var options = Object.assign({}, defaults, opt_options);

        if (options.element == null) {
          return;
        }

        // var element = document.createElement("div");
        // element.id = "sidebar"
        // element.className = "sidebar collapsed";
        // element.appendChild(options.element);

        var element = options.element;

        // console.log(element);

        super({
            element: options.element,
            target: options.target
        });

        this._tabitems = [];

        // Attach .sidebar-left/right class
        element.classList.add('sidebar-' + options.position);

        // Find sidebar > div.sidebar-content
        for (i = element.children.length - 1; i >= 0; i--) {
            child = element.children[i];
            if (child.tagName === 'DIV' &&
                    child.classList.contains('sidebar-content')) {
                this._container = child as HTMLDivElement;
            }
        }

        // Find sidebar ul.sidebar-tabs > li, sidebar .sidebar-tabs > ul > li
        this._tabitems = element.querySelectorAll('ul.sidebar-tabs > li, .sidebar-tabs > ul > li') as TabItemHtmlElement[];
        for (i = this._tabitems.length - 1; i >= 0; i--) {
            this._tabitems[i]._sidebar = this;
        }

        // Find sidebar > div.sidebar-content > div.sidebar-pane
        this._panes = [] as HTMLElement[];
        this._closeButtons = [];
        if (this._container !== undefined) {
          for (i = this._container.children.length - 1; i >= 0; i--) {
              child = this._container.children[i] as HTMLElement;
              if (child.tagName == 'DIV' &&
                      child.classList.contains('sidebar-pane')) {
                  this._panes.push(child);

                  var closeButtons = child.querySelectorAll('.sidebar-close');
                  for (var j = 0, len = closeButtons.length; j < len; j++) {
                      this._closeButtons.push(closeButtons[j] as HTMLButtonElement);
                  }
              }
          }
        }

        if (options && options.hasOwnProperty('initialOpenId') && options.initialOpenId) {

          // Initially is sidebar open ad the id of the pane is displayed
          if (this.element.classList.contains('collapsed')) {
            this.element.classList.remove('collapsed');
          }

            const id = options.initialOpenId;
            for (let i = 0; i < this._panes.length; ++i) {
                if (this._panes[i].id === id) {
                    this._panes[i].classList.add('active');
                }
            }

            for (let i = 0; i < this._tabitems.length; ++i) {
                const a = this._tabitems[i].querySelector('a');
                if (a && a.hash === '#' + id) {
                    this._tabitems[i].classList.add('active');
                }
            }
        }
    }

    // setContainer(element: HTMLElement) {
    //   // Find sidebar > div.sidebar-content
    //   for (let i = element.children.length - 1; i >= 0; i--) {
    //     const child = element.children[i];
    //     if (child.tagName === 'DIV' &&
    //             child.classList.contains('sidebar-content')) {
    //         return child as HTMLDivElement;
    //     }
    //   }
    //   return undefined;
    // }

    /**
    * Set the map instance the control is associated with.
    * @param {ol.Map} map The map instance.
    */
    setMap() {
        let i: number;
        let child: HTMLElement;

        for (i = this._tabitems.length - 1; i >= 0; i--) {
            child = this._tabitems[i];
            var sub: HTMLAnchorElement | null = child.querySelector('a');
            if (sub !== null && sub.href.slice(0,1) == '#') {
                sub.onclick = this._onClick.bind(child);
            }
        }

        for (i = this._closeButtons.length - 1; i >= 0; i--) {
            child = this._closeButtons[i];
            child.onclick = this._onCloseClick.bind(this);
        }
    };

    open(id: string) {
        var i: number;
        var child: HTMLElement;
        var a: HTMLAnchorElement | null;

        // hide old active contents and show new content
        for (i = this._panes.length - 1; i >= 0; i--) {
            child = this._panes[i];
            if (child.id == id)
                child.classList.add('active');
            else if (child.classList.contains('active'))
                child.classList.remove('active');
        }

        // remove old active highlights and set new highlight
        for (i = this._tabitems.length - 1; i >= 0; i--) {
            child = this._tabitems[i];
            a = child.querySelector('a');
            if (a !== null && a.hash == '#' + id)
                child.classList.add('active');
            else if (child.classList.contains('active'))
                child.classList.remove('active');
        }

        // open sidebar (if necessary)
        if (this.element.classList.contains('collapsed')) {
            this.element.classList.remove('collapsed');
        }

        return this;
    };

    close() {
        // remove old active highlights
        for (var i = this._tabitems.length - 1; i >= 0; i--) {
            var child = this._tabitems[i];
            if (child.classList.contains('active'))
                child.classList.remove('active');
        }

        // close sidebar
        if (!this.element.classList.contains('collapsed')) {
            this.element.classList.add('collapsed');
        }

        return this;
    };

    _onClick(evt: MouseEvent) {
        evt.preventDefault();
        if (this.classList.contains('active')) {
            this._sidebar.close();
        } else if (!this.classList.contains('disabled')) {
          const a = this.element.querySelector('a');
          if (a !== null) {
            this._sidebar.open(a.hash.slice(1));
          }
        }
    };

    _onCloseClick() {
        this.close();
    };
}

// Expose Sidebar as ol.control.Sidebar if using a full build of
// OpenLayers
//@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
if (window.ol && window.ol.control) {
  //@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
    window.ol.control.Sidebar = Sidebar;
}
