/* eslint-disable */
import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';
import MapLegendsTable from './MapLegendsTable';

function MapLegendsPage() {
  return (
    <AppPage
      titlett="admin:page.map_legends"
      needPermission={[Permissions.Administration]}
    >
      <Box m={0} style={{ height: '100%' }}>
        <MapLegendsTable />
      </Box>
    </AppPage>
  );
}

export default MapLegendsPage;
