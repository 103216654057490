/* eslint-disable */
import { useContext } from 'react';

// Custom components
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';
import useApi from '@/lib/api/useApi';

// Types
import { LoginData } from '@/@types/lib/api';
import { DCRecord } from '@/@types/lib/dataController';
import {
  ActivateData,
  CheckData,
  LogoutData,
  RegisterData,
  RequestResetPasswordData,
  ResetPasswordData,
  ChangePasswordData,
} from '@/@types/services/authService';
import { IUserStatePreParsed } from '@/@types/common';

export interface IAuthService {
  register: (userData: DCRecord) => Promise<RegisterData>;
  check: (token: string) => Promise<CheckData>;
  activate: (data: DCRecord) => Promise<ActivateData>;
  requestResetPassword: (data: DCRecord) => Promise<RequestResetPasswordData>;
  checkResetPasswordToken: (token: string) => Promise<CheckData>;
  resetPassword: (data: DCRecord) => Promise<ResetPasswordData>;
  changePassword: (data: DCRecord) => Promise<ChangePasswordData>;
  login: (username: string, password: string) => Promise<LoginData>;
  logout: (tenantContext: TenantContextType) => Promise<LogoutData>;
  whoAmI: () => Promise<IUserStatePreParsed>;
}

const authApiUrl = 'core/auth/';
const appKey = process.env.REACT_APP_APPKEY;

function register(userData: DCRecord): Promise<RegisterData> {
  const api = useApi();

  const bodyData = {
    ime: userData.ime,
    prezime: userData.prezime,
    email: userData.email,
    oib: userData.oib,
    telefon: userData.telefon,
    adresa: userData.adresa,
    appkey: appKey,
  };

  const url = `${authApiUrl}register`;

  return api.post(url, bodyData);
}

function check(token: string): Promise<CheckData> {
  const api = useApi();
  const url = `${authApiUrl}activate-check/${token}`;

  return api
    .get(url)
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as CheckData;
        return Promise.resolve(respData);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

function checkResetPasswordToken(token: string): Promise<CheckData> {
  const apiInstance = useApi();
  const url = `${authApiUrl}password-reset-check/${token}`;

  return apiInstance
    .get(url)
    .then((resp) => {
      if (resp.success) {
        const data = resp.data as CheckData;
        return Promise.resolve(data);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

function activate(data: DCRecord): Promise<ActivateData> {
  const api = useApi();
  const url = `${authApiUrl}activate`;

  return api
    .put(url, {
      email: data.email,
      activationToken: data.activation_token,
      password: data.password,
      passwordNew: data.password_new,
      appkey: appKey,
    })
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as LoginData;
        if (respData.auth_token) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          localStorage.setItem('auth_token', respData.auth_token);
        }
      }
      return resp;
    });
}

function requestResetPassword(
  data: DCRecord
): Promise<RequestResetPasswordData> {
  const api = useApi();
  const url = `${authApiUrl}password-reset-request`;

  return api
    .post(url, {
      email: data.email,
      appkey: appKey,
    })
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as RequestResetPasswordData;
        return Promise.resolve(respData);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

function resetPassword(data: DCRecord): Promise<ResetPasswordData> {
  const api = useApi();
  const url = `${authApiUrl}password-reset`;

  return api
    .put(url, {
      email: data.email,
      passwordreset_token: data.passwordreset_token,
      password_new: data.password_new,
      appkey: appKey,
    })
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as ResetPasswordData;
        return Promise.resolve(respData);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

function changePassword(data: DCRecord): Promise<ChangePasswordData> {
  const api = useApi();
  const url = `${authApiUrl}password-change`;

  return api
    .put(url, {
      password: data.password,
      password_new: data.password_new,
    })
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as ChangePasswordData;
        return Promise.resolve(respData);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

function login(username: string, password: string): Promise<LoginData> {
  const api = useApi();
  const url = `${authApiUrl}login`;

  return api
    .post(url, {
      username,
      password,
    })
    .then((resp) => {
      // login successful if there's a user in the response
      if (resp.success) {
        const respData = resp.data as LoginData;
        if (respData.auth_token) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          localStorage.setItem('auth_token', respData.auth_token);
        }
        return Promise.resolve(respData);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

function logout(tenantContext: TenantContextType): Promise<LogoutData> {
  // const tenantContext = useContext(TenantContext);
  const api = useApi();

  return api
    .post(`${authApiUrl}logout`)
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as LogoutData;
        localStorage.removeItem('auth_token');
        localStorage.setItem(
          'auth_token',
          tenantContext?.publicToken as string
        );
        return Promise.resolve(respData);
      }
      return Promise.reject(resp.error);
    })
    .catch((err) => {
      localStorage.removeItem('auth_token');
      localStorage.setItem('auth_token', tenantContext?.publicToken as string);
      return err;
    });
}

function whoAmI(): Promise<IUserStatePreParsed> {
  const api = useApi();
  return api
    .get(`${authApiUrl}who-am-i`)
    .then((resp) => {
      if (resp.success) {
        const respData = resp.data as IUserStatePreParsed;
        if (respData !== undefined) {
          return Promise.resolve(respData);
        }
        return Promise.reject({});
      }
      if (resp.error) {
        return Promise.reject(resp.error);
      }
      return Promise.reject({});
    })
    .catch((err) => Promise.reject(err));
}

// The authentication service is used to login and logout of the application
export const authService: IAuthService = {
  register,
  check,
  activate,
  login,
  logout,
  requestResetPassword,
  resetPassword,
  changePassword,
  checkResetPasswordToken,
  whoAmI,
};
