import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const EnaSettings: IModel = {
  title: 'Ena Settings',
  apiPath: 'system/ena_settings',
  forms: {
    insert: {
      fields: [{ source: 'tenant_id', readonly: false }, 'koef_korisna_pov', 'zapisnici_folder'],
    },
    update: {
      fields: [{ source: 'tenant_id', readonly: false }, 'koef_korisna_pov', 'zapisnici_folder'],
    },
    search: {
      fields: ['tenant_id', 'koef_korisna_pov', 'zapisnici_folder'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'tenant_id', 'koef_korisna_pov', 'zapisnici_folder'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'picker',
      subModel: {
        apiPath: 'system/tenants?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      validation: {
        required: true,
      },
      filter: true
    },
    {
      title: 'koef_korisna_pov',
      source: 'koef_korisna_pov',
      ttoken: 'admin:ena.koef_korisna_pov',
      type: 'numeric',
      filter: true,
      validation: {
        required: true
      }
    },
    {
      title: 'zapisnici_folder',
      source: 'zapisnici_folder',
      ttoken: 'admin:ena.zapisnici_folder',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 100
      }
    }
  ],
};

export default EnaSettings;
