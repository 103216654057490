// Custom components
import useApi from '@/lib/api/useApi';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import {
  ActivateData,
  AnonymizeData,
  DeactivateData,
  SetSettingsData,
  SetPassword,
} from '@/@types/services/userService';
import { IUserSettings } from '@/@types/common';

export interface IUserService {
  activate: (id: number, tenantId?: number) => Promise<ActivateData>;
  deactivate: (id: number, tenantId?: number) => Promise<DeactivateData>;
  anonymize: (id: number, tenantId?: number) => Promise<AnonymizeData>;
  setUserSettings: (
    id: number,
    settings: IUserSettings
  ) => Promise<SetSettingsData>;
  setPassword: (id: number, data: DCRecord) => Promise<SetPassword>;
}

const userApiUrl = 'system/users/';

function activate(id: number, tenantId?: number): Promise<ActivateData> {
  const api = useApi();
  const url = tenantId ? `tenant-admin/tenants/${tenantId}/users/${id.toString()}/activate` : `system/users/${id.toString()}/activate`;

  return api.patch(url);
}

function deactivate(id: number, tenantId?: number): Promise<DeactivateData> {
  const api = useApi();
  const url = tenantId ? `tenant-admin/tenants/${tenantId}/users/${id.toString()}/deactivate` : `system/users/${id.toString()}/deactivate`;

  return api.patch(url);
}

function anonymize(id: number, tenantId?: number): Promise<AnonymizeData> {
  const api = useApi();
  const url =  tenantId ? `tenant-admin/tenants/${tenantId}/users/${id.toString()}/anonymize` : `system/users/${id.toString()}/anonymize`;

  return api.patch(url);
}

function setUserSettings(
  id: number,
  settings: IUserSettings
): Promise<SetSettingsData> {
  const api = useApi();
  const url = `${userApiUrl + id.toString()}/set-settings`;

  return api.put(url, settings);
}

function setPassword(id: number, data: DCRecord): Promise<SetPassword> {
  const api = useApi();
  const url = `${userApiUrl + id.toString()}/set-password`;

  return api
    .patch(url, {
      password: data.password,
    })
    .then((resp: any) => {
      if (resp.success) {
        return Promise.resolve(resp);
      }
      return Promise.reject(resp.error);
    })
    .catch((error) => Promise.reject(error));
}

export const userService: IUserService = {
  activate,
  deactivate,
  anonymize,
  setUserSettings,
  setPassword,
};
