/* eslint-disable */
import * as React from "react";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { IFloatNumberControlProps } from "@/@types/controls/controls";

function FloatNumberControl(props: IFloatNumberControlProps) {
  const { value, onChange } = props;

  const handleChange = (value: string | number | null, source: string) => {
    if (typeof value === "string") {
      if (value.length > 0) {
        const valueCorrectDot = value.replace(".", ",");
        const match = valueCorrectDot.match(/^[-|0-9]{1}[0-9]{0,}[,]{0,1}[0-9]{0,}$/);
        if (match !== null) {
          onChange(valueCorrectDot, source);
        }
      } else {
        onChange(null, source);
      }

    } else if (typeof value === "number" ) {
      onChange(value, source);

    } else if (value === null || value === undefined) {
      onChange(null, source);
    }
  };

  const displayValue = value !== null ? value.toString() : "";

  return (
    <BaseInputControl
      {...props}
      onChange={handleChange}
      value={displayValue}
    />
  );
}

export default FloatNumberControl;
