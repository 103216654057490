import { IModel } from "@/@types/models/model";
import coreFields from "./helpers/coreFields";
import commonFields from "./helpers/commonFields";

const Toponimi: IModel = {
  title: "Toponimi",
  apiPath: "core/toponimi",
  forms: {
    "default": {
      fields: ["id", "naziv"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "naziv"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "data.naziv",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Naziv Translation Token",
      source: "naziv_ttoken",
      ttoken: "data.naziv",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Opis",
      source: "opis",
      ttoken: "data.opis",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Opis Translation Token",
      source: "opis_ttoken",
      ttoken: "data.opis",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Tip",
      source: "toponim_tip_id",
      ttoken: "data.tip",
      type: "wholenum"
    },
    commonFields.wkt,
    commonFields.proj
  ]
}

export default Toponimi;