/* eslint-disable */
import { FunctionComponent, createContext } from 'react';

// Custom Components
import {
  ConfirmResult,
  IConfirmDialogOptions,
} from '@/ui/Dialog/ConfirmDialog/ConfirmDialog';

// Types
import {IAnyHash, IStringHash } from '@/@types/common';

export interface IDialogProps extends IAnyHash {}

export type DialogContextType = {
  component1: FunctionComponent<IDialogProps> | null;
  component2: FunctionComponent<IDialogProps> | null;
  props1: IDialogProps;
  props2: IDialogProps;
  showDialog: (
    component: FunctionComponent<IDialogProps>,
    props?: IDialogProps,
    level?: number
  ) => void;
  hideDialog: (level?: number) => void;
  showConfirmDialog: (options: IConfirmDialogOptions) => Promise<ConfirmResult>;
  updateDialogProps: (level: number, newProps: IDialogProps) => void;
};

const DialogContext = createContext<DialogContextType>({
  component1: null,
  component2: null,
  props1: {},
  props2: {},
  showDialog: () => {},
  hideDialog: () => {},
  showConfirmDialog: () => Promise.reject(),
  updateDialogProps: () => {}
});
DialogContext.displayName = 'DialogContext';

export default DialogContext;
