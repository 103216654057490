/* eslint-disable */
// @ts-nocheck

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Typography, Card, Box, Button } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ImageDialog from "@/views/Comments/ImageDialog";
import DialogContext from "@/context/DialogContext/DialogContext";

const imageStyles = {
  height: "100%",
  aspectRatio: "4/3",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  cursor: "pointer",
  width:"100%",
  height:"auto",
  objectFit: "cover"
}

const ImageSlider = (props) => {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const {images} = props;

  const handleOpenImageDialog = (image) => {

    dialogContext.showDialog(ImageDialog, {
      image,
      onClose: handleDialogClose
    })
  }

  const handleDialogClose = () => {
    dialogContext.hideDialog();
  }
  
  const handleButtonScroll = (direction: string) => {
    if(direction === 'right' && activeIndex < images.length - 1) {
      setActiveIndex(activeIndex => activeIndex + 1);
    } else if(direction === 'left'  && activeIndex > 0) {
      setActiveIndex(activeIndex => activeIndex - 1);
    }
  }

  return (
    <Box sx={{ p: 0, mt: 0, width: "100%" }} borderRadius="5px" >
      {/* <Typography sx={{ textAlign: 'center'}}>{t("dump_report.photos")}</Typography> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "left",
          flexWrap: "wrap",
          margin: "0px",
          padding: "0px",
          overflow: "hidden",
        }}
      >
      {images && 
      <div style={{overflow: "none", height: "75%",borderTopRightRadius:"15px",borderTopLeftRadius: "15px",width:"100%"}}>
        {images.map((image, index) => {
          if(index === activeIndex) {
            return <img
              key={`image-${  index}`}
              src={`data:image/jpeg;base64, ${image}`}
              style={imageStyles}
              onClick={() => handleOpenImageDialog(image)}
            />
          } 
            return null;
          
        })}
      </div>
      }

        {activeIndex > 0 &&
          <Button
            onClick={
              () => {
                handleButtonScroll('left');
              }
            }
            sx={{
              visibility: "visible",
              borderRadius:"50%",
              position:"absolute",
              top:"50%",
              left:2,
              p:0,
              width: "2rem",
              minWidth:"2rem",
              height: "2rem",
              backgroundColor: "rgb(255,255,255,0.7)",
              "&:hover":{
                backgroundColor:"rgb(255,255,255,0.5)",
                borderColor: "rgb(0,0,0,0.7)"
              },
              border:" 1px solid black"
              }}>
            <ArrowLeftIcon fontSize='large' sx={{
              color:"black",
              "&:hover":{
                borderColor: "rgb(0,0,0,0.7)"
              },
            }} />
            </Button>
        }
        
        {activeIndex < images.length - 1 && 
          <Button 
            onClick={
              () => {
                handleButtonScroll('right');
              }
            }
            sx={{
              visibility: "visible",
              borderRadius:"50%",
              position:"absolute",
              top:"50%",
              right:2,
              p:0,
              width: "2rem",
              minWidth:"2rem",
              height: "2rem",
              backgroundColor: "rgb(255,255,255,0.7)",
              "&:hover":{
                backgroundColor:"rgb(255,255,255,0.5)",
                borderColor: "rgb(0,0,0,0.7)"
              },
              border:" 1px solid black"
              }}>
            <ArrowRightIcon fontSize='large' sx={{
              color:"black",
              "&:hover":{
                borderColor: "rgb(0,0,0,0.7)"
              },
            }}  />
            </Button>
          }

      </div>
    </Box>
  );
}

export default ImageSlider;
