/* eslint-disable */
import React, { useEffect } from 'react';
import ReactPannellum, { setYaw } from 'react-pannellum';
import { useTranslation } from 'react-i18next';

// Types
interface IImage360Props {
  imageId: number;
  finishLoad: () => void;
  northOffset: number;
}

const Image360 = (props: IImage360Props) => {
  const { t } = useTranslation();
  const { imageId,finishLoad, northOffset } = props;

  const setNorth = () => {
    setYaw(0);
  };

  useEffect(() => {
    const elements = document.getElementsByClassName('pnlm-compass');
    const compass = elements.length ? elements[0] : null;
    if (compass !== null) {
      compass.addEventListener('click', setNorth);
      return () => {
        compass.removeEventListener('click', setNorth);
      };
    }
    return () => {};
  });

  const config = {
    // autoRotate: -2,
    autoRotate: 0,
    autoLoad: true,
    compass: true,
    //yaw: northOffset,
    northOffset: northOffset,
    strings: {
      loadButtonLabel: t('360.btn_lbl'),
      loadingLabel: t('360.ldg_lbl'),
      bylineLabel: t('360.lin_lbl'),
      noPanoramaError: t('360.no_pan'),
      fileAccessError: t('360.fil_acs'),
      malformedURLError: t('360.mal_url'),
    },
    uiText: {
      loadButtonLabel: t('360.btn_lbl'),
      loadingLabel: t('360.ldg_lbl'),
      bylineLabel: t('360.lin_lbl'),
      noPanoramaError: t('360.no_pan'),
      fileAccessError: t('360.fil_acs'),
      malformedURLError: t('360.mal_url'),
    },
    showFullscreenCtrl: !document.fullscreen
  };

  return (
    <div>
      {imageId ? (
        <ReactPannellum
          key={`panorama-key-${imageId}`}
          id={`${imageId}`}
          sceneId={`scene-${imageId}`}
          imageSource={`${process.env.REACT_APP_APIPATH}core/photo360/${imageId}?authToken=${localStorage.getItem('auth_token')}`}
          config={config}
          onPanoramaLoaded={finishLoad}
        />
      ) : null}
    </div>
  );
};

export default Image360;
