/* eslint-disable */
import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';

//MUI
import MUIButton from '@mui/material/Button';

//OpenLayers
import OlControl from 'ol/control/Control';

//Custom components
import ButtonGroupContext from '../ButtonGroup/ButtonGroupContext';
import ButtonClass from './button';
import MapContext from '@/context/MapContext/MapContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { ButtonProps, useTheme } from '@mui/material';

export interface IButtonControl extends ButtonProps {
  id: string;
  order?: number;
}

const ButtonControlRoot: FunctionComponent<IButtonControl> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const buttonGroupContext = useContext(ButtonGroupContext);

  const { id } = props;

  const theme = useTheme();

  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const options = {
        element: elementRef.current,
        target:
          buttonGroupContext.isInGroup && buttonGroupContext.element
            ? buttonGroupContext.element.current
            : undefined,
      };

      let allOptions = {};
      Object.assign(allOptions, options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new ButtonClass(id, definedOptions);

      if (id) {
        control.set('id', id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, ButtonClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as OlControl);
      } else {
        mapContext.initOptions.controls.push(control as OlControl);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, ButtonClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <MUIButton
      className="ol-button ol-custom-button"
      variant="contained"
      size="small"
      {...props}
      sx={{ minWidth: 'unset', p: 0.5,color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main), ...props.sx }}
      ref={elementRef}
    >
      {props.children}
    </MUIButton>
  );
};

export default ButtonControlRoot;
