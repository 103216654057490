import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

// MUI Icons
import LaunchIcon from '@mui/icons-material/Launch';
import CommentIcon from '@mui/icons-material/Comment';

// Openlayers
import { Feature as OlFeature } from 'ol';
import {
  Point as OlPoint,
  LineString as OlLineString,
  MultiLineString as OlMultiLineString,
  Polygon as OlPolygon,
  MultiPolygon as OlMultiPolygon,
  Geometry as OlGeometry,
} from 'ol/geom';
import { Coordinate } from 'ol/coordinate';

// Custom
import useComments from '@/components/useComments';

// Types
import { GFIFeatureType } from '@/@types/services/gsService';
import CustomBadge from './CustomBadge';

interface AttachDocumentGFIActionProps {
  gid: string;
  feature: GFIFeatureType;
  handleCloseMenu?: () => void;
  stats: { loading: boolean, count: number},
  onDialogClose: () => void;
}
const CommentListItem = (props: AttachDocumentGFIActionProps) => {
  const { t } = useTranslation();

  const { gid, feature, handleCloseMenu, stats, onDialogClose } = props;

  const olFeature: OlFeature<OlGeometry> = useMemo(() => {
    if (feature) {
      let geometry;
      switch (feature.geometry.type) {
        case 'Point':
          geometry = new OlPoint(feature.geometry.coordinates as Coordinate);
          break;
        case 'LineString':
          geometry = new OlLineString(
            feature.geometry.coordinates as Array<Coordinate> | Array<number>
          );
          break;
        case 'MultiLineString':
          geometry = new OlMultiLineString(
            feature.geometry.coordinates as
              | Array<Array<Coordinate> | OlLineString>
              | Array<number>
          );
          break;
        case 'Polygon':
          geometry = new OlPolygon(
            feature.geometry.coordinates as
              | Array<Array<Coordinate>>
              | Array<number>
          );
          break;
        case 'MultiPolygon':
          geometry = new OlMultiPolygon(
            feature.geometry.coordinates as
              | Array<Array<Array<Coordinate>> | OlPolygon>
              | Array<number>
          );
          break;
        default:
          // eslint-disable-next-line no-console
          console.error('Unsupported geometry type:', feature.geometry.type);
      }

      return new OlFeature({
        geometry: geometry as OlGeometry,
        id: feature.id,
        properties: feature.properties,
      });
    }
    return new OlFeature();
  }, [feature]);

  const { showCommentsDialog } = useComments();

  const handleAttachComment = () => {
    showCommentsDialog(gid, olFeature, onDialogClose);
  };

  const handleClick = () => {
    handleAttachComment();
    if (handleCloseMenu) {
      handleCloseMenu();
    }
  };
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <CommentIcon color="primary" fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('buttons.comments')}</ListItemText>
      <CustomBadge loading={stats.loading} badgeContent={stats.count}/>
      <LaunchIcon sx={{ ml: 1 }} color="disabled" fontSize="small" />
    </MenuItem>
  );
};

export default CommentListItem;
