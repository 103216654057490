/* eslint-disable */
import React, { ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI Core Components
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Box, Collapse, Icon } from '@mui/material';

// Material-UI Icons
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// Custom Components
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';
import HeaderButton from '@/ui/Header/HeaderButton';
import menuRoutes from '@/routes/menu';
import { getNavDrawerBgLevelColors } from '@/lib/colorUtilities';

// Types
import { MenuType } from '@/@types/common';

export type AppHeaderMenuButtonProps = {
  icon?: ReactNode;
  text?: string;
  showCloseIcon?: boolean;
};

const AppHeaderMenuButton = (props: AppHeaderMenuButtonProps) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { icon, text, showCloseIcon } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const levelColors = getNavDrawerBgLevelColors(15);

  const handleClick = (evt: React.SyntheticEvent) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt: React.SyntheticEvent) => {
    setAnchorEl(null);
  };

  const hookAction = (action: Function) => {
    setAnchorEl(null);
    action();
  };

  const generateMenuItem = (
    menu: MenuType,
    level: number
  ): React.JSX.Element => {
    let action: () => void;
    if (menu.nested && menu.nested.length) {
      const children: Array<JSX.Element> = [];

      menu.nested.forEach((item) => {
        if (
          item.needPermission &&
          userContext.hasAnyPermission(item.needPermission)
        ) {
          children.push(generateMenuItem(item, level + 1));
        }
      });

      const [collapsed, setCollapsed] = useState(false);

      return (
        <Box
          key={`nav-item-wrapper-${menu.name}`}
          bgcolor={collapsed ? levelColors[level] : 'initial'}
          sx={{
            transition: 'background-color 0.3s',
          }}
        >
          <MenuItem
            onClick={() => setCollapsed((prevState) => !prevState)}
            key={`menu-item-${menu.name}`}
          >
            <Box display="flex" justifyContent="space-between">
              <Icon
                sx={{
                  marginLeft: `${level * 10}px`,
                  marginRight: '10px',
                }}
                fontSize="small"
                color="primary"
              >
                {menu.icon}
              </Icon>
              <Typography>{t(menu.ttoken)}</Typography>
              {collapsed ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </MenuItem>
          <Collapse
            aria-label={`menu-collapse-${menu.name}`}
            key={`nav-drawer-collapse-${menu.name}`}
            in={collapsed}
          >
            {children}
          </Collapse>
        </Box>
      );
    }

    if (menu.external) {
      action = () => {
        window.open(menu.path, 'blank');
      };
    } else {
      action = () => {
        navigate(menu.path);
      };
    }

    return (
      <MenuItem
        onClick={() => hookAction(action)}
        key={`menu-item-${menu.name}`}
      >
        <Icon
          sx={{
            marginLeft: `${level * 10}px`,
            marginRight: '10px',
          }}
          fontSize="small"
          color="primary"
        >
          {menu.icon}
        </Icon>
        <Typography>{t(menu.ttoken)}</Typography>
      </MenuItem>
    );
  };

  const isOpen = Boolean(anchorEl);
  const menuIcon =
    showCloseIcon && isOpen ? <CloseIcon /> : icon || <MenuIcon />;

  return (
    <>
      <HeaderButton onClick={handleClick} startIcon={menuIcon || null}>
        {text || null}
      </HeaderButton>
      <Menu
        id="dialogt-toolbar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper': {
            mt: 1,
            padding: 2,
            backgroundColor: 'palette.background',
            color: 'palette.primary.main',
            width: '100%',
            maxWidth: '100%',
            left: '0px !important',
            right: '0px',
          },
          '.MuiMenu-list': {
            color: '#202020',
            fontSize: '18px',
            lineHeight: '25px',
            fontWeight: 500,
          },
        }}
      >
        {menuRoutes.map((m) => {
          if (
            m.needPermission !== undefined &&
            !userContext.hasAnyPermission(m.needPermission)
          ) {
            return null;
          }
          return generateMenuItem(m, 0);
        })}
      </Menu>
    </>
  );
};

export default AppHeaderMenuButton;
