import { IModel } from "../@types/models/model";

const Comment: IModel = {
  title: "Komentari fotografije",
  apiPath: "comments/photos",
  forms : {
    "default": {
      fields: ["image"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", ""]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "Comment id",
      source: "comment_id",
      ttoken: "komentari.comment_id",
      type: "numeric",
      idattr: true,
    },
    {
      title: "Image",
      source: "image",
      ttoken: "komentari.image",
      type: "images"
    },
    {
      title: "Image name",
      source: "image_name",
      ttoken: "komentari.image_name",
      type: "numeric",
      idattr: true,
    },
    {
      title: "Image size",
      source: "image_size",
      ttoken: "komentari.image_size",
      type: "numeric",
      idattr: true,
    },
    {
      title: "Image type",
      source: "image_type",
      ttoken: "komentari.image_type",
      type: "numeric",
      idattr: true,
    },
    {
        title: "tenant_id",
        source: "tenant_id",
        ttoken: "komentari.tenant_id",
        type: "numeric",
        readonly: true
      },
  ]
}

export default Comment;