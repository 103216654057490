/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCalculator } from '@fortawesome/free-solid-svg-icons';

// Material-UI Core Components
import TextField from '@mui/material/TextField';

// Material-UI Icons
import LockIcon from '@mui/icons-material/Lock';

// Types
import { IBaseInputControlProps } from '@/@types/controls/controls';

const BaseInputControl = (props: IBaseInputControlProps<string>) => {
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  const {
    field,
    value,
    helperText,
    validation,
    formMode,
    controlMode,
    onChange,
    onFocus,
    onBlur,
    inputProps,
    inputLabelProps,
    notFullWidth,
    ...otherProps
  } = props;

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    // There is regex in replace because without global parameter g replace replaces only the first occurence
    // const val: string = (field && field.mask) ? evt.currentTarget.value.replace(/ /g, '') : evt.currentTarget.value;
    const val = evt.currentTarget.value;
    if (onChange) {
      onChange(val, field.source);
    }
  };

  const submitValue = () => {
    if (onChange) {
      onChange(JSON.stringify({ '#submit': true }), field.source);
    }
  };

  const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const getValue = (val: string | number | null) => {
    if (field && field.items && field.items.values && field.items.labels) {
      if (val) {
        const pos = field.items.values.indexOf(val);
        return field.translate
          ? t(field.items.labels[pos])
          : field.items.labels[pos];
      }
      if (field.items.default) {
        const pos = field.items.values.indexOf(field.items.default);
        return field.translate
          ? t(field.items.labels[pos])
          : field.items.labels[pos];
      }
      return '';
    }
    return val === null || val === undefined ? '' : val;
  };

  const isRequired = !!(field.validation && field.validation.required);
  const hasValue = !!(value !== undefined && value !== null && value !== '');
  const hasError = validation && validation.valid === false;
  const hasPlaceholder = !!(field && field.placeholder);

  const isReadOnly = controlMode === 'view' || (field && field.readonly);
  const isValueCalculated = !!(field && field.calculated);
  const isAutoFocused = field && field.focused === true;
  const isHelperTextHidden = field && field.hideHelperText === true;

  const label = field.ttoken ? t(field.ttoken) : field.title;
  const displayValue = getValue(value);
  const myHelperText =
    field && field.tooltip ? t(field.tooltip) : helperText || ' ';

  const InputProps = {
    readOnly: isReadOnly,
    endAdornment: isValueCalculated ? (
      <FontAwesomeIcon icon={faCalculator} color="grey" />
    ) : isReadOnly ? (
      <LockIcon style={{ fontSize: 20 }} color="disabled" />
    ) : null,
    ...inputProps,
  };

  const InputLabelProps = {
    shrink: hasValue || isFocused || isAutoFocused || displayValue !== '',
    ...inputLabelProps,
  };

  return (
    <TextField
      margin="none"
      fullWidth={notFullWidth?false:true}
      required={isRequired}
      error={hasError}
      label={formMode === 'form' ? label : ''}
      value={displayValue}
      placeholder={hasPlaceholder ? field.placeholder : ''}
      onChange={handleChange}
      onKeyDown={(evt) => {
        if (evt.key === 'Enter') {
          submitValue();
        }
      }}
      InputProps={InputProps}
      inputProps={{
        autoComplete: 'off',
      }}
      InputLabelProps={InputLabelProps}
      helperText={
        hasError ? validation.msg : !isHelperTextHidden ? myHelperText : null
      }
      autoFocus={isAutoFocused}
      color="primary"
      onBlur={handleBlur}
      variant="outlined"
      {...otherProps}
      onFocus={handleFocus}
    />
  );
};

export default BaseInputControl;
