import React, { useContext } from 'react';

// Custom components
import DialogContext from './DialogContext';

// Types
import { DialogContextType } from '@/context/DialogContext/DialogContext';

const DialogContainer = () => {
  const dialogContext = useContext(DialogContext) as DialogContextType;

  const { component1, props1, component2, props2 } = dialogContext;

  return (
    <>
      {component1 ? React.createElement(component1, props1) : null}
      {component2 ? React.createElement(component2, props2) : null}
    </>
  );
};

export default DialogContainer;
