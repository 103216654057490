import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const RasvjetaTijela: IModel = {
  title: 'Rasvjeta tijela',
  apiPath: 'rasvjeta/mjesta/{mjesto_id}/tijela',
  forms: {
    default: {
      fields: [
        'id',
        'visina',
        'tip',
        'broj_zarulja',
        'zarulja_tip',
        'zarulja_snaga',
        'napomena',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'visina',
        'tip',
        'broj_zarulja',
        'zarulja_tip',
        'zarulja_snaga',
        'napomena',
      ],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'mjesto_id',
      source: 'mjesto_id',
      ttoken: 'rasvjeta:tijela.mjesto_id',
      type: 'picker',
      subModel: {
        apiPath: 'rasvjeta/mjesto',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'visina',
      source: 'visina',
      ttoken: 'rasvjeta:tijela.visina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'tip',
      source: 'tip',
      ttoken: 'rasvjeta:tijela.tip',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'napomena',
      source: 'napomena',
      ttoken: 'rasvjeta:tijela.napomena',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'broj_zarulja',
      source: 'broj_zarulja',
      ttoken: 'rasvjeta:tijela.broj_zarulja',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'zarulja_tip',
      source: 'zarulja_tip',
      ttoken: 'rasvjeta:tijela.zarulja_tip',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'zarulja_snaga',
      source: 'zarulja_snaga',
      ttoken: 'rasvjeta:tijela.zarulja_snaga',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      readonly: true,
    },
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default RasvjetaTijela;
