import * as React from 'react';
import { FunctionComponent, ReactNode } from 'react';

// Types
export interface IToolbarFillContent {
  children?: ReactNode;
}

const ToolbarFillContent: FunctionComponent<IToolbarFillContent> = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        flexGrow: 1,
        paddingLeft: 5,
        paddingRight: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
};

export default ToolbarFillContent;
