/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useRef } from 'react';

// MUI
import { useTheme } from '@mui/material';
import SidebarHeading from './SidebarHeading';
import SidebarContext from '@/context/SidebarContext/SidebarContext';

const SidebarContent: FunctionComponent<any> = (props) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();

  const { children } = props;

  const sidebarContext = React.useContext(SidebarContext)

  const element = document.createElement('div');
  element.className = 'sidebar-content';

  contentRef.current = element;

  return (
    <div
      className="sidebar-content"
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      {children}
    </div>
  );
};

export default SidebarContent;
