/* eslint-disable */
import React, { ReactElement } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import { Box, GridSize } from '@mui/material';

// Custom Components
import { GridItem } from '@/ui/Grid';
import FormGenerator from '@/lib/FormGenerator';

// Types
import {
  ControlField,
  FieldAny,
  IFieldsCollection,
  IFieldsWControlCollection,
} from '@/@types/models/model';
import {
  ChangeHandlerFunction,
  FormFieldControls,
} from '@/@types/lib/formGenerator';
import { PickerDataControllers } from '@/@types/components/authFormController';
import { RecordValidation } from '@/@types/lib/appValidator';
import { DCRecord } from '@/@types/lib/dataController';

export type FormContentMode = 'view' | 'insert' | 'update' | 'simpleView';
export type FormContentColumns = 1 | 2 | 3 | 4;
export type PickerStyleProps = {
  ListboxMaxHeight?: number;
}

export type FormContentProps = {
  title?: string;
  pickerDataControllers?: PickerDataControllers;
  mode: FormContentMode;
  onFieldChange?: ChangeHandlerFunction;
  onSubmit?: () => void;
  fieldNames: Array<string>;
  validation?: RecordValidation;
  fields: IFieldsCollection;
  columns?: FormContentColumns;
  record: DCRecord;
  hidden?: string[];
  pickerStyleProps?: PickerStyleProps;
};

const FormContent = (props: FormContentProps) => {
  const { onFieldChange, onSubmit, columns } = props;
  const {
    title,
    mode,
    record,
    fieldNames,
    validation,
    pickerDataControllers,
    fields,
    hidden,
    pickerStyleProps
  } = props;

  const fg = new FormGenerator();

  type HashMap = { [id: string | number]: any };

  const handleFieldChange = (value: unknown, source: string) => {
    let isValueParsedAndSubmit = false;
    try {
      const tmp: HashMap = JSON.parse(value as string);
      isValueParsedAndSubmit = '#submit' in tmp && tmp['#submit'];
    } catch (_) {
      // do nothing and proceed
    }

    if (
      isValueParsedAndSubmit /* value && typeof value == "object" && value.hasOwnProperty("keyCode") && (value as { keyCode: number })["keyCode"] === 13 */
    ) {
      if (onSubmit) {
        onSubmit();
      }
    } else if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const getWidth = (f: FieldAny): number => {
    if (f && f.width && f.width === 'full') {
      return 12;
    }
    switch (columns) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 6;
    }
  };

  const renderFields: IFieldsWControlCollection = fieldNames
    ? fieldNames
        .map((name) => fields.find((f) => f.source === name))
        .filter((x) => x !== undefined)
        .map((x) => x as ControlField)
    : [];

  let controls: FormFieldControls = {};
  switch (mode) {
    case 'insert':
    case 'update': {
      controls = fg.generateFormControls(
        renderFields,
        'form',
        'edit',
        record,
        validation,
        handleFieldChange,
        pickerDataControllers,
        pickerStyleProps
      );
      break;
    }
    case 'view': {
      controls = fg.generateFormControls(
        renderFields,
        'form',
        'view',
        record,
        undefined,
        undefined,
        pickerDataControllers,
        pickerStyleProps
      );
      break;
    }
    case 'simpleView': {
      controls = fg.generateFormControls(
        renderFields,
        'form',
        'simpleView',
        record,
        undefined,
        undefined,
        pickerDataControllers,
        pickerStyleProps
      );
      break;
    }
    default:
      break;
  }

  renderFields.forEach((field) => {
    field.control = Object.prototype.hasOwnProperty.call(
      controls,
      field.source
    ) ? (
      (controls[field.source] as ReactElement)
    ) : (
      <>-</>
    );
  });

  return (
    <>
      {title ? (
        <GridItem xs={12}>
          <Typography variant="h6" component="h2" paragraph>
            {title}
          </Typography>
        </GridItem>
      ) : null}
      {renderFields.map((f, i) =>
        hidden?.includes(f.source) ? (
          <Box key={`grid-${i}`} display={'none'}>
            {f.control}
          </Box>
        ) : (
          <GridItem
            xs={12}
            sm={getWidth(f)}
            key={`grid-${i}`}
            // style={f.bottom ? customBottomStyle : f.originalHeight ? customFormHeightStyle : null}
          >
            {f.control}
          </GridItem>
        )
      )}
    </>
  );
};

export default FormContent;
