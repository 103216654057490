import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';
import DataController, { IDataController } from '@/lib/DataController';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import DialogContext, { DialogContextType } from '@/context/DialogContext/DialogContext';
import LoaderContext, { LoaderContextType } from '@/context/LoaderContext/LoaderContext';
import SearchSettingsDialog from './SearchSettingsDialog';
import ToponimTipoviModel from '@/models/toponim_tipovi';

const MapLayersTable = () => {
  const { showDialog } = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new DataController(ToponimTipoviModel, 'system/toponim-tipovi');

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    showDialog(SearchSettingsDialog, {
      dc,
      mode: 'insert',
      form: 'insert',
      onClose: handleDialogClose,
    });
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = Object.keys(sel)[0];
    showDialog(SearchSettingsDialog, {
      dc,
      mode: 'update',
      form: 'insert',
      initialRecord: sel[selId],
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result: {
    dataChanged: boolean;
    action: string;
    id: number;
  }) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecords(resp.data);
        } else {
          setRecords([]);
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  return (
    <ModelTable
      title={t('titles.toponim_tipovi')}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter
      allowExport
      allowAdd
      allowColumnPicker
      addLabel={t('buttons.new_m') as string}
      handleAdd={handleAdd} 
      onRowAction={()=>{}}
      allowRowAction={() => false}
      unselectRow={false}
      onDoubleClick={handleEdit}
      viewName="admin"
    >
      <TableButtonEdit variant="contained" onClick={handleEdit} />
    </ModelTable>
  );
}

export default MapLayersTable;
