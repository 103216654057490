/* eslint-disable */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

// MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Collapse, CollapseProps, Fade, FadeProps, useMediaQuery, useTheme } from '@mui/material';

// MUI Icons
import LayersIcon from '@mui/icons-material/Layers';

// OpenLayers
import OlBaseLayer from 'ol/layer/Base';

export type BaseLayerSwitcherUIProps = {
  className?: string;
  layers: Array<OlBaseLayer>;
  type?: "minimap" | "map";
  mapId?: number;
};

type ResponsiveAnimationProps = (CollapseProps | FadeProps) & {
  children: React.ReactElement<any, any>;
} & {type?: "map" | "minimap"};

const ResponsiveAnimation: React.FC<ResponsiveAnimationProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  // Conditionally render Collapse or Fade with the provided props and children
  // Because Collapse is buggy with mobiel view
 if (props.type === "minimap" || !mdUp) {
    return <Fade {...(props as FadeProps)}>{children}</Fade>;
  } else {
    return <Collapse {...(props as CollapseProps)}>{children}</Collapse>;
  }
};

const BaseLayerSwitcherUI = React.forwardRef<
  HTMLDivElement,
  BaseLayerSwitcherUIProps
>((props, ref) => {
  const theme = useTheme();

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const minimap = props.type === "minimap" || !mdUp;

  const styles = {
    Box: {
      bgcolor: theme.palette.background.paper,
      width: !minimap ? 70 : "fit-content",
      marginTop: '3px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.background.paper,
      borderRadius: '5px',
      float: !minimap ? 'initial' : 'right' 
    },
    layerBox: {
      width: 66,
      '&:hover': {
        '.image-container': {
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
        },
        a: {
          color: theme.palette.primary.main,
        },
      },
    },
    layerBoxSelected: {
      width: 66,
      '.image-container': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
      },
      a: {
        color: theme.palette.primary.main,
      },
    },
  };

  const { className, layers, type, mapId } = props;

  const [selectedLayer, setSelectedLayer] = useState<null | OlBaseLayer>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    layers.forEach((l) => {
      if (l.getVisible()) setSelectedLayer(l);
    });
  }, []);

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
  };

  const handleChangeLayer = (layer: OlBaseLayer, layersArray: OlBaseLayer[]) => {
    layersArray.forEach((v) => v.setVisible(false));
    layer.setVisible(!layer.getVisible());
    setSelectedLayer(layer);

    const mapLayerId = mapId || 1;
    
    try {
      const storedBaseLayers = localStorage.getItem("mapbaselayers");
      const parsedBaseLayers = storedBaseLayers ? JSON.parse(storedBaseLayers) : {};
      parsedBaseLayers[mapLayerId] = layer.get("code");
      localStorage.setItem("mapbaselayers", JSON.stringify(parsedBaseLayers));
    } catch (error) {
      // In case of an error (e.g., invalid JSON in localStorage), initialize a new entry
      const newLocalStorageEntry: { [key: number]: string } = {};
      newLocalStorageEntry[mapLayerId] = layer.get("code");
      localStorage.setItem("mapbaselayers", JSON.stringify(newLocalStorageEntry));
    }
  };

  return (
    <div
      ref={ref}
      id="base-layer-switcher"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={clsx(className, 'ol-unselectable', 'ol-control')}
      style={{ top: '3em', right: '0.5em', width: 70, height: 70 }}
    >
      <Button
        onClick={handleClick}
        style={{ width: '100%', height: '100%', textAlign: 'center' }}
      >
        <img
          src={selectedLayer?.getProperties().preview}
          style={{
            width: '90%',
            height: '90%',
            maskImage:
              'linear-gradient(to top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 50% 100%)',
            WebkitMaskImage:
              'linear-gradient(to top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 50% 100%)',
          }}
        />
        <a
          style={{
            fontSize: 12,
            fontStretch: 'ultra-condensed',
            fontWeight: 'normal',
            whiteSpace: 'nowrap',
            position: 'absolute',
            bottom: '20%',
            left: '50%',
            transform: 'translate(-50%, 50%)',
          }}
        >
          {selectedLayer?.getProperties().title}
        </a>
      </Button>

      <ResponsiveAnimation type={type} in={open}>
        <Box sx={styles.Box}>
          <Stack spacing={1} direction={!minimap ? "column" : "row"}>
            {layers.map((l, index, array) => (
              <Box
                key={index}
                textAlign="center"
                sx={
                  l.getProperties().id === selectedLayer?.getProperties().id
                    ? styles.layerBoxSelected
                    : styles.layerBox
                }
                onClick={(e)=>handleChangeLayer(l,array)}
              >
                <Box className="image-container" sx={{ height: 66, width: 66, transition: "border-color 0.2s"}}>
                  <img src={l.getProperties().preview} style={{ width: 62 }} />
                </Box>
                <a style={{ fontSize: 14, transition: "color 0.2s" }}>{l.getProperties().title}</a>
              </Box>
            ))}
          </Stack>
        </Box>
      </ResponsiveAnimation>
    </div>
  );
});

BaseLayerSwitcherUI.displayName = 'BaseLayerSwitcherUI';

export default BaseLayerSwitcherUI;
