/* eslint-disable */
import * as React from 'react';
import {
  useState,
  useContext,
  useEffect,
  FunctionComponent,
  ReactNode,
} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

// MUI
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

// Custom Components
import NavDrawer from '@/ui/NavDrawer/NavDrawer';
import menuRoutes from '@/routes/menu';
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';
import Loader from '@/ui/Loader/Loader';
import AppHeader from '@/ui/Header/AppHeader';
import useTitle from '@/components/useTitle';
import { authService } from '@/services/authService';
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';

// Types
import { IUserStatePreParsed, Permission, MenuType } from '@/@types/common';
import { ErrorData } from '@/@types/services/authService';
import Permissions from '@/lib/permissions';
import ErrorContext, {
  ErrorContextType,
} from '@/context/ErrorContext/ErrorContext';
import { ConstructionOutlined } from '@mui/icons-material';
import { SxProps } from '@mui/material';

export interface IAppPage {
  needPermission?: Permission[];
  menu?: MenuType[];
  modul?: string;
  titlett: string;
  children: ReactNode;
  showBg?: boolean;
  hideNavDrawer?: boolean;
}

type SidebarSettingsType = {
  open: boolean;
  collapsed: string[];
};

const AUTH_TOKEN = 'auth_token';

const AppPage: FunctionComponent<IAppPage> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const tenantContext = useContext(TenantContext) as TenantContextType;
  const { showError } = useContext(ErrorContext) as ErrorContextType;

  const sidebarSettings = getSidebarSetting();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(sidebarSettings.open);
  const [collapsedNavLinks, setCollapsedNavLinks] = useState<string[]>(
    sidebarSettings.collapsed
  );

  const [denied, setDenied] = useState<boolean>(false);

  const {
    needPermission = [],
    menu,
    titlett,
    showBg = false,
    hideNavDrawer = false,
  } = props;

  useTitle(titlett);

  const navigate = useNavigate();

  const toggleSidebar = (): void => {
    setSidebarOpen((prevState) => !prevState);

    var sidebarSettings = JSON.parse(localStorage.getItem('sidebar') as string);
    sidebarSettings.open = !sidebarOpen;

    localStorage.setItem('sidebar', JSON.stringify(sidebarSettings));
  };

  const handleNavbarLinkCollapse = (name: string): void => {
    var collapsedCopy = [...collapsedNavLinks];

    // add name to collapseedcopy or remove from it if it exists
    let found = false;
    for (let i = 0; i < collapsedCopy.length; i++) {
      if (collapsedCopy[i] === name) {
        found = true;
        collapsedCopy.splice(i, 1);
      }
    }

    if (!found) {
      collapsedCopy.push(name);
    }

    var sidebarSettings = JSON.parse(localStorage.getItem('sidebar') as string);
    sidebarSettings.collapsed = collapsedCopy;

    localStorage.setItem('sidebar', JSON.stringify(sidebarSettings));

    setCollapsedNavLinks(collapsedCopy);
  };

  const forbidden: boolean =
    needPermission.length > 0 && !userContext.hasAnyPermission(needPermission);

  useEffect(() => {
    if (userContext && userContext.loaded && forbidden) {
      console.warn('Tried to access forbidden page, Redirecting...');
      if (
        !userContext.hasAnyPermission([Permissions.Public]) &&
        userContext.hasAnyPermission([Permissions.Elevate])
      ) {
        navigate('/login');
      } else if (userContext.hasAnyPermission([Permissions.Public])) {
        navigate('/');
      } else {
        setDenied(false);
      }
    }
  }, [userContext?.loaded, forbidden]);

  const accessToken = localStorage.getItem(AUTH_TOKEN);
  const hasAccessToken = !!(accessToken !== null && accessToken.length > 0);

  if (!hasAccessToken) {
    localStorage.setItem(AUTH_TOKEN, tenantContext.publicToken);
    window.location.reload();
  }

  const knowsWhoAmI = !!(
    userContext &&
    userContext.username &&
    userContext.username.length > 0
  );
  const isAuthenticated = hasAccessToken && knowsWhoAmI;

  if (hasAccessToken && !knowsWhoAmI) {
    authService
      .whoAmI()
      .then((user: IUserStatePreParsed) => {
        if (user === undefined) {
          // localStorage.removeItem(AUTH_TOKEN);
          localStorage.setItem(AUTH_TOKEN, tenantContext.publicToken);
          window.location.reload();
        } else if (userContext !== null) {
          userContext.setUserData(user);
        }
      })
      .catch((err: ErrorData) => {
        console.warn(err);
        // TODO fix error types
        // @ts-ignore
        if (err.detail && err.detail.errcode === 6) {
          // @ts-ignore
          showError(err.status, err.message, err.detail.errmsg);
          setDenied(true);
        } else {
          localStorage.removeItem(AUTH_TOKEN);
        }
        // window.location.reload();
      });
  }

  if (sidebarOpen && hideNavDrawer) {
    toggleSidebar();
  }

  let b64ImgString = tenantContext?.tenantTheme?.background_image;
  const backgroundImageUrl = b64ImgString
    ? `url("data:image/png;base64, ${b64ImgString.replace(/\s/g, '')}")`
    : '';

  const bgStyles: SxProps = {
    backgroundImage: backgroundImageUrl,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  if (
    isAuthenticated &&
    userContext &&
    userContext.loaded &&
    !forbidden &&
    tenantContext.loaded
  ) {
    return (
      <div id="page">
        <AppHeader
          hideNavDrawer={hideNavDrawer}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <Box m={0} sx={{ display: { xs: 'none', md: 'block' } }}>
          {!hideNavDrawer && (
            <NavDrawer
              menu={menu || menuRoutes}
              open={sidebarOpen}
              toggleOpen={toggleSidebar}
              collapsedNavLinks={collapsedNavLinks}
              onNavLinkCollapse={handleNavbarLinkCollapse}
            />
          )}
        </Box>

        <main id="content-wrapper" style={{ flexGrow: 1 }}>
          <Toolbar variant="dense" />
          <Box id="content" sx={showBg ? bgStyles : {}}>
            {props.children}
          </Box>
        </main>
      </div>
    );
  }
  if (accessToken === '' && !knowsWhoAmI) {
    return <Navigate to={{ pathname: '/login' }} />;
  }
  if (accessToken === tenantContext.publicToken && denied) {
    return null;
  }
  return <Loader open />;
};

const getSidebarSetting = (): SidebarSettingsType => {
  var sidebarSettings;
  try {
    sidebarSettings = JSON.parse(localStorage.getItem('sidebar') as string);

    if (!isSidebarSettingsType(sidebarSettings)) {
      sidebarSettings = { open: false, collapsed: [] };
      localStorage.setItem('sidebar', JSON.stringify(sidebarSettings));
    }

    return sidebarSettings as SidebarSettingsType;
  } catch {
    sidebarSettings = { open: false, collapsed: [] };
    localStorage.setItem('sidebar', JSON.stringify(sidebarSettings));

    return sidebarSettings as SidebarSettingsType;
  }
};

const isSidebarSettingsType = (obj: any): obj is SidebarSettingsType => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'open' in obj &&
    typeof obj.open === 'boolean' &&
    'collapsed' in obj &&
    Array.isArray(obj.collapsed)
  );
};

export default AppPage;
