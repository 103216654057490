import { IModel } from '@/@types/models/model';

import buttonFields from './helpers/buttonFields';
import coreFields from './helpers/coreFields';

const KuceZaOdmor: IModel = {
  title: 'Kuće za odmor',
  source: 'kuce_za_odmor',
  layer: 'kuce_za_odmor',
  apiPath: 'ena/kuce-za-odmor',
  forms: {
    default: {
      fields: [
        'obveznik',
        'ko_ime',
        'kc',
        'adresa_objekta',
        'adresa_obveznika',
        'vlasnik',
        'povrsina',
        'komentar',
        'status',
      ],
    },
    update: {
      fields: ['komentar', 'status'],
    },
  },
  listViews: {
    default: {
      fields: [
        'obveznik',
        'ko_ime',
        'kc',
        'adresa_objekta',
        'naselje_objekta',
        'adresa_obveznika',
        'vlasnik',
        'povrsina',
        'status',
        'komentar',
        'btn_show_object',
        'btn_show_photos',
      ],
      hidden: ['id'],
    },
    default_with_3d: {
      fields: [
        'obveznik',
        'ko_ime',
        'kc',
        'adresa_objekta',
        'naselje_objekta',
        'adresa_obveznika',
        'vlasnik',
        'povrsina',
        'status',
        'komentar',
        'btn_show_object',
        'btn_show_photos',
        'btn_show_3d',
      ],
      hidden: ['id'],
    },
    secondary: {
      fields: [
        'obveznik',
        'ko_ime',
        'kc',
        'adresa_objekta',
        'adresa_obveznika',
        'vlasnik',
        'povrsina',
        'status',
        'komentar',
      ],
      hidden: ['id'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'Komunalni obveznik',
      source: 'obveznik',
      ttoken: 'ena:kuce_za_odmor.obveznik',
      type: 'text',
      filter: true,
    },
    {
      title: 'Komunalna općina',
      source: 'ko_ime',
      ttoken: 'ena:kuce_za_odmor.opcina',
      type: 'text',
      filter: true,
    },
    {
      title: 'Katastarska čestica',
      source: 'kc',
      ttoken: 'ena:kuce_za_odmor.cestica',
      type: 'text',
      filter: true,
    },
    {
      title: 'Adresa objekta',
      source: 'adresa_objekta',
      ttoken: 'ena:common.adresa_objekta',
      type: 'text',
      filter: true,
    },
    {
      title: 'Naselje objekta',
      source: 'naselje_objekta',
      ttoken: 'ena:common.naselje_objekta',
      type: 'text',
      filter: true,
    },
    {
      title: 'Adresa obveznika',
      source: 'adresa_obveznika',
      ttoken: 'ena:common.adresa_obveznika',
      type: 'text',
      filter: true,
    },
    {
      title: 'Vlasnik',
      source: 'vlasnik',
      ttoken: 'ena:kuce_za_odmor.vlasnik',
      type: 'text',
      filter: true,
    },
    {
      title: 'Površina',
      source: 'povrsina',
      ttoken: 'ena:kuce_za_odmor.povrsina',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Status',
      source: 'status',
      ttoken: 'common.status',
      type: 'picker',
      filter: true,
      items: {
        labels: ['common.yes', 'common.no', 'common.unknown'],
        values: [1, -1, 0],
      },
    },
    {
      title: 'Komentar',
      source: 'komentar',
      ttoken: 'ena:kuce_za_odmor.komentar',
      type: 'text',
      // rows: 3,
      // maxRows: 5,
      filter: true,
      validation: {
        maxLength: 1000,
      },
    },
    {
      title: 'cx',
      source: 'cx',
      ttoken: '',
      type: 'numeric',
      sort: true,
    },
    {
      title: 'cy',
      source: 'cy',
      ttoken: '',
      type: 'numeric',
      sort: true,
    },
    {
      title: 'cz',
      source: 'cz',
      ttoken: '',
      type: 'numeric',
      sort: true,
    },
    {
      title: 'Lokacija ID',
      source: 'lokacija_id',
      ttoken: '',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'Objekt ID',
      source: 'objekt_id',
      ttoken: '',
      type: 'numeric',
      readonly: true,
    },
    {
      ...coreFields.geom,
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
    },
    coreFields.gid,
    buttonFields.show_3d,
    buttonFields.show_object,
    buttonFields.show_photos,
  ],
};

export default KuceZaOdmor;
