import * as React from 'react';

import Box from '@mui/material/Box';
import AppPage from '@/ui/AppPage/AppPage';
import Permissions from '@/lib/permissions';
import SearchSettingsTable from './SearchSettingsTable';

const SearchSettingsPage = () => (
  <AppPage
    titlett="admin:search_settings"
    needPermission={[Permissions.Administration]}
  >
    <Box m={0} style={{ height: '100%' }}>
      <SearchSettingsTable />
    </Box>
  </AppPage>
);

export default SearchSettingsPage;
