import React from 'react';

// MUI
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { Icon } from '@mui/material';

// Types
export interface IMapCardAvatar extends AvatarProps {
  icon: string;
  ariaLabel?: string;
}

const MapCardAvatar = (props: IMapCardAvatar) => {
  const { icon, ariaLabel } = props;

  return (
    <Avatar
      aria-label={ariaLabel}
      sx={{
        '.MuiAvatar-root': {
          backgroundColor: 'common.white',
        },
      }}
    >
      <Icon>
        {icon}
      </Icon>
    </Avatar>
  );
};

export default MapCardAvatar;
