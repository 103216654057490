import React, { FunctionComponent } from 'react';

// MUI
import Box from '@mui/material/Box';

const SidebarTabs: FunctionComponent<any> = (props) => {
  const { children } = props;

  return (
    <Box
      m={0}
      p={0}
      className="sidebar-tabs"
      sx={{
        top: 0,
        bottom: 0,
        height: '100%',
        bgcolor: 'primary.light',
      }}
    >
      {children}
    </Box>
  );
};

export default SidebarTabs;
