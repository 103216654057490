// The handleResponse function checks responses from the api to see if the request was unauthorised, forbidden or unsuccessful.

// If the response status is 401 Unauthorized or 403 Forbidden then the user is automatically logged out of the application,
// this handles if the user token is no longer valid for any reason. If the response contains an error
// then a rejected promise is returned that includes the error message, otherwise if the request was successful then the response data is returned as a JSON object.

export default async function handleResponse(response: any): Promise<JSON> {
  const text = await response.text();
  const data = text ? JSON.parse(text) : null;

  if (!response.ok) {
    if ([403].indexOf(response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // authService.logout();

      // if errcode 6 (licence expired)
      if (data.errcode && data.errcode === 6) {
        return Promise.reject({
          message: response.statusText,
          status: response.status,
          detail: {
            errcode: data.errcode,
            errmsg: data.message,
          },
        });
      }
      localStorage.removeItem('auth_token');
      window.location.href = "/"
    }
    const error = (data && data.message) || response.statusText;
    try {
      const detailParsed = data || {
        errcode: 101,
        errmsg: 'notifications.no_message_received',
      };
      return await Promise.reject({ message: error, detail: detailParsed });
    } catch (err) {
      return Promise.reject({
        message: err,
        detail: { errcode: 101, errmsg: 'notifications.no_message_received' },
      });
    }
  }

  return data;
}

// TODO see what to do with this function

// export async function handleFileResponse(response) {
//   if (!response.ok) {
//     if ([401, 403].indexOf(response.status) !== -1) {
//       // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
//       //authService.logout();
//       window.location.reload();
//     }
//     return response.text()
//     .then(text => {
//       const data = text && JSON.parse(text);
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject({ message: error, detail: data.detail });
//     })
//   }

//   const cd = response.headers.get('content-disposition');
//   const isAttach = cd && cd.indexOf('attachment') === 0 ? true : false;
//   const ind = cd ? cd.indexOf('filename=') : -1;
//   const fileName = cd && ind !== -1 ? cd.substr(ind + "filename=".length) : null;

//   return response.text()
//   .then(text => {
//     return {
//       attachment: isAttach,
//       filename: fileName,
//       data: text
//     }

//   })
// }
