import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const AppLogs: IModel = {
  title: 'App Logs',
  apiPath: 'system/app_logs',
  forms: {
    default: {
      fields: ['id', 'action_id', 'caller_ip', 'caller_hostname', 'response_status'],
    },
    search: {
      fields: ['id', 'action_id', 'caller_ip', 'caller_hostname', 'response_status'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'action_id', 'caller_ip', 'caller_hostname', 'response_status'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'action_id',
      source: 'action_id',
      ttoken: 'admin:app_logs.action_id',
      type: 'wholenum',
      filter: true,
      validation: {
        required: true
      }
    },
    {
      title: 'caller_ip',
      source: 'caller_ip',
      ttoken: 'admin:app_logs.caller_ip',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 50,
      },
    },
    {
      title: 'caller_hostname',
      source: 'caller_hostname',
      ttoken: 'admin:app_logs.caller_hostname',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 500,
      },
    },
    {
      title: 'response_status',
      source: 'response_status',
      ttoken: 'admin:app_logs.response_status',
      type: 'wholenum',
      filter: true,
    }
  ],
};

export default AppLogs;
