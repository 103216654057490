import * as React from 'react';
import { FunctionComponent } from 'react';

// MUI
import DialogTitle from '@mui/material/DialogTitle';

// Types
import { IDialogHeader } from '@/@types/ui/Dialog';

const DialogHeader: FunctionComponent<IDialogHeader> = (props) => {
  const { children, color } = props;

  return (
    <DialogTitle
      className="dialogTitle grabbable"
      sx={{
        color: 'common.white',
        backgroundColor: color || 'primary.main',
        padding: {xs: "5px", md: "16px 24px"}
      }}
    >
      {children}
    </DialogTitle>
  );
};

export default DialogHeader;
