/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

// Types
import { TCustomGFIActionProps } from '@/@types/views/map';

// OL
import { transform as OlTransform } from 'ol/proj';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import Permissions from '@/lib/permissions';

// MUI
import LaunchIcon from '@mui/icons-material/Launch';

const Show3DButton = (props: TCustomGFIActionProps) => {
  const { t } = useTranslation();

  const { featureData } = props;

  const handleShow3D = () => {
    const [cx, cy] = OlTransform([featureData.position[0], featureData.position[1]], 'EPSG:3857', 'EPSG:4326').map(
      (x) => (x * Math.PI) / 180
    );

    // todo add z coordinate
    const url = `/3d?cx=${cx}&cy=${cy}&cz=0`;

    window.open(url, '_blank');
  };

  return (
    <MapCardActionButton
      onClick={handleShow3D}
      aria-label="3d"
      disabled={false}
      sx={{ minWidth: '40px' }}
      needPermission={[Permissions.ThreeDView]}
      tooltip={t("buttons.show_3d") as string}
    >
        <span style={{fontWeight:700}}>3D</span>
      <LaunchIcon style={{ marginLeft: '7px', width: '16px', height: '16px' }} />
    </MapCardActionButton>
  );
};

export default Show3DButton;
