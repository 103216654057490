/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

// Open Layers
import OlFormatWKT from 'ol/format/WKT';
import OlPoint from 'ol/geom/Point';
import { Point } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import DataController from '@/lib/DataController';
import model from '@/models/mapviews';
import MapContext from '@/context/MapContext/MapContext';
import DialogContext from '@/context/DialogContext/DialogContext';
import MapAddViewDialog from '@/components/MapDialogs/MapAddViewDialog';

// Types
import { DCRecord } from '@/@types/lib/dataController';

const MapViewsPane = () => {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const mapContext = useContext(MapContext);
  const [records, setRecords] = useState<DCRecord[]>([]);

  const wktFormatter = new OlFormatWKT();
  const dc = new DataController(model);

  function refreshData() {
    dc.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setRecords(resp.data);
        } else {
          setRecords([]);
        }
      }
    });
  }

  function handleDialogClose() {
    refreshData();
  }

  useEffect(() => {
    refreshData();
  }, []);

  function handleRowAction(action: string, id: number) {
    const record = records.find((x: DCRecord) => x.id === id);

    if (!record) return;

    const { zoom } = record;

    const geom = wktFormatter.readGeometry(record.center as string, {
      dataProjection: 'EPSG:3857',
      featureProjection: 'EPSG:3857',
    }) as OlPoint;

    const center = geom.getCoordinates();

    switch (action) {
      case 'show_on_map':
        // @ts-ignore
        handleShowOnMap(center, zoom);
        break;
      case 'delete':
        handleDelete(id);
        break;
      default:
        break;
    }
  }

  function handleShowOnMap(center: Coordinate, zoom: number) {
    if (mapContext?.map) {
      const v = mapContext.map.getView();
      v.animate({
        center,
        zoom,
        duration: 500,
      });
    }
  }

  function handleDelete(id: number) {
    dc.DeleteRecord(id).then(() => refreshData());
  }

  function handleAdd() {
    if (!mapContext || !mapContext.map) return;

    const view = mapContext.map.getView();
    const zoom = view.getZoom();
    const center = view.getCenter();

    if (!center) return; // REFACTOR
    const point = new Point(center);
    const wktPoint = wktFormatter.writeGeometry(point);

    dialogContext.showDialog(MapAddViewDialog, {
      // @ts-ignore
      dc,
      form: 'default',
      mode: 'insert',
      // @ts-ignore
      onClose: handleDialogClose,
      // @ts-ignore
      defaultValues: { zoom, center: wktPoint },
    });
  }

  const recordsNotEmpty = records.length > 0;

  if (recordsNotEmpty) {
    return (
      <ModelTable
        key={`tblid-${records.length}`}
        title={t('titles.map_views')}
        dc={dc}
        tableName={`${t('titles.map_views')}`}
        addLabel={`${t('buttons.new_f')}`}
        records={records}
        allowSelection="none"
        allowSearch={false}
        allowColumnPicker={false}
        allowAdd
        handleAdd={handleAdd}
        onRowAction={handleRowAction}
        allowRowAction={() => true}
        viewName=""
      />
    );
  }
  return (
    <>
      <Typography gutterBottom>{t('map:map_view.empty')}</Typography>
      <Button color="primary" onClick={() => handleAdd()} variant="contained">
        <Add />
        {` ${t('buttons.new_map_view')}`}
      </Button>
    </>
  );
};

export default MapViewsPane;
