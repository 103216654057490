/* eslint-disable */
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI Icons
import AddIcon from '@mui/icons-material/Add';

// Custom Components
import DialogActionButton from '@/ui/Dialog/DialogActionButton';

// Types
import { IDialogActionButtonDetail } from '@/@types/ui/Dialog';

const DialogActionButtonAdd: FunctionComponent<IDialogActionButtonDetail> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <DialogActionButton
      startIcon={<AddIcon />}
      {...props}
      ariaLabel="add"
      ariaControls="add"
    >
      {`${t('buttons.add')}`}
    </DialogActionButton>
  );
};

export default DialogActionButtonAdd;
