/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

// d.ts

// Material-UI Core Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DCRecord } from '@/@types/lib/dataController';
import DataController, { IDataController } from '@/lib/DataController';
import { ResetPasswordToken } from '@/@types/views/Auth/passwordreset';

// Custom Components
import AppPage from '@/ui/AppPage/AppPage';
import Loader from '@/ui/Loader/Loader';
import PasswordResetForm from '@/views/core/Auth/PasswordResetForm';

import model from '@/models/user';

import { authService } from '@/services/authService';

const PasswordResetPage = () => {
  const [token, setToken] = useState<ResetPasswordToken>({
    ok: null,
    email: null,
    username: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const dc: IDataController = new DataController(model);

  useEffect(() => {
    if (paramToken) {
      setIsLoading(true);
      authService
        .checkResetPasswordToken(paramToken)
        .then((response) => {
          setToken({
            ok: response.success,
            email: response.email,
            username: response.username,
          });
        })
        .catch((error) => {
          setToken({
            ok: false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const getQueryToken = () => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    return token;
  };

  const paramToken = getQueryToken();

  let defaultValues: DCRecord = {};
  if (token.ok && token.email) {
    defaultValues = {
      email: token.email,
      passwordreset_token: paramToken,
    };
  }

  return (
    <AppPage hideNavDrawer showBg titlett="page.password_reset">
      <Box m={2}>
        <Container maxWidth="sm">
          {
          /*token.ok === null ? (
            <Card>
              <CardContent>
                <Typography variant="body1">
                  {t('messages.please_wait')}
                </Typography>
              </CardContent>
            </Card>
          ) : */token.ok ? (
            <PasswordResetForm
              dc={dc}
              form="password_reset"
              mode="insert"
              onClose={() => {}}
              // @ts-ignore
              initialRecord={defaultValues}
            />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="body1">
                  {t('messages.passwordreset_token_unknown')}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Container>
        <Loader open={isLoading} />
      </Box>
    </AppPage>
  );
};

export default PasswordResetPage;
