/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// MUI
import Button, { ButtonProps } from '@mui/material/Button';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { UserContextType } from '@/context/UserContext/UserContext';
import { Permission } from '@/@types/common';

export interface IFormButton extends ButtonProps {
  needPermission?: Permission[];
}

const FormButton: FunctionComponent<IFormButton> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { needPermission, ...rest } = props;

  if (needPermission && !userContext.hasAnyPermission(needPermission)) {
    return null;
  }
  return (
    <Button
      color="primary"
      size="medium"
      sx={{
        mx: 1,
      }}
      {...rest}
    >
      {props.children}
    </Button>
  );
};

export default FormButton;
