/* eslint-disable */
import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// MUI
import IconButton from '@mui/material/IconButton';

// Custom Components
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

// Types
import { IDialogToolbarButton } from '@/@types/ui/Dialog';

const DialogToolbarButton: FunctionComponent<IDialogToolbarButton> = (
  props
) => {
  const userContext = useContext(UserContext) as UserContextType;

  const { needPermission, onClick, ariaLabel, ariaControls } = props;

  return needPermission &&
    !userContext.hasAnyPermission(needPermission) ? null : (
    <IconButton
      className="nodrag"
      color="inherit"
      onClick={onClick}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
    >
      {props.children}
    </IconButton>
  );
};

export default DialogToolbarButton;
