import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import DialogContext, { DialogContextType }  from '@/context/DialogContext/DialogContext';
import LoaderContext, { LoaderContextType } from '@/context/LoaderContext/LoaderContext';
import MapLayersDialog from './MapLayersDialog';
import MapLayerModel from '@/models/map_layer';
import DataController, { IDataController } from '@/lib/DataController';

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them
const MapLayersTable = () => {
  const navigate = useNavigate();
  const { showDialog } = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new DataController(MapLayerModel);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    showDialog(MapLayersDialog, {
      dc,
      mode: 'insert',
      form: 'insert',
      onClose: handleDialogClose,
    });
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = Object.keys(sel)[0]; // neven check if works
    navigate(`/admin/map_layers/${  sel[selId].id}`);
  };

  const handleRowActionMapLayer = (action: string, id: number, record?: { [key: string]: any }) => {
    if (action === 'copy') {
      showDialog(MapLayersDialog, {
        dc,
        mode: 'insert',
        form: 'insert',
        dataId: id,
        onClose: handleDialogClose,
        initalRecord: record || undefined,
      });
    };
  };


  const handleDialogClose = (result: {
    dataChanged: boolean;
    action: string;
    id: number;
  }) => {
    if (result.dataChanged && result.action !== 'cancel') {
      refreshRecords();
    }
    if (result.action === 'insert') {
      navigate(`/admin/map_layers/${result.id}`);
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            setRecords(resp.data);
          } else {
            setRecords([]);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  return (
    <ModelTable
      title={t('admin:titles.map_layers')}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter
      allowExport
      allowAdd
      allowColumnPicker
      addLabel={t('buttons.new_m') as string}
      handleAdd={handleAdd}
      onRowAction={handleRowActionMapLayer}
      allowRowAction={() => true}
      unselectRow={false}
      onDoubleClick={handleEdit}
    >
      <TableButtonEdit variant="contained" onClick={handleEdit} />
    </ModelTable>
  );
}

export default MapLayersTable;
