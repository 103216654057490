import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const KupoprodajniUgovori: IModel = {
  title: 'Ugovori',
  apiPath: 'data/kupoprodajni-ugovori',
  forms: {
    default: {
      fields: ['id'],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'zkc_id',
        'zkc_datum',
        'ix',
        'zkc_cij_e_m2',
        'zkc_stan_pov',
        'razred',
        'nek_status_sif_tekst',
        'status',
        'indeks',
      ],
      hidden: ['id', 'zkc_id', 'status', 'indeks', 'zkc_cij_e_m2'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'ZKC ID',
      source: 'zkc_id',
      ttoken: 'kupoprodajni_ugovori.zkc_id',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'cjenovni_blok_id',
      source: 'cjenovni_blok_id',
      ttoken: 'kupoprodajni_ugovori.cjenovni_blok_id',
      type: 'picker',
      subModel: {
        apiPath: 'data/cjenovni-blokovi',
        format: '{label}',
        idattr: 'id',
      },
      filter: true,
      readonly: true,
    },
    {
      title: 'zkc_datum',
      source: 'zkc_datum',
      ttoken: 'kupoprodajni_ugovori.zkc_datum',
      type: 'date',
      filter: true,
      filterType: "year"
    },
    {
      title: 'nek_status_sif_tekst',
      source: 'nek_status_sif_tekst',
      ttoken: 'kupoprodajni_ugovori.nek_status',
      type: 'checkbox',
      items: {
        labels: [
          'Provedena evaluacija',
          'Izvorno unesen',
          'Evaluacija u tijeku',
          'Preuzeto od PU',
        ],
        values: [
          'Provedena evaluacija',
          'Izvorno unesen',
          'Evaluacija u tijeku',
          'Preuzeto od PU',
        ],
      },
      filter: true,
    },
    {
      title: 'zkc_cij_e_m2',
      source: 'zkc_cij_e_m2',
      ttoken: 'kupoprodajni_ugovori.zkc_cij_e_m2',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'zkc_stan_pov',
      source: 'zkc_stan_pov',
      ttoken: 'kupoprodajni_ugovori.zkc_stan_pov',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'razred',
      source: 'razred',
      ttoken: 'kupoprodajni_ugovori.razred',
      type: 'checkbox',
      items: {
        labels: [
          'Do 20,00',
          '20,01-35,00',
          '35,01-55,00',
          '55,01-75,00',
          '75,01-100,00',
          'Od 100,01',
        ],
        values: [
          'Do 20,00:',
          '20,01-35,00:',
          '35,01-55,00:',
          '55,01-75,00:',
          '75,01-100,00:',
          'Od 100,01:',
        ],
      },
      filter: true,
    },
    {
      title: 'status',
      source: 'status',
      ttoken: 'kupoprodajni_ugovori.status',
      type: 'checkbox',
      items: {
        labels: [
          '1a-2023-ev-eut-iu',
          '1a-2023-ev-eut-iu-ppu',
          '1b-2023-ev-eut-iu',
          '1b-2023-ev-eut-iu-ppu',
          '1c-2023-ev-eut-iu',
          '1c-2023-ev-eut-iu-ppu',
          '1d-2023-ev-eut-iu',
          '1d-2023-ev-eut-iu-ppu',
          '1e-2023-ev-eut-iu',
          '1e-2023-ev-eut-iu-ppu',
          '1f-2023-ev-eut-iu',
          '1f-2023-ev-eut-iu-ppu',
          '1g-2023-ev-eut-iu',
          '1g-2023-ev-eut-iu-ppu',
          '1h-2023-ev-eut-iu',
          '1h-2023-ev-eut-iu-ppu',
          '1i-2023-ev-eut-iu',
          '1i-2023-ev-eut-iu-ppu',
          '1j-2023-ev-eut-iu',
          '1j-2023-ev-eut-iu-ppu',
          '2a-2023-ev-eut-iu',
          '2a-2023-ev-eut-iu-ppu',
          '2b-2023-ev-eut-iu',
          '2b-2023-ev-eut-iu-ppu',
          '2c-2023-ev-eut-iu',
          '2c-2023-ev-eut-iu-ppu',
          '2d-2023-ev-eut-iu',
          '2d-2023-ev-eut-iu-ppu',
          '2e-2023-ev-eut-iu',
          '2e-2023-ev-eut-iu-ppu',
          '2f-2023-ev-eut-iu',
          '2f-2023-ev-eut-iu-ppu',
          '2g-2023-ev-eut-iu',
          '2g-2023-ev-eut-iu-ppu',
          '2h-2023-ev-eut-iu',
          '2h-2023-ev-eut-iu-ppu',
          '2i-2023-ev-eut-iu',
          '2i-2023-ev-eut-iu-ppu',
          '2j-2023-ev-eut-iu',
          '2j-2023-ev-eut-iu-ppu',
          'izuzet iz izračuna prosječne vrijednosti',
          'izuzet iz izračuna prosječne vrijednosti za ukupno razdoblje',
          'nedovoljno podataka za analizu',
        ],
        values: [
          '1a-2023-ev-eut-iu',
          '1a-2023-ev-eut-iu-ppu',
          '1b-2023-ev-eut-iu',
          '1b-2023-ev-eut-iu-ppu',
          '1c-2023-ev-eut-iu',
          '1c-2023-ev-eut-iu-ppu',
          '1d-2023-ev-eut-iu',
          '1d-2023-ev-eut-iu-ppu',
          '1e-2023-ev-eut-iu',
          '1e-2023-ev-eut-iu-ppu',
          '1f-2023-ev-eut-iu',
          '1f-2023-ev-eut-iu-ppu',
          '1g-2023-ev-eut-iu',
          '1g-2023-ev-eut-iu-ppu',
          '1h-2023-ev-eut-iu',
          '1h-2023-ev-eut-iu-ppu',
          '1i-2023-ev-eut-iu',
          '1i-2023-ev-eut-iu-ppu',
          '1j-2023-ev-eut-iu',
          '1j-2023-ev-eut-iu-ppu',
          '2a-2023-ev-eut-iu',
          '2a-2023-ev-eut-iu-ppu',
          '2b-2023-ev-eut-iu',
          '2b-2023-ev-eut-iu-ppu',
          '2c-2023-ev-eut-iu',
          '2c-2023-ev-eut-iu-ppu',
          '2d-2023-ev-eut-iu',
          '2d-2023-ev-eut-iu-ppu',
          '2e-2023-ev-eut-iu',
          '2e-2023-ev-eut-iu-ppu',
          '2f-2023-ev-eut-iu',
          '2f-2023-ev-eut-iu-ppu',
          '2g-2023-ev-eut-iu',
          '2g-2023-ev-eut-iu-ppu',
          '2h-2023-ev-eut-iu',
          '2h-2023-ev-eut-iu-ppu',
          '2i-2023-ev-eut-iu',
          '2i-2023-ev-eut-iu-ppu',
          '2j-2023-ev-eut-iu',
          '2j-2023-ev-eut-iu-ppu',
          'izuzet iz izračuna prosječne vrijednosti',
          'izuzet iz izračuna prosječne vrijednosti za ukupno razdoblje',
          'nedovoljno podataka za analizu',
        ],
      },
      filter: true,
    },
    {
      title: 'indeks',
      source: 'indeks',
      ttoken: 'kupoprodajni_ugovori.indeks',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'ix',
      source: 'ix',
      ttoken: 'kupoprodajni_ugovori.ix',
      type: 'numeric',
      filter: true,
    },
    coreFields.geom,
  ],
};

export default KupoprodajniUgovori;
