import { createContext, Dispatch, SetStateAction } from 'react';

// Types
import { DCRecord } from '@/@types/lib/dataController';

export type DataContextType = {
  searchData: DCRecord[] | null;
  setSearchData: Dispatch<SetStateAction<DCRecord[] | null>>;
};

const DataContext = createContext<DataContextType | null>(null);

DataContext.displayName = 'DataContext';

export default DataContext;
