/* eslint-disable */
import { FunctionComponent, useContext, useEffect } from 'react';

//OpenLayers
import { DragPan } from 'ol/interaction';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlInteraction } from '@/lib/olHelpers';
import { Kinetic as OlKinetic } from 'ol';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IDragPanInteraction } from '@/@types/components/Map/Interactions/OpenLayers';

const DragPanInteraction: FunctionComponent<IDragPanInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const defaultKinetic = new OlKinetic(-0.005, 0.05, 100);

  const options = {
    condition: undefined,
    kinetic: defaultKinetic,
  };

  const events = {
    change: undefined,
    'change:active': undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(
    () => registerOlInteraction(context, DragPan, props, options, events),
    []
  );

  return null;
};

export default DragPanInteraction;
