/* eslint-disable */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";

//MUI Icons
import SaveIcon from "@mui/icons-material/Save";

//Custom Components
import CardActionButton from "@/ui/Card/CardActionButton";

//Types
import { ICardActionButtonDetail } from "@/@types/ui/Card";

const CardActionButtonSave: FunctionComponent<ICardActionButtonDetail> = (props) => {
  const { t } = useTranslation();

  return (
    <CardActionButton startIcon={<SaveIcon />} {...props} ariaLabel="Save" ariaControls="Save">
      {t("buttons.save")}
    </CardActionButton>
  );
};

export default CardActionButtonSave;
