import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const Images: IModel = {
  title: 'Imaegs',
  source: 'images',
  apiPath: 'images',
  forms: {
    default: {
      fields: ['id'],
    },
  },
  listViews: {
    default: {
      fields: ['id'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'Path',
      source: 'path',
      sort: false,
      ttoken: 'images.path',
      type: 'text',
    },
    {
      title: 'Thumb path',
      source: 'thumb_path',
      sort: false,
      ttoken: 'images.thumb_path',
      type: 'text',
    },
    {
      title: 'Serial',
      source: 'serial',
      ttoken: 'images.serial',
      type: 'text',
    },
    {
      title: 'Width',
      source: 'width',
      ttoken: 'images.width',
      type: 'wholenum',
    },
    {
      title: 'Height',
      source: 'height',
      ttoken: 'images.height',
      type: 'wholenum',
    },
    {
      title: 'Selected',
      source: 'selected',
      ttoken: 'images.selected',
      type: 'boolean',
    },
    {
      title: 'Object Point X',
      source: 'objpx',
      ttoken: 'images.objpx',
      type: 'wholenum',
    },
    {
      title: 'Object Point Y',
      source: 'objpy',
      ttoken: 'images.objpy',
      type: 'wholenum',
    },
    {
      title: 'Object Polygon',
      source: 'objpoly',
      ttoken: 'images.objpoly',
      type: 'text',
    },
  ],
};

export default Images;
