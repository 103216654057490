import React, { useEffect, useContext } from 'react';

// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Custom Components
// import PublicPage from '@/ui/PublicPage/PublicPage';
import LoginForm from '@/views/core/Auth/LoginForm';
import AppPage from '@/ui/AppPage/AppPage';

import model from '@/models/user';
import DataController from '@/lib/DataController';
import Permissions from '@/lib/permissions';

import TenantContext, { TenantContextType } from '@/context/TenantContext/TenantContext';

const LoginPage = () => {
  const dc = new DataController(model);

  const tenantContext = useContext(TenantContext) as TenantContextType;

  useEffect(()=>{
    if (localStorage.getItem('auth_token') !== tenantContext?.publicToken) {
      window.location.href = "/";
    }
  },[])

  return (
    <AppPage hideNavDrawer showBg titlett="page.login" needPermission={[Permissions.Elevate]}>
      <Box m={2}>
        <Container maxWidth="sm">
          <LoginForm dc={dc} form="login" mode="update" onClose={() => {}} />
        </Container>
      </Box>
    </AppPage>
  );
};

export default LoginPage;
