/* eslint-disable */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import OlFormatWKT from 'ol/format/WKT';

// MUI
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';

// Custom Components
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogActionButtonClose from '@/ui/Dialog/ActionButtons/DialogActionButtonClose';
import DialogContext, {
  IDialogProps,
  DialogContextType,
} from '@/context/DialogContext/DialogContext';
import imgService from '@/services/imgService';
import Canvas from './Canvas';

// Types
import { ClosingDetails } from '@/@types/components/formController';

import { DCRecord } from '@/@types/lib/dataController';

type Clip = [number, number, number, number];
type Ratio = [number, number];
type Pair<A, B> = [A, B];
type Coordinates = Pair<number, number>[] | null;

const PT_RADIUS = 5;
const IMAGE_WIDTH = 800;
const IMAGE_HEIGHT = IMAGE_WIDTH * 0.6666;
const MIN_WIDTH = 400;

const PhotoCentricImageDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  
  const { t } = useTranslation();
  const [b64, setB64] = useState<string>('');

  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { record, baseRecordPath, baseRecordId, onClose } = props;

  const [zoomClip, setZoomClip] = useState<Clip | null>(null);
  const [fullB64, setFullB64] = useState<string>('');
  const [initialB64, setInitialB64] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const imageId: number | null = record ? (record.id as number) : null;

  const handleClose = (evt: SyntheticEvent) => {
    close({ dataChanged: false, action: 'cancel' });
  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const getCoordinates = (wkt: string) => {
    let coords: Coordinates = null;
    try {
      const WKT = new OlFormatWKT();
      const geom = WKT.readGeometry(wkt);
      // @ts-ignore
      coords = geom.getCoordinates()[0];
    } catch (err) {
      // pass
    }
    return coords;
  };

  const getClip = (coords: Coordinates) => {
    const { width, height } = record;
    if (coords) {
      let minX = width * 2;
      let minY = height * 2;
      let maxX = -minX;
      let maxY = -minY;
      coords.forEach(([x, y]) => {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      });

      const dx = maxX - minX;
      const dy = maxY - minY;
      let pad = Math.max(dx, dy);
      if (2 * pad < MIN_WIDTH) {
        pad = MIN_WIDTH - pad;
      }
      const bx = minX - pad / 2;
      const by = minY - pad / 2;
      const ex = maxX + pad / 2;
      const ey = maxY + pad / 2;

      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          return Math.round(n + incX - decX);
        }
        return Math.round(n + incY - decY);
      }) as Clip;
    }

    const { objpx, objpy } = record;
    if (objpx && objpy) {
      const ratio = height / width;
      const sz = width / 8;
      const bx = objpx - sz;
      const by = objpy - sz * ratio;
      const ex = objpx + sz;
      const ey = objpy + sz * ratio;

      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          return Math.round(n + incX - decX);
        }
        return Math.round(n + incY - decY);
      }) as Clip;
    }

    return [0, 0, width, height] as Clip;
  };

  const getRatio = (clip: Clip, w: number, h: number) => {
    const [bx, by, ex, ey] = clip;
    const width = ex - bx;
    const height = ey - by;
    
    // Calculate the ratio relative to the full image
    const wRatio = w / width;
    const hRatio = h / height;

    return [wRatio, hRatio] as Ratio;
  };

  const drawPolygon = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { objpoly } = record;
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#00FF00';

    if (!record.objpoly) {
      return;
    }
    const coords = getCoordinates(objpoly);
    if (!coords) {
      return;
    }
    const clip = getClipNew();
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);

    for (let i = 0; i < coords.length; ++i) {
      const [_u, _v] = coords[i];
      const u = (_u - bx) * wRatio;
      const v = (_v - by) * hRatio;
      if (i === 0) {
        ctx.moveTo(u, v);
      }
      ctx.lineTo(u, v);
    }

    ctx.closePath();
    ctx.stroke();
  };

  const drawPoint = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { objpx, objpy, objpoly } = record;
    const coords = getCoordinates(objpoly);
    const clip = getClipNew();
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#FF0000';
    ctx.fillStyle = '#FF0000';

    const u = (objpx - bx) * wRatio;
    const v = (objpy - by) * hRatio;
    ctx.arc(u, v, PT_RADIUS, 0, 2 * Math.PI);

    ctx.fill();
  };

  const onImgLoad = () => {
    let _ctx: CanvasRenderingContext2D | null = null;
    const hasCanvas = canvasRef && canvasRef.current;
    if (hasCanvas) {
      _ctx = canvasRef.current.getContext('2d');
    }
    if (_ctx === null) {
      return;
    }
    const hasImg = imgRef && imgRef.current;
    const ctx: CanvasRenderingContext2D = _ctx as CanvasRenderingContext2D;
    if (hasCanvas && hasImg) {
      
      // jbg
      canvasRef.current.width = IMAGE_WIDTH;
      canvasRef.current.height = IMAGE_HEIGHT;
      
      ctx.drawImage(imgRef.current, 0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);

      drawPolygon(ctx, IMAGE_WIDTH, IMAGE_HEIGHT);
      drawPoint(ctx, IMAGE_WIDTH, IMAGE_HEIGHT);
    }
  };

  /** *************************************************************** New functions *********************************************************************** */
  const handleZoomInitial = () => {
    if (record.id !== null) {
      const { objpoly } = record;
      const coords = getCoordinates(objpoly);

      setZoomClip(calculateClip(coords));
    }
  };

  const handleZoomOut = () => {
    if (record) {
      setZoomClip([0, 0, record.width as number, record.height as number]);
    }
  };

  const getClipNew = () => {
    if (zoomClip !== null) {
      return zoomClip;
    }
    if (record !== null) {
      return [0, 0, record.width, record.height] as Clip;
    }
    return [0, 0, 0, 0] as Clip;
  };

  const calculateClip = (coords: Coordinates) => {
    const { width, height } = record;
    const ratio = height / width;

    if (coords) {
      let minX = width * 2;
      let minY = height * 2;
      let maxX = -minX;
      let maxY = -minY;
      coords.forEach(([x, y]) => {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      });

      const dx = maxX - minX;
      const dy = maxY - minY;
      let pad = Math.max(dx, dy);
      if (2 * pad < MIN_WIDTH) {
        pad = MIN_WIDTH - pad;
      }
      let bx = minX - pad / 2;
      let by = minY - pad / 2;
      let ex = maxX + pad / 2;
      let ey = maxY + pad / 2;

      const sizeX = ex - bx;
      const sizeY = ey - by;
      const maxSize = Math.max(sizeX, sizeY);
      if (maxSize === sizeX) {
        const correctHeight = sizeX * ratio;
        const padHeight = Math.floor((correctHeight - sizeY) / 2);
        by -= padHeight;
        ey += padHeight;
      } else if (maxSize === sizeY) {
        const correctWidth = sizeY / ratio;
        const padWidth = Math.floor((correctWidth - sizeX) / 2);
        bx -= padWidth;
        ex += padWidth;
      }

      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          return Math.round(n + incX - decX);
        }
        return Math.round(n + incY - decY);
      }) as Clip;
    }

    const { objpx, objpy } = record;
    if (objpx && objpy) {
      const sz = width / 8;
      const bx = objpx - sz;
      const by = objpy - sz * ratio;
      const ex = objpx + sz;
      const ey = objpy + sz * ratio;

      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          return Math.round(n + incX - decX);
        }
        return Math.round(n + incY - decY);
      }) as Clip;
    }

    return [0, 0, width, height] as Clip;
  };

  const calculateRealCoordinates = (
    x: number,
    y: number,
    w: number,
    h: number,
    currentZoomClip: Clip,
    img: DCRecord
  ) => {
    if (currentZoomClip && img && img.width && img.height) {
      const [bx, by, ex, ey] = currentZoomClip;
      const clip_width = ex - bx;
      const clip_height = ey - by;

      const zoom_start_x = Math.floor(x);
      const zoom_start_y = Math.floor(y);
      const zoom_end_x = Math.floor(x + w);
      const zoom_end_y = Math.floor(y + h);

      const clip_start_x = Math.floor(
        (zoom_start_x / IMAGE_WIDTH) * (clip_width as number)
      );
      const clip_start_y = Math.floor(
        (zoom_start_y / IMAGE_HEIGHT) * (clip_height as number)
      );
      const clip_end_x = Math.floor(
        (zoom_end_x / IMAGE_WIDTH) * (clip_width as number)
      );
      const clip_end_y = Math.floor(
        (zoom_end_y / IMAGE_HEIGHT) * (clip_height as number)
      );

      const real_start_x = bx + clip_start_x;
      const real_start_y = by + clip_start_y;
      const real_end_x = bx + clip_end_x;
      const real_end_y = by + clip_end_y;

      return [real_start_x, real_start_y, real_end_x, real_end_y] as Clip;
    }
    return null;
  };

  const handleStartDrawing = () => {};

  const handleDrawZoomBox = (x: number, y: number, w: number, h: number) => {
    if (zoomClip && record) {
      const newZoomClip = calculateRealCoordinates(
        x,
        y,
        w,
        h,
        zoomClip,
        record
      );
      if (newZoomClip) {
        setZoomClip(newZoomClip);
      }
    }
  };

  useEffect(() => {
    if (record.id !== null) {
      const { objpoly } = record;
      const coords = getCoordinates(objpoly);
      setZoomClip(calculateClip(coords));
      setFullB64('');
      setInitialB64('');
    }
  }, [imageId]);

  useEffect(() => {
    if (baseRecordId && record && record.id && zoomClip !== null) {
      const reqClip = getClipNew();
      const isFullImage = !!(
        reqClip[0] === 0 &&
        reqClip[1] === 0 &&
        reqClip[2] === record.width &&
        reqClip[3] === record.height
      );

      const { objpoly } = record;
      const coords = getCoordinates(objpoly);
      const initialClip = calculateClip(coords);
      const isInitialImage = !!(
        reqClip[0] === initialClip[0] &&
        reqClip[1] === initialClip[1] &&
        reqClip[2] === initialClip[2] &&
        reqClip[3] === initialClip[3]
      );

      if (isFullImage && fullB64 !== '') {
        setB64(fullB64);
      } else if (isInitialImage && initialB64 !== '') {
        setB64(initialB64);
      } else if (
        (isFullImage && fullB64 === '') ||
        (isInitialImage && initialB64 === '') ||
        (!isFullImage && !isInitialImage)
      ) {
        setIsLoading(true);
        imgService
          .getObjImage(
            baseRecordPath,
            baseRecordId,
            record.id as number,
            IMAGE_WIDTH,
            reqClip
          )
          .then((resp) => {
            if (resp.success) {
              setB64(resp.data.image);
              if (isFullImage) {
                setFullB64(resp.data.image);
              }
              if (isInitialImage) {
                setInitialB64(resp.data.image);
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [zoomClip]);

  const zoomedOutFully =
    record &&
    zoomClip &&
    zoomClip[0] === 0 &&
    zoomClip[1] === 0 &&
    zoomClip[2] === record.width &&
    zoomClip[3] === record.height;

  const imageLoaded = !!(!isLoading && record && b64.length);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  
  return (
    <Dialog
      open
      maxWidth="lg"
      fullScreen={!smUp}
      onClose={handleClose}
      PaperProps={{ sx: { width: '100%', maxWidth: IMAGE_WIDTH } }}
    >
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <DialogToolbarHeading>{t('titles.image')}</DialogToolbarHeading>
          <ToolbarFillContent />

          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogContent sx={{ p: 0, width: '100%', maxWidth: IMAGE_WIDTH, display: "flex", alignItems: "center" }}>
        {imageLoaded ? (
          <div style={{position: "relative", width: "100%"}} >
            <img
              ref={imgRef}
              src={`data:image/png;base64, ${b64}`}
              alt=""
              style={{ display: 'none' }}
              onLoad={() => onImgLoad()}
            />
            <Canvas
              ref={canvasRef}
              baseDraw={onImgLoad}
              onStartDrawing={handleStartDrawing}
              onDrawZoomBox={handleDrawZoomBox}
              onWheelDown={handleZoomOut}
              onCanvasClick={() => {}}
              viewOtherObjects={false}
              drawClip={zoomClip}
              containerWidth={IMAGE_WIDTH}
              containerHeight={IMAGE_HEIGHT}
              style={{
                width: '100%',
                maxWidth: IMAGE_WIDTH,
                height: 'auto',
                maxHeight: IMAGE_HEIGHT,
              }}
            />

            <Tooltip title={t('buttons.zoom_object') as string}>
              <Fab
                color="primary"
                size="small"
                sx={{ position: 'absolute', top: 16, right: 16, transform: {xs: "scale(0.85)", md: "unset"} }}
                onClick={handleZoomInitial}
              >
                <CenterFocusWeakIcon />
              </Fab>
            </Tooltip>
            <Tooltip title={t('buttons.zoom_out') as string}>
              <Fab
                color="primary"
                size="small"
                sx={{ position: 'absolute', bottom: 16, right: 16, transform: {xs: "scale(0.85)", md: "unset"} }}
                onClick={handleZoomOut}
                disabled={Boolean(zoomedOutFully)}
              >
                <ZoomOutIcon />
              </Fab>
            </Tooltip>
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={IMAGE_HEIGHT}
            animation="wave"
            style={{ display: 'inline-block' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {imageLoaded ? (
          mdUp ? 
            <Typography variant="caption">{t('messages.how_to_zoom')}</Typography> 
          :
          <Typography fontSize="1.1rem" variant="caption">{t('messages.how_to_zoom_mobile')}</Typography> 
          
        ) : null}
        {mdUp ? <DialogActionButtonClose variant="outlined" onClick={handleClose}/> : null}
      </DialogActions>
    </Dialog>
  );
};

export default PhotoCentricImageDialog;
