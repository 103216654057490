import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const GFIModel: IModel = {
  title: 'Models',
  apiPath: 'system/models',
  forms: {
    insert: {
      fields: ['code', 'opis', 'title_token'],
    },
    update: {
      fields: [
        'id',
        'code',
        'opis',
        'title_token',
        'title_field_id',
        'title_type',
        'icon'
      ],
    },
    search: {
      fields: ['code', 'opis'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'code', 'opis', 'icon'],
    },
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: 'code',
      source: 'code',
      ttoken: 'admin:common.code',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'opis',
      source: 'opis',
      ttoken: 'admin:models.opis',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 255,
      },
    },
    {
      title: 'title_token',
      source: 'title_token',
      ttoken: 'admin:models.title_token',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 250,
      },
    },
    {
      title: 'title_field_id',
      source: 'title_field_id',
      ttoken: 'admin:models.title_field',
      type: 'picker',
      subModel: {
        apiPath: 'system/models/{model_id}/fields?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      translate: true,
    },
    {
      title: 'title_type',
      source: 'title_type',
      ttoken: 'admin:models.title_type',
      type: 'radio',
      items: {
        labels: [
          'admin:models.title_types.entity_name',
          'admin:models.title_types.feature_field',
          'admin:models.title_types.combined',
        ],
        values: [1, 2, 3],
        default: 1,
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'icon',
      source: 'icon',
      ttoken: 'admin:models.icon',
      type: 'icon'
    },
  ],
};

export default GFIModel;
