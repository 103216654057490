import { createContext } from 'react';

// Types
export type MapConfigContexType = {
  revision: number;
  userLayers: any[];
  layer: any | undefined;
  filtered: boolean;
  addLayer: (layer: any) => void;
  setGridLayer: (layer: any) => void;
  setFiltered: (value: boolean) => void;
};

const MapConfigContext = createContext<MapConfigContexType | null>(null);

MapConfigContext.displayName = 'MapConfigContext';
export default MapConfigContext;
