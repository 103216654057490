/* eslint-disable */
import Control from "ol/control/Control";
import { Options } from "ol/control/Control";

export default class Status extends Control {

  constructor(opt_options: Options) {

    super(opt_options);

    var options = Object.assign({}, null, opt_options);

    var element = document.createElement('div');
    element.id = "status-control";
    element.className = "status";
    //@ts-ignore TODO: Property 'element' does not exist on type 'never'
    element.appendChild(options.element);
  }

}

//@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
if (window.ol && window.ol.control) {
  //@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
  window.ol.control.Status = Status;
}
