/* eslint-disable */
import * as React from "react";
import { useState } from "react";
import numbro from "numbro";

//Material-UI Core Components
import InputAdornment from "@mui/material/InputAdornment";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { ICurrencyControlProps } from "@/@types/controls/controls";

function CurrencyControl(props: ICurrencyControlProps) {
  const [isFocused, setIsFocused] = useState(false);
  const { field, value, onChange } = props;

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  const handleChange = (newValue: string | number | null, source: string) => {
    if (typeof newValue === "string") {
      if (newValue.length > 0 ) {
        const decValue = newValue.replace(".", ",");
        const valueIsValid = numbro(decValue).value() !== undefined;

        const numbers = newValue.split(",");
        if (numbers && numbers.length === 2 && numbers[1].length > 2) {
          return;
        }

        if (valueIsValid) {
          onChange(decValue, source);
        }
      } else if (typeof newValue === "number") {
        onChange(newValue, source);
      } else if (newValue === null || newValue === undefined) {
        onChange(null, source);
      }
    };
  }

  const formatEditingValue = (value: string | number | null): string => {
    if (typeof value === "string") {
      return value;
    } else if (typeof value === "number") {
      const isInt = Number.isInteger(value);
      return numbro(value).format({ thousandSeparated: false, mantissa: isInt ? 0 : 2 });
    } else if (value === null) {
      return "";
    }
    // else if (typeof value === "object") {
    //   const isInt = Number.isInteger(value.value());
    //   return value.format({ thousandSeparated: false, mantissa: isInt ? 0 : 2 });
    // }
    else {
      return value;
    }
  };

  const formatDisplayValue = (value: string | number | null): string => {
    const numValue = numbro(value);
    if (numValue.value() !== undefined && numValue.value() !== null) {
      return numValue.format({ thousandSeparated: true, mantissa: 2 });
    } else {
      return "";
    }
  };

  const hasValue = value !== undefined && value !== null;
  const currency = field && field.currency ? field.currency : "Kn";
  const negativeRed = field && field.negativeRed ? true : false;

  const displayValue = hasValue ? (isFocused ? formatEditingValue(value) : formatDisplayValue(value)) : "";

  return (
    <BaseInputControl
      {...props}
      value={displayValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}

      inputProps={{
        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        style: negativeRed ? { color: "#F00" } : {}
      }}
      inputLabelProps={{
        shrink: true
      }}
    />
  );
}

export default CurrencyControl;
