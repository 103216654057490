/* eslint-disable */
import React from "react";

import CardActions, { CardActionsProps } from "@mui/material/CardActions";

function MapCardActions(props: CardActionsProps) {
  return <CardActions {...props} sx={{ p: 1 }} disableSpacing={true} />;
}

export default MapCardActions;
