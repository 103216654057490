/* eslint-disable */
import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Material UI
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// Material-UI Icons
import LockIcon from '@mui/icons-material/Lock';
import EventIcon from '@mui/icons-material/Event';

// Custom Components
import { formats } from '@/lib/formats';
import { IDateTimeControlProps } from '@/@types/controls/controls';

// TODO:
// additional functionalities:
//    today button - with jump to today
//    there exists range picker but only for week on their page - it is probably possible to extend the functionality to pick the range on calendar

const DateTimeControl = (props: IDateTimeControlProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [dateNotValid, setDateNotValid] = useState<boolean>(false);

  const { field, value, onChange, validation, hasTime, controlMode } = props;

  const { t } = useTranslation();

  const handleSelectDate = (value: moment.Moment | string | null) => {
    setInputValue(null);

    if (typeof value === 'string') {
      const mom = moment.utc(value).local();
      applyDate(mom);
    } else if (moment.isMoment(value)) {
      if (hasTime === false) {
        value = value.set('hour', 12);
      }

      applyDate(value.local().toISOString());
    }
  };

  // const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {    
  //   if (evt.currentTarget) {
  //     const { value } = evt.currentTarget;
  //     if (value === '') {
  //       setDateNotValid(false);
  //     }

  //     setInputValue(value);
  //   }
  //   //@ts-ignore
  //   if (evt._d) {
  //     //@ts-ignore
  //     const { value } = evt._d;
  //     if (value === 'Invalid Date') {
  //       setDateNotValid(true);
  //     } else {
  //       setDateNotValid(false);
  //       //@ts-ignore
  //       handleSelectDate(evt);
  //     }

  //     setInputValue(value);
  //   } 
  // };

  // const handleFocusLost = () => {
  //   if (inputValue === '') {
  //     applyDate(inputValue);
  //   } else if (inputValue !== undefined) {
  //     let mom = moment(inputValue, formats.supported_date_formats);
  //     if (mom.isValid()) {
  //       if (hasTime === false) {
  //         mom = mom.set('hour', 12);
  //       }
  //       applyDate(mom.utc().toISOString());
  //     }
  //   }

  //   setIsFocused(false);
  // };

  const applyDate = (value: string | moment.Moment | null) => {
    let resultValue: moment.Moment | null = null;

    if (value === undefined || value === null) {
      resultValue = null;
      setDateNotValid(true);
    } else if (typeof value === 'string') {
      if (value === '') {
        resultValue = null;
        setDateNotValid(false);
      } else {
        const mom = moment.utc(value, formats.supported_date_formats);
        if (mom.isValid()) {
          resultValue = mom.utc();
          setDateNotValid(false);
        } else {
          resultValue = null;
          setDateNotValid(true);
        }
      }
    } else {
      resultValue = value.utc();
    }

    setInputValue(null);

    if (onChange) {
      onChange(resultValue, field.source);
    }
  };

  const isRequired = field.validation && field.validation.required;
  const label = t(field.ttoken);
  const hasError = validation && validation.valid === false;
  const isReadOnly = controlMode === 'view' || field.readonly;

  // TODO refactor
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {hasTime ? (
        <DateTimePicker
          label={t(label)}
          value={value ? moment.utc(value).local() : null} // Validation error occurs if trying to convert null to local time, hence ternary operator
          onChange={handleSelectDate}
          readOnly={isReadOnly}
          slots={{
            textField: TextField,
            openPickerIcon: isReadOnly ? LockIcon : EventIcon,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              helperText: dateNotValid
                ? t('validation.datetimeformat')
                : field.tooltip
                ? field.tooltip
                : hasError
                ? t(validation.msg)
                : ' ',
              required: isRequired,
              // onChange: handleInputChange,
              // onBlur: handleFocusLost,
              error: dateNotValid || hasError,
            },
          }}
        />
      ) : (
        <DatePicker
          label={t(label)}
          value={value ? moment.utc(value).local() : null} // Validation error occurs if trying to convert null to local time, hence ternary operator
          onChange={handleSelectDate}
          readOnly={isReadOnly}
          // components={{ OpenPickerIcon: isReadOnly ? LockIcon : EventIcon }}
          // renderInput={(params: any) => (
          //   <TextField
          //     fullWidth
          //     helperText={
          //       dateNotValid
          //         ? t('validation.dateformat')
          //         : field.tooltip
          //         ? field.tooltip
          //         : hasError
          //         ? t(validation.msg)
          //         : ' '
          //     }
          //     required={isRequired}
          //     onChange={handleInputChange}
          //     onBlur={handleFocusLost}
          //     {...params}
          //     error={dateNotValid || hasError}
          //   />
          // )}
          slots={{
            textField: TextField,
            openPickerIcon: isReadOnly ? LockIcon : EventIcon,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              helperText: dateNotValid
                ? t('validation.datetimeformat')
                : field.tooltip
                ? field.tooltip
                : hasError
                ? t(validation.msg)
                : ' ',
              required: isRequired,
              // onChange: handleInputChange,
              // onBlur: handleFocusLost,
              error: dateNotValid || hasError,
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default DateTimeControl;
