import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import buttonFields from './helpers/buttonFields';

const GFIModelFields: IModel = {
  title: 'Model Fields',
  apiPath: 'system/models/{id}/fields',
  forms: {
    insert: {
      fields: ['source', 'ftype', 'ttoken', 'sortorder'],
    },
    update: {
      fields: ['source', 'ftype', 'ttoken', 'sortorder'],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'model_id',
        'source',
        'ftype',
        'ttoken',
        'sortorder',
        'created_on',
        'created_by',
        'modified_on',
        'modified_by',
        'edit',
      ],
    },
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: 'model_id',
      source: 'model_id',
      ttoken: 'admin:common.model_id',
      type: 'numeric',
      validation: {
        required: true,
      },
    },
    {
      title: 'source',
      source: 'source',
      ttoken: 'admin:common.source',
      type: 'text',
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'ftype',
      source: 'ftype',
      ttoken: 'admin:fields.ftype',
      type: 'picker',
      items: {
        labels: ['text', 'numeric', 'wholenum', 'boolean', 'date', 'datetime', 'ttoken', 'images', 'link'],
        values: ['text', 'numeric', 'wholenum', 'boolean', 'date', 'datetime', 'ttoken', 'images', 'link'],
      },
      validation: {
        required: true,
        maxLength: 20,
      },
    },
    {
      title: 'ttoken',
      source: 'ttoken',
      ttoken: 'admin:fields.ttoken',
      type: 'text',
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'sortorder',
      source: 'sortorder',
      ttoken: 'core:common.sortorder',
      type: 'numeric',
    },
    buttonFields.edit,
  ],
};

export default GFIModelFields;
