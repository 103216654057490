/* eslint-disable */
import React from 'react';

import CardContent, { CardContentProps } from '@mui/material/CardContent';

const MapCardContent = (props: CardContentProps) => (
  <CardContent {...props} sx={{ p: 1 }} />
);

export default MapCardContent;
