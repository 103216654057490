/* eslint-disable */
import * as React from 'react';
import {
  CSSProperties,
  Fragment,
  FunctionComponent,
  SyntheticEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Status } from '@/@types/common';

// Types
export interface IFormHeaderStatusBreadCrumbs {
  statuses: Status[];
  recordStatus: number;
  showLabels: boolean;
  onClick: (evt: SyntheticEvent) => void;
}

export interface IBreadCrumb {
  length: number;
  isFinished: boolean;
  isActive: boolean;
  onClick: (evt: SyntheticEvent) => void;
  label: string | null;
}

const BreadCrumb: FunctionComponent<IBreadCrumb> = (props) => {
  const theme = useTheme();
  const { length, isFinished, isActive, onClick } = props;

  const { t } = useTranslation();

  const breadCrumbStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: `${(100 / length).toString()}%`,
    float: 'left',
    background: isFinished
      ? theme.palette.primary.light
      : isActive
      ? theme.palette.primary.dark
      : grey[500],
    color: '#FFF',
  };

  return (
    <div className="breadcrumb" style={breadCrumbStyle} onClick={onClick}>
      {props.label ? (
        <Typography
          variant="caption"
          component="span"
          align="center"
          className="noselect"
        >
          {t(props.label)}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
};

const FormHeaderStatusBreadCrumbs: FunctionComponent<
  IFormHeaderStatusBreadCrumbs
> = (props) => {
  const { statuses, recordStatus, showLabels, onClick } = props;

  const statusLength = statuses.length;

  return (
    <>
      {statuses.map((status, i) => (
        <BreadCrumb
          key={i}
          length={statusLength}
          isFinished={status.id < recordStatus}
          isActive={status.id === recordStatus}
          onClick={onClick}
          label={showLabels ? status.label : null}
        />
      ))}
    </>
  );
};

export default FormHeaderStatusBreadCrumbs;
