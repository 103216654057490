/* eslint-disable */
import React, { FunctionComponent, useContext, useEffect } from 'react';
import clsx from 'clsx';

//MUI
import { styled, useTheme } from '@mui/material/styles';

//OpenLayers
import Zoom from 'ol/control/Zoom';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlControl } from '@/lib/olHelpers';
import makeMUIControlStyle from '../makeMUIControlStyle';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IZoomControl } from '@/@types/components/Map/Controls/OpenLayers';

const ZoomControl: FunctionComponent<IZoomControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    duration: undefined,
    className: undefined,
    zoomInClassName: 'ol-zoom-in',
    zoomOutClassName: 'ol-zoom-out',
    zoomInLabel: undefined,
    zoomOutLabel: undefined,
    zoomInTipLabel: undefined,
    zoomOutTipLabel: undefined,
    delta: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(() => {
    const overridenProps = {
      ...props,
      className: clsx(props.className, 'ol-zoom'),
    };
    registerOlControl(context, Zoom, overridenProps, options, events);
  }, [
    props.duration,
    props.className,
    props.zoomInLabel,
    props.zoomOutLabel,
    props.zoomInTipLabel,
    props.zoomOutTipLabel,
    props.delta,
    props.target,
  ]);

  return null;
};

const StyledZoomControl = styled(ZoomControl)(({ theme, stickySidebar }: any) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control,
    ...(stickySidebar ? styles.sidebarSticky : {}),
    ...styles.zoom
  };
});

export default StyledZoomControl;
