/* eslint-disable */
// REACT
import React,{useContext} from 'react'

// MUI
import { useTheme } from '@mui/material';

// CUSTOM
import ThemeContext from '@/context/ThemeContext/ThemeContext';

// CONSTANTS
const MAX_LEVELS = 5;

const getNavDrawerBgLevelColors = (colorIncrement:number) =>{

    const theme = useTheme();
    const themeContext = useContext(ThemeContext);

    var currentColor = theme.palette.background.default;
  
    var levelColors = [];
  
    for (let i=0;i<MAX_LEVELS;i++) {
      currentColor = currentColor.replace(/^#/, '');
  
      let r = parseInt(currentColor.substring(0, 2), 16);
      let g = parseInt(currentColor.substring(2, 4), 16);
      let b = parseInt(currentColor.substring(4, 6), 16);
  
      if (themeContext?.theme==="light") {
        r = Math.max(0, r - colorIncrement);
        g = Math.max(0, g - colorIncrement);
        b = Math.max(0, b - colorIncrement);
      } else if (themeContext?.theme==="dark") {
        r = Math.min(255, r + colorIncrement);
        g = Math.min(255, g + colorIncrement);
        b = Math.min(255, b + colorIncrement);
      }
     
  
      currentColor = '#' +
      ('00' + r.toString(16)).slice(-2) +
      ('00' + g.toString(16)).slice(-2) +
      ('00' + b.toString(16)).slice(-2);
  
      levelColors.push(currentColor);
    }

    return levelColors;
}

const getOptimalTextColorForBackgroundColor = (bgColor: string) => { // bgcolor is hex string with #
  
  let red = parseInt(bgColor.substring(1, 3), 16);
  let green = parseInt(bgColor.substring(3, 5), 16);
  let blue = parseInt(bgColor.substring(5, 7), 16);

  if ((red*0.299 + green*0.587 + blue*0.114) > 186) {
    return "#424242"
  } else {
    return "#ffffff"
  }
}

export{ getNavDrawerBgLevelColors,getOptimalTextColorForBackgroundColor};