import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, CardContent, Fab, Menu, Stack, Tab, Tabs, Typography } from '@mui/material';

// MUI Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Custom Components
import MapCard from '@/ui/MapCard/MapCard';
import MapCardHeader from '@/ui/MapCard/MapCardHeader';
import MapCardAvatar from '@/ui/MapCard/MapCardAvatar';
import MapCardHeaderButtonClose from '@/ui/MapCard/MapCardHeaderButtonClose';
import MapCardHeaderButtonLayers from '@/ui/MapCard/MapCardHeaderButtonLayers';
import { GridContainer } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import GoogleMapsListItem from './LayerFunctionalities/GoogleMapsListItem';
import DialogContext from '@/context/DialogContext/DialogContext';
import GSInfoDialog from '@/components/Map/InfoCards/Dialogs/GSInfoDialog';
import useApi from '@/lib/api/useApi';

// Models
import modelData from '@/models/data';

// Types
import { IGFICardOverlayData, IGFIOverlayFeatureIndex } from '@/@types/views/map';
import { DCRecord } from '@/@types/lib/dataController';
import EnumLayerFunctionality from '@/@types/services/gsServiceEnums';
import PosjedniciButton from './LayerFunctionalities/PosjedniciButton';
import VlasniciButton from './LayerFunctionalities/VlasniciButton';
import MapCardActions from '@/ui/MapCard/MapCardActions';
import Permissions from '@/lib/permissions';
import UserContext, { UserContextType } from '@/context/UserContext/UserContext';
import AttachDocumentButtonListItem from './LayerFunctionalities/AttachDocumentButtonListItem';
import KuceZaOdmorButton from './LayerFunctionalities/KuceZaOdmorButton';
import DetachListItem from './LayerFunctionalities/DetachListItem';
import { GFIFeatureType, IGIDStats } from '@/@types/services/gsService';
import ShowKOButton from './LayerFunctionalities/ShowKOButton';
import ShowPhotodocButton from './LayerFunctionalities/ShowPhotodocButton';
import UkopaniButton from './LayerFunctionalities/UkopaniButton';
import CommentListItem from './LayerFunctionalities/CommentListItem';
import Show3DButton from './LayerFunctionalities/Show3DButton';

export interface GSInfoCardProps {
  featureData: IGFICardOverlayData;
  onClose: () => void;
  setIndex: React.Dispatch<React.SetStateAction<IGFIOverlayFeatureIndex>>;
  currentIndex: any;
  handlePopoverOverlayClick: any;
  layerPickerDisabled?: boolean;
  handleSetDetach: (newState: boolean) => void;
  isDetached: boolean;
}

const GSInfoCard = (props: GSInfoCardProps) => {
  const { t } = useTranslation();

  const { featureData, onClose, setIndex, currentIndex, handlePopoverOverlayClick, layerPickerDisabled, handleSetDetach, isDetached } =
    props;

  const [dataCache, setDataCache] = useState<Map<number, Map<String, DCRecord[]>>>(new Map<number, Map<String, DCRecord[]>>()); // To save requests made by Menu List Items (e.g. Vlasnici, Posjednici fetch)

  const [entityStats, setEntityStats] = useState<IGIDStats>({ loading: false, documents: 0, comments: 0 });

  const { hideDialog, showDialog } = useContext(DialogContext);
  const userContext = useContext(UserContext) as UserContextType;

  const { hasAnyPermission } = userContext;

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionsMenuOpen = Boolean(actionsMenuAnchorEl);
  const handleOpenActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setActionsMenuAnchorEl(event.currentTarget);
  };
  const handleCloseActionsMenu = () => {
    setActionsMenuAnchorEl(null);
  };

  const { layerData } = featureData;

  const { layer, model, layerFunctionalityId } = layerData;

  const api = useApi();

  const modelFields = model?.fields && model?.fields.length > 0 ? model.fields : modelData.fields; // default to data.ts model

  const record = useMemo(() => {
    if (featureData) {
      if (featureData.record) {
        return featureData.record;
      }
      if (featureData.features[currentIndex[layer]]) {
        return featureData.features[currentIndex[layer]].properties;
      }
      if (featureData.features[0]) {
        return featureData.features[0].properties;
      }
    }
    return null;
  }, [featureData, currentIndex, layer]);

  useEffect(() => {
    if (featureData && featureData.features[0] && !featureData.record) {
      if (!currentIndex[layer]) {
        // if it's not already set initialize it
        setIndex((prev) => {
          const obj = { ...prev };
          obj[layer] = 0; // Move the state update to `useEffect`
          return obj;
        });
      }
    }
  }, [featureData, setIndex, layer]);

  const handleAddToCache = (identifier: string, recordId: number, data: DCRecord[]) => {
    setDataCache((prevCache) => {
      const newCache = new Map(prevCache); // Create a new Map from the previous state
      const recordCache = newCache.get(recordId) || new Map<string, DCRecord[]>();
      recordCache.set(identifier, data);
      newCache.set(recordId, recordCache);
      return newCache; // Return the new Map to trigger a state update
    });
  };

  const handleGetFromCache = (identifier: string, recordId: number): DCRecord[] | null => {
    const recordCache = dataCache.get(recordId);
    if (recordCache) {
      return recordCache.get(identifier) || null;
    }
    return null;
  };

  const getEntityStats = () => {
    if (record.gid && userContext.hasAnyPermission([Permissions.Private])) {
      setEntityStats((prev) => ({ ...prev, loading: true }));
      api
        .get(`core/entity-stats/${record.gid}`)
        .then((resp) => {
          if (resp.success) {
            setEntityStats({ ...(resp.data as IGIDStats), loading: false });
          } else {
            setEntityStats({ comments: 0, documents: 0, loading: false });
          }
        })
        .catch(() => {
          setEntityStats({ comments: 0, documents: 0, loading: false });
        });
    } else {
      setEntityStats({ comments: 0, documents: 0, loading: false });
    }
  };

  useEffect(() => {
    getEntityStats();
  }, [record.gid]);

  const generateCustomActions = (): ReactNode[] => {
    const nodes: ReactNode[] = [];

    switch (layerFunctionalityId) {
      case EnumLayerFunctionality.dkp_show_posjednici_vlasnici:
        nodes.push(
          <PosjedniciButton
            key="posjednici-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        nodes.push(
          <VlasniciButton
            key="vlasnici-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        break;
      case EnumLayerFunctionality.dkp_show_posjednici:
        nodes.push(
          <PosjedniciButton
            key="posjednici-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        break;
      case EnumLayerFunctionality.kuce_za_odmor_button:
        nodes.push(
          <KuceZaOdmorButton
            key="kzo-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
            handleCloseMenu={() => {
              hideDialog();
            }}
          />
        );
        break;
      case EnumLayerFunctionality.objekti_ko_photodoc:
        nodes.push(<ShowKOButton key="ko-btn" featureData={featureData} layerIndex={currentIndex[featureData.layerData.layer]} />);
        nodes.push(
          <ShowPhotodocButton key="photodoc-btn" featureData={featureData} layerIndex={currentIndex[featureData.layerData.layer]} />
        );
        break;
      case EnumLayerFunctionality.grobna_mjesta_show_ukopani:
        nodes.push(
          <UkopaniButton
            key="ukopani-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        break;
      default:
        break;
    }
    if (hasAnyPermission([Permissions.ThreeDView])) {
      nodes.push(
        // Will not render if client does not have permission for 3D
        <Show3DButton key="show-3d-btn" featureData={featureData}/>
      );
    }

    return nodes;
  };

  const generateActionList = (): ReactNode[] => {
    const nodes: ReactNode[] = [];

    /* if (hasAnyPermission([Permissions.ThreeDView])) {
      nodes.push(
        // Will not render if client does not have permission for 3D
        <Show3DButtonListItem key="show-3d-li" handleCloseMenu={handleCloseActionsMenu} featureData={featureData} />
      );
    } */

    if (record.gid) {
      if (userContext?.hasAnyPermission([Permissions.Private])) {
        nodes.push(
          <AttachDocumentButtonListItem
            stats={{ loading: entityStats.loading, count: entityStats.documents }}
            key="attach-document-li"
            handleCloseMenu={handleCloseActionsMenu}
            gid={record.gid}
            onDialogClose={getEntityStats}
          />,
          <CommentListItem
            stats={{ loading: entityStats.loading, count: entityStats.comments }}
            key="comment-li"
            handleCloseMenu={handleCloseActionsMenu}
            gid={record.gid}
            feature={featureData.features[currentIndex[layer]]}
            onDialogClose={getEntityStats}
          />
        );
      }
    }

    switch (layerFunctionalityId) {
      case EnumLayerFunctionality.odvoz_location_button:
        break;
      case EnumLayerFunctionality.kuce_za_odmor_button:
        break;
      case EnumLayerFunctionality.google_maps_navigate_button:
        nodes.push(<GoogleMapsListItem key="google-maps-li" featureData={featureData} />);
        break;
      default:
        break;
    }
    nodes.push(
      <DetachListItem isDetached={isDetached} key="detach-li" handleDetach={handleSetDetach} closeMenu={handleCloseActionsMenu} />
    );

    return nodes;
  };

  const fields = modelFields;
  const fieldNames = modelFields.map((field) => field.source);

  const recordId = record.id ? record.id : record.fid ? record.fid : null;

  // const handleToggleKuceZaOdmor = () => {
  //   const newValue = !expandedKuceZaOdmor;
  //   setExpandedKuceZaOdmor(newValue);
  // };

  // const handleOdvozLocationRedirect = () => {
  //   const locId =
  //     initialRecod.lokacija_i === null
  //       ? ""
  //       : `&loc_id=${initialRecod.lokacija_i}`;
  //   if (locId !== "") {
  //     const url =
  //       location.origin +
  //       "/komunalne-obveze/?" +
  //       locId.toString() +
  //       "&open_first=true";
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/komunalne-obveze";
  //     window.open(url, "_blank");
  //   }
  // };

  // const handleKomunaneObvezeRedirect = () => {
  //   const locId =
  //   initialRecod.lokacija_id === null
  //   ? ""
  //   : `&loc_id=${initialRecod.lokacija_id}`;
  //   if (locId !== "") {
  //     const url =
  //       location.origin +
  //       "/komunalne-obveze/?" +
  //       locId.toString() +
  //       "&open_first=true";
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/komunalne-obveze";
  //     window.open(url, "_blank");
  //   }
  // };

  // const handleFotodokumentacijaRedirect = () => {
  //   const objektId =
  //     initialRecod.objekt_id === null
  //       ? ""
  //       : `${initialRecod.objekt_id}`;
  //   console.log(initialRecod)
  //   if (objektId !== "") {
  //     const url =
  //       location.origin +
  //       "/fotodokumentacija-objekata/?rec_id=" + objektId.toString();
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/fotodokumentacija-objekata";
  //     window.open(url, "_blank");
  //   }
  // };

  const modelTitle =
    model?.title_type === 1
      ? t(`${model.title_token}`)
      : model?.title_type === 2 && model?.title_field
      ? record[model.title_field]
      : model?.title_type === 3 && model?.title_field
      ? `${t(`${model.title_token}`)}: ${record[model.title_field]}`
      : null;

  const title: string = model ? modelTitle : record.naziv ? record.naziv : layer ? t(`map:layer.${layer}`) : 'Info';

  const GSFormContent = useMemo(
    () => (
      <GridContainer spacing={0}>
        <FormContent fields={fields} fieldNames={fieldNames} columns={1} mode="simpleView" record={record} />
      </GridContainer>
    ),
    [fields, fieldNames, record]
  );

  const handleOpenDialog = (node: ReactNode, identifier: string) => {
    showDialog(GSInfoDialog, {
      body: node,
      handleClose: () => {
        hideDialog();
      },
      identifier,
      FormContent: GSFormContent,
    });
  };

  const customActionList = generateActionList();
  const customActions = generateCustomActions();

  return (
    <MapCard
      sx={{
        backgroundColor: 'background.default',
        width: 'min-content',
        maxWidth: { xs: '300px', md: '500px' },
      }}
    >
      <MapCardHeader
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              maxWidth: '100%',
            }}
          >
            <Box display="flex" maxWidth="100%" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Stack justifyContent="center" color="white">
                <Typography
                  noWrap={!(title && title.length > 45)}
                  fontSize="0.9rem"
                  lineHeight={1.15}
                  variant="body1"
                  sx={{ maxWidth: '100%' }}
                >
                  {title}
                </Typography>
                {recordId && recordId !== null ? (
                  <Typography
                    noWrap
                    fontSize="0.85rem"
                    lineHeight={1.15}
                    sx={{ display: { xs: 'none', md: 'block' }, maxWidth: '100%' }}
                    variant="body2"
                  >{`${t('common.id')}: ${recordId}`}</Typography>
                ) : null}
              </Stack>
            </Box>
          </div>
        }
        disableTypography
        avatar={<MapCardAvatar ariaLabel="gfi_icon" icon={model?.icon ?? "info"} />}
        action={
          <Stack m={0} direction="row">
            {!layerPickerDisabled && <MapCardHeaderButtonLayers onClick={handlePopoverOverlayClick} />}
            <MapCardHeaderButtonClose onClick={onClose} />
          </Stack>
        }
      />
      {featureData.features && featureData.features.length > 1 ? (
        <Box width="100%" borderBottom="0.1px solid lightgray">
          <Tabs
            sx={{ minHeight: '24px', height: '24px' }}
            variant="scrollable"
            scrollButtons="auto"
            value={currentIndex[layer]}
            onChange={(event, value) => {
              setIndex((prev: any) => {
                const obj = { ...prev };
                obj[layer] = value;
                return obj;
              });
            }}
            aria-label="pagination tabs"
            TabScrollButtonProps={{
              sx: {
                transition: 'width 0.15s',
                '&.Mui-disabled': {
                  width: 0,
                },
              },
            }}
          >
            {featureData.features.map((feat: GFIFeatureType, index: number) => (
              <Tab
                key={`feature-tab-${feat.id}}`}
                sx={{
                  flexGrow: 1,
                  borderRight: index !== featureData.features.length - 1 ? '0.1px solid lightgray' : '',
                  p: 0,
                  height: '24px',
                  minHeight: '24px',
                  minWidth: '48px',
                }}
                label={index + 1}
              />
            ))}
          </Tabs>
        </Box>
      ) : null}
      <CardContent
        sx={{
          p: 1,
          '&:last-child': {
            paddingBottom: 1,
          },
          width: '100%',
        }}
      >
        <Box display="flex" gap={1} width="100%">
          <GridContainer spacing={0} width="calc( 100% - 40px )">
            {GSFormContent}
          </GridContainer>
          {!customActions.length && customActionList.length ? (
            <>
              <Fab
                color="primary"
                aria-label="more"
                id="long-button"
                aria-controls={actionsMenuOpen ? 'long-menu' : undefined}
                aria-expanded={actionsMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenActionsMenu}
                size="small"
                sx={{
                  minWidth: '32px',
                  minHeight: '32px',
                  height: '32px',
                  width: '32px',
                  alignSelf: 'flex-end',
                }}
              >
                <MoreVertIcon fontSize="medium" />
              </Fab>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                sx={{ p: 0 }}
                anchorEl={actionsMenuAnchorEl}
                open={actionsMenuOpen}
                onClose={handleCloseActionsMenu}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                {customActionList}
              </Menu>
            </>
          ) : null}
        </Box>
      </CardContent>
      {customActions.length ? (
        <MapCardActions>
          <Box display="flex" justifyContent="space-between" width="100%" sx={{ gap: { xs: 1, md: 0 } }}>
            <Box display="flex" width="100%" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              {customActions}
            </Box>
            {customActionList.length ? (
              <Box width="fit-content" display="flex" alignItems="flex-end">
                <Fab
                  color="primary"
                  aria-label="more"
                  id="long-button"
                  aria-controls={actionsMenuOpen ? 'long-menu' : undefined}
                  aria-expanded={actionsMenuOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleOpenActionsMenu}
                  size="small"
                  sx={{
                    minWidth: '32px',
                    minHeight: '32px',
                    height: '32px',
                    width: '32px',
                    alignSelf: 'flex-end',
                  }}
                >
                  <MoreVertIcon fontSize="medium" />
                </Fab>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  sx={{ p: 0 }}
                  anchorEl={actionsMenuAnchorEl}
                  open={actionsMenuOpen}
                  onClose={handleCloseActionsMenu}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                  {customActionList}
                </Menu>
              </Box>
            ) : null}
          </Box>
        </MapCardActions>
      ) : null}
    </MapCard>
  );
};

export default GSInfoCard;
