import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

interface CustomBadgeProps {
  badgeContent: ReactNode;
  showZero?: boolean;
  height?: number;
  bgColor?: string;
  maxContent?: number;
  loading?: boolean;
}

const CustomBadge: React.FC<CustomBadgeProps> = ({ badgeContent, showZero = false, height = 18, bgColor, maxContent, loading = false }) => {
  const theme = useTheme();

  const displayContent = React.useMemo(() => {
    if (typeof badgeContent === 'number' && maxContent !== undefined) {
      return badgeContent > maxContent ? `${maxContent}+` : badgeContent;
    }
    return badgeContent;
  }, [badgeContent, maxContent]);

  if (!showZero && badgeContent === 0 && !loading) {
    return null;
  }

  const computedBgColor = bgColor || theme.palette.primary.main;
  const badgeWidth = Math.max(height, String(displayContent).length * (height / 2));

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: badgeWidth,
        height: `${height}px`,
        fontSize: `${height * 0.7}px`,
        color: getOptimalTextColorForBackgroundColor(computedBgColor),
        borderRadius: height,
        backgroundColor: computedBgColor,
        padding: '0 4px',
        fontWeight: 900,
      }}
    >
      {loading ? (
        <CircularProgress size={height * 0.7} style={{ color: getOptimalTextColorForBackgroundColor(computedBgColor) }} />
      ) : (
        displayContent
      )}
    </div>
  );
};

export default CustomBadge;
