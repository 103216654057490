/* eslint-disable */
import * as React from 'react';
import { FunctionComponent } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Types
import { IDialogToolbarHeading } from '@/@types/ui/Dialog';

const DialogToolbarHeading: FunctionComponent<IDialogToolbarHeading> = (
  props
) => {
  const { children, header, subheader, lowercase } = props;

  const typographyStyle = {
    mx: 2,
  };

  return header ? (
    <Stack spacing={0} direction="column">
      <Typography align="left" variant="subtitle1" sx={typographyStyle}>
        {header}
      </Typography>
      {subheader ? (
        <Typography align="left" variant="subtitle2" sx={typographyStyle}>
          {subheader}
        </Typography>
      ) : null}
    </Stack>
  ) : (
    <Typography align="left" variant="subtitle1" sx={typographyStyle}>
      {children}
    </Typography>
  );
};

export default DialogToolbarHeading;
