/* eslint-disable */
import React, { FunctionComponent } from 'react';

// MUI
import IconButton from '@mui/material/IconButton';

// Types
import { ICardToolbarButton } from '@/@types/ui/Card';

const CardToolbarButton: FunctionComponent<ICardToolbarButton> = (props) => (
  <IconButton className="nodrag" color="inherit" {...props}>
    {props.children}
  </IconButton>
);

export default CardToolbarButton;
