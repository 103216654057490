/* eslint-disable */
import React from 'react';
import PeopleIcon from '@mui/icons-material/People';

//Types
import { SvgIconProps } from '@mui/material';

function UserIcon(props: SvgIconProps) {
  return <PeopleIcon {...props} />;
}

export default UserIcon;
