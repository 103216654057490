/* eslint-disable */
import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import CancelIcon from "@mui/icons-material/Cancel";

//Custom Components
import DialogActionButton from "@/ui/Dialog/DialogActionButton";

//Types
import { IDialogActionButtonDetail } from "@/@types/ui/Dialog";

const DialogActionButtonCancel: FunctionComponent<IDialogActionButtonDetail> = (props) => {
  const { t } = useTranslation();

  return (
    
    <DialogActionButton startIcon={<CancelIcon />} {...props} ariaLabel="cancel" ariaControls="cancel">
      
      {t("buttons.cancel")}

    </DialogActionButton>
  );
};

export default DialogActionButtonCancel;
