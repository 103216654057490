/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import {renderToString} from 'react-dom/server'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import proj4 from 'proj4';
import { transform } from "ol/proj";
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Collapse, createStyles, Theme, Tooltip, useTheme } from '@mui/material';
import OlFormatWKT from "ol/format/WKT";

import {
  Cartesian2,
  Cartesian3,
  Rectangle,
  Camera,
  Matrix4,
  EasingFunction,
  Color,
  ScreenSpaceEventHandler,
  Viewer,
  Scene,
  Globe,
  Ellipsoid,
  EllipsoidGeodesic,
  HorizontalOrigin,
  VerticalOrigin,
  ProviderViewModel,
  WebMapServiceImageryProvider,
  OpenStreetMapImageryProvider,
  Cesium3DTileset,
  Cartographic,
  PolylineCollection,
  buildModuleUrl,
  PointPrimitiveCollection,
  Credit,
  Resource,
  SceneMode,
  defined,
  Material,
  ScreenSpaceEventType,
  PointPrimitive,
  Polyline,
  Math as CesiumMath,
  Entity,
  NearFarScalar,
  LabelStyle,
  JulianDate,
  CesiumTerrainProvider,
  RequestErrorEvent
} from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';

import { styled } from '@mui/material';

import useApi, { IApi } from '@/lib/api/useApi';
import authHeader from '@/lib/api/authHeader';
import { DCRecord } from '@/@types/lib/dataController';
import { LokacijeData } from '@/@types/views/ThreeD';
import OlPoint from 'ol/geom/Point';
import EntityInfoPane from './EntityInfoPane';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

// Types
type FlyToOptions = {
  destination: Cartesian3 | Rectangle;
  orientation?: any;
  duration?: number;
  complete?: Camera.FlightCompleteCallback;
  cancel?: Camera.FlightCancelledCallback;
  endTransform?: Matrix4;
  maximumHeight?: number;
  pitchAdjustHeight?: number;
  flyOverLongitude?: number;
  flyOverLongitudeWeight?: number;
  convert?: boolean;
  easingFunction?: EasingFunction.Callback;
};

type Point = {
  cartographic?: Cartographic;
  latitude?: number;
  longitude?: number;
} & PointPrimitive;

type SourceData = {
  folders: string[],
  files: string[],
  count: number
}

const DH = 12;

const GS_URL = process.env.REACT_APP_GEOSERVERPATH;

const apiPath = process.env.REACT_APP_APIPATH;

const LINEPOINTCOLOR = Color.RED;
const LINEWIDTH = 3;

// @ts-ignore
window.CESIUM_BASE_URL = '/static/Cesium/';

const age = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [drawAllowed, setDrawAllowed] = useState(false);
  const [drawActive, setDrawActive] = useState(false);
  const [refresh, setRefreshToken] = useState(false);
  const [handler, setHandler] = useState<ScreenSpaceEventHandler | null>(null);

  const [sourceCount, setSourceCount] = useState<number|null>(null);
  const [locationRecords, setLocationRecords] = useState<LokacijeData[]>([]);;
  const [showEntities, setShowEntities] = useState(true);
  const [selectedEntityRecord, setSelectedEntityRecord] = useState<DCRecord | undefined>(undefined);
  const [selectedEntityId,setSelectedEntityId] = useState(0);
  const [trackedEntityId, setTrackedEntityId] = useState(0);
  const [isOpenGFI, setIsOpenGFI] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const tilesetList = useRef<Cesium3DTileset[]>();

  const viewer = useRef<Viewer>(); // new Viewer('cesiumContainer');
  const camera = useRef<Camera>();
  const scene = useRef<Scene>();
  const globe = useRef(new Globe(Ellipsoid.WGS84));
  globe.current.baseColor = Color.BLACK;
  const ellipsoid = useRef(Ellipsoid.WGS84);
  const geodesic = useRef(new EllipsoidGeodesic());

  const points = useRef<PointPrimitiveCollection>();
  const point1 = useRef<PointPrimitive>();
  const point2 = useRef<PointPrimitive>();
  const point1GeoPosition = useRef<Cartographic>();
  const point2GeoPosition = useRef<Cartographic>();
  const point3GeoPosition = useRef<Cartographic>();

  const polylines = useRef<PolylineCollection>();
  const polyline1 = useRef<Polyline>();
  const polyline2 = useRef<Polyline>();
  const polyline3 = useRef<Polyline>();

  const distanceLabel = useRef<Entity>();
  const verticalLabel = useRef<Entity>();
  const horizontalLabel = useRef<Entity>();
  
  const wkt = new OlFormatWKT()

  const labelStyle = {
    font: '14px monospace',
    showBackground: true,
    horizontalOrigin: HorizontalOrigin.CENTER,
    verticalOrigin: VerticalOrigin.CENTER,
    pixelOffset: new Cartesian2(0, 0),
    eyeOffset: new Cartesian3(0, 0, -10),
    fillColor: Color.WHITE,
    text: '',
    disableDepthTestDistance: Number.POSITIVE_INFINITY,
  };

  // used when opening 3d model from the same tab as the main map
  /* const {state} = useLocation();
  const cx = state?.cx;
  const cy = state?.cy;
  const cz = state?.cz; */

  // used when opening 3d model from a new tab
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const cx =
    searchParams.get('cx') !== null
      ? parseFloat(searchParams.get('cx') as string)
      : undefined;
  const cy =
    searchParams.get('cy') !== null
      ? parseFloat(searchParams.get('cy') as string)
      : undefined;
  const cz =
    searchParams.get('cz') !== null
      ? parseFloat(searchParams.get('cz') as string)
      : undefined;

  const handleRefresh = () => {
    setRefreshToken((prev) => !prev);
  };

  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );

  const replaceTooltipTextLanguage = () => {
    const cesiumClassnames = [
      'cesium-navigation-help-pan',
      'cesium-navigation-help-details',
      'cesium-navigation-help-zoom',
      'cesium-navigation-help-rotate',
      'cesium-baseLayerPicker-sectionTitle',
      'cesium-navigation-help-button',
      'cesium-navigation-help-tilt',
      'cesium-home-button'
    ];

    let count = 0;
    for(const classnameOriginal of cesiumClassnames) {
      const elements = document.getElementsByClassName(classnameOriginal);
      const classname = classnameOriginal.replaceAll('-', '_');
      for(const element of elements) {
        //@ts-ignore
        if(element.title !== '' && element.title !== null && element.title !== undefined) {
          //@ts-ignore
          element.title = t('3D:' + classname);
          continue;
        }
        if(classnameOriginal === 'cesium-navigation-help-details') {
          // multiple elements with same class require different translations
          count += 1;
          element.textContent = t('3D:' + classname + '_' + String(count));
        } else {
          element.textContent = t('3D:' + classname);
        }
      }
    }
  }

  const cesiumButtons = document.querySelectorAll<HTMLButtonElement>('.cesium-button');
  cesiumButtons.forEach((button) => {
    button.style.backgroundColor = theme.palette.primary.main;
    button.style.fill = getOptimalTextColorForBackgroundColor(theme.palette.primary.main);
    button.style.color = getOptimalTextColorForBackgroundColor(theme.palette.primary.main);
    button.style.border = '0';
    button.style.boxShadow = '0'
    button.style.transition = 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

    // Adding hover, focus, and active event listeners
    button.addEventListener('mouseenter', () => {
      button.style.backgroundColor = theme.palette.primary.dark;
      button.style.boxShadow = '0'
    });
    button.addEventListener('mouseleave', () => {
      button.style.backgroundColor = theme.palette.primary.main;
      button.style.boxShadow = '0'
    });
  })

  const getColorByStatus = (status: number) => {
    switch (status)
    {
      case 1:
        return "#3944BC";
        break;
      case 2:
        return "#990000";
        break;
      case 3:
        return "#009999";
        break;
      case 5:
        return "#808c00";
        break;
      default:
        return theme.palette.primary.main;
        break;
    }
  }

  useEffect(()=>{
    const apiInstance = useApi();

    const url_lokacije = "threeD/locations";
    apiInstance.get(url_lokacije).then(
      (resp) => {
        if (resp.data && Array.isArray(resp.data) && resp.data.length!==0) setLocationRecords(resp.data);
      }
    )
  }, [])

  useEffect(()=>{
    if (locationRecords.length>0 && viewer.current ){
      for (const locationKey in locationRecords){

        const ENTITY_PADDING = 0;

        const location = locationRecords[locationKey]; // contains wkt,kc,kb,id 

        const geometry = wkt.readGeometry(location.wkt);
        let coordsFromWKT;
        if (geometry instanceof OlPoint) {
          coordsFromWKT = geometry.getCoordinates();
        } else {
          continue;
        }
        const [cx,cy] = transform([coordsFromWKT[0],coordsFromWKT[1]], "EPSG:3765", "EPSG:4326")

        //console.log(location);

        // TODO: FIND WAY TO UPDATE TEXT ON TRANSLATION CHANGE
        const newEntity = viewer.current.entities.add({
          id: location.id + "",
          //name: t("komunalni_obveznici.kc")+": "+location.kc+", "+t("objekti.kucni_broj")+": "+location.kb,
          name: t("komunalni_obveznici.lok"),
          allowPicking: false,
          position: Cartesian3.fromDegrees(cx, cy, coordsFromWKT[2]+ENTITY_PADDING),
          point: {
            pixelSize: 8,
            color: Color.fromCssColorString(getColorByStatus(location.lokacija_status_id)),
            outlineColor: Color.BLACK,
            outlineWidth: 2,
            scaleByDistance: new NearFarScalar(200, 1, 1000, 0.75)
          },
          description: renderToString(<>{"Loading..."}</>),
          label: {
            text: location.kb,
            font: '20pt "Roboto", monospace',
            style: LabelStyle.FILL_AND_OUTLINE,
            outlineWidth: 4,
            verticalOrigin: VerticalOrigin.BOTTOM,
            pixelOffset: new Cartesian2(0, -15),
            translucencyByDistance: new NearFarScalar(200, 1, 300.0, 0),
            scaleByDistance: new NearFarScalar(105, 1, 200.0, 0.5)
          },
          properties:
          {
            record: {...location,adresa: (location.ul_ime?location.ul_ime:"")+" "+(location.kb?location.kb:"")+", "+ (location.na_ime?location.na_ime:"")}
          }
        } as Entity.ConstructorOptions
      )};
    }
  }, [locationRecords, viewer.current])

  useEffect(() => {
    if (viewer.current) {
      viewer.current.selectedEntityChanged.addEventListener((selectedEntity: Entity) => {
        if (defined(selectedEntity)) {
          if (defined(selectedEntity.id)) {
            if (selectedEntity.properties) {
              setSelectedEntityRecord(selectedEntity.properties.getValue(JulianDate.now()).record);
              setSelectedEntityId(parseInt(selectedEntity.id));
              setIsOpenGFI(true);
            }
          } else {
            handleCloseInfoPane();
          }
        } else {
          handleCloseInfoPane();
        }
      });

      viewer.current.trackedEntityChanged.addEventListener((trackedEntity) => {
        if (trackedEntity!==undefined) {
          setTrackedEntityId(parseInt(trackedEntity.id));
        } else {
          setTrackedEntityId(0);
        }
      });
    }
  }, [viewer.current]);

  useEffect(()=>{
    const apiInstance = useApi();

    const url = `threeD/config/count`;
    apiInstance.get(url).then(
      (resp) => {
        try {
          const source = resp.data as SourceData
          setSourceCount(source.count);  
        } catch {
          setSourceCount(null);
        }
      }
    ).catch();
  }, []);

  const resourceRetryCallback = (resource?: Resource, error?: RequestErrorEvent) => {
    if (error) {
      if (error.statusCode === 401)  navigate('/')
        return true;
    } else return false;
  }

  useEffect(() => {
    if(sourceCount === null) return;
    
    async function initialize() {
      // Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiMDVhZmY2Yi1mYTRjLTQ1NTYtODJmZC0wOTZlZDE4Yzg1MzEiLCJpZCI6MTE1Nzc0LCJpYXQiOjE2NjkxNTI5NzV9.3yUjYxWXb2KtUMQjH9WWd1DQDVcN-8WnxJeTAO8mmXI";
      // ne zelimo koristiti Ion

      // Viewer
      if (!viewer.current) {
        viewer.current = new Viewer('cesiumContainer', {
          animation: false,
          baseLayerPicker: true,
          geocoder: false,
          // Ovo ne radi?
          maximumRenderTimeChange: Infinity,
          requestRenderMode: true,
          scene3DOnly: true,
          timeline: false,
          infoBox: false,
          selectionIndicator: true,
          globe: globe.current,
          terrainProviderViewModels: [],
          
        });

        // DGU DOF base layer view model
        const dguDofViewModel = new ProviderViewModel({
          name: 'DGU DOF',
          tooltip: 'DGU DOF',
          iconUrl:
            'https://geoportal.dgu.hr/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng8&TRANSPARENT=true&LAYERS=DOF&TILED=true&WIDTH=256&HEIGHT=256&CRS=EPSG%3A3857&STYLES=&BBOX=2015491.5618235283%2C5257033.057341281%2C2016103.0580498097%2C5257644.553567563',
          creationFunction: () =>
            new WebMapServiceImageryProvider({
              url: 'https://geoportal.dgu.hr/wms?',
              layers: 'DOF',
              crs: 'EPSG:3857',
              parameters: {
                format: 'image/png',
                transparent: true,
              },
            }),
        });

        // OpenStreetMap base layer view model
        const osmViewModel = new ProviderViewModel({
          name: 'OpenStreetMap',
          iconUrl: buildModuleUrl(
            'Widgets/Images/ImageryProviders/openStreetMap.png'
          ),
          tooltip:
            'OpenStreetMap (OSM) is a collaborative project to create a free editable map of the world.\nhttp://www.openstreetmap.org',
          creationFunction() {
            return new OpenStreetMapImageryProvider({
              url: 'https://a.tile.openstreetmap.org/',
            });
          },
        });

        // Set the imageryProviderViewModels property to an array containing only the two base layers you want to keep
        viewer.current.baseLayerPicker.viewModel.imageryProviderViewModels = [
          dguDofViewModel,
          osmViewModel,
        ];
        viewer.current.baseLayerPicker.viewModel.selectedImagery =
          dguDofViewModel;
      }

      if (viewer.current) {
        camera.current = viewer.current.camera;
        scene.current = viewer.current.scene;
        points.current = scene.current.primitives.add(
          new PointPrimitiveCollection()
        );
        polylines.current = scene.current.primitives.add(
          new PolylineCollection()
        );

        setHandler(new ScreenSpaceEventHandler(scene.current.canvas));

        // var ellipsoid = Ellipsoid.WGS84;
        // var geodesic = new EllipsoidGeodesic();

        // define layers
        // 	layers.push(new ImageryLayer(new WebMapServiceImageryProvider({
        //   url: gs_url,
        //   layers: "ENA:105_DOF",
        //   crs: "EPSG:3857",
        //   parameters: {
        //     format: "image/vnd.jpeg-png",
        //     transparent: true,
        //   }
        // })))
        // layers.push(new ImageryLayer(new WebMapServiceImageryProvider({
        //     url: gs_url,
        //     layers: "ENA:105_DKP",
        //     crs: "EPSG:3857",
        //     parameters: {
        //       format: "image/vnd.jpeg-png",
        //       transparent: true,
        //     }
        // })))
        // layers.push(new ImageryLayer(new WebMapServiceImageryProvider({
        //     url: gs_url,
        //     layers: "ENA:105_KB",
        //     crs: "EPSG:3857",
        //     parameters: {
        //       format: "image/vnd.jpeg-png",
        //       transparent: true,
        //     }
        // })))

        // add layers to viewer
        // layers.forEach((layer) => viewer.current.imageryLayers.add(layer))

        // Prehnit d.o.o. logo
        viewer.current.creditDisplay.addStaticCredit(
          new Credit('<a href="https://prehnit.hr"><img src="logo.png"/></a>')
        );

        // tileset

        const tilesets = [];
        const authorizationHeader = authHeader();

        const cesiumPath = process.env.REACT_CESIUM_APIPATH?.trim();

        //@ts-ignore Checked at the start of useEffect, safe to ignore null warning
        for (let i = 0; i < sourceCount; i++) {
          // const tilesUrl = `${cesiumPath}tiles/${i}/config`; // use when using GO service
          const tilesUrl = `${cesiumPath}${i}/config`;
          // console.log("tilesurl",tilesUrl)
  
          const tilesResource = new Resource({
            url: tilesUrl,
            headers: authorizationHeader,
            // uncomment when using GO service
            // retryAttempts: 1,
            // retryCallback: resourceRetryCallback
          });

          try {
            const newSet = await Cesium3DTileset.fromUrl(tilesResource, {
              dynamicScreenSpaceError: true,
              immediatelyLoadDesiredLevelOfDetail: true,
              maximumScreenSpaceError: 1,
              skipLevelOfDetail: true,
            });

            tilesets.push(newSet);
          } catch (error) {
            console.log(error);
          }
        }

        tilesetList.current = tilesets;

        // Konfiguriraj što radi home gumb
        viewer.current.homeButton.viewModel.command.beforeExecute.addEventListener(
          (commandInfo) => {
            if (
              tilesetList?.current?.length &&
              tilesetList.current.length > 0 &&
              viewer.current
            )
              viewer.current.flyTo(tilesetList.current[0]);
            commandInfo.cancel = true;
          }
        );

        // Add tileset to viewer and set initial camera position
        scene.current.screenSpaceCameraController.enableCollisionDetection =
          true;

        tilesets.forEach((tileset) => {
          if (viewer.current) {
            viewer.current.scene.primitives.add(tileset);
          }
        });

        if (tilesets.length > 0) {
          viewer.current.zoomTo(tilesets[0]);
        }

        const terrainUrl = `${cesiumPath}terrain`;
        // console.log("terrainurl",terrainUrl)

        const terrainResource = new Resource({
          url: terrainUrl,
          headers: authorizationHeader,
          // uncomment when using GO service
          // retryAttempts: 1,
          // retryCallback: resourceRetryCallback
        });

        const terrainProvider = await CesiumTerrainProvider.fromUrl(terrainResource,{requestVertexNormals: true});
        viewer.current.terrainProvider = terrainProvider;


        replaceTooltipTextLanguage();
      }
    }
    initialize();
  }, [sourceCount]);

  useEffect(() => {
    replaceTooltipTextLanguage();
  }, [i18n.language]);

  useEffect(() => {
    if (viewer.current !== undefined && viewer.current.camera) {
      const flyToHome =
        cx === undefined || cy === undefined || cz === undefined;

      // If object coordinates are not set, fly to the entire tileset
      if (flyToHome) {
        setTimeout(() => {
          // Check if tilesetList.current is a valid array and not empty
          if (
            !Array.isArray(tilesetList.current) ||
            tilesetList.current.length === 0 ||
            !viewer.current
          )
            return;

          viewer.current.flyTo(tilesetList.current[0]);
        }, 2000);
        return;
      }
    }
  }, []);

  useEffect(() => {
    
    if (viewer.current !== undefined && viewer.current.camera) {
      // Else take and fly to given coordinates
      const heightOffset = 100;
      const longitude = cx;
      const latitude = cy;
      const height = cz === undefined ? 100 : cz + heightOffset;

      const posCartographic = new Cartographic(longitude, latitude, height);
      const posCartesian = Cartographic.toCartesian(posCartographic);
      const flyOptions: FlyToOptions = {
        destination: posCartesian,
      };

      setTimeout(() => {
        if (cx !== undefined && cy !== undefined && cz !== undefined) {
          // Check if camera.current is defined before trying to access its properties
          if (camera.current) {
            camera.current.flyTo(flyOptions);
          }
        }
      }, 2000);
    }
  }, [viewer.current, cx, cy, cz, tilesetList.current]);

  useEffect(() => {
    // Mouse over the globe to see the cartographic position

    handler?.setInputAction(
      (click: ScreenSpaceEventHandler.PositionedEvent) => {
        //console.log("klik",click);
        if (!drawAllowed) {
           //console.log("Drawing not allowed!");
          return;
        }
        setDrawActive(true);
        if (
          scene.current &&
          viewer.current &&
          points.current &&
          polylines.current &&
          scene.current.mode !== SceneMode.MORPHING
        ) {
          const pickedObject = scene.current.pick(click.position);
          if (scene.current.pickPositionSupported && defined(pickedObject)) {
            const cartesian = scene.current.pickPosition(click.position);
             //console.log(cartesian);
            if (defined(cartesian)) {
              if (points.current.length === 2) {
                points.current.removeAll();
                polylines.current.removeAll();
                if (distanceLabel.current)
                  viewer.current.entities.remove(distanceLabel.current);
                if (horizontalLabel.current)
                  viewer.current.entities.remove(horizontalLabel.current);
                if (verticalLabel.current)
                  viewer.current.entities.remove(verticalLabel.current);
              }
              // add first point
              if (points.current.length === 0) {
                point1.current = points.current.add({
                  position: new Cartesian3(
                    cartesian.x,
                    cartesian.y,
                    cartesian.z
                  ),
                  color: LINEPOINTCOLOR,
                });
              } // add second point and lines
              else if (points.current.length === 1) {
                point2.current = points.current.add({
                  position: new Cartesian3(
                    cartesian.x,
                    cartesian.y,
                    cartesian.z
                  ),
                  color: LINEPOINTCOLOR,
                });
                if (point1.current) {
                  point1GeoPosition.current = Cartographic.fromCartesian(
                    point1.current.position
                  );
                  point2GeoPosition.current = Cartographic.fromCartesian(
                    point2.current.position
                  );
                  point3GeoPosition.current = Cartographic.fromCartesian(
                    new Cartesian3(
                      point2.current.position.x,
                      point2.current.position.y,
                      point1.current.position.z
                    )
                  );

                  const pl1Positions: Cartesian3[] = [
                    Cartesian3.fromRadians(
                      point1GeoPosition.current.longitude,
                      point1GeoPosition.current.latitude,
                      point1GeoPosition.current.height
                    ),
                    Cartesian3.fromRadians(
                      point2GeoPosition.current.longitude,
                      point2GeoPosition.current.latitude,
                      point2GeoPosition.current.height
                    ),
                  ];
                  const pl2Positions = [
                    Cartesian3.fromRadians(
                      point2GeoPosition.current.longitude,
                      point2GeoPosition.current.latitude,
                      point2GeoPosition.current.height
                    ),
                    Cartesian3.fromRadians(
                      point2GeoPosition.current.longitude,
                      point2GeoPosition.current.latitude,
                      point1GeoPosition.current.height
                    ),
                  ];
                  const pl3Positions = [
                    Cartesian3.fromRadians(
                      point1GeoPosition.current.longitude,
                      point1GeoPosition.current.latitude,
                      point1GeoPosition.current.height
                    ),
                    Cartesian3.fromRadians(
                      point2GeoPosition.current.longitude,
                      point2GeoPosition.current.latitude,
                      point1GeoPosition.current.height
                    ),
                  ];

                  polyline1.current = polylines.current.add({
                    show: true,
                    positions: pl1Positions,
                    width: 1,
                    material: new Material({
                      fabric: {
                        type: 'Color',
                        uniforms: {
                          color: LINEPOINTCOLOR,
                        },
                      },
                    }),
                  });
                  polyline2.current = polylines.current.add({
                    show: true,
                    positions: pl2Positions,
                    width: 1,
                    material: new Material({
                      fabric: {
                        type: 'PolylineDash',
                        uniforms: {
                          color: LINEPOINTCOLOR,
                        },
                      },
                    }),
                  });
                  polyline3.current = polylines.current.add({
                    show: true,
                    positions: pl3Positions,
                    width: 1,
                    material: new Material({
                      fabric: {
                        type: 'PolylineDash',
                        uniforms: {
                          color: LINEPOINTCOLOR,
                        },
                      },
                    }),
                  });
                  let labelZ;
                  if (
                    point2GeoPosition.current.height >=
                    point1GeoPosition.current.height
                  ) {
                    labelZ =
                      point1GeoPosition.current.height +
                      (point2GeoPosition.current.height -
                        point1GeoPosition.current.height) /
                        2.0;
                  } else {
                    labelZ =
                      point2GeoPosition.current.height +
                      (point1GeoPosition.current.height -
                        point2GeoPosition.current.height) /
                        2.0;
                  }

                  addDistanceLabel(point1.current, point2.current, labelZ);
                }
              }
            }
          }
        }
      },
      ScreenSpaceEventType.LEFT_CLICK
    );
  }, [drawAllowed]);

  useEffect(()=>{
    if (viewer.current!==undefined) {
      viewer.current.entities.values.map((entity) => {
        entity.show = showEntities;
      });

      const updateVisibility = () => {
        if (viewer.current)
          viewer.current.scene.requestRender(); // Force Cesium to update the scene
      };
      
      viewer.current.selectedEntity = undefined;
      viewer.current.trackedEntity = undefined;
  
      window.requestAnimationFrame(updateVisibility);

    }
  }, [showEntities])

  const addDistanceLabel = (p1: Point, p2: Point, height: number) => {
    p1.cartographic = ellipsoid.current.cartesianToCartographic(p1.position);
    p2.cartographic = ellipsoid.current.cartesianToCartographic(p2.position);
    p1.longitude = CesiumMath.toDegrees(p1.position.x);
    p1.latitude = CesiumMath.toDegrees(p1.position.y);
    p2.longitude = CesiumMath.toDegrees(p2.position.x);
    p2.latitude = CesiumMath.toDegrees(p2.position.y);

    const l1 = { ...labelStyle };
    l1.text = getHorizontalDistanceString(p1, p2);
    if (viewer.current && point1GeoPosition.current) {
      horizontalLabel.current = viewer.current.entities.add({
        position: getMidpoint(p1, p2, point1GeoPosition.current.height),
        label: l1,
      });

      const l2 = { ...labelStyle };
      l2.text = getDistanceString(p1, p2);
      distanceLabel.current = viewer.current.entities.add({
        position: getMidpoint(p1, p2, height),
        label: l2,
      });

      const l3 = { ...labelStyle };
      l3.text = getVerticalDistanceString();
      verticalLabel.current = viewer.current.entities.add({
        position: getMidpoint(p2, p2, height),
        label: l3,
      });
    }
  };

  const getHorizontalDistanceString = (p1: Point, p2: Point) => {
    if (p1.cartographic && p2.cartographic)
      geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const meters = Number(geodesic.current.surfaceDistance.toFixed(2));
    if (meters >= 1000) {
      return `${(meters / 1000).toFixed(1)} км ⟷`;
    }
    return `${meters} м ⟷`;
  };

  const getVerticalDistanceString = () => {
    if (point1GeoPosition.current && point2GeoPosition.current) {
      const heights = [
        point1GeoPosition.current.height,
        point2GeoPosition.current.height,
      ];
      const meters = Math.max(...heights) - Math.min(...heights);
      if (meters >= 1000) {
        return `${(meters / 1000).toFixed(1)} км ↥`;
      }
      return `${meters.toFixed(2)} м ↥`;
    }
    return 'unknown';
  };

  const getDistanceString = (p1: Point, p2: Point) => {
    if (p1.cartographic && p2.cartographic)
      geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const horizontalMeters = Number(
      geodesic.current.surfaceDistance.toFixed(2)
    );
    if (point1GeoPosition.current && point2GeoPosition.current) {
      const heights = [
        point1GeoPosition?.current?.height,
        point2GeoPosition?.current?.height,
      ];
      const verticalMeters = Math.max(...heights) - Math.min(...heights);
      const meters = (horizontalMeters ** 2 + verticalMeters ** 2) ** 0.5;

      if (meters >= 1000) {
        return `${(meters / 1000).toFixed(1)} км`;
      }
      return `${meters.toFixed(2)} м`;
    }
    return 'unknown';
  };

  const getMidpoint = (p1: Point, p2: Point, height: number) => {
    const scratch = new Cartographic();
    if (p1.cartographic && p2.cartographic)
      geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const midpointCartographic = geodesic.current.interpolateUsingFraction(
      0.5,
      scratch
    );
    return Cartesian3.fromRadians(
      midpointCartographic.longitude,
      midpointCartographic.latitude,
      height
    );
  };

  const getTriangleMidpoint = (p1: Point, p2: Point, height: number) => {
    const scratch = new Cartographic();
    if (p1.cartographic && p2.cartographic)
      geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const midpointCartographic = geodesic.current.interpolateUsingFraction(
      0.5,
      scratch
    );
    return Cartesian3.fromRadians(
      midpointCartographic.longitude,
      midpointCartographic.latitude,
      height
    );
  };

  const handleResetDraw = () => {
    if (
      points.current &&
      polylines.current &&
      viewer.current &&
      distanceLabel.current &&
      horizontalLabel.current &&
      verticalLabel.current
    ) {
      points.current.removeAll();
      polylines.current.removeAll();
      viewer.current.entities.remove(distanceLabel.current);
      viewer.current.entities.remove(horizontalLabel.current);
      viewer.current.entities.remove(verticalLabel.current);
    }
    setDrawActive(false);
  };
  
  //useEffect(()=>{console.log("selentityid",selectedEntityId)},[selectedEntityId]) // selected is for selection indicator
  //useEffect(()=>{console.log("trackedneityid",trackedEntityId)},[trackedEntityId]) // tracked is for camera rotating around object

  const handleCloseInfoPane = () => {
    setIsOpenGFI(false);
    setTrackedEntityId(0);
    setSelectedEntityId(0);
    if (viewer.current) {
      viewer.current.selectedEntity = undefined;
      viewer.current.trackedEntity = undefined;
    }    
  }

  const focusOnObject = (id: number) => {
    if (viewer.current!==undefined) {
      viewer.current.entities.values.forEach((entity) => {
        if (entity.id === id + "") {
          if (viewer.current) {
            if (viewer.current.trackedEntity && viewer.current.trackedEntity.id === entity.id) {
              viewer.current.trackedEntity = undefined;
              setTrackedEntityId(0);
            } else {
              viewer.current.trackedEntity = entity;
              setTrackedEntityId(id);
            }
          }
        }
      });
    }
  }

  return (
    <>
      <div
        id="cesium-draw-toolbar"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          position: 'absolute',
          right: '5px',
          top: '25%',
          height: '50%',
          justifyContent: 'center',
          alignItems: 'end',
        }}
      >
        <Tooltip
          title={
            drawAllowed
              ? t('buttons.toggle_drawing_off')
              : t('buttons.toggle_drawing_on')
          }
          placement="left"
        >
          <DesignServicesOutlinedIcon
            fontSize="large"
            cursor="pointer"
            sx={{
              transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundColor: drawAllowed ? theme.palette.primary.dark : theme.palette.primary.main,
              borderRadius: '10%',
              color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
              zIndex: 999,
              ':hover': {
                backgroundColor: theme.palette.primary.dark
              },
            }}
            onClick={() => {
              if (drawActive) handleResetDraw();
              if (drawAllowed) setDrawAllowed(false);
              else setDrawAllowed(true);
            }}
          />
        </Tooltip>
        <Tooltip title={t('3D:buttons.delete_drawings')} placement="left">
          <DeleteOutlineOutlinedIcon
            fontSize="large"
            cursor={drawActive ? 'pointer' : 'not-allowed'}
            sx={{
              transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundColor: drawActive ? theme.palette.primary.main : theme.palette.primary.light,
              borderRadius: '10%',
              color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
              zIndex: 999,
              ':hover': {
                backgroundColor: drawActive ? theme.palette.primary.dark : theme.palette.primary.light
              },
            }}
            onClick={() => {
              if (!drawActive) return;
              handleResetDraw();
              handleRefresh();
            }}
          />
        </Tooltip>
        { locationRecords.length > 0 && <Tooltip title={t("3D:buttons.show_locations")} placement='left'>
          <LocationOnIcon 
            fontSize='large' 
            cursor={'pointer'}
            sx={{
              mt: 3,
              transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundColor: showEntities ? theme.palette.primary.dark : theme.palette.primary.main,
              borderRadius: '10%',
              color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
              zIndex: 999,
              ':hover': {
                backgroundColor: theme.palette.primary.dark
              },
            }}
            onClick={()=>{
              setShowEntities(showEntities => !showEntities);
            }}
          />
        </Tooltip>}
      </div>
      <div style={{position: "relative"}} id="cesiumContainer">
        <Box zIndex={1200} margin={"10px"} position={"absolute"} left={0} top={0}>
            <Collapse orientation={"horizontal"} in={isOpenGFI}>
            { selectedEntityId!==0 && 
              <EntityInfoPane 
                focusOnObject={focusOnObject} 
                onClose={handleCloseInfoPane}
                trackedEntityId={trackedEntityId}
                entityId={selectedEntityId} 
                record={selectedEntityRecord}
              />
            }
            </Collapse>
        </Box>
      </div>
    </>
  );
};

export default age;
