/* eslint-disable no-param-reassign */
import { useContext, useEffect, useRef, useState } from 'react';

// OL
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import { getCenter } from 'ol/extent';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import { Geometry, LineString, MultiPolygon, Polygon } from 'ol/geom';
import OlSourceVector from 'ol/source/Vector';
import { Coordinate } from 'ol/coordinate';

// MUI
import { useTheme } from '@mui/material';

// Custom components
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';

type HighlightAnimationProps = {
  features: Feature<Geometry>[];
  setSource: (source: any) => void;
};

const HighlightAnimation = (props: HighlightAnimationProps) => {
  const { features, setSource } = props;
  const mapContext = useContext(MapContext) as MapContextType;
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [vectorSource, setVectorSource] = useState<VectorSource<any>>(new VectorSource());
  const layerLoaded = useRef<boolean>(false);

  const currentAnimationFeatures = useRef<Array<any>>([]);

  const hex2rgb = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return { r, g, b };
  };
  const animationColor = hex2rgb(theme.palette.primary.main);

  const pointStyle = new Style({
    stroke: new Stroke({
      color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`,
      width: 2,
    }),
    fill: new Fill({
      color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 0.2)`,
    }),
  });

  useEffect(() => {
    if (mapContext && mapContext !== null && mapContext.map !== null && layerLoaded.current === false) {
      mapContext.map.addLayer(
        new VectorLayer({
          className: 'ol-layer-animation-expanding',
          source: vectorSource,
          zIndex: 899,
        })
      );
      layerLoaded.current = true;
    }
  }, [mapContext]);

  const animateMultiPolygon = (
    feature: Feature<MultiPolygon>,
    direction: number,
    color: number,
    maxColor: number,
    originalStyle: Style,
    scale: number = 1,
    originalCoordinates: Array<Array<Array<Coordinate>>> | undefined = feature.getGeometry()?.getCoordinates()
  ) => {
    if (!originalCoordinates) return;
    if (direction === 1) {
      color += maxColor / 75;
      scale += 0.0115;
    } else if (direction === 2) {
      color -= maxColor / 125;
      if (scale > 1) {
        scale -= 0.0085;
      } else {
        scale = 1;
      }
    }

    const newStyle = new Style({
      stroke: new Stroke({
        color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`,
        width: 4,
      }),
      fill: new Fill({
        // Change the color over the course of the animation
        color: `rgba(${String(256 - color * (256 - animationColor.r))}, ${String(256 - color * (256 - animationColor.g))}, ${String(
          256 - color * (256 - animationColor.b)
        )}, ${String(color)})`,
      }),
    });
    feature.setStyle(newStyle);

    // Scale polygon geometry for every polygon in multipolygon
    const geometry = feature.getGeometry();
    if (!geometry) return;
    if (geometry.getType() === 'MultiPolygon') {
      const polygons = geometry.getPolygons(); 

      // const coordinates = originalCoordinates[0];
      // const center = getCenter(geometry.getExtent());
      // const scaledCoordinates = coordinates.map((coord) => [
      //   center[0] + (coord[0] - center[0]) * scale,
      //   center[1] + (coord[1] - center[1]) * scale,
      // ]);
      // geometry.setCoordinates([scaledCoordinates]);

      const scaledPolygonCoordinates = polygons.map((polygon, i) => {
        const coordinates = originalCoordinates[i][0];
        const center = getCenter(polygon.getExtent());
    
        const scaledCoordinates = coordinates.map((coord) => [
          center[0] + (coord[0] - center[0]) * scale,
          center[1] + (coord[1] - center[1]) * scale,
        ]);
    
        return [scaledCoordinates];
      });
    
      // set geometry to newly scaled MultiPolygon geometry
      geometry.setCoordinates(scaledPolygonCoordinates);
    }

    // Recursive calls to the animation API
    if (direction === 1 && color <= maxColor) {
      requestAnimationFrame(() => animateMultiPolygon(feature, 1, color, maxColor, originalStyle, scale, originalCoordinates));
    } else if (direction === 2 && scale > 1) {
      requestAnimationFrame(() => animateMultiPolygon(feature, 2, color, maxColor, originalStyle, scale, originalCoordinates));
    } else if (scale > 1) {
      requestAnimationFrame(() => animateMultiPolygon(feature, 2, color, maxColor, originalStyle, scale, originalCoordinates));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        geometry.setCoordinates(originalCoordinates); // Ensure the geometry is set to original coordinates
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter((f) => f !== feature.getId());
        setSource(new OlSourceVector({}));
      }, 300);
    }
  }

  const animatePolygon = (
    feature: Feature<Polygon>,
    direction: number,
    color: number,
    maxColor: number,
    originalStyle: Style,
    scale: number = 1,
    originalCoordinates: Array<Array<Array<number>>> | undefined = feature.getGeometry()?.getCoordinates()
  ) => {
    if (!originalCoordinates) return;
    if (direction === 1) {
      color += maxColor / 75;
      scale += 0.0115;
    } else if (direction === 2) {
      color -= maxColor / 125;
      if (scale > 1) {
        scale -= 0.0085;
      } else {
        scale = 1;
      }
    }

    const newStyle = new Style({
      stroke: new Stroke({
        color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`,
        width: 4,
      }),
      fill: new Fill({
        // Change the color over the course of the animation
        color: `rgba(${String(256 - color * (256 - animationColor.r))}, ${String(256 - color * (256 - animationColor.g))}, ${String(
          256 - color * (256 - animationColor.b)
        )}, ${String(color)})`,
      }),
    });
    feature.setStyle(newStyle);

    // Scale polygon geometry
    const geometry = feature.getGeometry();
    if (!geometry) return;
    if (geometry.getType() === 'Polygon') {
      const coordinates = originalCoordinates[0];
      const center = getCenter(geometry.getExtent());
      const scaledCoordinates = coordinates.map((coord) => [
        center[0] + (coord[0] - center[0]) * scale,
        center[1] + (coord[1] - center[1]) * scale,
      ]);
      geometry.setCoordinates([scaledCoordinates]);
    }

    // Recursive calls to the animation API
    if (direction === 1 && color <= maxColor) {
      requestAnimationFrame(() => animatePolygon(feature, 1, color, maxColor, originalStyle, scale, originalCoordinates));
    } else if (direction === 2 && scale > 1) {
      requestAnimationFrame(() => animatePolygon(feature, 2, color, maxColor, originalStyle, scale, originalCoordinates));
    } else if (scale > 1) {
      requestAnimationFrame(() => animatePolygon(feature, 2, color, maxColor, originalStyle, scale, originalCoordinates));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        geometry.setCoordinates(originalCoordinates); // Ensure the geometry is set to original coordinates
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter((f) => f !== feature.getId());
        setSource(new OlSourceVector({}));
      }, 300);
    }
  };

  const animateLineString = (feature: Feature<LineString>, direction: number, width: number, maxWidth: number, originalStyle: Style) => {
    if (direction === 1) {
      width += maxWidth / 50;
    } else {
      width -= maxWidth / 75;
    }

    const newStyle = new Style({
      stroke: new Stroke({
        color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`,
        lineJoin: 'miter',
        width,
      }),
    });
    feature.setStyle(newStyle);

    // Recursive calls to the animation API
    if (direction === 1 && width <= maxWidth) {
      requestAnimationFrame(() => animateLineString(feature, 1, width, maxWidth, originalStyle));
    } else if (width >= 4) {
      requestAnimationFrame(() => animateLineString(feature, 2, width, maxWidth, originalStyle));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter((f) => f !== feature.getId());
        setSource(new OlSourceVector({}));
      }, 300);
    }
  };

  const animatePoint = (
    feature: Feature<Point>,
    direction: number,
    radius: number,
    maxRadius: number,
    originalStyle: Style,
    prevCircleFeature?: any
  ) => {
    if (direction === 1) {
      radius += maxRadius / 50;
    } else {
      radius -= maxRadius / 75;
    }

    const circleGeometry = new Circle(
      feature?.getGeometry()?.getCoordinates() || [1],
      radius
    );    
    const circleFeature = new Feature({ id: 1, geometry: circleGeometry });

    if (prevCircleFeature) {
      vectorSource.removeFeature(prevCircleFeature);
    }

    circleFeature.setStyle(pointStyle);
    vectorSource.addFeature(circleFeature);

    // Recursive calls to the animation API
    if (direction === 1 && radius <= maxRadius) {
      requestAnimationFrame(() => animatePoint(feature, 1, radius, maxRadius, originalStyle, circleFeature));
    } else if (radius >= 0.55) {
      requestAnimationFrame(() => animatePoint(feature, 2, radius, maxRadius, originalStyle, circleFeature));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter((f) => f !== feature.getId());
        vectorSource.removeFeature(circleFeature);
        setSource(new OlSourceVector({}));
      }, 100);
    }
  };

  useEffect(() => {
    if (layerLoaded.current && features && features.length > 0) {
      vectorSource.clear();
      const timeoutLength = mapContext.map?.getView().getAnimating() === true ? 1000 : 0;

      setTimeout(() => {
        features.forEach((feature) => {
          const featureId = feature.getId();
          if (currentAnimationFeatures.current.indexOf(featureId) === -1) {
            const originalStyle = feature.getStyle() as Style;

            if (feature.getGeometry()?.getType() === 'MultiPolygon') {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, featureId];
              requestAnimationFrame(() => animateMultiPolygon(feature as Feature<MultiPolygon>, 1, 0.5, 0.9, originalStyle));
            }
            if (feature.getGeometry()?.getType() === 'Polygon') {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, featureId];
              requestAnimationFrame(() => animatePolygon(feature as Feature<Polygon>, 1, 0.5, 0.9, originalStyle));
            }
            if (feature.getGeometry()?.getType() === 'LineString') {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, featureId];
              requestAnimationFrame(() => animateLineString(feature as Feature<LineString>, 1, 4, 36, originalStyle));
            }
            if (feature.getGeometry()?.getType() === 'Point') {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, featureId];
              requestAnimationFrame(() => animatePoint(feature as Feature<Point>, 1, 5, 18, originalStyle));
            }
          }
        });
      }, timeoutLength);
    }
  }, [features]);

  return null;
};

export default HighlightAnimation;