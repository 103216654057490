/* eslint-disable */
import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";

//Types
import { IRadioControlProps, PickerItem } from "@/@types/controls/controls";
import { PickerItemValue } from "@/@types/models/model";

function RadioControl(props: IRadioControlProps) {
  const [items, setItems] = useState<Array<PickerItem>>([]);

  const { field, onChange, dc, value, validation, formMode, controlMode } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [props]);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    const item = getItem(value);

    if (item !== undefined) {
      onChange(item, field.source);
    } else {
      onChange(null, field.source);
    }
  };

  const getItems = () => {
    if (field && field.hasOwnProperty("subModel") && dc) {
      dc.GetData().then((data) => {
        if (Array.isArray(data)) {
          setItems(data as Array<PickerItem>);
        } else {
          setItems([]);
        }
      });
    } else if (field && field.items) {
      let items: PickerItem[] = [];
      field.items.labels.forEach((label, i) => {
        const v = field.items?.values[i];
        if (v !== undefined && v !== null) {
          items.push({ label: t(label), value: v });
        }
      });
      setItems(items);
    } else {
      setItems([]);
    }
  };

  const getItem = (value: PickerItemValue) => {
    switch (typeof value) {
      case "string":
        let item = items.find((x) => x.value === value)
        if (item === undefined) {
          item = items.find((x) => x.value === parseInt(value, 10));
        }
        return item;
      case "number":
      case "boolean":
        return items.find((x) => x.value === value);
      default:
        return undefined;
    }
  };

  const getDefaultItem = () => {
    // if (field && field.subModel && field.subModel.name && dc) {
    //   defaultValue = dc.defaultItem;
    // }
    if (field && field.items && field.items.hasOwnProperty("default")) {
      const defaultValue = field.items.default;
      if (defaultValue !== undefined) {
        const item = getItem(defaultValue);
        return item;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }

  };

  const getValue = (value: PickerItem | PickerItemValue | null) => {
    switch (typeof value) {
      case "object":
        if (value !== null) {
          return (value as PickerItem).value;
        } else {
          return "";
        }
      case "string":
      case "number":
      case "boolean":
        return value;
      default:
        return "";
    }

  }

  const hasValue = value !== undefined && value !== null;
  const hasProperValue = hasValue && value.hasOwnProperty("value") && value.hasOwnProperty("value");

  const defaultItem = getDefaultItem();
  const hasDefaultvalue = defaultItem !== undefined;
  const isRequired = field && field.validation && field.validation.required ? true : false;
  const hasError = validation && validation.valid === false;
  const isReadOnly = controlMode === "view" || (field && field.readonly);
  const title = field.ttoken ? t(field.ttoken) : t(field.title);

  const displayValue = getValue(value);

  return (
    <FormControl error={hasError}>
      <FormLabel color="primary" required={isRequired}>
        {title}
      </FormLabel>
      <RadioGroup
        row={field.row}
        value={hasProperValue ? displayValue : hasValue ? value : hasDefaultvalue ? defaultItem.value : ""}
        onChange={handleChange}
        aria-label={field.title}
      >
        {items.map((item, i) => (
          <FormControlLabel
            control={<Radio color="primary" key={i} />}
            value={item.value}
            label={t(item.label) as string}
            key={i}
            disabled={isReadOnly}
          />
        ))}
      </RadioGroup>
      <FormHelperText id={field.source + "-helper"}>
        {hasError ? t(validation.msg) : field.tooltip ? field.tooltip : " "}
      </FormHelperText>
    </FormControl>
  );
}

export default RadioControl;
