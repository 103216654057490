/* eslint-disable */
import React, { useContext, MouseEvent } from 'react';

// MUI
import Button from '@mui/material/Button';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { ITableButtonProps, AllowType } from '@/@types/ui/Table';
import { DCRecord } from '@/@types/lib/dataController';

const TableButton: React.FC<ITableButtonProps> = (props) => {
  const userContext = useContext(UserContext);
  const {
    allow = 'always',
    selection,
    onClick,
    needPermission,
    isEnabledRecord,
    ...otherProps
  } = props;

  const isEnabled = function (
    allow: AllowType,
    selection: {[key: string]: DCRecord}
  ): boolean {
    switch (allow) {
      case 'always':
        return true;
      case 'one':
        return Object.keys(selection).length === 1;
      case 'many':
        return Object.keys(selection).length > 0;
      default:
        return false;
    }
  };

  const handleClick = function (evt: MouseEvent<HTMLButtonElement>) {
    onClick(evt, selection);
  };

  const disabled =
    !isEnabled(allow, selection) ||
    (isEnabledRecord ? !isEnabledRecord(selection) : false);

  return needPermission &&
    userContext &&
    !userContext.hasAnyPermission(needPermission) ? null : (
    <Button
      color="primary"
      size="small"
      {...otherProps}
      disabled={disabled}
      onClick={handleClick}
      sx={{
        ml: 1,
        mr: 0,
      }}
    >
      {props.children}
    </Button>
  );
};

export default TableButton;
