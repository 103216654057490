/* eslint-disable */
import React from 'react';
import SignpostIcon from '@mui/icons-material/Signpost';

//Types
import { SvgIconProps } from '@mui/material';

function MapLegendIcon(props: SvgIconProps) {
  return <SignpostIcon {...props} />;
}

export default MapLegendIcon;
