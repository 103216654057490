/* eslint-disable */
import { createContext } from "react";

//Types
import { GroupLayerContextType } from "@/@types/components/Map/Layers/GroupLayer";

const GroupLayerContext = createContext<GroupLayerContextType | null>(null);

GroupLayerContext.displayName = "GroupLayerContext";

export default GroupLayerContext;
