/* eslint-disable */
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import IconButton from "@mui/material/IconButton";

//MUI Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//Types
import { ICardToolbarButton } from "@/@types/ui/Card";

const CardToolbarButtonBack: FunctionComponent<ICardToolbarButton> = (props) => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <IconButton className="nodrag" color="inherit" {...props} onClick={handleClick}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default CardToolbarButtonBack;
