import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import buttonFields from './helpers/buttonFields';

const Licence: IModel = {
  title: 'Licences',
  apiPath: 'system/tenants/{id}/licences',
  forms: {
    insert: {
      fields: [
        'app_module_id',
        'started_on',
        'ends_on',
        'max_users',
        'app_permissions',
      ],
    },
    update: {
      fields: [
        'app_module_id',
        'started_on',
        'ends_on',
        'max_users',
        'app_permissions',
      ],
    },
    insert_user_licence: {
      fields: ['tenant_licence_id', 'ul_app_permissions']
    },
    update_user_licence: {
      fields: [{source: 'tenant_licence_id', readonly: true}, 'ul_app_permissions']
    },
    insert_tenant_licence: {
      fields: [
        'app_module_id',
        'started_on',
        'ends_on',
        'max_users',
        'tl_app_permissions']
    },
    update_tenant_licence: {
      fields: [
        {source: 'app_module_id', readonly: true},
        'started_on',
        'ends_on',
        'max_users',
        'tl_app_permissions']
    }
  },
  listViews: {
    default: {
      fields: ['id'],
    },
    tenant_admin: {
      fields: [
        'id',
        'app_module_name',
        'started_on',
        'ends_on',
        'max_users',
        'app_permissions',
        'edit'
      ]
    },
    system_admin_user: {
      fields: [
        'id',
        'app_module_name',
        'started_on',
        'ends_on',
        'app_permissions',
        'created_by',
        'modified_by',
        'edit'
      ]
    },
    tenant_admin_user: {
      fields: [
        'id',
        'app_module_name',
        'started_on',
        'ends_on',
        'app_permissions_tenants',
        'created_by',
        'modified_by',
        'edit'
      ]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'numeric',
      validation: {
        required: true,
      },
    },
    {
      title: 'app_module_id',
      source: 'app_module_id',
      ttoken: 'admin:licence.app_module_id',
      type: 'picker',
      subModel: {
        apiPath: "system/app_modules?view=picker",
        format: "{label}",
        idattr: "id"
      },
      readonly: false,
      validation: {
        required: true
      }
    },
    {
      title: 'app_module_name',
      source: 'app_module_name',
      ttoken: 'admin:licence.app_module_name',
      type: 'text',
    },
    {
      title: 'app_permissions',
      source: 'app_permissions',
      ttoken: 'admin:licence.app_permissions',
      multi: true,
      type: 'checkbox',
      subModel: {
        apiPath: "system/app_permissions?view=picker",
        format: "{label}",
        idattr: "id"
      },
      readonly: false,
      filter: true
    },
    {
      title: 'app_permissions',
      source: 'app_permissions_tenants',
      ttoken: 'admin:licence.app_permissions',
      multi: true,
      type: 'checkbox',
      subModel: {
        apiPath: "tenant-admin/app_permissions?view=picker",
        format: "{label}",
        idattr: "id"
      },
      readonly: false,
      filter: true
    },
    {
      title: 'max_users',
      source: 'max_users',
      ttoken: 'admin:common.licence_max_users',
      type: 'wholenum',
      validation: {
        required: true,
        minValue: 0,
        maxValue: 100,
      },
    },
    {
      title: 'started_on',
      source: 'started_on',
      ttoken: 'admin:common.licence_started_on',
      type: 'date',
      validation: {
        required: true,
      },
      filter: true
    },
    {
      title: 'ends_on',
      source: 'ends_on',
      ttoken: 'admin:common.licence_ends_on',
      type: 'date',
      validation: {
        required: true,
      },
      filter: true
    },
    {
      title: 'tenant_licence_id',
      source: 'tenant_licence_id',
      ttoken: 'admin:common.tenant_licence_id',
      type: 'picker',
      subModel: {
        apiPath: "system/tenants/{tenant_id}/licences?view=picker",
        format: "{label}",
        idattr: "id"
      },
      readonly: false,
      validation: {
        required: true
      }
    },
    {
      title: 'ul_app_permissions',
      source: 'ul_app_permissions',
      ttoken: 'admin:licence.ul_app_permissions',
      multi: true,
      type: 'checkbox',
      subModel: {
        apiPath: "system/tenants/{tenant_id}/users/{user_id}/licences/{licence_id}/app_permissions?view=picker",
        format: "{label}",
        idattr: "id",
      },
      readonly: false,
      validation: {
        required: true
      }
    },
    {
      title: 'tl_app_permissions',
      source: 'tl_app_permissions',
      ttoken: 'admin:licence.tl_app_permissions',
      multi: true,
      type: 'checkbox',
      subModel: {
        apiPath: "system/app_modules/{module_id}/app_permissions?view=picker",
        format: "{label}",
        idattr: "id"
      },
      readonly: false,
      validation: {
        required: true
      }
    },
    {
      title: 'user_id',
      source: 'user_id',
      ttoken: 'admin:licence.user_id',
      type: 'numeric',
    },
    buttonFields.edit
  ],
};

export default Licence;
