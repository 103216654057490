import * as React from 'react';

import Box from '@mui/material/Box';
import AppPage from '@/ui/AppPage/AppPage';
import Permissions from '@/lib/permissions';
import TranslationAdministrationTable from '@/views/TranslationAdministration/TranslationAdministrationTable';

const TenantsPage = () => (
  <AppPage
    titlett="admin:page.system_administration"
    needPermission={[Permissions.Administration]}
  >
    <Box m={0} style={{ height: '100%' }}>
      <TranslationAdministrationTable />
    </Box>
  </AppPage>
);

export default TenantsPage;
