import * as React from 'react';
import { FunctionComponent, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI Icons
import LoginIcon from '@mui/icons-material/Login';

// MUI
import {
  ThemeProvider as MuiThemeProvider,
  useTheme,
} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

// Custom Components
import { appHeaderTheme } from '@/context/ThemeContext/ThemeProvider';
import AppHeaderMenuButton from '@/ui/Header/AppHeaderMenuButton';
import HeaderMenuButton from '@/ui/Header/HeaderMenuButton';
import ThemeSwitch from '@/ui/Header/ThemeSwitch';
import LanguageSelector from '@/ui/Header/LanguageSelector';
import ProfileMenu from '@/ui/Header/ProfileMenu';
import AccessibilityMenu from '@/ui/Header/AccessibilityMenu';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import HeaderIconButton from '@/ui/Header/HeaderIconButton';
import TenantContext from '@/context/TenantContext/TenantContext';
import UserContext from '@/context/UserContext/UserContext';
import Permissions from '@/lib/permissions';

// Types
export interface IAppHeader {
  modul?: string;
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  hideNavDrawer?: boolean;
}

const AppHeader: FunctionComponent<IAppHeader> = (props) => {
  const tenantContext = useContext(TenantContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modul, sidebarOpen, toggleSidebar, hideNavDrawer = false } = props;
  const theme = useTheme();
  const hidden: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const drawerWidth = 240;

  const isPublic =
    localStorage.getItem('auth_token') === tenantContext?.publicToken;

  const toolbarStyle = {
    '& > button': {
      mx: { sm: 0, md: 1 },
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: 'solid',
    borderColor: 'primary.main',
  };

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: sidebarOpen && !hidden ? drawerWidth : 0,
    width: sidebarOpen && !hidden ? `calc(100% - ${drawerWidth}px)` : '100%',
    transition: sidebarOpen && !hidden
      ? theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
  };

  return (
    <MuiThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          secondary: appHeaderTheme.palette.secondary,
        },
      })}
    >
      <AppBar position="fixed" sx={appBarStyle} color="secondary">
        <Toolbar variant="dense" disableGutters sx={toolbarStyle}>
          
          <Box m={0} sx={{ display: { xs: 'block', md: 'none' } }}>
            <AppHeaderMenuButton showCloseIcon />
            </Box>
            {!hideNavDrawer && !sidebarOpen  ? (
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <HeaderMenuButton toggleSidebar={toggleSidebar} />
              </Box>
            ) : null}
          
          <ToolbarFillContent>
            <Typography
              variant="h6"
              component="h2"
              style={{ color: '#fff', fontSize: '1.15rem' }}
            >
              <NavLink
                to="/"
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                }}
              >
                {hidden
                  ? t('app_title_short')
                  : t('app_title') + (modul ? ` - ${t(`modul.${modul}`)}` : '')}
              </NavLink>
            </Typography>
          </ToolbarFillContent>
          <LanguageSelector />
          <AccessibilityMenu />
          <ThemeSwitch />
          {!isPublic ? (
            <ProfileMenu />
          ) : userContext?.hasAnyPermission([Permissions.Elevate]) ? (
            <HeaderIconButton
              onClick={() => {
                navigate('/login');
              }}
              desc="login"
            >
              <LoginIcon />
            </HeaderIconButton>
          ) : null}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default AppHeader;
