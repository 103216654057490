import { useContext } from 'react';

// Context
import DialogContext from '@/context/DialogContext/DialogContext';

// Custom
import InsertDocumentDialog from '@/views/core/Documents/InsertDocumentDialog';
import useApi from '@/lib/api/useApi';

import { DCFile, DCRecord } from '@/@types/lib/dataController';
import { ClosingDetails } from '@/@types/components/authFormController';

import DataController from '@/lib/DataController';

import modelDocuments from '@/models/documents';
import AttachedDocumentDialog from '@/views/core/Documents/AttachedDocumentDialog';

type HandleCloseFn = (result: ClosingDetails) => void;

export interface IDocuments {
  checkIfHasGid: (records: DCRecord[]) => boolean;
  showDocumentsDialog: (gid: string, onClose?: HandleCloseFn) => void;
  downloadDocument: (gid: string) => void;
  saveDocument: (file: DCFile) => void;
}

// Hook
function useDocuments(): IDocuments {
  const dc = new DataController(modelDocuments);

  const { hideDialog } = useContext(DialogContext);

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshDocuments = async (gid: string): Promise<DCRecord[]> => {
    // setIsLoading(true);
    try {
      const resp = await dc.GetData(`${dc.apiPath}/${gid}`);
      if (resp.success) {
        if (resp.data && !Array.isArray(resp.data) && resp.data.files) {
          return resp.data.files as DCRecord[];
        }
        return [];
      }
      // eslint-disable-next-line no-console
      console.error(
        'Error while fetching comments for gid:',
        gid,
        'response:',
        resp
      );
      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while fetching comments:', error);
      return [];
    } finally {
      // setIsLoading(false);
    }
  };

  const { showDialog } = useContext(DialogContext);

  const showDocumentsDialog = async (gid: string, onClose?: HandleCloseFn) => {
    refreshDocuments(gid).then((documents) => {
      if (documents && documents.length) {
        console.log('opening attached docs dialog');
        showAttachedDocumentsDialog(gid, documents, onClose);
      } else {
        console.log('opening new doc dialog');
        showNewDocumentDialog(gid, onClose);
      }
    });
  };

  const showNewDocumentDialog = (gid: string, onClose?: HandleCloseFn) => {
    showDialog(
      InsertDocumentDialog,
      {
        dc,
        mode: 'insert',
        form: 'insert',
        onClose,
        initialRecord: {
          entity_gid: gid,
        },
        level: 2,
      },
      2
    );
  };

  const onAddNewDocument = async (result: ClosingDetails) => {
    if (!result) return;
    if (typeof result.success === 'boolean' && !result.success) return;

    if (result.dataChanged) {
      hideDialog(2);
    }
  };

  const showAttachedDocumentsDialog = (
    gid: string,
    documents: DCRecord[],
    onClose?: HandleCloseFn
  ) => {
    showDialog(
      AttachedDocumentDialog,
      {
        dc,
        onClose,
        initialRecord: documents,
        gid,
        showNewDocumentDialog,
        onAddNewDocument,
      },
      1
    );
  };

  // ###

  const checkIfHasGid = (records: DCRecord[]) =>
    !!(
      records &&
      records.length > 0 &&
      Object.hasOwn(records[0], 'gid') === true
    );

  const downloadDocument = (gid: string) => {
    // Fetch from db
    const apiInstance = useApi();
    apiInstance.downloadWithMetadata('core/documents', gid).then((resp) => {
      if (resp.success && resp.data) {
        const file = resp.data;

        if (!file.content) {
          console.error('File has no content');
        } else {
          saveDocument(file);
        }
      }
    });
  };

  const saveDocument = (file: DCFile) => {
    // Download to pc
    if (file.content) {
      const byteCharacters = atob(file.content);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(undefined)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: file.type });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      link.click();
    }
  };

  return {
    checkIfHasGid,
    showDocumentsDialog,
    downloadDocument,
    saveDocument,
  };
}

export default useDocuments;
