const Permissions = {
  Core: {
    id: 'core',
    code: 100,
    display: 'permissions.core',
    shortDisplay: 'COR',
  },
  Public: {
    id: 'public',
    code: 101,
    display: 'permissions.public',
    shortDisplay: 'PUB',
  },
  Elevate: {
    id: 'elevate',
    code: 105,
    display: 'permissions.elevate',
    shortDisplay: 'ELE',
  },
  Private: {
    id: 'private',
    code: 110,
    display: 'permissions.private',
    shortDisplay: 'PRI',
  },
  View_DKP: {
    id: 'DKP view',
    code: 121,
    display: 'permissions.view_dkp',
    shortDisplay: 'VDKP',
  },
  View_DKP2: { // DKP nova izmjera - korištena u Ogulinu
    id: 'DKP2 view', 
    code: 122,
    display: 'permissions.view_dkp2',
    shortDisplay: 'VDKP2',
  },
  View_Adrese: {
    id: 'Adrese view',
    code: 123,
    display: 'permissions.view_adrese',
    shortDisplay: 'VADR',
  },
  TenantUserAdministration: {
    id: 'Tenant user administration',
    code: 140,
    display: 'permissions.tenant-user_administration',
    shortDisplay: 'TUA',
  },
  ThreeDView: {
    id: 'threed',
    code: 150,
    display: 'permissions.3d',
    shortDisplay: '3D',
  },
  Ena: {
    id: 'ena',
    code: 200,
    display: 'permissions.ena',
    shortDisplay: 'ENA',
  },
  EnaKuceZaOdmor: {
    id: 'ena-kzo',
    code: 202,
    display: 'permissions.ena_kzo',
    shortDisplay: 'KZO',
  },
  Rasvjeta: {
    id: 'rasvjeta',
    code: 300,
    display: 'permissions.rasvjeta',
    shortDisplay: 'RAS',
  },
  NerazvrstaneCeste: {
    id: 'nerazvrstane-ceste',
    code: 350,
    display: 'permissions.nerazvrstane_ceste',
    shortDisplay: 'NC',
  },
  Groblja: {
    id: 'groblja',
    code: 400,
    display: 'permissions.groblja',
    shortDisplay: 'GRO',
  },
  GrobljaEdit: {
    id: 'groblja-edit',
    code: 401,
    display: 'permissions.groblja_edit',
    shortDisplay: 'EGRO',
  },
  GrobljaPhotodoc: {
    id: 'groblja-photodoc',
    code: 402,
    display: 'permissions.groblja_photodoc',
    shortDisplay: 'PGRO',
  },
  Parking: {
    id: 'parking',
    code: 500,
    display: 'permissions.parking',
    shortDisplay: 'PAR',
  },
  Test: {
    id: 'test',
    code: 800,
    display: 'permissions.test',
    shortDisplay: 'TST',
  },
  Administration: {
    id: 'administration',
    code: 990,
    display: 'permissions.administration',
    shortDisplay: 'ADM',
  },
};

export default Permissions;
